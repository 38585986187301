import React from "react";
import { useTotalPrice } from "../../../hooks";
import { formatPrice } from "../../../utils";

import PyamentPageDetailsCurrenciesChart from "./PyamentPageDetailsCurrenciesChart";

export default function PaymentPageStatistics({ data }) {
  const totalRevenue = useTotalPrice(data?.revenues, "totalAmount");

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[20px] h-min">
        <div className="bg-[#f6f6f6] dark:bg-darkCardColor py-3 px-3 rounded">
          <span className="block text-sm text-grayColor dark:text-darkGrayColor">
            Total sales
          </span>
          <span className="block text-lg font-[600] mt-1">
            {data?.totalSales || 0}
          </span>
        </div>
        <div className="bg-[#f6f6f6] dark:bg-darkCardColor py-3 px-3 rounded">
          <span className="block text-sm text-grayColor dark:text-darkGrayColor">
            Revenue
          </span>
          <span className="block text-lg font-[600] mt-1">
            ${formatPrice(totalRevenue) || 0}
          </span>
        </div>
      </div>

      <PyamentPageDetailsCurrenciesChart revenues={data?.revenues} />
    </div>
  );
}
