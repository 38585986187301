import React, { useRef } from "react";
import { BiLockAlt, BiLogOutCircle, BiUser } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useHandleClickOutside } from "../../hooks";
import { logoutUser } from "../../redux/slices/userSlice";

export default function UserDropdown({ setIsUserDropdownOpen }) {
    const { user, isLoggedIn } = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const wrapperRef = useRef();

    useHandleClickOutside(wrapperRef, () => setIsUserDropdownOpen(false));

    return (
        <div
            ref={wrapperRef}
            className="bg-white dark:bg-darkCardColor rounded absolute right-0 top-[54px] min-w-[160px] shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] z-10"
        >
            {user?.isMobileNumberVerified && (
                <>
                    <Link
                        to="/settings/profile"
                        className="flex items-center gap-[10px] text-[13px] py-[6px] px-5 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor "
                        onClick={() => setIsUserDropdownOpen(false)}
                    >
                        <BiUser />
                        Profile
                    </Link>
                    {user?.type === "personal" && (
                        <Link
                            to="/upgrade/merchant"
                            className="flex items-center gap-[10px] text-[13px] py-[6px] px-5 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor "
                            onClick={() => setIsUserDropdownOpen(false)}
                        >
                            <BiLockAlt />
                            Merchant
                        </Link>
                    )}
                    <Link
                        to="/settings/profile"
                        className="flex items-center gap-[10px] text-[13px] py-[6px] px-5 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor "
                        onClick={() => setIsUserDropdownOpen(false)}
                    >
                        <FiSettings />
                        Settings
                    </Link>
                </>
            )}

            {isLoggedIn && (
                <div className="border-t dark:border-darkBorderColor py-2">
                    <span className="block text-sm px-5">
                        @{user?.username}
                    </span>
                    {user?.mobileNumber && (
                        <span className="block text-[13px] px-5 text-grayColor dark:text-darkGrayColor mt-[3px]">
                            {user?.phonecode} {user?.mobileNumber}
                        </span>
                    )}
                </div>
            )}
            <span
                className="text-[#f46a6a] border-t border-borderColor dark:border-darkBorderColor flex items-center gap-[10px] cursor-pointer text-[13px] py-[6px] px-5 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                onClick={() => {
                    setIsUserDropdownOpen(false);
                    dispatch(logoutUser());
                }}
            >
                <BiLogOutCircle />
                Logout
            </span>
        </div>
    );
}
