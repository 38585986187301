import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function RegisterRoute({ children, ...rest }) {
    const { isLoggedIn, user } = useSelector((state) => state.user);

    if (isLoggedIn) {
        if (user.type === "merchant" && !user.isMerchantInfoProvided) {
            return <Navigate replace to="/auth/merchant/details" />;
        } else if (!user.isMobileNumberVerified) {
            return <Navigate replace to="/auth/mobile-number" />;
        } else {
            return <Navigate replace to="/" />;
        }
    } else {
        return children;
    }
}

function MobileNumberRoute({ children, ...rest }) {
    const { isLoggedIn, user } = useSelector((state) => state.user);

    if (!isLoggedIn) {
        return <Navigate replace to="/auth/login" />;
    } else {
        if (user?.type === "merchant" && !user?.isMerchantInfoProvided) {
            return <Navigate replace to="/auth/merchant/details" />;
        } else if (user?.isMobileNumberVerified) {
            return <Navigate replace to="/" />;
        }
        return children;
    }
}

function EmailVerifyRoute({ children, ...rest }) {
    const { isLoggedIn, user } = useSelector((state) => state.user);

    if (!isLoggedIn) {
        return <Navigate replace to="/auth/login" />;
    } else {
        if (user?.isEmailVerified) {
            return <Navigate replace to="/" />;
        }
        return children;
    }
}

function MerchantDetailsRoute({ children, ...rest }) {
    const { isLoggedIn, user } = useSelector((state) => state.user);

    if (!isLoggedIn) {
        return <Navigate replace to="/auth/login" />;
    } else {
        if (user?.isMerchantInfoProvided || user?.type !== "merchant") {
            return <Navigate replace to="/" />;
        }
        return children;
    }
}

export {
    RegisterRoute,
    EmailVerifyRoute,
    MerchantDetailsRoute,
    MobileNumberRoute,
};
