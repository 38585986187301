import React, { useState } from "react";
import { useSelector } from "react-redux";

import axios from "../../axios";
import { BtnLoader, OtpPinInput } from "../../components";
import { toasts } from "../../utils";

export default function PinNumberSettingPage() {
    const [section, setSection] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [pin, setPin] = useState({
        pin1: "",
        pin2: "",
        pin3: "",
        pin4: "",
        pin5: "",
    });
    const [otp, setOtp] = useState({
        pin1: "",
        pin2: "",
        pin3: "",
        pin4: "",
        pin5: "",
    });

    const { jwtToken } = useSelector((state) => state.user);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            setIsLoading(true);
            setError("");

            if (section === 1) {
                await axios.post(
                    "/otp/generate-otp/mobile",
                    {},
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                setSection(2);
                setIsLoading(false);
                return;
            }

            if (section === 2) {
                let myPin = `${pin.pin1}${pin.pin2}${pin.pin3}${pin.pin4}${pin.pin5}`;
                let myOtp = `${otp.pin1}${otp.pin2}${otp.pin3}${otp.pin4}${otp.pin5}`;
                await axios.post(
                    "/pin/create",
                    {
                        pinNumber: myPin,
                        otpNumber: myOtp,
                    },
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                toasts.showSuccess("PIN Number generated successfully");

                setSection(1);
                setPin({
                    pin1: "",
                    pin2: "",
                    pin3: "",
                    pin4: "",
                    pin5: "",
                });
                setOtp({
                    pin1: "",
                    pin2: "",
                    pin3: "",
                    pin4: "",
                    pin5: "",
                });
                setIsLoading(false);
            }
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
            console.log(err);
        }
    };

    return (
        <div>
            <h1 className="text-lg font-[600] mb-3">Generate PIN Number</h1>
            <span className="block text-[14px] mb-4 leading-[28px] text-grayColor dark:text-darkGrayColor">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Suscipit facilis quaerat veritatis eum labore aliquam corporis?
                Deleniti nihil, odit, ipsa sapiente quae sequi quia recusandae,
                qui ab quo incidunt in?
            </span>

            <form onSubmit={handleSubmit}>
                <div className="mt-5 max-w-[380px]">
                    <div>
                        <label htmlFor="">New PIN number</label>
                        <OtpPinInput pin={pin} setPin={setPin} />
                    </div>
                    <div
                        className={
                            "mt-4 " + (section === 2 ? "block" : "hidden")
                        }
                    >
                        <label htmlFor="">OTP</label>
                        <OtpPinInput pin={otp} setPin={setOtp} />
                        <span className="text-sm text-grayColor block mt-[7px]">
                            We have sent you an OTP to your registered mobile
                            number.
                        </span>
                    </div>
                    <div className="mt-4">
                        {error && (
                            <span className="text-sm text-red-500 leading-[28px]">
                                {error}
                            </span>
                        )}
                        <button
                            className="block ml-auto w-[100px] hover:bg-btnHoverColor disabled:opacity-80 disabled:hover:bg-primaryColor"
                            disabled={
                                isLoading ||
                                !pin.pin1 ||
                                !pin.pin2 ||
                                !pin.pin3 ||
                                !pin.pin4 ||
                                !pin.pin5 ||
                                (section === 2 &&
                                    (!otp.pin1 ||
                                        !otp.pin2 ||
                                        !otp.pin3 ||
                                        !otp.pin4 ||
                                        !otp.pin5))
                            }
                        >
                            {isLoading ? <BtnLoader /> : "Submit"}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
