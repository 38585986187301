import React from "react";
import { MdDelete } from "react-icons/md";
import axios from "../../../axios";
import { useSelector } from "react-redux";

function UserListTable({ users, fetchUsers }) {
  return (
    <div>
      <div className="overflow-x-auto">
        <table className="w-[100%]">
          <thead className="text-left bg-[#f6f6f6] dark:bg-darkCardColor">
            <tr className="text-[13px]">
              <th className="p-[12px] font-[600]">No</th>
              <th className="p-[12px] font-[600]">Email Address</th>
              <th className="p-[12px] font-[600]">Name</th>
              <th className="p-[12px] font-[600]">Country</th>
              <th className="p-[12px] font-[600]">Account Type</th>
              <th className="p-[12px] font-[600]">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((item, index) => {
              return (
                <tr
                  className="border-b  dark:border-darkBorderColor text-[13px] hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                  key={index}
                >
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {index + 1}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.email}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.name}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.country?.countryName}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.type}
                  </td>
                  <td
                    className="px-[16px] text-xl text-red-500 cursor-pointer py-[19px] max-w-max min-w-[100px]"
                    onClick={() => {
                      //   handleDeleteAdmin(item?._id);
                    }}
                  >
                    <MdDelete />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserListTable;
