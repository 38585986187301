import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";

const fetchPayments = createAsyncThunk(
    "/user/fetchPayments",
    async (args, { getState }) => {
        const { jwtToken } = getState().user;
        const { filters, sort, skip } = getState().payments;
        const response = await axios.get(
            `/payments/${args?.id}?currency=${filters.currency}&date=${filters.date}&sort=${sort}&transactionId=${filters.transactionId}&emailOrUsername=${filters.emailOrUsername}&skip=${skip}`,
            {
                headers: { Authorization: `Bearer ${jwtToken}` },
            }
        );
        return response.data;
    }
);

const initialState = {
    payments: [],
    totalPayments: 0,
    isLoading: true,
    paymentError: false,
    filters: {
        transactionId: "",
        currency: "all",
        date: "all",
        emailOrUsername: "",
    },
    sort: "default",
    skip: 0,
    limit: 10,
};

export const paymentsSlice = createSlice({
    name: "payments",
    initialState,
    reducers: {
        updateIsPaymentsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updatePaymentsFilters: (state, action) => {
            state.filters[action.payload?.name] = action.payload?.value;
            state.skip = 0;
        },
        updatePaymentsSort: (state, action) => {
            state.sort = action.payload;
            state.skip = 0;
        },
        clearPaymentsFilters: (state, action) => {
            state.filters = {
                transactionId: "",
                currency: "all",
                date: "all",
                emailOrUsername: "",
            };
            state.sort = "default";
            state.limit = 10;
            state.skip = 0;
        },
        updatePaymentsSkip: (state, action) => {
            state.skip = action.payload;
        },
        incOrDecPaymentsSkip: (state, action) => {
            state.skip += action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPayments.fulfilled, (state, action) => {
            state.payments = action.payload?.payments;
            state.totalPayments = action.payload?.totalPayments;
            state.isLoading = false;
        });
        builder.addCase(fetchPayments.rejected, (state, action) => {
            state.paymentError = true;
            state.isLoading = false;
        });
    },
});

export { fetchPayments };

export const {
    clearPaymentsFilters,
    incOrDecPaymentsSkip,
    updateIsPaymentsLoading,
    updatePaymentsFilters,
    updatePaymentsSkip,
    updatePaymentsSort,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
