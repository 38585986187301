import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function LoggedInRoute({ children, ...rest }) {
  const { isLoggedIn, user } = useSelector((state) => state.user);

  if (!isLoggedIn) {
    return <Navigate replace to="/auth/login" />;
  } else if (user.type === "merchant" && !user.isMerchantInfoProvided) {
    return <Navigate replace to="/auth/merchant/details" />;
  } else if (!user.isMobileNumberVerified) {
    return <Navigate replace to="/auth/mobile-number" />;
  } else {
    return children;
  }
}

function AdminLoggedInRoute({ children, ...rest }) {
  const { isLoggedIn } = useSelector((state) => state.admin);
  if (!isLoggedIn) {
    return <Navigate to={"/admin/auth/login"} />;
  } else {
    return children;
  }
}

function UserOnlyRoute({ children, ...rest }) {
  const { user } = useSelector((state) => state.user);

  if (user.type !== "personal") {
    return <Navigate replace to="/" />;
  } else {
    return children;
  }
}

function MerchantOnlyRoute({ children, ...rest }) {
  const { user } = useSelector((state) => state.user);

  if (user.type !== "merchant") {
    return <Navigate replace to="/" />;
  } else {
    return children;
  }
}

export { LoggedInRoute, MerchantOnlyRoute, UserOnlyRoute, AdminLoggedInRoute };
