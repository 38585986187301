import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  kycDetails: {
    firstName: "",
    lastName: "",
    country: "",
    // isocode: "",
    date_of_birth: "",
    pan_number: "",
    gender: "",
    address: "",
    city: "",
    pincode: 0,
    VatOrTin: "",
    phone: "",
    CSTNo: "",
    service_tax_no: "",
    company_UIN: "",
    declaration: "",
  },
};

export const kycSlice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    setKycData: (state, { payload }) => {
      state.kycDetails[payload.name] = payload.value;
    },
  },
});

export const { setKycData } = kycSlice.actions;

export default kycSlice.reducer;
