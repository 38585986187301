import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AccountTypeSelectPage() {
    const [selectedType, setSelectedType] = useState("personal");

    const navigate = useNavigate();

    const handleClick = () => {
        if (selectedType === "merchant") {
            navigate("/auth/register/merchant");
        } else {
            navigate("/auth/register");
        }
    };

    return (
        <div className="flex flex-col items-center justify-center mx-auto min-h-[calc(var(--doc-height)-4.2em)] sm:min-h-[calc(100vh-5em)] max-w-[450px] px-5">
            <div className="w-full">
                <h1 className="font-bold text-lg sm:text-xl mb-10 text-center">
                    Select Account Type
                </h1>
            </div>

            <div className="w-full">
                <label
                    className={
                        "flex items-start gap-[15px] rounded p-4 cursor-pointer " +
                        (selectedType === "personal"
                            ? "bg-[#f6f6f6] dark:bg-darkCardColor"
                            : "bg-transparent")
                    }
                    htmlFor="personal-account"
                >
                    <input
                        type="radio"
                        name="account-type"
                        id="personal-account"
                        className="w-[18px] h-[18px] min-w-[18px] min-h-[18px] mt-[4px]"
                        onChange={(e) =>
                            setSelectedType(
                                e.target.checked ? "personal" : "merchant"
                            )
                        }
                        defaultChecked={selectedType === "personal"}
                    />
                    <div>
                        <h1
                            className={
                                "font-[600] text-base sm:text-[17px] " +
                                (selectedType === "personal"
                                    ? "text-primaryColor"
                                    : "")
                            }
                        >
                            Personal Account
                        </h1>
                        <ul className="mt-2 text-sm leading-[24px] text-grayColor dark:text-darkGrayColor">
                            <li className="">
                                Lorem ipsum dolor sit amet. Lorem, ipsum dolor
                                sit amet consectetur dolorem.
                            </li>
                            <li className="mt-2 ">
                                Lorem ipsum dolor sit amet.
                            </li>
                        </ul>
                    </div>
                </label>
                <label
                    className={
                        "flex items-start gap-[15px] mt-2 rounded p-4 cursor-pointer " +
                        (selectedType === "merchant"
                            ? "bg-[#f6f6f6] dark:bg-darkCardColor"
                            : "bg-transparent")
                    }
                    htmlFor="merchant-account"
                >
                    <input
                        type="radio"
                        name="account-type"
                        id="merchant-account"
                        className="w-[18px] h-[18px] min-w-[18px] min-h-[18px] mt-[4px]"
                        onChange={(e) =>
                            setSelectedType(
                                e.target.checked ? "merchant" : "personal"
                            )
                        }
                        defaultChecked={selectedType === "merchant"}
                    />
                    <div>
                        <h1
                            className={
                                "font-[600] text-base sm:text-[17px] " +
                                (selectedType === "merchant"
                                    ? "text-primaryColor"
                                    : "")
                            }
                        >
                            Merchant Account
                        </h1>
                        <ul className="mt-2 text-sm leading-[24px] text-grayColor dark:text-darkGrayColor">
                            <li className="">
                                Lorem ipsum dolor sit amet. Lorem, ipsum
                            </li>
                            <li className="mt-2 ">
                                Lorem ipsum dolor sit amet.
                            </li>
                        </ul>
                    </div>
                </label>

                <button
                    className="mt-10 w-[100%] hover:bg-btnHoverColor"
                    onClick={handleClick}
                >
                    Next
                </button>
            </div>
        </div>
    );
}
