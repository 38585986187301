import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import { useSelector } from "react-redux";
import PresalePageDetailInfo from "../../features/PresalePage/components/PresalePageDetailInfo";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";

import { useCallback } from "react";
import PresalPageDetailBasicInfo from "../../features/PresalePage/components/PresalePageDetailBasicInfo";
import PresalePagePaymentTable from "../../features/PresalePage/components/PresalePagePaymentTable";
import PresaleEditAndShare from "../../features/PresalePage/components/PresaleEditAndShare";

function PresalePageSingleView() {
  const [error, setError] = useState("");
  const [isShowMore, setIsShowMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [presale, setPresale] = useState({});

  const params = useParams();
  const { jwtToken } = useSelector((state) => state.user);

  const fetchSingleDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/presale/single/${params.id}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setPresale(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }, [params.id, jwtToken]);

  useEffect(() => {
    fetchSingleDetails();
  }, []);

  return (
    <div>
      <div className="py-6">
        {isLoading ? (
          <div className="flex items-center justify-center h-[200px]">
            <div className="w-[30px] h-[30px] border-4 border-primaryColor border-r-transparent rounded-full animate-spin "></div>
          </div>
        ) : error ? (
          <div className="flex flex-col items-center justify-center h-[200px]">
            <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
              <BiSearch />
            </div>
            <span className="block text-xl font-[600] mt-3">
              No Result found
            </span>
            <span className="block text-sm mt-2 text-grayColor dark:text-darkGrayColor">
              Invalid payment page reference no!!.
            </span>
          </div>
        ) : (
          <>
            <div
              className={
                "relative grid grid-cols-1 lg:grid-cols-2 gap-[2em] mb-[2em]  " +
                (isShowMore ? "h-auto" : "h-[370px] overflow-hidden")
              }
            >
              <PresalPageDetailBasicInfo
                presale={presale}
                setPresale={setPresale}
              />
              <div>
                <PresaleEditAndShare
                  presale={presale}
                  fetchSingleDetails={fetchSingleDetails}
                />
                <PresalePageDetailInfo
                  presale={presale}
                  setPresale={setPresale}
                />
              </div>

              <div className="absolute left-0 bottom-0 h-[70px] w-[100%] bg-gradient-to-b from-transparent to-[#fff] dark:to-[#1a1d21]">
                <div className="flex justify-center h-full items-end">
                  <button
                    className="h-auto py-[5px] flex items-center gap-[8px] hover:bg-btnHoverColor"
                    onClick={() => setIsShowMore(!isShowMore)}
                  >
                    {isShowMore ? (
                      <>
                        Show Less <FaChevronUp />
                      </>
                    ) : (
                      <>
                        Show more <FaChevronDown />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="border-t dark:border-darkBorderColor">
              <PresalePagePaymentTable />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PresalePageSingleView;
