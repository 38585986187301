import React from "react";
import { FaLightbulb } from "react-icons/fa";

export default function PaymentModal({
    setTransactionError,
    setTransactionSuccess,
    reciever,
    data,
}) {
    // const [isLoading, setIsLoading] = useState(false);
    // const { isPaymentModalOpen } = useSelector((state) => state.layout);
    // const { jwtToken } = useSelector((state) => state.user);
    // const dispatch = useDispatch();

    // const doTransfer = async () => {
    //     try {
    //         setIsLoading(true);
    //         const response = await axios.post(
    //             "/transfer",
    //             {
    //                 amount: data?.amount,
    //                 recipientEmail: reciever?.email,
    //                 recipientUsername: reciever?.username,
    //                 currencyId: data?.currencyId,
    //             },
    //             {
    //                 headers: { Authorization: `Bearer ${jwtToken}` },
    //             }
    //         );
    //         setTransactionSuccess(true);
    //     } catch (err) {
    //         setTransactionError(
    //             err?.response?.data?.error || "Something went wrong, Try again"
    //         );
    //         console.log(err);
    //     } finally {
    //         dispatch(setIsPaymentModalOpen(false));
    //     }
    // };

    return (
        <div className="fixed inset-0 flex bg-[#0005] backdrop-bluer-sm items-center justify-center z-20 transition-all p-[15px]">
            <div className="relative flex flex-col bg-[#fff] transition-all w-[100%] max-w-[350px] h-[570px] overflow-y-auto shadow-sm">
                <div className="p-4 border-b flex items-center gap-[10px] justify-between bg-[#f6f6f6]">
                    <div className="w-[35px] h-[35px]">
                        <img
                            src="https://app.hami.live/static/media/hamilogo.b98b74641e3caeca8a7d591025e520a8.svg"
                            alt=""
                            className="w-[100%] h-[100%] object-cover"
                        />
                    </div>
                    <div className="flex items-center gap-[20px]">
                        <button className="bg-transparent text-[#222] text-lg p-0 ">
                            <FaLightbulb />
                        </button>
                        <div className="w-[35px] h-[35px] rounded-full overflow-hidden">
                            <img
                                src="https://themesbrand.com/skote-symfony/layouts/assets/images/users/avatar-1.jpg"
                                alt=""
                                className="w-[100%] h-[100%] objct-cover"
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="flex-1 p-4">
                    <div>
                        <span className="font-[600] text-[15px] block mb-2">
                            Sending To:
                        </span>
                        <div className="flex items-center gap-[10px] mb-5">
                            <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                                <img
                                    src="https://themesbrand.com/skote-symfony/layouts/assets/images/users/avatar-1.jpg"
                                    alt=""
                                    className="w-[100%] h-[100%] objct-cover"
                                />
                            </div>
                            <div>
                                <span className="block font-medium text-[#222]">
                                    @{reciever?.username}
                                </span>
                                <span className="block text-grayColor text-sm">
                                    {reciever?.email}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                            Asset
                        </span>
                        <div className="flex items-center gap-[10px] text-[#222]">
                            BNB
                            <img
                                src="https://cryptologos.cc/logos/bnb-bnb-logo.png"
                                alt=""
                                className="w-[20px] h-[20px] rounded-full object-cover"
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                            Amount
                        </span>
                        <div className="flex items-center gap-[1.5em]">
                            <span className="block text-lg font-[600] text-[#222]">
                                {data?.amount || 0} BNB
                            </span>
                            <span className="text-sm text-grayColor font-medium">
                                &asymp; $24.01 USD
                            </span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                            Gas Fee
                        </span>
                        <span className="font-medium block text-[15px] text-[#222]">
                            0.02
                        </span>
                        <span className="text-[13px] text-blue-500 block mt-1">
                            This is likely in less than 30s
                        </span>
                    </div>
                    <div className="mt-3">
                        <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                            Total
                        </span>
                        <span className="text-xl font-[600] text-[#222]">
                            {data?.amount || 0} BNB
                        </span>
                    </div>
                </div>
                <div className="px-4 mb-3">
                    <div className="rounded text-[12px] text-grayColor leading-[20px]">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Vel expedita quia odio,{" "}
                        <span className="font-medium text-primaryColor">
                            Terms
                        </span>{" "}
                        and{" "}
                        <span className="font-medium text-primaryColor">
                            Privacy
                        </span>
                    </div>
                </div>
                <div className="px-4 pb-4 flex items-center gap-[10px]">
                    <button
                        className="w-[100%] bg-transparent border border-[#ced4da] text-textColor"
                        onClick={() => {
                            dispatch(setIsPaymentModalOpen(false));
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="w-[100%] hover:bg-btnHoverColor"
                        onClick={() => doTransfer()}
                    >
                        Confirm
                    </button>
                </div> */}

                <div></div>
            </div>
        </div>
    );
}
