import React, { useCallback, useEffect, useState } from "react";
import AdminListTable from "../../../features/admin/admins/AdminListTable";
import axios from "../../../axios";
import { useSelector } from "react-redux";
import { Pagination } from "../../../components";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import AdminPageFilter from "../../../features/admin/admins/AdminPageFilter";
import AddModal from "../../../features/admin/admins/AddModal";

function AdminPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    totalAdmnis: 0,
    email: "",
    role: "",
  });

  const [adminAddModal, setAdminAddModal] = useState(false);

  const { jwtToken } = useSelector((state) => state.admin);

  const fetchAdmins = async () => {
    try {
      const res = await axios.get(
        `/admin/auth/all?email=${filters?.email}&role=${filters.role}&skip=${filters.skip}&limit=${filters.limit}`,
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      setAdmins(res.data);
      setFilters({
        ...filters,
        totalAdmnis: res.data.length,
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  return (
    <div className="py-5 sm:py-6">
      <div className="mb-6">
        <div className="flex items-ceenter gap-[10px] justify-between">
          <h3 className="text-base font-[600]">Admins Pages</h3>
          <div className="flex items-center gap-[15px]">
            <button
              className="bg-in hover:bg-btnHoverColor"
              onClick={() => {
                setAdminAddModal(true);
              }}
            >
              + Add Admin
            </button>
          </div>
        </div>
        <div
          className={
            "sm:mt-3 z-20 sm:z-1 bg-white sm:bg-[#f6f6f6] dark:bg-darkCardColor sm:rounded "
          }
        >
          <AdminPageFilter
            setFilters={setFilters}
            filters={filters}
            fetchAdmins={fetchAdmins}
          />
        </div>
      </div>

      <div>
        {isLoading ? (
          <div className="flex items-center justify-center h-[200px]">
            <div className="w-[30px] h-[30px] border-4 border-primaryColor border-r-transparent rounded-full animate-spin "></div>
          </div>
        ) : !admins || admins?.length < 1 ? (
          <div className="flex flex-col items-center justify-center h-[200px]">
            <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
              <BiSearch />
            </div>
            <span className="block text-xl font-[600] mt-3">
              No Result found
            </span>
            <span className="block text-sm mt-2 text-grayColor">
              There are no Payment Pages created yet!!.
            </span>
          </div>
        ) : (
          <div>
            <AdminListTable admins={admins} fetchAdmins={fetchAdmins} />

            <Pagination
              limit={filters.limit}
              skip={filters.skip}
              total={filters.totalAdmnis}
              incOrDecSkip={(number) =>
                setFilters({
                  ...filters,
                  skip: number,
                })
              }
              updateSkip={(skip) =>
                setFilters({
                  ...filters,
                  skip: skip,
                })
              }
            />
          </div>
        )}
      </div>
      <div>
        {adminAddModal && (
          <AddModal
            setAdminAddModal={setAdminAddModal}
            fetchAdmins={fetchAdmins}
          />
        )}
      </div>
    </div>
  );
}

export default AdminPage;
