import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { avatarImg } from "../../../assets/images";
import { formatDate } from "../../../utils";

export default function SinglePaymentLinkDetails({ paymentLink }) {
  const [isPaymentDetailsDropdownOpen, setIsPaymentDetailsDropdownOpen] =
    useState(false);

  return (
    <div className="flex flex-col gap-[2em]">
      <div className="relative flex-1">
        <div
          className="block md:hidden sticky top-[4.2em] left-0 w-full"
          onClick={() =>
            setIsPaymentDetailsDropdownOpen(!isPaymentDetailsDropdownOpen)
          }
        >
          <div className="bg-[#f6f6f6] dark:bg-darkCardHoverColor p-5 flex items-center justify-between">
            <div className="flex items-center gap-[10px]">
              <div className="w-[45px] h-[45px] rounded-full overflow-hidden">
                <img
                  src={
                    paymentLink?.creator?.avatar
                      ? process.env.REACT_APP_SERVER_URL +
                        paymentLink?.creator?.avatar
                      : avatarImg
                  }
                  alt=""
                  className="w-[100%] h-[100%] object-cover"
                />
              </div>
              <div>
                <span className="block font-medium">
                  {paymentLink?.creator?.name}
                </span>
                <span className="block text-grayColor dark:text-darkGrayColor text-sm">
                  @{paymentLink?.creator?.username}
                </span>
              </div>
            </div>
            <div>
              <FiChevronDown />
            </div>
          </div>
        </div>

        <div
          className={
            "absolute md:relative top-[100%] md:top-auto p-5 md:p-0 left-0 md:left-auto w-[100%] z-10 bg-[#fff] dark:bg-darkCardColor md:bg-transparent shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] md:shadow-none " +
            (isPaymentDetailsDropdownOpen
              ? "h-auto block"
              : "h-0 hidden md:h-auto md:block")
          }
        >
          <h3 className="font-[600] text-[17px] mb-5">
            Payment Requested From
          </h3>
          <div className="flex items-center gap-[10px] mb-5">
            <div className="w-[45px] h-[45px] rounded-full overflow-hidden">
              <img
                src={
                  paymentLink?.creator?.avatar
                    ? process.env.REACT_APP_SERVER_URL +
                      paymentLink?.creator?.avatar
                    : avatarImg
                }
                alt=""
                className="w-[100%] h-[100%] object-cover"
              />
            </div>
            <div>
              <span className="block font-medium">
                {paymentLink?.creator?.name}
              </span>
              <span className="block text-grayColor dark:text-darkGrayColor text-sm">
                @{paymentLink?.creator?.username}
              </span>
            </div>
          </div>
          <div>
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Amount
            </span>
            <span className="block text-xl font-bold">
              {paymentLink?.amount} {paymentLink?.currency?.symbol}
            </span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Payment For
            </span>
            <span className="text-[15px] font-medium">
              {paymentLink?.paymentFor}
            </span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Accepted Currencies
            </span>
            <div className="flex flex-wrap items-center gap-[10px] md:gap-[20px] mt-[7px]">
              {paymentLink?.acceptedCurrencies?.map((currency, index) => {
                return (
                  <div
                    key={index}
                    className="py-[5px] rounded px-[5px] md:px-0 bg-[#f6f6f6] dark:bg-darkCardHoverColor md:bg-transparent dark:md:bg-transparent flex items-center gap-[6px]"
                  >
                    <img
                      src={process.env.REACT_APP_SERVER_URL + currency?.logo}
                      alt=""
                      className="w-[20px] h-[20px] rounded-full object-cover"
                    />
                    <span>{currency?.symbol}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Payment Link id
            </span>
            <span className="text-[15px] font-medium">{paymentLink.id}</span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Expiry Date
            </span>
            <span className="text-[15px] font-medium">
              {paymentLink?.expiryDate
                ? formatDate(paymentLink?.expiryDate)
                : "No Expiry"}
            </span>
          </div>
        </div>
      </div>

      <div className="hidden md:flex items-center gap-[10px] text-[12px]">
        <span>
          Powered by{" "}
          <span className="font-[600] text-sm">
            Simple
            <span className="text-primaryColor">pay</span>
          </span>
        </span>
        <span className="text-grayColor">|</span>
        <span>Terms</span>
        <span>Privacy</span>
      </div>
    </div>
  );
}
