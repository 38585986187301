import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";

const fetchPaymentLinks = createAsyncThunk(
    "/user/fetchPaymentLinks",
    async (args, { getState }) => {
        const { jwtToken, accountId } = getState().user;
        const { filters, sort, limit, skip } = getState().paymentLinks;
        const response = await axios.get(
            `/payment-links/user/all?accountId=${accountId}&limit=${limit}&skip=${skip}&currency=${filters.currency}&id=${filters.id}&date=${filters.date}&status=${filters.status}&sort=${sort}`,
            {
                headers: { Authorization: `Bearer ${jwtToken}` },
            }
        );
        console.log(response.data);
        return response.data;
    }
);

const initialState = {
    paymentLinks: [],
    isLoading: true,
    totalPaymentLinks: 0,
    filters: {
        id: "",
        currency: "all",
        date: "all",
        status: "all",
    },
    sort: "default",
    skip: 0,
    limit: 10,
};

export const paymentLinksSlice = createSlice({
    name: "payment-links",
    initialState,
    reducers: {
        addNewPaymentLink: (state, action) => {
            state.paymentLinks.unshift(action.payload);
        },
        updateIsPaymentLinkLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updatePaymentLinkFilters: (state, action) => {
            state.filters[action.payload?.name] = action.payload?.value;
            state.skip = 0;
        },
        updatePaymentLinkSort: (state, action) => {
            state.sort = action.payload;
            state.skip = 0;
        },
        clearPaymentLinkFilters: (state, action) => {
            state.filters = {
                id: "",
                currency: "all",
                date: "all",
                status: "all",
            };
            state.sort = "default";
            state.limit = 10;
            state.skip = 0;
        },
        updatePaymentLinkSkip: (state, action) => {
            state.skip = action.payload;
        },
        incOrDecPaymentLinkSkip: (state, action) => {
            state.skip += action.payload;
        },
        updatePaymentLinkStatus: (state, action) => {
            const objIndex = state.paymentLinks?.findIndex(
                (obj) => obj.id === action.payload.id
            );
            state.paymentLinks[objIndex].status = action.payload?.status;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPaymentLinks.fulfilled, (state, action) => {
            state.paymentLinks = action.payload?.paymentLinks;
            state.totalPaymentLinks = action.payload?.totalPaymentLinks;
            state.isLoading = false;
        });
    },
});

export { fetchPaymentLinks };

export const {
    addNewPaymentLink,
    clearPaymentLinkFilters,
    incOrDecPaymentLinkSkip,
    updateIsPaymentLinkLoading,
    updatePaymentLinkFilters,
    updatePaymentLinkSkip,
    updatePaymentLinkSort,
    updatePaymentLinkStatus,
} = paymentLinksSlice.actions;

export default paymentLinksSlice.reducer;
