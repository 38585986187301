import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { HiDownload } from "react-icons/hi";

import { invoiceImg } from "../../../assets/images";
import { formatDate } from "../../../utils";

export default function SingleInvoiceDetails({ invoice }) {
  const [isPaymentDetailsDropdownOpen, setIsPaymentDetailsDropdownOpen] =
    useState(false);

  return (
    <div className="flex flex-col gap-[2em]">
      <div className="relative flex-1">
        <div
          className="block md:hidden sticky top-[4.2em] left-0 w-full"
          onClick={() =>
            setIsPaymentDetailsDropdownOpen(!isPaymentDetailsDropdownOpen)
          }
        >
          <div className="bg-[#f6f6f6] dark:bg-darkCardHoverColor p-5 flex items-center justify-between">
            <div className="flex items-center gap-[10px]">
              <h3 className="font-[600] text-[17px]">
                Invoice From {invoice?.merchant?.name}
              </h3>
            </div>
            <div>
              <FiChevronDown />
            </div>
          </div>
        </div>

        <div
          className={
            "absolute md:relative top-[100%] md:top-auto p-5 md:p-0 left-0 md:left-auto w-[100%] z-10 bg-[#fff] dark:bg-darkCardColor md:bg-transparent shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] md:shadow-none " +
            (isPaymentDetailsDropdownOpen
              ? "h-auto block"
              : "h-0 hidden md:h-auto md:block")
          }
        >
          <h3 className="hidden md:block font-[600] text-[20px]">
            Invoice From {invoice?.merchant?.name}
          </h3>
          <div className="md:mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Invoice To
            </span>
            <span className="text-[15px] font-medium">
              {invoice?.customerName}
            </span>
            <span className="block text-[14px]">{invoice?.customerEmail}</span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor mb-2">
              Download
            </span>
            <a
              href={process.env.REACT_APP_SERVER_URL + invoice?.pdf}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <div className="relative w-[80px] h-[100px]">
                <img
                  src={invoiceImg}
                  alt=""
                  className="w-[100%] h-[100%] object-fit"
                />

                <div className="absolute inset-0 group bg-[#fff7] dark:bg-[#000a] flex items-center justify-center cursor-pointer transition-all hover:bg-[#fffa]">
                  <span className="text-2xl transition-all group-hover:text-3xl">
                    <HiDownload />
                  </span>
                </div>
              </div>
            </a>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Amount
            </span>
            <span className="block text-xl font-bold">
              {invoice?.amount} USDT
            </span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Accepted Currencies
            </span>
            <div className="flex flex-wrap items-center gap-[10px] md:gap-[20px] mt-[7px]">
              {invoice?.acceptedCurrencies?.map((currency, index) => {
                return (
                  <div
                    key={index}
                    className="py-[5px] rounded px-[5px] md:px-0 bg-[#f6f6f6] dark:bg-darkCardHoverColor md:bg-transparent dark:md:bg-transparent flex items-center gap-[6px]"
                  >
                    <img
                      src={process.env.REACT_APP_SERVER_URL + currency?.logo}
                      alt=""
                      className="w-[20px] h-[20px] rounded-full object-cover"
                    />
                    <span>{currency?.symbol}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Expiry Date
            </span>
            <span className="text-[15px] font-medium">
              {invoice?.expiryDate
                ? formatDate(invoice?.expiryDate)
                : "No Expiry"}
            </span>
          </div>
        </div>
      </div>

      <div className="hidden md:flex items-center gap-[10px] text-[12px]">
        <span>
          Powered by{" "}
          <span className="font-[600] text-sm">
            Simple
            <span className="text-primaryColor">pay</span>
          </span>
        </span>
        <span className="text-grayColor">|</span>
        <span>Terms</span>
        <span>Privacy</span>
      </div>
    </div>
  );
}
