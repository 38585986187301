import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import axios from "../../../axios";

export default function RecentWithdrawals() {
    const [isLoading, setIsLoading] = useState(true);
    const [recentWithdrawals, setRecentWithdrawals] = useState([]);

    const { accountId, jwtToken } = useSelector((state) => state.user);

    const fetchRecentWithdrawals = async () => {
        try {
            const response = await axios.get(
                `/withdrawals/user/recent?accountId=${accountId}`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            setRecentWithdrawals(response.data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchRecentWithdrawals();
    }, []);

    return (
        <div>
            <div className="flex items-center justify-between mb-4">
                <h2 className="font-[500]">Recent Withdrawals</h2>
                {/* <span className="text-[13px] font-medium text-primaryColor cursor-pointer">
                    View All
                </span> */}
            </div>
            <div className="text-[15px]">
                {isLoading ? (
                    Array.from({ length: 2 }).map((_, index) => {
                        return (
                            <div
                                key={index}
                                className="flex items-center gap-[10px] mb-5"
                            >
                                <div className="h-[15px] w-[50%] bg-slate-300 dark:bg-slate-600 animate-pulse"></div>
                                <span className="h-[13px] w-[25%] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
                            </div>
                        );
                    })
                ) : recentWithdrawals?.length < 1 ? (
                    <div>
                        <span className="text-sm text-grayColor dark:text-darkGrayColor">
                            Recent Withdrawals is empty
                        </span>
                    </div>
                ) : (
                    recentWithdrawals?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="flex items-center gap-[10px] mb-5"
                            >
                                <Link
                                    to={`/withdraw?address=${item?.receiverAddress}`}
                                    className="block"
                                >
                                    {item?.receiverAddress?.slice(0, 8)}
                                    ...
                                    {item?.receiverAddress?.slice(-8)}
                                </Link>
                                <span className="text-[13px] text-grayColor dark:text-darkGrayColor">
                                    - {moment(item?.createdAt).fromNow()}
                                </span>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
}
