import React, { useEffect, useState } from "react";
import { FiChevronDown, FiCopy } from "react-icons/fi";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useSelector } from "react-redux";
import { CurrencySelectDropdown } from "../../components";

import { formatBalance } from "../../utils";

export default function ReceivePage() {
  const { currencies, isFetching } = useSelector((state) => state.currencies);

  const [isCurrenciesDropdownOpen, setIsCurrenciesDropdownOpen] =
    useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [section, setSection] = useState(1);

  console.log(selectedCurrency);

  useEffect(() => {
    setSelectedCurrency(currencies[0]);
  }, [currencies]);

  return (
    <div className="flex flex-col items-center sm:justify-center mx-auto py-5 sm:py-6 sm:min-h-[calc(100vh-5em)] max-w-[430px]">
      <div className="w-full relative flex items-center sm:justify-between gap-[15px] mb-6 sm:mb-10">
        {section === 2 && (
          <button
            className="h-auto p-0 bg-transparent text-textColor text-xl "
            onClick={() => setSection(1)}
          >
            <HiArrowNarrowLeft />
          </button>
        )}
        <h1 className="font-bold text-lg sm:text-xl text-left sm:text-center">
          Receive
        </h1>
        <span></span>
      </div>

      <div className={section === 1 ? "w-full block" : "hidden"}>
        <CurrencySelectDropdown
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
        />

        <div className="mt-6">
          <div>
            <label htmlFor="">Amount (optional)</label>
            <input
              type="number"
              placeholder="0.00"
              name="amount"
              // onChange={handleChange}
              className="arrow-hidden h-[40px] text-[15px] font-[600] "
            />
          </div>
          <span className="text-[13px] text-grayColor dark:text-darkGrayColor leading-[23px] mt-3 block">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed
            repellat placeat itaque ullam sapiente tempora
          </span>
        </div>

        <div className="mt-6">
          <button
            className="w-full hover:bg-btnHoverColor"
            onClick={() => setSection(2)}
          >
            Next
          </button>
        </div>
      </div>

      <div className={section === 2 ? "block w-full" : "hidden"}>
        <div className="w-[200px] h-[200px] mx-auto p-2 border dark:border-darkBorderColor rounded">
          <img
            src="https://www.investopedia.com/thmb/KfGSwVyV8mOdTHFxL1T0aS3xpE8=/1148x1148/smart/filters:no_upscale()/qr-code-bc94057f452f4806af70fd34540f72ad.png"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex items-center justify-between gap-[10px] mt-6 bg-gradient-to-r from-[#f4f4f4] via-[#fdfdfd] to-[#f4f4f4] dark:from-darkCardColor dark:to-darkCardColor px-[15px] py-[8px] rounded">
          <span className="text-sm font-medium">SimplePay Id - 543645</span>
          <button className="bg-transparent p-0 text-textColor dark:text-darkTextColor">
            <FiCopy />
          </button>
        </div>

        <div className="mt-6">
          <span className="font-medium text-sm mb-2 block">Important</span>
          <ul className="text-sm text-grayColor dark:text-darkGrayColor list-disc list-inside leading-[26px] ">
            <li>
              You can only use this SimplePay id and QRcode on{" "}
              <span className="font-medium text-primaryColor">
                SimplePay app
              </span>{" "}
              & <span className="font-medium text-primaryColor">Website</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
