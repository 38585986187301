import React, { useEffect, useState } from "react";
import { VscClose } from "react-icons/vsc";
import axios from "../../../axios";
import { BtnLoader } from "../../../components";
import { useSelector } from "react-redux";
import AddNetWorkInRouter from "./AddNetWorkInRouter";

function AddRouterModal({ setRouterAddModal, fetchRouters }) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState([]);

  const [data, setData] = useState({
    contract_address: "",
    title: "",
    networks: [],
  });

  const { jwtToken } = useSelector((state) => state.admin);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);

      const res = await axios.post(
        `/admin/router/create`,
        {
          title: data.title,
          contract_address: data.contract_address,
          networks: selectedNetwork,
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      fetchRouters();
      setRouterAddModal(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.response.data.error || "somthing went wrong");
    }
  };

  return (
    <div className="fixed inset-0 bg-[#fff5] dark:bg-[#0005] flex items-center justify-center z-20 sm:px-5">
      <div className="bg-[#fff] dark:bg-darkCardColor w-[100%] sm:max-w-[450px] h-[100vh] sm:h-[85vh] overflow-y-auto rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
        <div className="flex items-center justify-between border-b border-borderColor dark:border-[#4c4e53] px-4 py-3">
          <h3 className="font-medium">Create Router</h3>
          <button
            className="bg-transparent text-textColor dark:text-darkTextColor p-0 text-xl"
            onClick={() => {
              setRouterAddModal(false);
            }}
          >
            <VscClose />
          </button>
        </div>
        <form action="" className="p-5" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="">Title*</label>
            <input
              type="text"
              placeholder="Enter Title"
              name="title"
              value={data.title}
              onChange={handleOnChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Contract Address*</label>
            <input
              type="text"
              placeholder="Enter Contract Address"
              name="contract_address"
              value={data.contract_address}
              onChange={handleOnChange}
            />
          </div>
          <div className="mb-5">
            <label htmlFor="">Routers</label>
            <AddNetWorkInRouter
              selectedNetwork={selectedNetwork}
              setSelectedNetwork={setSelectedNetwork}
            />
          </div>

          {error && (
            <span className="text-[13px] font-medium text-red-500 block mt-3">
              {error}
            </span>
          )}

          <div className="pt-6">
            <button className="w-[100%] disabled:opacity-80">
              {isLoading ? <BtnLoader /> : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddRouterModal;
