import React from "react";
import { MdClose } from "react-icons/md";
import { useParams } from "react-router-dom";

function PresalePagePaymentFilter({
  isFiltersModalOpen,
  setIsFilterModalOpen,
  presalePage,
}) {
  const { id } = useParams();

  return (
    <div>
      <div
        className={
          "sm:mt-3 z-20 sm:z-1 bg-white sm:bg-[#f6f6f6] dark:bg-darkCardColor sm:rounded " +
          (isFiltersModalOpen ? "fixed inset-0 sm:relative" : "hidden sm:block")
        }
      >
        <div className="flex sm:hidden items-center justify-between p-5 border-b dark:border-[#4c4e53]">
          <h1 className="font-medium">Filters</h1>
          <button
            className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
            onClick={() => {
              setIsFilterModalOpen(false);
            }}
          >
            <MdClose />
          </button>
        </div>
        <div className="sm:flex flex-wrap items-end gap-[15px] p-5 h-[100%] overflow-y-auto">
          <div>
            <label htmlFor="">Transaction Id</label>
            <input
              type="number"
              placeholder="Transaction Id"
              name="transactionId"
              //   onChange={handleFilterChange}
              className="arrow-hidden"
              //   value={filters.transactionId || ""}
            />
          </div>
          <div className="mt-4 sm:mt-0">
            <label htmlFor="">Currency</label>
            <select
              name="currency"
              id=""
              className="w-[100%] sm:w-[180px]"
              //   onChange={handleFilterChange}
              //   value={filters.currency || ""}
            >
              <option value="all">All</option>
              {/* {paymentPage?.acceptedCurrencies?.map((currency, index) => {
                return (
                  <option key={index} value={currency?._id}>
                    {currency?.symbol}
                  </option>
                );
              })} */}
            </select>
          </div>
          <div className="mt-4 sm:mt-0">
            <label htmlFor="">Slect Date</label>
            <select name="date" id="" className="w-[100%] sm:w-[180px]">
              <option value="all">All</option>
              <option value="7">Last Weak</option>
              <option value="30">Last Month</option>
              <option value="360">Last Year</option>
            </select>
          </div>
          <div className="mt-4 sm:mt-0">
            <label htmlFor="">Sort</label>
            <select name="sort" id="" className="w-[100%] sm:w-[180px]">
              <option value="default">Default</option>
              <option value="createdAt:asc">Date (Ascending)</option>
              <option value="createdAt:desc">Date (Descending)</option>
            </select>
          </div>
          <div className="mt-4 sm:mt-0">
            <label htmlFor="">Email / username</label>
            <input
              type="text"
              placeholder="Ex: nihal"
              name="emailOrUsername"
              //   onChange={handleFilterChange}
              //   value={filters.emailOrUsername || ""}
            />
          </div>
          <div className="flex items-center justify-end sm:justify-start mt-5 sm:mt-0 gap-3">
            <button className="border border-borderColor dark:border-[#4c4e53] text-textColor dark:text-darkTextColor bg-transparent">
              Clear
            </button>
            <button className="hover:bg-btnHoverColor">Search</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PresalePagePaymentFilter;
