import { createSlice } from "@reduxjs/toolkit";
const currency = [
  {
    name: "BNB",
    isChecked: false,
  },
  {
    name: "BUSD",
    isChecked: false,
  },
  {
    name: "USDT",
    isChecked: false,
  },
  {
    name: "USDC",
    isChecked: false,
  },
];

const initialState = {
  formData: {
    token_name: "",
    token_symbol: "",
    total_supply: "",
    pay_symbol: 0,
    // isMultyFundRaising: false,
    // fundRaising_round_count: 0,
    isVesting: false,
    percentage_for_sale: 0,
    percentage_for_vesting: 0,
    currency: [],
    fee_options: 0,
    affiliate_program: false,
    liquidity_price: 0,
    router: "",
    UTC: [
      {
        start_time: "",
        end_time: "",
        presale_price: 0,
        token_price: 0,
      },
    ],
    liquidity_locup: 0,
    website: "",
    twitter: "",
    facebook: "",
    github: "",
    telegram: "",
    descord: "",
    reddit: "",
    youtube: "",
    description: "",
    instagram: "",
    token_address: "",
  },
};

export const presaleSlice = createSlice({
  name: "presales",
  initialState,
  reducers: {
    setPresaleFormData: (state, { payload }) => {
      state.formData[payload.name] = payload.value;
      // if (payload.name === "isMultyFundRaising" && payload.value === false) {
      //   state.formData.fundRaising_round_count = 0;
      // }
    },
    setUTCInitialFields: (state, { payload }) => {
      state.formData.UTC.push({
        start_time: "",
        end_time: "",
        presale_price: 0,
        token_price: 0,
      });
    },
    removeUTCInitialFields: (state, { payload }) => {
      state.formData.UTC = state.formData.UTC.filter(
        (_, index) => index !== payload
      );
    },
    setUTCFieldData: (state, { payload }) => {
      if (state.formData.UTC.length) {
        state.formData.UTC[payload.index][payload.name] = payload.value;
      }
    },
    setCurrency: (state, { payload }) => {
      if (state.formData.currency.length) {
        if (!state.formData.currency.includes(payload.value)) {
          state.formData.currency[payload.index].isChecked = payload.value;
        }
      } else {
        state.formData.currency[payload.index].isChecked = payload.value;
      }
    },
    clearAllFormData: (state, { payload }) => {
      const data = {
        token_name: "",
        token_symbol: "",
        total_supply: "",
        pay_symbol: "",
        // isMultyFundRaising: false,
        // fundRaising_round_count: 0,
        isVesting: false,
        percentage_for_sale: 0,
        percentage_for_vesting: 0,
        currency: currency,
        fee_options: 0,
        affiliate_program: false,
        liquidity_price: 0,
        router: "",
        token_address: "",
        UTC: [
          {
            start_time: "",
            end_time: "",
            presale_price: 0,
            token_price: 0,
          },
        ],
        liquidity_locup: 0,
        website: "",
        twitter: "",
        facebook: "",
        github: "",
        telegram: "",
        descord: "",
        reddit: "",
        youtube: "",
        description: "",
      };
      state.formData = data;
    },

    setEditInitialPersonalData: (state, { payload }) => {
      state.formData.website = payload.website;
      state.formData.twitter = payload.twitter;
      state.formData.facebook = payload.facebook;
      state.formData.github = payload.github;
      state.formData.telegram = payload.telegram;
      state.formData.descord = payload.descord;
      state.formData.reddit = payload.reddit;
      state.formData.youtube = payload.youtube;
      state.formData.description = payload.description;
      state.formData.instagram = payload.instagram;
    },

    setEditPersonalData: (state, { payload }) => {
      state.formData[payload.name] = payload.value;
    },
  },
});

export const {
  setPresaleFormData,
  setUTCInitialFields,
  removeUTCInitialFields,
  setUTCFieldData,
  setCurrency,
  clearAllFormData,
  setEditInitialPersonalData,
  setEditPersonalData,
} = presaleSlice.actions;
export default presaleSlice.reducer;
