import React from "react";
import { useState } from "react";

export default function NotificationsSettingsPage() {
    const [browserNotification, setBrowserNotification] = useState(
        Notification.permission === "granted"
    );

    return (
        <div className="mb-8 max-w-[650px]">
            <h2 className="text-lg font-[600] mb-5">Notifications</h2>
            <div className="flex items-start gap-[10px]">
                <input
                    type="checkbox"
                    className="w-[16px] h-[16px] min-w-[16px] min-h-[16px]"
                />
                <div>
                    <label htmlFor="" className="mb-0">
                        Email Notifications
                    </label>
                    <span className="block text-sm text-grayColor dark:text-darkGrayColor leading-[28px]">
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        voluptatibus, at molestiae temporibus eveniet possimus,
                        repellendus. Quasi minima nam modi!
                    </span>
                </div>
            </div>
            <div className="flex items-start gap-[10px] mt-4">
                <input
                    type="checkbox"
                    className="w-[16px] h-[16px] min-w-[16px] min-h-[16px]"
                    checked={browserNotification || false}
                    onChange={(e) =>
                        Notification.requestPermission((permission) => {
                            setBrowserNotification(permission === "granted");
                        })
                    }
                />
                <div>
                    <label htmlFor="" className="mb-0">
                        Browser Notification
                    </label>
                    <span className="block text-sm text-grayColor dark:text-darkGrayColor leading-[28px]">
                        Go to your browser settings to turn on/off browser
                        notification
                    </span>
                </div>
            </div>
        </div>
    );
}
