import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  admin: {},
  jwtToken: localStorage.getItem("admin-token") || "",
  isLoggedIn: false,
  adminId: localStorage.getItem("admin-account") || "",
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdmin: (state, { payload }) => {
      localStorage.setItem("admin-token", payload.jwtToken);
      localStorage.setItem("admin-account", payload.admin._id);
      state.isLoggedIn = true;
      state.admin = payload.admin;
      state.jwtToken = payload.jwtToken;
      state.adminId = payload.admin._id;
    },

    logoutAdmin: (state, { payload }) => {
      localStorage.removeItem("admin-token");
      state.isLoggedIn = false;
      state.admin = {};
      state.jwtToken = "";
    },
    updateAdmin: (state, { payload }) => {
      state.admin = payload;
      state.isLoggedIn = true;
    },
  },
});

export const { setAdmin, logoutAdmin, updateAdmin } = adminSlice.actions;
export default adminSlice.reducer;
