import React, { useState } from "react";
import { Pagination } from "../../../components";
import PresalePagePaymentFilter from "./PresalePagePaymentFilter";
import { BiSearch } from "react-icons/bi";
import { IoFilterSharp } from "react-icons/io5";
import { formatDate, formatBalance } from "../../../utils";
import { useDispatch } from "react-redux";

function PresalePagePaymentTable({ presale }) {
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [presalePage, setPresalePage] = useState();
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPayments, setTotalPayments] = useState(0);
  const [paymentError, setPaymentError] = useState("");

  const dispatch = useDispatch();

  return (
    <div>
      <div className="mt-6">
        <div>
          <div className="flex items-center gap-[10px] justify-between ">
            <h1 className="text-[17px] font-[600]">Payments</h1>
            <button
              className="sm:hidden bg-transparent text-textColor dark:text-darkTextColor h-auto p-0 text-xl"
              onClick={() => setIsFiltersModalOpen(!isFiltersModalOpen)}
            >
              <IoFilterSharp />
            </button>
          </div>
        </div>

        <PresalePagePaymentFilter
          isFiltersModalOpen={isFiltersModalOpen}
          setIsFiltersModalOpen={setIsFiltersModalOpen}
          presalePage={presalePage}
        />

        {isLoading ? (
          <div className="flex items-center justify-center h-[200px]">
            <div className="w-[30px] h-[30px] border-4 border-primaryColor border-r-transparent rounded-full animate-spin "></div>
          </div>
        ) : paymentError ? (
          <div className="flex flex-col items-center justify-center h-[200px]">
            <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
              X
            </div>
            <span className="block text-xl font-[600] mt-3">
              Something went wrong
            </span>
            <span className="block text-sm mt-2 text-grayColor dark:text-darkGrayColor">
              Please reload page!!.
            </span>
          </div>
        ) : !payments || payments?.length < 1 ? (
          <div className="flex flex-col items-center justify-center h-[200px]">
            <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
              <BiSearch />
            </div>
            <span className="block text-xl font-[600] mt-3">
              No Result found
            </span>
            <span className="block text-sm mt-2 text-grayColor">
              There are no Payments yet!!.
            </span>
          </div>
        ) : (
          <div className="mt-5">
            <div className="overflow-x-auto">
              <table className="w-[100%]">
                <thead className="hidden sm:table-header-group text-left bg-[#f6f6f6] dark:bg-darkCardColor">
                  <tr className="text-[13px] text-grayColor dark:text-darkGrayColor">
                    <th className="p-[12px] font-[600]">Txn.Id</th>
                    <th className="font-[600] p-[12px]">Name</th>
                    <th className="font-[600] p-[12px]">Amount</th>
                    <th className="font-[600] p-[12px]">Date</th>
                    <th className="font-[600] p-[12px]">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {payments?.map((payment, index) => {
                    return (
                      <tr
                        className="text-[13px] border-b dark:border-darkBorderColor hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor cursor-pointer"
                        key={index}
                      >
                        <td className="hidden sm:table-cell pl-0 p-[12px]">
                          #{payment?.transactionId}
                        </td>
                        <td className="p-[12px] pl-0 sm:pl-[12px] py-[17px] sm:py-[12px] whitespace-nowrap">
                          <div className="flex items-center gap-[1em]">
                            <div className="w-[35px] h-[35px] min-w-[35px] min-h-[35px] rounded overflow-hidden flex items-center justify-center bg-[#e5e7eb] dark:bg-darkCardColor text-lg">
                              <img
                                // src={
                                //   payment?.sender?.avatar
                                //     ? process.env.REACT_APP_SERVER_URL +
                                //       payment?.sender?.avatar
                                //     : avatarImg
                                // }
                                alt=""
                                className="w-[100%] h-[100%] object-cover"
                              />
                            </div>
                            <div>
                              <h4 className="font-[500] text-[14px]">
                                Paid by
                              </h4>
                              <span className="block text-[14px] text-grayColor capitalize mt-[2px] sm:mt-0">
                                {payment?.sender?.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="p-[12px] pr-0 sm:pr-[12px] whitespace-nowrap">
                          <div className="flex items-center justify-end sm:justify-start gap-[10px]">
                            <div className="hidden sm:block w-[17px] h-[17px] min-w-[17px] min-h-[17px] rounded-full overflow-hidden">
                              <img
                                src={
                                  process.env?.REACT_APP_SERVER_URL +
                                  payment?.currency?.logo
                                }
                                alt=""
                                className="w-[100%] h-[100%] object-cover"
                              />
                            </div>
                            <div className="block text-[15px]">
                              <span className="font-[500] ">
                                {formatBalance(payment?.amount)}{" "}
                              </span>
                              <span className="">
                                {payment?.currency?.symbol}
                              </span>
                            </div>
                          </div>
                          <span className="text-right block text-sm text-grayColor mt-1 sm:hidden">
                            {formatDate(payment?.updatedAt)}
                          </span>
                          {payment?.status === "failed" && (
                            <span className="text-right  block text-sm text-red-500 mt-1 sm:hidden">
                              Failed <span>!</span>
                            </span>
                          )}
                          {payment?.status === "pending" && (
                            <span className="text-right block text-sm text-gray-400 mt-1 sm:hidden">
                              Pending <span>!</span>
                            </span>
                          )}
                        </td>
                        <td className="hidden sm:table-cell p-[12px]">
                          {formatDate(payment?.createdAt)}
                        </td>
                        <td className="hidden sm:table-cell p-[12px] pr-0">
                          <span
                            className={
                              "text-[11px] rounded px-[4.5px] py-[2.7px] capitalize " +
                              (payment?.status === "completed"
                                ? "bg-[#daf4eb] text-[#34c38f]"
                                : payment?.status === "failed"
                                ? "text-[#f46a6a] bg-[#fde4e4]"
                                : "text-gray-500 bg-gray-100")
                            }
                          >
                            {payment?.status}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <Pagination
              skip={skip}
              limit={limit}
              total={totalPayments}
              //   incOrDecSkip={(number) => dispatch(incOrDecPaymentsSkip(number))}
              //   updateSkip={(skip) => dispatch(updatePaymentsSkip(skip))}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PresalePagePaymentTable;
