import React from "react";

export default function ErrorAndReloadModal() {
    return (
        <div className="fixed inset-0 flex bg-[#0007] items-center justify-center z-20 transition-all p-[15px]">
            <div className="relative flex flex-col bg-[#fff] transition-all w-[100%] max-w-[380px] overflow-y-auto shadow-sm p-4 rounded">
                <h1 className="font-[600]">Sorry, Something went wrong?</h1>
                <span className="text-sm text-grayColor leading-[28px] block mt-3">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Odit voluptatibus impedit necessitatibus placeat praesentium
                    sint esse ut expedita velit numquam.
                </span>
                <div className="mt-6">
                    <button
                        className="w-full hover:bg-btnHoverColor"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        Reload
                    </button>
                </div>
            </div>
        </div>
    );
}
