import React from "react";

export default function MainLoader() {
  return (
    <div className="flex items-center justify-center h-[50vh]">
      <div>
        <div className="flex items-center gap-[10px]">
          <div className="w-[40px]">
            <img
              src="https://app.hami.live/static/media/hamilogo.b98b74641e3caeca8a7d591025e520a8.svg"
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <h1 className="text-2xl font-bold">
            SIMPLE<span className="text-primaryColor">PAY</span>
          </h1>
        </div>
        <div className="mt-5 relative w-[160px] h-[4px] bg-[#f2f2f2] dark:bg-darkCardColor overflow-hidden rounded">
          <div
            className="absolute top-0 left-0 h-[100%] bg-primaryColor"
            id="main-loader"
          ></div>
        </div>
      </div>
    </div>
  );
}
