import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../axios";
import { BtnLoader } from "../../../components";
import {
    addNewProduct,
    updateProduct,
} from "../../../redux/slices/invocesSlice";

export default function AddProductModal({
    setIsAddProductModalOpen,
    isEdit,
    product,
    rowIndex,
}) {
    const [data, setData] = useState({
        name: isEdit ? product?.name : "",
        price: isEdit ? product?.price : "",
        description: isEdit ? product?.description : "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const { jwtToken, accountId } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            setError("");

            if (isEdit) {
                const response = await axios.patch(
                    `/products/single/${product?._id}`,
                    {
                        ...data,
                        accountId,
                    },
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                dispatch(updateProduct(response.data));
            } else {
                const response = await axios.post(
                    "/products/add",
                    {
                        ...data,
                        accountId,
                    },
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                dispatch(addNewProduct({ product: response.data, rowIndex }));
            }

            setIsLoading(false);
            setIsAddProductModalOpen({ bool: false, isEdit: false });
        } catch (err) {
            console.log(err);
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div
            id="add-product-modal"
            className="fixed inset-0 bg-[#fff7] dark:bg-[#0005] z-10 flex items-center justify-center p-4"
            onClick={(e) => {
                if (e.target === document.getElementById("add-product-modal")) {
                    setIsAddProductModalOpen({ bool: false, isEdit: false });
                }
            }}
        >
            <div className="bg-[#fff] dark:bg-darkCardColor max-h-[100%] sm:max-h-[90%] overflow-y-auto w-[100%] sm:max-w-[400px] rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
                <div className="flex items-center justify-between gap-[10px] p-5 border-b dark:border-darkBorderColor">
                    <h1 className="font-[600]">
                        {isEdit ? "Edit" : "Add"} Product
                    </h1>
                    <button
                        className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
                        onClick={() =>
                            setIsAddProductModalOpen({
                                bool: false,
                                isEdit: false,
                            })
                        }
                        type="button"
                    >
                        <IoMdClose />
                    </button>
                </div>
                <form className="p-5" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="">Name</label>
                        <input
                            type="text"
                            placeholder="Enter Product Name"
                            name="name"
                            onChange={handleChange}
                            value={data.name || ""}
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">Price in USDT</label>
                        <input
                            type="number"
                            name="price"
                            onChange={handleChange}
                            value={data.price || ""}
                            className="arrow-hidden"
                            placeholder="0.00"
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">Description</label>
                        <textarea
                            name="description"
                            id=""
                            className="h-[80px]"
                            onChange={handleChange}
                            value={data.description || ""}
                        ></textarea>
                    </div>
                    {error && (
                        <span className="text-sm text-red-500 block mt-3">
                            {error}
                        </span>
                    )}
                    <div className="mt-6">
                        <button
                            className="w-full hover:bg-btnHoverColor disabled:opacity-80"
                            disabled={isLoading || !data.price || !data.name}
                        >
                            {isLoading ? (
                                <BtnLoader />
                            ) : isEdit ? (
                                "Update Product"
                            ) : (
                                "Add Product"
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
