import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  PresalePageFilter,
  PresalePageTable,
} from "../../features/PresalePage";
import { Pagination } from "../../components";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";

function PresalePageDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [presalePage, setPresalePage] = useState([]);
  const { jwtToken } = useSelector((state) => state.user);

  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    totalPresale: 0,
    name: "",
  });

  const navigate = useNavigate();

  const getPresaleList = async () => {
    try {
      const res = await axios.get(`/presale/all?name=${filters.name}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setPresalePage(res?.data?.presales);
      filters({
        ...filters,
        totalPresale: res?.data?.total,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPresaleList();
  }, []);

  return (
    <div className="py-5 sm:py-6">
      <div className="mb-6">
        <div className="flex items-ceenter gap-[10px] justify-between">
          <h3 className="text-base font-[600]">Presale Pages</h3>
          <div className="flex items-center gap-[15px]">
            {/* <button
              className="sm:hidden bg-transparent text-textColor dark:text-darkTextColor h-auto p-0 text-xl"
              //   onClick={() => setIsFiltersModalOpen(!isFiltersModalOpen)}
            >
              <IoFilterSharp />
            </button> */}
            <button
              className="bg-in hover:bg-btnHoverColor"
              onClick={() => {
                navigate("/presale/create");
              }}
            >
              + Create Presale
            </button>
          </div>
        </div>
        <div
          className={
            "sm:mt-3 z-20 sm:z-1 bg-white sm:bg-[#f6f6f6] dark:bg-darkCardColor sm:rounded "
          }
        >
          <PresalePageFilter
            setFilters={setFilters}
            filters={filters}
            getPresaleList={getPresaleList}
          />
        </div>
      </div>

      <div>
        {isLoading ? (
          <div className="flex items-center justify-center h-[200px]">
            <div className="w-[30px] h-[30px] border-4 border-primaryColor border-r-transparent rounded-full animate-spin "></div>
          </div>
        ) : !presalePage || presalePage?.length < 1 ? (
          <div className="flex flex-col items-center justify-center h-[200px]">
            <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
              <BiSearch />
            </div>
            <span className="block text-xl font-[600] mt-3">
              No Result found
            </span>
            <span className="block text-sm mt-2 text-grayColor">
              There are no Payment Pages created yet!!.
            </span>
          </div>
        ) : (
          <div>
            <PresalePageTable presales={presalePage} />

            <Pagination
              limit={filters.limit}
              skip={filters.skip}
              total={filters.totalPresale}
              incOrDecSkip={(number) =>
                setFilters({
                  ...filters,
                  skip: number,
                })
              }
              updateSkip={(skip) =>
                setFilters({
                  ...filters,
                  skip: skip,
                })
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PresalePageDetails;
