import { BiPencil } from "react-icons/bi";
import { IoNotificationsOutline } from "react-icons/io5";
import {
  MdAccountBalanceWallet,
  MdVerified,
  MdAccountTree,
} from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { Si1Password } from "react-icons/si";

const settingsNavLinks = [
  {
    name: "Edit Profile",
    link: "/settings/profile",
    icon: <BiPencil />,
  },
  {
    name: "Accounts",
    link: "/settings/accounts",
    icon: <MdAccountBalanceWallet />,
  },
  // {
  //     name: "Import Account",
  //     link: "/settings/accounts/import",
  //     icon: <MdAccountBalanceWallet />,
  // },
  {
    name: "KYC Verification",
    link: "/settings/kyc-verification",
    icon: <MdVerified />,
  },
  {
    name: "Notifications",
    link: "/settings/notifications",
    icon: <IoNotificationsOutline />,
  },
  {
    name: "Pin Number",
    link: "/settings/pin",
    icon: <Si1Password />,
  },
  {
    name: "Password & Security",
    link: "/settings/password",
    icon: <RiLockPasswordFill />,
  },
  {
    name: "Merchant Details",
    link: "/settings/merchant",
    icon: <MdAccountTree />,
  },
];

export default settingsNavLinks;
