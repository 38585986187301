import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import { HiOutlineShare } from "react-icons/hi";
import ShareModal from "./ShareModal";
import EditModal from "./EditModal";

function PresaleEditAndShare({ presale, fetchSingleDetails }) {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { id } = useParams();

  return (
    <div>
      <div className="flex items-center gap-[10px] justify-end mb-6">
        <button
          className="bg-transparent border dark:border-darkBorderColor text-textColor dark:text-darkTextColor"
          onClick={() => {
            setIsShareModalOpen(true);
          }}
        >
          <HiOutlineShare />
        </button>
        {isShareModalOpen && (
          <ShareModal
            setIsShareModalOpen={setIsShareModalOpen}
            url={`${process.env.REACT_APP_CLIENT_URL}/payment-page/${id}`}
          />
        )}
        <button
          className="flex items-center gap-[10px]"
          onClick={() => setIsEditModalOpen(true)}
        >
          <AiOutlineEdit />
          Edit Page
        </button>
        {isEditModalOpen && (
          <EditModal
            presale={presale}
            setEditModal={setIsEditModalOpen}
            fetchSingleDetails={fetchSingleDetails}
          />
        )}
      </div>
    </div>
  );
}

export default PresaleEditAndShare;
