import React from "react";

export default function HomeWalletSkeleton() {
    return (
        <div
            className="relative flex items-start justify-between bg-gradient-to-r from-[#f4f4f4] via-[#fdfdfd] to-[#f4f4f4] dark:from-darkCardColor dark:to-darkCardColor p-3 rounded mb-3 last:mb-0"
            style={{ zIndex: -1 }}
        >
            <div className="flex items-center gap-[10px]">
                <div className="w-[35px] h-[35px]  sm:w-[40px] sm:h-[40px] rounded-full bg-slate-300 dark:bg-slate-600 animate-pulse overflow-hidden"></div>
                <div>
                    <span className="block h-[15px] w-[150px] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
                    <span className="block h-[14px] w-[60px] mt-[6px] bg-slate-300 dark:bg-slate-600 animate-pulse "></span>
                </div>
            </div>
        </div>
    );
}
