import React, { useEffect, useState } from "react";
import { BiLink } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "../../../axios";
import { ConfirmModal } from "../../../components";
import { formatDate } from "../../../utils";

export default function PyamentPageDetailedInfo({
    paymentPage,
    setPaymentPage,
}) {
    const [isCopied, setIsCopied] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isStatusUpdateLoading, setIsStatusUpdateLoading] = useState(false);

    const { id } = useParams();
    const { jwtToken } = useSelector((state) => state.user);

    const handleUpdateStatus = async (isConfirmed) => {
        try {
            setIsConfirmModalOpen(false);

            if (isConfirmed) {
                setIsStatusUpdateLoading(true);
                const response = await axios.patch(
                    `/payment-pages/update/status/${id}`,
                    { status: !paymentPage.isActive },
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );
                setPaymentPage((prev) => {
                    return {
                        ...prev,
                        isActive: response.data?.isActive,
                    };
                });
                setIsStatusUpdateLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsCopied(false);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [isCopied]);

    return (
        <div>
            <h1 className="font-[600] text-lg">{paymentPage?.title}</h1>
            <span className="text-sm text-grayColor dark:text-darkGrayColor leading-[30px] block mt-2">
                {paymentPage?.description}
            </span>

            <table className="w-[100%] mt-4">
                <tbody>
                    <tr>
                        <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                            Payment Page Id
                        </td>
                        <td className="flex text-sm font-medium py-3 pl-[10px]">
                            {paymentPage?.id}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                            Link Type
                        </td>
                        <td className="text-sm font-medium py-3 flex gap-[7px] pl-[10px]">
                            <span className="text-lg">
                                <BiLink />
                            </span>
                            Payment Page
                        </td>
                    </tr>
                    <tr>
                        <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                            Status
                        </td>
                        <td className="py-3 pl-[10px]">
                            <div className="flex gap-[15px]">
                                <span
                                    className={
                                        "text-[11px] rounded px-[4.5px] py-[2.7px] " +
                                        (paymentPage?.isActive === true
                                            ? "bg-[#daf4eb] text-[#34c38f]"
                                            : "text-[#f46a6a] bg-[#fde4e4]")
                                    }
                                >
                                    {paymentPage?.isActive
                                        ? "Active"
                                        : "Disabled"}
                                </span>

                                <button
                                    className="block h-auto p-0 bg-transparent font-medium text-[13px] text-blue-500 cursor-pointer"
                                    onClick={() => setIsConfirmModalOpen(true)}
                                    disabled={isStatusUpdateLoading}
                                >
                                    {isStatusUpdateLoading
                                        ? paymentPage?.isActive
                                            ? "Deactivating..."
                                            : "Activating..."
                                        : paymentPage?.isActive
                                        ? "Deactivate"
                                        : "Activate"}
                                </button>

                                {isConfirmModalOpen && (
                                    <ConfirmModal
                                        handleClick={handleUpdateStatus}
                                        confirmText="Are you sure to cancel this payment link ?. This action can't be reverted."
                                    />
                                )}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                            Amount
                        </td>
                        <td className="text-sm font-medium py-3 pl-[10px]">
                            {paymentPage?.amountType === "fixed"
                                ? `${paymentPage?.amount}
                    ${paymentPage?.currency?.symbol}`
                                : "Non Fixed"}
                        </td>
                    </tr>
                    <tr>
                        <td className="flex text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                            Accepted Currenices
                        </td>
                        <td className="text-sm font-medium py-3 pl-[10px]">
                            <div className="flex flex-wrap items-center gap-[10px]">
                                {paymentPage?.acceptedCurrencies?.map(
                                    (currency, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="py-[5px] rounded px-[5px] bg-[#f6f6f6] dark:bg-darkCardColor flex items-center gap-[6px]"
                                            >
                                                <img
                                                    src={
                                                        process.env
                                                            .REACT_APP_SERVER_URL +
                                                        currency?.logo
                                                    }
                                                    alt=""
                                                    className="w-[20px] h-[20px] rounded-full object-cover"
                                                />
                                                <span>{currency?.symbol}</span>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                            Payment Page Url
                        </td>
                        <td className="text-sm font-medium py-3 pl-[10px]">
                            <a
                                href={`${process.env.REACT_APP_CLIENT_URL}/page/${paymentPage?.id}`}
                                target="blank"
                                className="text-primaryColor"
                            >
                                {`https://...${paymentPage?.id?.slice(0, 8)}`}
                            </a>
                            <button
                                className="bg-transparent text-textColor ml-1"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `${process.env.REACT_APP_CLIENT_URL}/page/${paymentPage?.id}`
                                    );
                                    setIsCopied(true);
                                }}
                            >
                                {isCopied ? <FaCheck /> : <FiCopy />}
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                            Contact Name
                        </td>
                        <td className="text-sm font-medium py-3 pl-[10px]">
                            {paymentPage?.contactName}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                            Contact Email
                        </td>
                        <td className="text-sm font-medium py-3 pl-[10px]">
                            {paymentPage?.contactEmail}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                            Created Date
                        </td>
                        <td className="text-sm font-medium py-3 pl-[10px]">
                            {formatDate(paymentPage?.updatedAt)}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                            Expiry
                        </td>
                        <td className="text-sm font-medium py-3 pl-[10px]">
                            {paymentPage?.expiryDate
                                ? formatDate(paymentPage?.expiryDate)
                                : "No Expiry"}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
