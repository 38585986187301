import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { addNewPorductRow } from "../../../redux/slices/invocesSlice";
import SingleProductRow from "./SingleProductRow";

export default function CreateInvoiceTable() {
    const { productsRows } = useSelector((state) => state.invoices);
    const dispatch = useDispatch();

    return (
        <div className="mt-[3em]">
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead className="bg-[#f6f6f6] dark:bg-darkCardColor">
                        <tr>
                            <th className="text-left font-[500] text-sm py-2 px-[10px]">
                                Item
                            </th>
                            <th className="text-right font-[500] text-sm py-2 px-[10px]">
                                Price/Item
                            </th>
                            <th className="text-right font-[500] text-sm py-2 px-[10px]">
                                Qty
                            </th>
                            <th className="text-right font-[500] text-sm py-2 px-[10px]">
                                Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {productsRows.map((rowData, index) => {
                            return (
                                <SingleProductRow
                                    key={index}
                                    rowData={rowData}
                                    rowIndex={index}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <span
                className="block text-sm text-blueColor cursor-pointer mt-4"
                onClick={() => dispatch(addNewPorductRow())}
            >
                + Add New Line
            </span>
        </div>
    );
}
