import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPresaleFormData } from "../../../redux/slices/presaleSlices";
import { BtnLoader } from "../../../components";

function PresalePagePersonal({
  setForms,
  isLoading,
  setImage,
  handleSubmit,
  error,
}) {
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.presales);
  const [selectedLogo, setSelectedLogo] = useState();

  const handleLogo = (e) => {
    const file = e.target.files[0];
    setImage(file);

    const reader = new FileReader();
    reader.onload = () => {
      setSelectedLogo(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div>
      <div className="flex justify-end">
        <button
          className="bg-white text-black dark:text-white  dark:bg-gray-900 border border-gray-600"
          onClick={() => {
            setForms({
              basic: true,
              personal: false,
            });
          }}
        >
          Previous
        </button>
      </div>
      <div>
        <div className="grid md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label htmlFor="">Logo</label>
            <input
              type="file"
              name="logo"
              onChange={(e) => {
                handleLogo(e);
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Website</label>
            <input
              type="text"
              placeholder="Enter Website URL"
              name="website"
              value={formData.website}
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "website",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Twitter</label>
            <input
              type="text"
              placeholder="Enter Twitter Account URL"
              name="twitter"
              value={formData.twitter}
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "twitter",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Facebook</label>
            <input
              type="text"
              value={formData.facebook}
              placeholder="Enter Twitter Account URL"
              name="facebook"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "facebook",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Github</label>
            <input
              type="text"
              value={formData.github}
              placeholder="Enter Telegram Account"
              name="github"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "github",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Telegram</label>
            <input
              type="text"
              value={formData.telegram}
              placeholder="Enter Dscord Account"
              name="telegram"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "telegram",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Descord</label>
            <input
              type="text"
              value={formData.descord}
              placeholder="Enter Dscord Account"
              name="descord"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "descord",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Reddit</label>
            <input
              type="text"
              value={formData.reddit}
              placeholder="Enter Reddit Account"
              name="reddit"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "reddit",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">YouTube</label>
            <input
              type="text"
              value={formData.youtube}
              placeholder="Enter YouTube Account"
              name="youtube"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "youtube",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Instagram</label>
            <input
              type="text"
              value={formData.instagram}
              placeholder="Enter Instagram Link"
              name="instagram"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "instagram",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="">Description</label>
          <textarea
            type="text"
            value={formData.description}
            placeholder="Enter Description"
            name="description"
            onChange={(e) => {
              dispatch(
                setPresaleFormData({
                  name: "description",
                  value: e.target.value,
                })
              );
            }}
          />
        </div>
        <div className="mt-5 mb-2 flex justify-end">
          {error && (
            <span className="text-red-500 block text-sm font-medium mb-3">
              {error}
            </span>
          )}
        </div>
        <button
          className="w-[100%] mb-4 hover:bg-btnHoverColor disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor"
          onClick={() => {
            handleSubmit();
          }}
        >
          {isLoading ? <BtnLoader /> : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default PresalePagePersonal;
