import React, { useCallback, useEffect, useState } from "react";
import { IoWarningOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";

import { successAnim } from "../../assets/gif";
import axios from "../../axios";
import { PaymentAccountBar } from "../../components";
import {
    SinglePaymentLinkDetailsSkeleton,
    SinglePaymentLinkFormSkeleton,
} from "../../features/SinglePaymentLink";
import SinglePaymentLinkDetails from "../../features/SinglePaymentLink/components/SinglePaymentLinkDetails";
import SinglePaymentLinkForm from "../../features/SinglePaymentLink/components/SinglePaymentLinkForm";

export default function SinglePaymentLinkPage() {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [error, setError] = useState("");
    const [paymentLink, setPaymentLink] = useState({});

    const { id } = useParams();

    const fetchPaymentLink = useCallback(async () => {
        try {
            const response = await axios.get(
                `/payment-links/single/${id}`
            );
            setPaymentLink(response.data);
            setIsPageLoading(false);
        } catch (err) {
            setError(true);
            setIsPageLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchPaymentLink();
    }, [fetchPaymentLink]);

    return (
        <div className="relative">
            {!error && (
                <div className="hidden md:block fixed top-0 left-0 w-[50vw] h-[100%] bg-[#f3f3f3] dark:bg-darkCardColor shadow-sm"></div>
            )}

            <PaymentAccountBar />

            {isPageLoading ? (
                <div className="relative grid md:grid-cols-2 md:min-h-[calc(100vh-70px)] max-w-[900px] gap-[2em] md:gap-[4em] lg:gap-[8em] md:px-[1.8em] mx-auto md:py-20">
                    <SinglePaymentLinkDetailsSkeleton />
                    <SinglePaymentLinkFormSkeleton />
                </div>
            ) : error ? (
                <div className="flex flex-col items-center justify-center min-h-[calc(100vh-4.2em)] md:min-h-[100vh] px-5">
                    <span className="block text-[110px] sm:text-[150px] md:text-[180px] font-[900]">
                        404
                    </span>
                    <span className="block text-xl font-[600] text-center">
                        We can't find the page you're looking for
                    </span>
                    <span className="text-sm text-grayColor dark:text-darkGrayColor block mt-4 text-center">
                        Visit our support page for further assistance.
                    </span>
                </div>
            ) : (
                <>
                    <div className="relative grid md:grid-cols-2 md:min-h-[calc(100vh-70px)] max-w-[900px] gap-[2em] md:gap-[4em] lg:gap-[8em] md:px-[1.8em] mx-auto md:py-20">
                        <SinglePaymentLinkDetails paymentLink={paymentLink} />
                        {paymentLink?.status === "paid" ? (
                            <div className="">
                                <div className="w-[140px] h-[140px] mx-auto">
                                    <img
                                        src={successAnim}
                                        alt=""
                                        className="w-[100%] h-[100%]"
                                    />
                                </div>
                                <h3 className="font-[600] text-xl text-center mt-3">
                                    Paymnet Completed !
                                </h3>
                                <span className="text-sm text-grayColor dark:text-darkGrayColor text-center block mt-3">
                                    This payment link is already completed
                                    paymnet.
                                </span>
                                <span className="block text-sm text-center text-grayColor dark:text-darkGrayColor mt-2">
                                    Transaction Id - #
                                    {paymentLink?.transactionId}
                                </span>

                                <Link
                                    to="/"
                                    className="text-sm underline text-blueColor text-center block mt-5"
                                >
                                    Back To Home
                                </Link>
                            </div>
                        ) : paymentLink?.expiryDate &&
                          new Date(paymentLink?.expiryDate).getDate() <
                              new Date().getDate() ? (
                            <div className="">
                                <div className="flex justify-center text-[100px] text-yellow-500">
                                    <IoWarningOutline />
                                </div>
                                <h3 className="font-[600] text-xl text-center mt-3">
                                    Link Expired !
                                </h3>
                                <span className="text-sm text-grayColor dark:text-darkGrayColor text-center block mt-3 leading-[28px]">
                                    This payment link is expired. Please contact
                                    to merchant to get new link.
                                </span>

                                <Link
                                    to="/"
                                    className="text-sm underline text-blueColor text-center block mt-5"
                                >
                                    Back To Home
                                </Link>
                            </div>
                        ) : (
                            <SinglePaymentLinkForm paymentLink={paymentLink} />
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
