import React, { useCallback, useEffect, useState } from "react";
import { BiMailSend } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import axios from "../../axios";
import {
  logoutUser,
  updateIsEmailVerified,
} from "../../redux/slices/userSlice";

export default function EmailConfirmPage() {
  const [timer, setTimer] = useState(0);
  const [timerActive, setTimerActive] = useState(false);
  const [status, setStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const { jwtToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const resendEmailHandler = async () => {
    try {
      setTimerActive(true);
      setTimer(45);

      await axios.post(
        "/users/resend-email",
        {},
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const verifyEmail = useCallback(async () => {
    try {
      await axios.post(
        "/users/verify-email",
        {
          emailVerificationToken: searchParams.get("emailVerificationToken"),
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      dispatch(updateIsEmailVerified(true));
      navigate("/");
    } catch (err) {
      setStatus(false);
      setIsLoading(false);
    }
  }, [dispatch, jwtToken, navigate, searchParams]);

  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  useEffect(() => {
    let interval = null;
    if (timerActive) {
      interval = window.setInterval(() => {
        if (timer <= 1) {
          setTimerActive(false);
          window.clearInterval(interval);
        }
        setTimer((prev) => {
          return prev - 1;
        });
      }, 1000);
    }

    return () => window.clearInterval(interval);
  }, [timerActive, timer]);

  return (
    <div className="flex items-center justify-center min-h-[calc(var(--doc-height)-4.2em)] sm:min-h-[calc(100vh-5em)] px-[20px] py-6">
      <div className="w-[100%] max-w-[450px]">
        <div className="relative">
          <div className="text-center">
            <div className="w-[72px] h-[72px] rounded-full flex items-center mx-auto justify-center bg-[#eff3f7] dark:bg-darkCardColor text-primaryColor text-3xl">
              <BiMailSend />
            </div>
            <h3
              className={
                "text-lg font-medium mt-4 " +
                (isLoading
                  ? "text-textColor"
                  : status === true
                  ? "text-green-500"
                  : "text-red-500")
              }
            >
              {isLoading ? "Verifying..." : status === false ? "Failed !" : ""}
            </h3>
            {isLoading ? (
              <span className=" text-[14px] block text-grayColor dark:text-darkGrayColor mt-3 leading-[28px]">
                Your Email address is verifying by SimplePay. After Verification
                you will be automatically redirected to Next page.
              </span>
            ) : (
              status === false && (
                <span className=" text-[14px] block text-grayColor dark:text-darkGrayColor mt-3 leading-[28px]">
                  Your Email Verification Failed. May be this is because of the
                  link is invalid, or expired. Please resend a new link and try
                  again.
                </span>
              )
            )}

            {isLoading ? (
              <div className="w-[30px] h-[30px] rounded-full border-4 border-r-transparent animate-spin border-primaryColor mx-auto mt-5"></div>
            ) : (
              status === false && (
                <button
                  className="mt-5 bg-greenColor hover:bg-greenHoverColor"
                  onClick={() => {
                    dispatch(logoutUser());
                    navigate("/auth/login");
                  }}
                >
                  Login With different E-mail
                </button>
              )
            )}
          </div>
        </div>
        <div className="text-center mt-5">
          {status === false && (
            <span className="block text-[13px] mb-[6px]">
              Resend Confirmation Email ?{" "}
              {timer > 0 ? (
                <span className="text-primaryColor font-medium cursor-not-allowed">
                  Resend in {timer} s
                </span>
              ) : (
                <button
                  className="p-0 h-auto bg-transparent text-primaryColor font-medium cursor-pointer"
                  onClick={resendEmailHandler}
                  disabled={isLoading}
                >
                  Resend
                </button>
              )}
            </span>
          )}
          <span className="block text-[13px]">
            &#169; SimplePay {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </div>
  );
}
