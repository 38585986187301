import React from "react";

export default function MerchantDetailsForm({ data, setData }) {
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    return (
        <div>
            <div>
                <label htmlFor="">Business Type</label>
                <select
                    name="businessType"
                    id=""
                    className="w-full"
                    onChange={handleChange}
                >
                    <option value="" hidden>
                        Select Business Type
                    </option>
                    <option value="individual">Individual</option>
                    <option value="partnership">Partnership</option>
                    <option value="government">Government Entity</option>
                    <option value="other">Other Coorporate Body</option>
                </select>
            </div>
            <div className="mt-4">
                <label htmlFor="">Website (optional)</label>
                <input
                    type="text"
                    placeholder="Enter Website URL"
                    name="website"
                    onChange={handleChange}
                />
            </div>
            <div className="mt-4">
                <label htmlFor="">Personal PAN Number</label>
                <input
                    type="text"
                    placeholder="Enter PAN number"
                    name="panNumber"
                    onChange={handleChange}
                />
                <span className="block mt-[7px] text-grayColor text-[13px] leading-[22px]">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Assumenda animi consequuntur totam. Iusto
                </span>
            </div>
        </div>
    );
}
