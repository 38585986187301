import React, { useState } from "react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { useSelector } from "react-redux";

import { avatarImg } from "../../../assets/images";
import { formatBalance, formatDate } from "../../../utils";
import TxnModal from "./TxnModal";

export default function TransactionsTable() {
    const [isTxnModalOpen, setIsTxnModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState({});

    const { user } = useSelector((state) => state.user);
    const { transactions } = useSelector((state) => state.transactions);

    return (
        <>
            <div className="overflow-x-auto">
                <table className="w-[100%]">
                    <thead className="hidden sm:table-header-group text-left bg-[#f6f6f6] dark:bg-darkCardColor">
                        <tr className="text-[13px] text-grayColor dark:text-darkGrayColor">
                            <th className="p-[12px] font-[600]">Txn Id</th>
                            <th className="font-[600] p-[12px]">Details</th>
                            <th className="font-[600] p-[12px]">Amount</th>
                            <th className="font-[600] p-[12px]">Type</th>
                            <th className="font-[600] p-[12px]">Date</th>
                            <th className="font-[600] p-[12px]">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions?.map((transaction, index) => {
                            const isSender =
                                transaction?.sender?._id === user?._id;
                            const type = transaction?.transactionType;

                            return (
                                <tr
                                    key={index}
                                    className="text-[13px] border-b dark:border-darkBorderColor hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor cursor-pointer"
                                    onClick={() => {
                                        setSelectedTransaction(transaction);
                                        setIsTxnModalOpen(true);
                                    }}
                                >
                                    <td className="hidden sm:table-cell pl-0 p-[12px]">
                                        #{transaction?.transactionId}
                                    </td>
                                    <td className="p-[12px] pl-0 sm:pl-[12px] py-[17px] sm:py-[12px] whitespace-nowrap">
                                        <div className="flex items-center gap-[1em]">
                                            <div className="w-[35px] h-[35px] min-w-[35px] min-h-[35px] rounded overflow-hidden flex items-center justify-center bg-[#e5e7eb] dark:bg-darkCardColor text-lg">
                                                {type === "transfer" ? (
                                                    <img
                                                        src={
                                                            isSender
                                                                ? transaction
                                                                      ?.receiver
                                                                      ?.avatar
                                                                    ? process
                                                                          .env
                                                                          .REACT_APP_SERVER_URL +
                                                                      transaction
                                                                          ?.receiver
                                                                          ?.avatar
                                                                    : avatarImg
                                                                : transaction
                                                                      ?.sender
                                                                      ?.avatar
                                                                ? process.env
                                                                      .REACT_APP_SERVER_URL +
                                                                  transaction
                                                                      ?.sender
                                                                      ?.avatar
                                                                : avatarImg
                                                        }
                                                        alt=""
                                                        className="w-[100%] h-[100%] object-cover"
                                                    />
                                                ) : type === "withdraw" ||
                                                  (type === "payment" &&
                                                      isSender) ? (
                                                    <span className="text-red-500">
                                                        <MdArrowUpward />
                                                    </span>
                                                ) : type === "deposit" ||
                                                  (type === "payment" &&
                                                      !isSender) ? (
                                                    <span className="text-green-500">
                                                        <MdArrowDownward />
                                                    </span>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div>
                                                <h4 className="font-[500] text-[14px]">
                                                    {type === "transfer"
                                                        ? isSender
                                                            ? "Transferred To"
                                                            : "Received From"
                                                        : type === "payment"
                                                        ? isSender
                                                            ? "Paid To"
                                                            : "Paid by"
                                                        : type === "withdraw"
                                                        ? "Withdrawn To"
                                                        : type === "deposit"
                                                        ? "Deposited To"
                                                        : ""}
                                                </h4>
                                                <span className="block text-[14px] text-grayColor dark:text-darkGrayColor capitalize mt-[2px] sm:mt-0">
                                                    {type === "transfer"
                                                        ? isSender
                                                            ? transaction
                                                                  ?.receiver
                                                                  ?.name
                                                            : transaction
                                                                  ?.sender?.name
                                                        : type === "deposit"
                                                        ? "Your Account"
                                                        : type === "payment"
                                                        ? isSender
                                                            ? transaction
                                                                  ?.receiver
                                                                  ?.name
                                                            : transaction
                                                                  ?.sender?.name
                                                        : type === "withdraw"
                                                        ? `${transaction?.receiverAddress?.slice(
                                                              0,
                                                              6
                                                          )}...${transaction?.receiverAddress?.slice(
                                                              -6
                                                          )}`
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="p-[12px] pr-0 sm:pr-[12px] whitespace-nowrap">
                                        <div className="flex items-center justify-end sm:justify-start gap-[10px]">
                                            <div className="hidden sm:block w-[17px] h-[17px] min-w-[17px] min-h-[17px] rounded-full overflow-hidden">
                                                <img
                                                    src={
                                                        process.env
                                                            ?.REACT_APP_SERVER_URL +
                                                        transaction?.currency
                                                            ?.logo
                                                    }
                                                    alt=""
                                                    className="w-[100%] h-[100%] object-cover"
                                                />
                                            </div>
                                            <div className="block text-[15px]">
                                                <span className="font-[500] ">
                                                    {transaction?.amount
                                                        ? formatBalance(
                                                              transaction?.amount
                                                          )
                                                        : 0}{" "}
                                                </span>
                                                <span className="">
                                                    {
                                                        transaction?.currency
                                                            ?.symbol
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <span className="text-right block text-sm text-grayColor dark:text-darkGrayColor mt-1 sm:hidden">
                                            {formatDate(transaction?.createdAt)}
                                        </span>
                                        {transaction?.status === "failed" && (
                                            <span className="text-right  block text-sm text-red-500 mt-1 sm:hidden">
                                                Failed <span>!</span>
                                            </span>
                                        )}
                                        {transaction?.status === "pending" && (
                                            <span className="text-right block text-sm text-gray-400 mt-1 sm:hidden">
                                                Pending <span>!</span>
                                            </span>
                                        )}
                                    </td>
                                    <td className="hidden sm:table-cell  p-[12px] capitalize">
                                        {transaction.transactionType}
                                    </td>
                                    <td className="hidden sm:table-cell p-[12px]">
                                        {formatDate(transaction?.createdAt)}
                                    </td>
                                    <td className="hidden sm:table-cell p-[12px] pr-0">
                                        <span
                                            className={
                                                "text-[11px] rounded px-[4.5px] py-[2.7px] capitalize " +
                                                (transaction?.status ===
                                                "completed"
                                                    ? "bg-[#daf4eb] text-[#34c38f]"
                                                    : transaction?.status ===
                                                      "failed"
                                                    ? "text-[#f46a6a] bg-[#fde4e4]"
                                                    : "text-gray-500 bg-gray-100")
                                            }
                                        >
                                            {transaction?.status}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {isTxnModalOpen && (
                <TxnModal
                    setIsTxnModalOpen={setIsTxnModalOpen}
                    transaction={selectedTransaction}
                />
            )}
        </>
    );
}
