import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../../axios";
import { changePrimaryAccountId } from "../../../redux/slices/userSlice";

export default function AccountSelect({
    account,
    index,
    selectedAccount,
    setSelectedAccount,
}) {
    const [isLoading, setIsLoading] = useState(false);

    const { jwtToken, user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const changePrimaryAccount = async (accountId) => {
        try {
            setIsLoading(true);
            const response = await axios.post(
                "/users/change-primary-account",
                {
                    accountId,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            dispatch(changePrimaryAccountId(response.data?.accountId));
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setSelectedAccount(user?.primaryAccountId);
        }
    };

    return (
        <label
            htmlFor={`account-select-${account?._id}`}
            className="p-3 flex flex-wrap gap-[10px] items-center justify-between bg-gradient-to-r from-[#f4f4f4] via-[#fdfdfd] to-[#f4f4f4] dark:from-darkCardColor dark:via-darkCardColor dark:to-darkCardColor mb-4 rounded"
        >
            <div className="flex items-center gap-[1em]">
                <input
                    type="radio"
                    name="account-select"
                    value={account?._id}
                    id={`account-select-${account?._id}`}
                    className="w-[14px] h-[14px]"
                    checked={selectedAccount?._id === account?._id}
                    onChange={(e) => {
                        setSelectedAccount({
                            _id: e.target.value,
                        });
                        changePrimaryAccount(e.target.value);
                    }}
                    disabled={isLoading}
                />
                <div>
                    <span className="block font-medium text-[15px]">
                        Account {index + 1}
                    </span>
                    <span className="block text-sm text-grayColor dark:text-darkGrayColor font-normal">
                        {account._id}
                    </span>
                </div>
            </div>
            {isLoading
                ? "Loading..."
                : user?.primaryAccountId === account._id && (
                      <span className="italic text-[13px] text-right">
                          Primary Wallet address
                      </span>
                  )}
        </label>
    );
}
