import { useSelector } from "react-redux";

import { getPrice } from "../../../utils";

const useSingleInvoiceAmount = (invoice, selectedCurrency) => {
    const { prices } = useSelector((state) => state.currencies);

    const amount =
        selectedCurrency?.symbol === "USDT"
            ? invoice?.amount
            : (1 / getPrice(prices, selectedCurrency)) * invoice?.amount;

    return amount;
};

export default useSingleInvoiceAmount;
