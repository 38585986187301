import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useSelector } from "react-redux";

export default function PaymentAcceptedCurrency({
  acceptedCurrencies,
  setAcceptedCurrencies,
}) {
  const { currencies, isFetching } = useSelector((state) => state.currencies);

  const [isAllCurrenciesDropdownOpen, setIsAllCurrenciesDropdownOpen] =
    useState("");
  const [allCurrencies, setAllCurrencies] = useState(currencies);

  const addAcceptedCurrency = (id) => {
    allCurrencies.map((currency) => {
      if (currency._id === id) {
        setAcceptedCurrencies([...acceptedCurrencies, currency]);
      }
      return currency;
    });

    let filteredAllCurrencies = allCurrencies.filter((curr) => {
      return curr._id !== id;
    });
    setAllCurrencies(filteredAllCurrencies);
  };

  const removeAcceptedCurrency = (id) => {
    const filteredCurrencies = acceptedCurrencies.filter((currency) => {
      if (currency._id === id) {
        setAllCurrencies([...allCurrencies, currency]);
      }
      return currency._id !== id;
    });
    setAcceptedCurrencies(filteredCurrencies);
  };

  return (
    <div>
      {acceptedCurrencies?.length > 0 && (
        <div className="flex items-center gap-[10px] flex-wrap mb-3">
          {acceptedCurrencies?.map((currency, index) => {
            return (
              <div
                key={index}
                className="border dark:border-darkBorderColor flex items-center gap-[10px] px-2 rounded text-sm bg-[#f6f6f6] dark:bg-darkBgColor py-[4px] cursor-pointer"
                onClick={() => {
                  removeAcceptedCurrency(currency._id);
                }}
              >
                <span>{currency.symbol}</span>
                <span>x</span>
              </div>
            );
          })}
        </div>
      )}

      <div className="relative w-full">
        <div
          className="border border-borderColor dark:border-darkBorderColor dark:bg-darkBgColor cursor-pointer w-full flex items-center gap-[10px] justify-between h-[45px] rounded px-[15px]"
          onClick={() => {
            if (!isFetching) {
              setIsAllCurrenciesDropdownOpen(!isAllCurrenciesDropdownOpen);
            }
          }}
        >
          {isFetching ? (
            <>
              <div className="flex items-center gap-[10px]">
                <div className="w-[25px] h-[25px] rounded-full bg-slate-300 dark:bg-slate-600 animate-pulse"></div>
                <span className="h-[14px] w-[100px] bg-slate-300 dark:bg-slate-600 block animate-pulse"></span>
              </div>
              <div className="w-[20px] h-[20px] animate-spin rounded-full border-4 border-r-transparent border-primaryColor"></div>
            </>
          ) : (
            <>
              <div className="flex items-center gap-[10px]">
                <span className="text-sm">Choose Curencies</span>
              </div>
              <div>
                <FiChevronDown />
              </div>
            </>
          )}
        </div>
        {isAllCurrenciesDropdownOpen && (
          <div className="absolute top-[100%] z-10 left-0 rounded max-h-[300px] overflow-y-auto w-full bg-white dark:bg-darkBgColor shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
            {allCurrencies?.length < 1 && (
              <span className="block py-[12px] text-center text-sm text-grayColor dark:text-darkGrayColor">
                Empty
              </span>
            )}
            {currencies
              ?.filter((curr) => {
                return !acceptedCurrencies?.some(
                  (accCurr) => accCurr._id === curr._id
                );
              })
              ?.map((currency, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between transition-all  hover:bg-darkCardHoverColor px-[15px] py-[7.5px] cursor-pointer"
                    onClick={() => {
                      addAcceptedCurrency(currency?._id);
                      setIsAllCurrenciesDropdownOpen(false);
                    }}
                  >
                    <div className="flex items-center gap-[10px] text-sm">
                      <div className="w-[25px] h-[25px] rounded-full overflow-hidden">
                        <img
                          src={
                            process.env?.REACT_APP_SERVER_URL + currency?.logo
                          }
                          alt=""
                          className="w-[100%] h-[100%] object-cover"
                        />
                      </div>
                      <span>
                        {currency?.name} - {currency?.symbol}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}
