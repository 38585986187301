import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHandleClickOutside } from "../../hooks";
import { Link } from "react-router-dom";
import { BiLogOutCircle, BiUser } from "react-icons/bi";
import { logoutAdmin } from "../../redux/slices/admin/adminSlice";

function AdminProfileDropDown({ setIsDropdownAcc }) {
  const wrapperRef = useRef();
  const dispatch = useDispatch();

  useHandleClickOutside(wrapperRef, () => setIsDropdownAcc(false));

  return (
    <div
      ref={wrapperRef}
      className="bg-white dark:bg-darkCardColor rounded absolute right-0 top-[54px] min-w-[160px] shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] z-10"
    >
      <>
        <Link
          // to="/settings/profile"
          className="flex items-center gap-[10px] text-[13px] py-[6px] px-5 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor "
          onClick={() => setIsDropdownAcc(false)}
        >
          <BiUser />
          Profile
        </Link>
      </>

      {/* 
    {isLoggedIn && (
        <div className="border-t dark:border-darkBorderColor py-2">
            <span className="block text-sm px-5">
                @{user?.username}
            </span>
            {user?.mobileNumber && (
                <span className="block text-[13px] px-5 text-grayColor dark:text-darkGrayColor mt-[3px]">
                    {user?.phonecode} {user?.mobileNumber}
                </span>
            )}
        </div>
    )} */}
      <span
        className="text-[#f46a6a] border-t border-borderColor dark:border-darkBorderColor flex items-center gap-[10px] cursor-pointer text-[13px] py-[6px] px-5 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
        onClick={() => {
          setIsDropdownAcc(false);
          dispatch(logoutAdmin());
        }}
      >
        <BiLogOutCircle />
        Logout
      </span>
    </div>
  );
}

export default AdminProfileDropDown;
