import React, { useState } from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils";
import PaymentLinkDetailsModal from "./PaymentLinkDetailsModal";

export default function PaymentLinksTable() {
    const [isPaymentLinkDetailsModalOpen, setIsPaymentLinkDetailsModalOpen] =
        useState(false);
    const [selectedPaymentLink, setSelectedPaymentLink] = useState({});

    const { paymentLinks } = useSelector((state) => state.paymentLinks);

    return (
        <>
            <div className="overflow-x-auto">
                <table className="w-[100%]">
                    <thead className="text-left bg-[#f6f6f6] dark:bg-darkCardColor">
                        <tr className="text-[13px]">
                            <th className="p-[12px] font-[600]">Payment Link Id</th>
                            <th className="font-[600] p-[12px]">
                                Created Date
                            </th>
                            <th className="font-[600] p-[12px]">Amount</th>
                            <th className="font-[600] p-[12px]">Payment For</th>
                            <th className="font-[600] p-[12px]">
                                Payment Expiry
                            </th>
                            <th className="font-[600] p-[12px]">
                                Payment Link
                            </th>
                            <th className="font-[600] p-[12px]">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentLinks?.map((item, index) => {
                            return (
                                <tr
                                    className="border-b dark:border-darkBorderColor text-[13px] hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                                    key={index}
                                >
                                    <td
                                        className="pl-[2px] pr-[12px] py-[19px] underline text-blueColor cursor-pointer"
                                        onClick={() => {
                                            setSelectedPaymentLink(item);
                                            setIsPaymentLinkDetailsModalOpen(
                                                true
                                            );
                                        }}
                                    >
                                        {item?.id}
                                    </td>
                                    <td className="px-[12px] py-[19px]">
                                        {formatDate(item?.createdAt)}
                                    </td>
                                    <td className="px-[12px] py-[19px] whitespace-nowrap">
                                        {item?.amount} {item?.currency?.symbol}
                                    </td>
                                    <td className="px-[12px] py-[19px]">
                                        {item?.paymentFor}
                                    </td>
                                    <td className="px-[12px] py-[19px]">
                                        {item?.expiryDate
                                            ? formatDate(item?.expiryDate)
                                            : "No Expiry"}
                                    </td>
                                    <td className="px-[12px] py-[19px] whitespace-nowrap">
                                        <a
                                            href={`${process.env.REACT_APP_CLIENT_URL}/link/${item?.id}`}
                                            target="blank"
                                            className="text-primaryColor"
                                        >
                                            {`https://...${item?.id?.slice(
                                                -8
                                            )}`}
                                        </a>
                                    </td>
                                    <td className="pr-[2px] pl-[12px] py-[19px] whitespace-nowrap">
                                        <span
                                            className={
                                                "text-[11px] rounded px-[4.5px] py-[2.7px] " +
                                                (item?.status === "paid"
                                                    ? "bg-[#daf4eb] text-[#34c38f]"
                                                    : item?.status === "unpaid"
                                                    ? "text-[#f46a6a] bg-[#fde4e4]"
                                                    : "text-gray-500 bg-gray-100")
                                            }
                                        >
                                            {item?.status}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {isPaymentLinkDetailsModalOpen && (
                <PaymentLinkDetailsModal
                    setIsPaymentLinkDetailsModalOpen={
                        setIsPaymentLinkDetailsModalOpen
                    }
                    id={selectedPaymentLink?.id}
                />
            )}
        </>
    );
}
