function sendNotification({ title, body, url }) {
    if (!("Notification" in window)) {
        alert("This browser does not support system notifications!");
    } else if (Notification.permission === "granted") {
        send(title, body, url);
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission((permission) => {
            if (permission === "granted") {
                send(title, body, url);
            }
        });
    }
}

const send = (title, body, url) => {
    const notification = new Notification(title, {
        icon: "https://api.hami.live/public/images/tokens/logo-1660913831311-795928585.png",
        body: body,
    });

    notification.onclick = () =>
        function () {
            window.open(url);
        };
};

export default sendNotification;
