import React from "react";
import { GiConfirmed } from "react-icons/gi";
import { Link } from "react-router-dom";
import { successAnim } from "../../../assets/gif";
import { getFormatedDate } from "../../../utils";

export default function TransferSuccessSection({
    section,
    clearData,
    transferResponse,
}) {
    return (
        <div className={"w-[100%] " + (section === 4 ? "block" : "hidden")}>
            <div className="w-[140px] h-[140px] mx-auto">
                <img src={successAnim} alt="" className="w-[100%] h-[100%]" />
            </div>
            <h3 className="font-[600] text-xl text-center mt-3">Success !</h3>
            <span className="text-sm text-grayColor dark:text-darkGrayColor leading-[28px] block mt-2 text-center">
                You have successfully transfered {transferResponse?.amount}{" "}
                {transferResponse?.currency?.symbol} to{" "}
                {transferResponse?.receiver?.name}. Transaction Reference Id is
                #{transferResponse?.transactionId}
            </span>
            <div className="text-center text-sm text-grayColor dark:text-darkGrayColor block mt-2">
                <span>
                    {getFormatedDate(transferResponse?.createdAt, true)}
                </span>
            </div>
            <Link to="/transactions">
                <button className="w-[100%] mt-6 hover:bg-btnHoverColor">
                    View Details
                </button>
            </Link>
            <button
                className="bg-transparent text-textColor w-full mt-2"
                onClick={clearData}
            >
                Go Back
            </button>
        </div>
    );
}
