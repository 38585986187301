import React, { useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import { VscClose } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { setIsKycModalOpen } from "../redux/slices/layoutSlice";
import { setKycData } from "../redux/slices/kycSlice";
import BtnLoader from "./BtnLoader";
import axios from "../axios";

export default function KycModal() {
  const [section, setSection] = useState(1);
  const [img, setImg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedImg, setSelectedImg] = useState("");

  const { kycDetails } = useSelector((state) => state.kyc);
  const { jwtToken } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleImg = (e) => {
    const file = e.target.files[0];
    setImg(file);

    if (file) {
      const url = URL.createObjectURL(file);
      setSelectedImg(url);
    }
  };

  const formData = new FormData();
  formData.append("firstName", kycDetails.firstName);
  formData.append("lastName", kycDetails.lastName);
  formData.append("country", kycDetails.country);
  formData.append("date_of_birth", kycDetails.date_of_birth);
  formData.append("pan_number", kycDetails.pan_number);
  formData.append("gender", kycDetails.gender);
  formData.append("address", kycDetails.address);
  formData.append("city", kycDetails.city);
  formData.append("pincode", kycDetails.pincode);
  formData.append("VatOrTin", kycDetails.VatOrTin);
  formData.append("phone", kycDetails.phone);
  formData.append("CSTNo", kycDetails.CSTNo);
  formData.append("service_tax_no", kycDetails.service_tax_no);
  formData.append("company_UIN", kycDetails.company_UIN);
  formData.append("declaration", kycDetails.declaration);
  formData.append("idProofImg", img);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const res = await axios.post(`/kyc/add`, formData, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setIsLoading(false);
      dispatch(setIsKycModalOpen(false));
    } catch (error) {
      setError(error.response.data.error || "somthing went wrong");
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-[#fff5] dark:bg-[#0005] flex items-center justify-center z-20"
      id="kyc-modal"
      onClick={(e) => {
        if (e.target === document.getElementById("kyc-modal")) {
          dispatch(setIsKycModalOpen(false));
        }
      }}
    >
      <div className="bg-[#fff] dark:bg-darkCardColor max-w-[800px] min-h-[490px] rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
        <div className="flex items-center justify-between border-b border-borderColor dark:border-darkBorderColor px-4 py-3">
          <h3 className="font-medium">Verify your Account</h3>
          <button
            className="bg-transparent text-textColor dark:text-darkTextColor p-0 text-xl"
            onClick={() => dispatch(setIsKycModalOpen(false))}
          >
            <VscClose />
          </button>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-4">
            <div
              className={
                "flex items-center gap-[10px] py-2 px-4 " +
                (section === 1
                  ? "bg-[#dde2fa] dark:bg-[#355068]"
                  : "bg-[#eef0fc] dark:bg-darkCardHoverColor")
              }
            >
              <div
                className={
                  "w-[38px] h-[38px] min-w-[38px] min-h-[38px] rounded-full flex items-center justify-center font-medium " +
                  (section === 1
                    ? "bg-blueColor text-[#fff]"
                    : "border border-blueColor text-blueColor")
                }
              >
                1
              </div>
              <span className="font-medium text-[14px]">Personal Info</span>
            </div>
            <div
              className={
                "flex items-center gap-[10px] py-2 px-4 " +
                (section === 2
                  ? "bg-[#dde2fa] dark:bg-[#355068]"
                  : "bg-[#eef0fc] dark:bg-darkCardHoverColor")
              }
            >
              <div
                className={
                  "w-[38px] h-[38px] min-w-[38px] min-h-[38px] rounded-full flex items-center justify-center font-medium " +
                  (section === 2
                    ? "bg-blueColor text-[#fff]"
                    : "border border-blueColor text-blueColor")
                }
              >
                2
              </div>
              <span className="font-medium text-[14px]">Confirm Address</span>
            </div>
            <div
              className={
                "flex items-center gap-[10px] py-2 px-4 " +
                (section === 3
                  ? "bg-[#dde2fa] dark:bg-[#355068]"
                  : "bg-[#eef0fc] dark:bg-darkCardHoverColor")
              }
            >
              <div
                className={
                  "w-[38px] h-[38px] min-w-[38px] min-h-[38px] rounded-full flex items-center justify-center font-medium " +
                  (section === 3
                    ? "bg-blueColor text-[#fff]"
                    : "border border-blueColor text-blueColor")
                }
              >
                3
              </div>
              <span className="font-medium text-[14px]">Document Details</span>
            </div>
            <div
              className={
                "flex items-center gap-[10px] py-2 px-4 " +
                (section === 4
                  ? "bg-[#dde2fa] dark:bg-[#355068]"
                  : "bg-[#eef0fc] dark:bg-darkCardHoverColor")
              }
            >
              <div
                className={
                  "w-[38px] h-[38px] min-w-[38px] min-h-[38px] rounded-full flex items-center justify-center font-medium " +
                  (section === 4
                    ? "bg-blueColor text-[#fff]"
                    : "border border-blueColor text-blueColor")
                }
              >
                3
              </div>
              <span className="font-medium text-[14px]">
                Document Verification
              </span>
            </div>
          </div>

          <div className="p-3">
            <div className={section === 1 ? "block" : "hidden"}>
              <div className="grid grid-cols-2 gap-[24px] mt-3">
                <div>
                  <label htmlFor="">First Name</label>
                  <input
                    value={kycDetails.firstName || ""}
                    name="firstName"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter First Name"
                  />
                </div>
                <div>
                  <label htmlFor="">Last Name</label>
                  <input
                    value={kycDetails.lastName || ""}
                    name="lastName"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter Last Name"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-[24px] mt-4">
                <div>
                  <label htmlFor="">Phone</label>
                  <input
                    value={kycDetails.phone || ""}
                    name="phone"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter Phone number"
                  />
                </div>
                <div>
                  <label htmlFor="">Date of Birth</label>
                  <input
                    value={kycDetails.date_of_birth || ""}
                    name="date_of_birth"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="date"
                    placeholder="Enter Date of Birth"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-[24px] mt-4">
                <div>
                  <label htmlFor="">Country</label>
                  <input
                    value={kycDetails.country || ""}
                    name="country"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter Phone number"
                  />
                </div>
                <div>
                  <label htmlFor="">City</label>
                  <input
                    value={kycDetails.city || ""}
                    name="city"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter Date of Birth"
                  />
                </div>
              </div>

              <div className="flex items-center gap-[10px] justify-end mt-5">
                <button
                  className="bg-blueColor disabled:bg-opacity-60"
                  type="button"
                  disabled
                >
                  Previous
                </button>
                <button
                  className="hover:bg-blueHoverColor"
                  onClick={() => setSection(2)}
                >
                  Next
                </button>
              </div>
            </div>
            <div className={section === 2 ? "block" : "hidden"}>
              <div className="grid grid-cols-2 gap-[24px] mt-3">
                <div>
                  <label htmlFor="">Gender</label>
                  <select
                    value={kycDetails.gender}
                    className="w-full"
                    name="gender"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    id=""
                  >
                    <option value="">Choose Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="">Address</label>
                  <input
                    value={kycDetails.address || ""}
                    name="address"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter Current Address"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-[24px] mt-4">
                <div>
                  <label htmlFor="">PIN Code </label>
                  <input
                    value={kycDetails.pincode}
                    name="pincode"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="number"
                    placeholder="Enter PIN Code"
                  />
                </div>
              </div>
              {/* <div className="grid grid-cols-2 gap-[24px] mt-4">
                <div>
                  <label htmlFor="">Company UIN</label>
                  <input
                    name="company_UIN"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter Company UIN"
                  />
                </div>
                <div>
                  <label htmlFor="">Declaration</label>
                  <input
                    name="declaration"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter Declaration"
                  />
                </div>
              </div> */}
              <div className="flex items-center gap-[10px] justify-end mt-5">
                <button
                  className="bg-blueColor hover:bg-blueHoverColor"
                  type="button"
                  onClick={() => setSection(1)}
                >
                  Previous
                </button>
                <button
                  className="hover:bg-blueHoverColor"
                  onClick={() => setSection(3)}
                >
                  Next
                </button>
              </div>
            </div>
            <div className={section === 3 ? "block" : "hidden"}>
              <div className="grid grid-cols-2 gap-[24px] mt-3">
                <div>
                  <label htmlFor="">PAN Card</label>
                  <input
                    value={kycDetails.pan_number}
                    name="pan_number"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter PAN Card No."
                  />
                </div>
                <div>
                  <label htmlFor="">VAT/TIN No</label>
                  <input
                    value={kycDetails.VatOrTin || ""}
                    name="VatOrTin"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter VAT/TIN No"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-[24px] mt-4">
                <div>
                  <label htmlFor="">CST No</label>
                  <input
                    value={kycDetails.CSTNo || ""}
                    name="CSTNo"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter CST No"
                  />
                </div>
                <div>
                  <label htmlFor="">Service Tax No</label>
                  <input
                    value={kycDetails.service_tax_no || ""}
                    name="service_tax_no"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter Service Tax No"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-[24px] mt-4">
                <div>
                  <label htmlFor="">Company UIN</label>
                  <input
                    value={kycDetails.company_UIN || ""}
                    name="company_UIN"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter Company UIN"
                  />
                </div>
                <div>
                  <label htmlFor="">Declaration</label>
                  <input
                    value={kycDetails.declaration || ""}
                    name="declaration"
                    onChange={(e) => {
                      dispatch(
                        setKycData({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      );
                    }}
                    type="text"
                    placeholder="Enter Declaration"
                  />
                </div>
              </div>
              <div className="flex items-center gap-[10px] justify-end mt-5">
                <button
                  className="bg-blueColor hover:bg-blueHoverColor"
                  type="button"
                  onClick={() => setSection(2)}
                >
                  Previous
                </button>
                <button
                  className="hover:bg-blueHoverColor"
                  onClick={() => setSection(4)}
                >
                  Next
                </button>
              </div>
            </div>
            <div className={section === 4 ? "block" : "hidden"}>
              <div>
                <label htmlFor="">
                  Upload document file for a verification
                </label>
                <div className="relative w-[100%] h-[190px] border-dashed border-2 rounded  ">
                  {!selectedImg ? (
                    <div className="absolute inset-0 flex flex-col items-center justify-center">
                      <span className="text-5xl">
                        <FiUploadCloud />
                      </span>
                      <span className="block mt-3 font-medium">
                        Drop file here or click to upload
                      </span>
                    </div>
                  ) : (
                    <div className="absolute inset-0 flex flex-col items-center justify-center ">
                      <img
                        className="object-fill w-full h-full"
                        src={selectedImg}
                        alt=""
                      />
                    </div>
                  )}
                  <input
                    onChange={handleImg}
                    type="file"
                    className="h-[100%] w-[100%] opacity-0 cursor-pointer"
                  />
                </div>
                <div className="flex items-center gap-[10px] justify-end mt-5">
                  <button
                    className="bg-blueColor hover:bg-blueHoverColor"
                    type="button"
                    onClick={() => setSection(3)}
                  >
                    Previous
                  </button>
                  {!isLoading ? (
                    <button
                      className="hover:bg-blueHoverColor"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Finish
                    </button>
                  ) : (
                    <button className="hover:bg-blueHoverColor">
                      <BtnLoader />
                    </button>
                  )}
                </div>
              </div>
            </div>
            {error ? (
              <div>
                <h1 className="text-red-500">{error}</h1>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
