import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { BiTimeFive } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { avatarImg } from "../../assets/images";
import axios from "../../axios";
import {
  setAllNotificationAsRead,
  setAllNotifications,
  setNotificationAsRead,
} from "../../redux/slices/userSlice";

export default function NotificationsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const { jwtToken, user, allNotifications, totalUnReadNotifications } =
    useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await axios.get("/notifications/user/all", {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });

      dispatch(setAllNotifications(response.data));
      setIsLoading(false);
    } catch (err) {
      setError(true);
    }
  }, [dispatch, jwtToken]);

  const updateNotificationAsRead = async (notification) => {
    try {
      if (
        (notification?.isGlobalNotification === true &&
          !notification?.openedUsers?.includes(user?._id)) ||
        (notification?.isGlobalNotification === false &&
          notification.isOpened === false)
      ) {
        const response = await axios.patch(
          `/notifications/read/${notification?._id}`,
          {},
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        console.log(response.data);
        dispatch(
          setNotificationAsRead({
            notificationId: notification._id,
            userId: user._id,
            name: "allNotifications",
          })
        );
      }

      if (notification?.url) {
        if (notification.urlType === "relative") {
          navigate(notification?.url);
        } else {
          window.open(notification?.url);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateAllAsRead = async () => {
    try {
      if (allNotifications.length > 0) {
        await axios.patch(
          "/notifications/all/read",
          {},
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        dispatch(
          setAllNotificationAsRead({
            userId: user?._id,
            name: "allNotifications",
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return (
    <div className="py-5 sm:py-6">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-[17px] font-[600]">Notification</h1>
        <button
          className="text-[10px] text-blueColor hidden sm:block bg-transparent h-auto p-0 font-medium disabled:cursor-not-allowed"
          onClick={updateAllAsRead}
          disabled={
            allNotifications?.length < 1 || totalUnReadNotifications <= 0
          }
        >
          Mark all as read
        </button>
      </div>

      <div>
        {isLoading ? (
          <div>
            {Array.from({ length: 4 }).map((_, index) => {
              return (
                <div
                  key={index}
                  className="relative py-3 flex items-start gap-4 transition-all "
                >
                  <div className="w-[32px] h-[32px] min-w-[32px] min-h-[32px] rounded-full bg-slate-300 dark:bg-slate-600 animate-pulse"></div>
                  <div>
                    <span className="block h-[13px] mb-[0.25rem] w-[300px] max-w-[100%] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
                    <span className="block h-[12px] mb-[0.25rem] w-[150px] max-w-[80%] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
                    <span className="block h-[12px] w-[50px] max-w-[50%] bg-slate-300 dark:bg-slate-600 animate-pulse "></span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : error ? (
          <div className="text-center">
            <span className="block text-sm text-grayColor">
              Something went wrong, Try again
            </span>
            <button
              onClick={refreshPage}
              className="hover:bg-btnHoverColor mt-3"
            >
              Reload
            </button>
          </div>
        ) : allNotifications?.length < 1 ? (
          <div className="">
            <span className="px-4 pb-8 pt-4 text-center block text-sm text-grayColor leading-[26px]">
              No notifications yet
              <br />
              Messages and alerts about your SimplePay account will show up
              here.
            </span>
          </div>
        ) : (
          allNotifications?.map((notification, index) => {
            return (
              <div
                key={index}
                className={
                  "relative py-3 flex items-start gap-4 transition-all dark:hover:bg-gray-800 hover:bg-gray-300 cursor-pointer px-2 mb-[2px] " +
                  ((
                    notification?.isGlobalNotification
                      ? notification?.openedUsers?.includes(user?._id)
                      : notification?.isOpened
                  )
                    ? ""
                    : "dark:bg-gray-700 bg-gray-200")
                }
                onClick={() => {
                  updateNotificationAsRead(notification);
                }}
              >
                <div className="w-[32px] h-[32px] min-w-[32px] min-h-[32px] rounded-full overflow-hidden">
                  <img
                    src={
                      notification?.notificationType === "transaction" ||
                      notification.notificationType === "payment-request"
                        ? notification?.relatedUser?.avatar
                          ? process.env.REACT_APP_SERVER_URL +
                            notification?.relatedUser?.avatar
                          : avatarImg
                        : "https://www.pngall.com/wp-content/uploads/2017/05/Alert.png"
                    }
                    alt=""
                    className="w-[100%] h-[100%] object-cover"
                  />
                </div>
                <div>
                  <span className="block text-[13px] font-medium mb-[0.25rem]">
                    {notification?.title?.replace(
                      "{name}",
                      notification?.relatedUser?.name
                    )}
                  </span>
                  <span className="block text-[12px] mb-[0.25rem] text-grayColor">
                    {notification?.description}
                  </span>
                  <span className="text-grayColor text-[12px] flex items-center gap-[5px]">
                    <BiTimeFive />
                    {moment(notification?.createdAt).fromNow()}
                  </span>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
