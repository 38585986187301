import React from "react";
import { formatDate } from "../../../utils";
import { BiLink } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";

function PresalePageDetailInfo({ presale, setPresale }) {
  return (
    <div className=" ">
      {presale.logo ? (
        <img
          className="w-24 h-24 object-cover rounded-full"
          src={process.env.REACT_APP_SERVER_URL + presale.logo}
          alt=""
        />
      ) : (
        ""
      )}
      <div className="py-4">
        <span className="text-sm text-grayColor dark:text-darkGrayColor leading-[30px] block mt-2">
          {presale?.description}
        </span>

        <table className="w-[100%] mt-4">
          <tbody>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Website Link
              </td>
              <td className="text-sm text-primaryColor font-medium py-3 pl-[10px]">
                <a href={presale?.website}> {presale?.website}</a>
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Twitter Link
              </td>
              <td className="text-sm text-primaryColor font-medium py-3 pl-[10px]">
                <a href={presale?.twitter}> {presale?.twitter}</a>
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Facebook Link
              </td>
              <td className="text-sm text-primaryColor  font-medium py-3 pl-[10px]">
                <a href={presale?.facebook}> {presale?.facebook}</a>
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Instagram Link
              </td>
              <td className="text-sm text-primaryColor font-medium py-3 pl-[10px]">
                <a href={presale?.instagram}> {presale?.instagram}</a>
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                YouTub Link
              </td>
              <td className="text-sm text-primaryColor font-medium py-3 pl-[10px]">
                <a href={presale?.youtube}> {presale?.youtube}</a>
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Telegram Link
              </td>
              <td className="text-sm font-medium text-primaryColor py-3 pl-[10px]">
                <a href={presale?.telegram}> {presale?.telegram}</a>
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Descord Link
              </td>
              <td className="text-sm font-medium text-primaryColor py-3 pl-[10px]">
                <a href={presale?.descord}> {presale?.descord}</a>
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Reddit Link
              </td>
              <td className="text-sm text-primaryColor font-medium py-3 pl-[10px]">
                <a href={presale?.reddit}> {presale?.reddit}</a>
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Github Link
              </td>
              <td className="text-sm text-primaryColor font-medium py-3 pl-[10px]">
                <a href={presale?.github}> {presale?.github}</a>
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Created Date
              </td>
              <td className="text-sm font-medium py-3 pl-[10px]">
                {formatDate(presale?.updatedAt)}
              </td>
            </tr>
            <br />
            <br />
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PresalePageDetailInfo;
