import React, { useState } from "react";
import { BiHome } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function MobileSidbarSingleMenu({ menu }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <li className="">
            <Link
                to={menu.link}
                className="flex items-center gap-[15px] py-3 border-b pr-5 pl-5"
                onClick={() => {
                    setIsDropdownOpen(true);
                }}
            >
                <span className="bg-[#f6f6f6] w-[30px] min-w-[30px] h-[30px] min-h-[30px] text-xl rounded-full flex items-center justify-center">
                    <BiHome />
                </span>
                <div>
                    <span className="block font-medium">{menu.name}</span>
                    <span className="text-[12px] text-grayColor block mt-[2px]">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Aliquid, ipsam!
                    </span>
                </div>
                <span>
                    <FiChevronDown />
                </span>
            </Link>

            {isDropdownOpen && (
                <ul className="pl-[65px] bg-[#f6f6f6]">
                    <li>
                        <Link
                            to="/"
                            className="block py-3 border-b text-sm font-medium"
                        >
                            Payment Links
                        </Link>
                    </li>
                    <li>
                        <Link to="/" className="block py-3 text-sm font-medium">
                            Payment Links
                        </Link>
                    </li>
                </ul>
            )}
        </li>
    );
}
