const menus = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Currencies",
    link: "/currencies",
  },
  {
    name: "Transactions",
    link: "/transactions",
  },
  // {
  //     name: "Swap",
  //     link: "/swap",
  // },
  // {
  //     name: "Help",
  //     link: "/",
  // },
];

export default menus;
