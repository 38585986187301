import React, { useEffect } from "react";
import { BiLockAlt } from "react-icons/bi";
import { FaLightbulb } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { avatarImg } from "../../assets/images";
import { BtnLoader } from "../../components";

const isLoading = false;
export default function PaymentConfirmPage({ amount, reciever }) {
    // const [isLoading, setIsLoading] = useState(false);

    const { user } = useSelector((state) => state.user);
    const [searchParams] = useSearchParams();

    const sendMessage = () => {
        window.close();
    };

    // const doTransfer = async () => {
    //     const channel = new BroadcastChannel("aiuhsdfuiwebraewreworbew");
    //     channel.postMessage("Hello Nihal");
    //     try {
    //         setIsLoading(true);

    //         const response = await axios.post(
    //             "/transfer",
    //             {
    //                 amount: searchParams.get("amount"),
    //                 recipientEmail: searchParams.get("email"),
    //                 recipientUsername: searchParams.get("username"),
    //                 currencyId: searchParams.get("currencyId"),
    //             },
    //             {
    //                 headers: { Authorization: `Bearer ${jwtToken}` },
    //             }
    //         );

    //         window.opener.postMessage(
    //             {
    //                 hamipay: {
    //                     err: "",
    //                     success: true,
    //                 },
    //             },
    //             "*"
    //         );
    //         setIsLoading(false);
    //         // setSection(5);
    //     } catch (err) {
    //         channel.postMessage("Hello Nihal");
    //         setIsLoading(false);
    //         window.opener.postMessage(
    //             {
    //                 hamipay: {
    //                     err:
    //                         err?.response?.data?.error ||
    //                         "Something went wrong, Try again",
    //                 },
    //             },
    //             "*"
    //         );
    //     }
    // };

    const fetchReceipient = async () => {
        try {
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchReceipient();
    }, []);

    return (
        <div>
            <div className="relative flex flex-col">
                {isLoading && (
                    <div className="absolute inset-0 bg-[#fff8]">
                        <div className="h-[100%] px-4 flex flex-col items-center justify-center">
                            <div className="text-5xl mb-3">
                                <BiLockAlt />
                            </div>
                            <span className="text-lg font-[600] text-[#111] block mb-1">
                                Processing...
                            </span>
                            <span className="block text-center font-[600] mb-4">
                                Do not close the window or tab This should take
                                a while.{" "}
                            </span>
                            <div className="w-[30px] h-[30px] rounded-full border-4 border-primaryColor border-r-transparent animate-spin"></div>
                        </div>
                    </div>
                )}

                <div className="px-4 py-3 border-b flex items-center gap-[10px] justify-between bg-[#f6f6f6] sticky top-0">
                    <div className="w-[30px] h-[30px]">
                        <img
                            src="https://app.hami.live/static/media/hamilogo.b98b74641e3caeca8a7d591025e520a8.svg"
                            alt=""
                            className="w-[100%] h-[100%] object-cover"
                        />
                    </div>
                    <div className="flex items-center gap-[20px]">
                        <button className="bg-transparent text-[#222] text-lg p-0 ">
                            <FaLightbulb />
                        </button>
                        <div className="w-[30px] h-[30px] rounded-full overflow-hidden">
                            <img
                                src={
                                    user?.avatar
                                        ? process.env.REACT_APP_SERVER_URL +
                                          user?.avatar
                                        : avatarImg
                                }
                                alt=""
                                className="w-[100%] h-[100%] objct-cover"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-1 p-4">
                    <div>
                        <span className="font-[600] text-[15px] block mb-2">
                            Sending To:
                        </span>
                        <div className="flex items-center gap-[10px] mb-5">
                            <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                                <img
                                    src="https://themesbrand.com/skote-symfony/layouts/assets/images/users/avatar-1.jpg"
                                    alt=""
                                    className="w-[100%] h-[100%] objct-cover"
                                />
                            </div>
                            <div>
                                <span className="block font-medium text-[#222]">
                                    @{searchParams.get("username")}
                                </span>
                                <span className="block text-grayColor text-sm">
                                    {searchParams.get("email")}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                            Asset
                        </span>
                        <div className="flex items-center gap-[10px] text-[#222]">
                            BNB
                            <img
                                src="https://cryptologos.cc/logos/bnb-bnb-logo.png"
                                alt=""
                                className="w-[20px] h-[20px] rounded-full object-cover"
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                            Amount
                        </span>
                        <div className="flex items-center gap-[1.5em]">
                            <span className="block text-lg font-[600] text-[#222]">
                                {searchParams.get("amount") || 0} BNB
                            </span>
                            <span className="text-sm text-grayColor font-medium">
                                &asymp; $24.01 USD
                            </span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                            Gas Fee
                        </span>
                        <span className="font-medium block text-[15px] text-[#222]">
                            0.02
                        </span>
                        <span className="text-[13px] text-blue-500 block mt-1">
                            This is likely in less than 30s
                        </span>
                    </div>
                    <div className="mt-3">
                        <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                            Total
                        </span>
                        <span className="text-xl font-[600] text-[#222]">
                            {amount || 0} BNB
                        </span>
                    </div>
                </div>
                <div className="px-4 mb-3">
                    <div className="rounded text-[12px] text-grayColor leading-[20px]">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Vel expedita quia odio,{" "}
                        <span className="font-medium text-primaryColor">
                            Terms
                        </span>{" "}
                        and{" "}
                        <span className="font-medium text-primaryColor">
                            Privacy
                        </span>
                    </div>
                </div>
                <div className="px-4 pb-4 flex items-center gap-[10px]">
                    <button
                        className="w-[100%] bg-transparent border border-[#ced4da] text-textColor"
                        // onClick={doTransfer}
                    >
                        Cancel
                    </button>
                    <button
                        className="w-[100%] hover:bg-btnHoverColor"
                        onClick={sendMessage}
                        id="btn"
                    >
                        {isLoading ? <BtnLoader /> : "Confirm"}
                    </button>
                </div>
            </div>
        </div>
    );
}
