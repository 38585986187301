import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

import { setIsCountriesModalOpen } from "../redux/slices/layoutSlice";
import { useEffect } from "react";

export default function CountriesModal({ setSelectedCountry }) {
    const dispatch = useDispatch();
    const [countries, setCountries] = useState([]);

    const { countries: allCountries } = useSelector((state) => state.user);

    const handleConutrySearch = (e) => {
        if (e.target.value) {
            const filteredCountries = allCountries.filter((country) => {
                if (
                    country.countryName
                        .toLowerCase()
                        .startsWith(e.target.value.toLowerCase())
                ) {
                    return country;
                }
                return "";
            });

            setCountries(filteredCountries);
        } else {
            setCountries(allCountries);
        }
    };

    useEffect(() => {
        setCountries(allCountries);
    }, [allCountries]);

    return (
        <div>
            <div
                id="country-modal"
                className="fixed inset-0 bg-[#0007] dark:bg-[#0005] z-10 flex items-center justify-center"
                onClick={(e) => {
                    if (e.target === document.getElementById("country-modal")) {
                        dispatch(setIsCountriesModalOpen(false));
                    }
                }}
            >
                <div className="bg-[#fff] dark:bg-[#212529] h-[100%] sm:h-[90%] sm:max-h-[400px] overflow-y-auto w-[100%] sm:max-w-[350px] rounded">
                    <div className="sticky top-0 px-5 bg-[#fff] dark:bg-[#212529] dark:bg-darkCardColor py-5">
                        <div className="flex items-center justify-between gap-[10px] mb-3">
                            <h1 className="font-[600]">Select Country</h1>
                            <button
                                className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
                                onClick={() =>
                                    dispatch(setIsCountriesModalOpen(false))
                                }
                                type="button"
                            >
                                <IoMdClose />
                            </button>
                        </div>
                        <input
                            type="text"
                            placeholder="search here..."
                            className="bg-lightBgColor"
                            onChange={handleConutrySearch}
                            onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                            }}
                        />
                    </div>
                    <ul>
                        {countries.map((country, index) => {
                            return (
                                <li
                                    key={index}
                                    className="flex items-center gap-[10px] py-[8px] hover:bg-[#f6f6f6] dark:hover:bg-darkBgColor cursor-pointer pl-[20px]"
                                    onClick={() => {
                                        setSelectedCountry(country);
                                        dispatch(
                                            setIsCountriesModalOpen(false)
                                        );
                                    }}
                                >
                                    <img
                                        src={country?.flag}
                                        alt=""
                                        className="w-[25px]"
                                    />
                                    <span className="text-[14px]">
                                        {country?.countryName}
                                    </span>
                                    <span className="text-grayColor text-[12px]">
                                        {country?.phonecode}
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}
