import React from "react";
import Chart from "react-apexcharts";

import { dataSeries } from "../../../data";

// Chart data
let ts2 = 1484418600000;
let dates = [];
for (let i = 0; i < 120; i++) {
    ts2 = ts2 + 86400000;
    dates.push([ts2, dataSeries[1][i]?.value]);
}

var options = {
    series: [
        {
            name: "XYZ MOTORS",
            data: dates,
        },
    ],
    chart: {
        type: "area",
        stacked: false,
        height: 350,
        zoom: {
            type: "x",
            enabled: false,
            autoScaleYaxis: true,
        },
        toolbar: {
            show: false,
            autoSelected: "zoom",
        },
    },
    dataLabels: {
        enabled: false,
    },
    markers: {
        size: 0,
    },
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
        },
    },
    yaxis: {
        labels: {
            formatter: function (val) {
                return (val / 1000000).toFixed(0);
            },
        },
    },
    xaxis: {
        type: "datetime",
    },
    tooltip: {
        shared: false,
        y: {
            formatter: function (val) {
                return (val / 1000000).toFixed(0);
            },
        },
    },
};

export default function CurrencyInfoCard() {
    return (
        <Chart
            series={options?.series}
            options={options}
            type="area"
            height="400px"
        />
    );
}
