import React from "react";
import { useSelector } from "react-redux";

import { avatarImg } from "../../../assets/images";
import { CurrencySelectDropdown } from "../../../components";
import { formatBalance } from "../../../utils";

export default function TransferAmountSection({
    section,
    setSection,
    user,
    setData,
    data,
    selectedCurrency,
    setSelectedCurrency,
}) {
    const { isFetching } = useSelector((state) => state.currencies);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    return (
        <div className={section === 2 ? "block" : "hidden"}>
            <h1 className="text-lg font-[600] text-left sm:text-center mb-4">
                Amount
            </h1>
            <div className="">
                <span className="font-medium text-grayColor dark:text-darkGrayColor text-[15px] block mb-1">
                    Send To
                </span>
                <div className="flex items-center gap-[1em] bg-gradient-to-r from-[#f4f4f4] via-[#fdfdfd] to-[#f4f4f4] dark:from-darkCardColor dark:to-darkCardColor px-4 py-2 rounded">
                    <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                        <img
                            src={
                                user?.avatar
                                    ? process.env?.REACT_APP_SERVER_URL +
                                      user?.avatar
                                    : avatarImg
                            }
                            alt=""
                            className="w-[100%] h-[100%] object-cover"
                        />
                    </div>
                    <div>
                        <span className="block font-medium text-[15px]">
                            @{user?.username}
                        </span>
                        <span className="block text-sm text-grayColor dark:text-darkGrayColor">
                            {user?.email}
                        </span>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <CurrencySelectDropdown
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                />
            </div>
            <div className="mt-4">
                <label htmlFor="">Amount *</label>
                <input
                    type="number"
                    placeholder="0.00"
                    name="amount"
                    onChange={handleChange}
                    className={
                        "arrow-hidden h-[40px] text-[15px] font-[600] " +
                        (Number(data?.amount) >
                            Number(selectedCurrency?.balance) ||
                        Number(data?.amount) <= 0
                            ? "text-red-500"
                            : "text-textColor")
                    }
                    value={data?.amount || ""}
                />
                <div className="flex items-center justify-between mt-[5px]">
                    <span className="text-[13px] font-medium text-grayColor dark:text-darkGrayColor block">
                        Balance: {formatBalance(selectedCurrency?.balance)}{" "}
                        {selectedCurrency?.currencySymbol} &asymp;{" "}
                        {selectedCurrency?.balance &&
                        selectedCurrency?.price?.price_usd
                            ? formatBalance(
                                  selectedCurrency?.balance *
                                      Number(selectedCurrency?.price?.price_usd)
                              )
                            : "0.0"}{" "}
                        USD
                    </span>
                    <button
                        className="text-[13px] p-0 h-auto bg-transparent font-medium text-blue-500 block cursor-pointer"
                        onClick={() => {
                            setData({
                                ...data,
                                amount: selectedCurrency?.balance,
                            });
                        }}
                        disabled={isFetching}
                    >
                        Max
                    </button>
                </div>
            </div>
            <div className="mt-4">
                <label htmlFor="">Note (optional)</label>
                <textarea
                    name="note"
                    id=""
                    className="h-[50px]"
                    onChange={handleChange}
                ></textarea>
            </div>

            <div className="flex flex-wrap items-center gap-[10px] justify-between mt-5">
                <div className="flex items-center gap-[10px] w-[100%]">
                    <button
                        className=" h-[40px] w-[100%] bg-gray-200 dark:bg-darkCardColor text-textColor dark:text-darkTextColor disabled:bg-opacity-60 py-0"
                        type="button"
                        onClick={() => setSection(1)}
                    >
                        Previous
                    </button>
                    <button
                        className="hover:bg-btnHoverColor h-[40px] w-[100%] p-0 disabled:opacity-80 disabled:hover:bg-primaryColor"
                        onClick={() => setSection(3)}
                        disabled={
                            !data?.amount ||
                            !selectedCurrency?.balance ||
                            Number(data?.amount) <= 0 ||
                            Number(data?.amount) >
                                Number(selectedCurrency?.balance)
                        }
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}
