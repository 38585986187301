import { useSelector } from "react-redux";
import { getPrice } from "../../../utils";

const usePaymentLinkAmount = (paymentLink, selectedCurrency, price) => {
    const { prices } = useSelector((state) => state.currencies);

    const amount =
        paymentLink?.currency?._id === selectedCurrency?._id
            ? paymentLink?.amount
            : (getPrice(prices, paymentLink.currency) / price) *
              paymentLink?.amount;

    return amount;
};

export default usePaymentLinkAmount;
