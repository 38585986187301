import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { FiChevronDown, FiCopy } from "react-icons/fi";
import { useSelector } from "react-redux";
import QRCode from "qrcode";
import { HiArrowNarrowLeft } from "react-icons/hi";

import { useNetworkChange } from "../../hooks";
import { formatBalance } from "../../utils";
import CurrencySelectDropdown from "../../components/CurrencySelectDropdown";

export default function DepositPage() {
  const { currencies, isFetching } = useSelector((state) => state.currencies);
  const { currentAccount } = useSelector((state) => state.user);

  const [isCurrenciesDropdownOpen, setIsCurrenciesDropdownOpen] =
    useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [selectedNetwork, setSelectedNetwork] = useState({});
  const [section, setSection] = useState(1);
  const [qrcode, setQrcode] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const address = useNetworkChange(currentAccount, selectedNetwork);

  const generateQRCode = async () => {
    try {
      const url = await QRCode.toDataURL(address, {
        width: 182,
        margin: 2,
      });

      setQrcode(url);
      setSection(2);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setSelectedCurrency(currencies[0]);
    // setSelectedNetwork(currencies[0]?.assets[0]?.network);
  }, [currencies]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  return (
    <div className="flex flex-col items-center sm:justify-center mx-auto py-5 sm:py-6 sm:min-h-[calc(100vh-5em)] max-w-[430px]">
      <div className="w-full relative flex items-center sm:justify-between gap-[15px] mb-6 sm:mb-10">
        {section === 2 && (
          <button
            className="h-auto p-0 bg-transparent text-textColor text-xl "
            onClick={() => setSection(1)}
          >
            <HiArrowNarrowLeft />
          </button>
        )}
        <h1 className="font-bold text-lg sm:text-xl text-left sm:text-center">
          Deposit
        </h1>
        <span></span>
      </div>

      <div className={section === 1 ? "block" : "hidden"}>
        <CurrencySelectDropdown
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
          setSelectedNetwork={setSelectedNetwork}
        />

        <div className="mt-6">
          <label htmlFor="">Select Network</label>
          {isFetching ? (
            <div className="flex items-center gap-[10px]">
              {Array.from({ length: 3 }).map((_, index) => {
                return (
                  <span
                    key={index}
                    className="bg-slate-300 dark:bg-slate-600 h-[29px] w-[50px] rounded px-3 py-1 text-[14px] block cursor-pointer"
                  ></span>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center gap-[10px]">
              {selectedCurrency?.assets?.map((asset, index) => {
                return (
                  <span
                    key={index}
                    className={
                      "rounded px-3 py-1 text-[14px] block cursor-pointer " +
                      (selectedNetwork?._id === asset?.network?._id
                        ? "bg-gray-300 dark:bg-gray-500"
                        : "border border-gray-700 dark:border-slate-300")
                    }
                    onClick={() => setSelectedNetwork(asset?.network)}
                  >
                    {asset?.network?.tokenStandard}
                  </span>
                );
              })}
            </div>
          )}
          <span className="text-[13px] text-grayColor dark:text-darkGrayColor leading-[23px] mt-3 block">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed
            repellat placeat itaque ullam sapiente tempora
          </span>
        </div>

        <div className="mt-6">
          <button
            className="w-full hover:bg-btnHoverColor"
            onClick={generateQRCode}
            disabled={isFetching || !address || !selectedNetwork}
          >
            Next
          </button>
        </div>
      </div>

      <div className={section === 2 ? "block" : "hidden"}>
        <div className="w-[200px] h-[200px] mx-auto  p-2 border dark:border-darkBorderColor rounded">
          <img src={qrcode} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="flex items-center justify-between gap-[10px] mt-6 bg-gradient-to-r from-[#f4f4f4] via-[#fdfdfd] to-[#f4f4f4] dark:from-darkCardColor dark:to-darkCardColor px-[15px] py-[8px] rounded">
          <span className="text-sm font-medium break-all">{address}</span>
          <button
            className="bg-transparent p-0 text-textColor dark:text-darkTextColor"
            onClick={() => {
              navigator.clipboard.writeText(address);
              setIsCopied(true);
            }}
            title={isCopied ? "Copied" : "Copy"}
          >
            {isCopied ? <FaCheck /> : <FiCopy />}
          </button>
        </div>

        <div className="mt-6">
          <span className="font-medium text-sm mb-2 block">Important</span>
          <ul className="text-sm text-grayColor dark:text-darkGrayColor list-disc list-inside leading-[26px]">
            <li>
              Only send{" "}
              <span className="text-primaryColor font-medium">
                {selectedCurrency?.symbol}
              </span>{" "}
              to this address. Any other assets deposited to this address will
              result in permanent loss.
            </li>
            <li className="mt-[10px]">
              Ensure the network is{" "}
              <span className="text-primaryColor font-medium">
                {selectedNetwork?.tokenStandard}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
