import React from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import {
    clearPaymentLinkFilters,
    fetchPaymentLinks,
    updateIsPaymentLinkLoading,
    updatePaymentLinkFilters,
    updatePaymentLinkSort,
} from "../../../redux/slices/paymentLinksSlice";

export default function PaymentLinksFilters({ setIsFiltersModalOpen }) {
    const dispatch = useDispatch();
    const { filters, sort } = useSelector((state) => state.paymentLinks);
    const { currencies } = useSelector((state) => state.currencies);

    const handleFilterChange = (e) => {
        dispatch(
            updatePaymentLinkFilters({
                name: e.target?.name,
                value: e.target?.value,
            })
        );
    };

    return (
        <div>
            <div className="flex sm:hidden items-center justify-between p-5 border-b dark:border-[#4c4e53]">
                <h1 className="font-medium">Filters</h1>
                <button
                    className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
                    onClick={() => {
                        setIsFiltersModalOpen(false);
                    }}
                >
                    <MdClose />
                </button>
            </div>
            <div className="sm:flex flex-wrap items-end gap-[15px] p-5 h-[100%] overflow-y-auto">
                <div>
                    <label htmlFor="">Link Id</label>
                    <input
                        type="text"
                        placeholder="Payment Link Id"
                        name="id"
                        onChange={handleFilterChange}
                        value={filters.id || ""}
                    />
                </div>
                <div className="mt-4 sm:mt-0">
                    <label htmlFor="">Accepted Currency</label>
                    <select
                        name="currency"
                        id=""
                        className="w-[100%] sm:w-[180px]"
                        onChange={handleFilterChange}
                        value={filters.currency || ""}
                    >
                        <option value="all">All</option>
                        {currencies?.map((currency, index) => {
                            return (
                                <option key={index} value={currency?._id}>
                                    {currency?.symbol}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="mt-4 sm:mt-0">
                    <label htmlFor="">Slect Date</label>
                    <select
                        name="date"
                        id=""
                        className="w-[100%] sm:w-[180px]"
                        onChange={handleFilterChange}
                        value={filters.date || ""}
                    >
                        <option value="all">All</option>
                        <option value="7">Last Weak</option>
                        <option value="30">Last Month</option>
                        <option value="360">Last Year</option>
                    </select>
                </div>
                <div className="mt-4 sm:mt-0">
                    <label htmlFor="">Select Status</label>
                    <select
                        name="status"
                        id=""
                        className="w-[100%] sm:w-[180px]"
                        onChange={handleFilterChange}
                        value={filters.status || ""}
                    >
                        <option value="all">All</option>
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                        <option value="cancelled">Cancelled</option>
                    </select>
                </div>
                <div className="mt-4 sm:mt-0">
                    <label htmlFor="">Sort</label>
                    <select
                        name="sort"
                        id=""
                        className="w-[100%] sm:w-[180px]"
                        onChange={(e) => {
                            dispatch(updatePaymentLinkSort(e.target.value));
                        }}
                        value={sort || ""}
                    >
                        <option value="default">Default</option>
                        <option value="createdAt:asc">Date (Ascending)</option>
                        <option value="createdAt:desc">
                            Date (Descending)
                        </option>
                    </select>
                </div>
                <div className="sm:ml-3 flex items-center justify-end sm:justify-start mt-5 sm:mt-0 gap-3">
                    <button
                        className="border border-borderColor dark:border-[#4c4e53] text-textColor dark:text-darkTextColor bg-transparent"
                        onClick={() => {
                            dispatch(clearPaymentLinkFilters());
                            dispatch(updateIsPaymentLinkLoading(true));
                            dispatch(fetchPaymentLinks());
                            setIsFiltersModalOpen(false);
                        }}
                    >
                        Clear
                    </button>
                    <button
                        className="hover:bg-btnHoverColor"
                        onClick={() => {
                            dispatch(updateIsPaymentLinkLoading(true));
                            dispatch(fetchPaymentLinks());
                            setIsFiltersModalOpen(false);
                        }}
                    >
                        Search
                    </button>
                </div>
            </div>
        </div>
    );
}
