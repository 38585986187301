import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useRoutes } from "react-router-dom";
import io from "socket.io-client";

import axios from "./axios";
import { updateTheme } from "./redux/slices/layoutSlice";
import {
  setCurrencies,
  updateBalance,
  updateIsFetching,
} from "./redux/slices/currenciesSlice";
import {
  addNewNotification,
  setAccounts,
  setCountries,
  setMerchantDetails,
  updateGeoDetails,
  updateRecentActivities,
  updateUser,
} from "./redux/slices/userSlice";
import { ThemeRoutes } from "./routes";
import { MainLoader } from "./components";
import { setRouterData } from "./redux/slices/routerSlice";
import { updateAdmin } from "./redux/slices/admin/adminSlice";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { isLoggedIn, jwtToken, user, accountId } = useSelector(
    (state) => state.user
  );
  const [socket, setSocket] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchUser = useCallback(async () => {
    try {
      const jwtToken = localStorage.getItem("random-string");
      const accountId = localStorage.getItem("current-account");

      if (jwtToken) {
        setIsLoading(true);
        const response = await axios.get(
          `/users/my-account?accountId=${accountId}`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        dispatch(updateUser(response.data?.user));
        dispatch(
          setAccounts({
            account: response?.data?.account,
            accounts: response?.data?.accounts,
          })
        );
        dispatch(setMerchantDetails(response.data.merchant));
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      localStorage.removeItem("random-string");
      navigate("/auth/login");
    }
  }, [dispatch, navigate]);

  const fetchAdmin = useCallback(async () => {
    try {
      const jwtToken = localStorage.getItem("admin-token");

      if (jwtToken) {
        setIsLoading(true);
        const response = await axios.get(`/admin/auth/details`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });
        dispatch(updateAdmin(response.data));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      localStorage.removeItem("admin-token");
      navigate("/admin/auth/login");
    }
  }, [dispatch, navigate]);

  const fetchCurrencies = useCallback(async () => {
    try {
      dispatch(updateIsFetching(true));

      const options = {
        method: "GET",
        url: "https://l4chsalter-alternative-me-crypto-v1.p.rapidapi.com/v1/ticker/",
        params: { limit: "12" },
        headers: {
          "X-RapidAPI-Key":
            "1687ad4495msh867a1e0b6e9aecep15601djsn887a5f622916",
          "X-RapidAPI-Host":
            "l4chsalter-alternative-me-crypto-v1.p.rapidapi.com",
        },
      };

      const requestOne = axios.get(`/currencies/all?accountId=${accountId}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      const requestTwo = axios.request(options);

      const requestThree = axios.get(`/routers/all`);

      Promise.all([requestOne, requestTwo, requestThree])
        .then((response) => {
          dispatch(
            setCurrencies({
              currencies: response[0]?.data?.currencies,
              balances: response[0]?.data?.balances,
              prices: response[1]?.data,
            })
          );
          dispatch(setRouterData(response[2]?.data));
        })
        .catch((errors) => {
          console.log(errors);
        });
    } catch (err) {
      console.log(err);
    }
  }, [accountId, dispatch, jwtToken]);

  const getGeoInfo = useCallback(async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      dispatch(updateGeoDetails(response.data));
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  const fetchCountries = useCallback(async () => {
    try {
      const response = await axios.get("/countries");
      dispatch(setCountries(response.data));
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    getGeoInfo();
  }, [getGeoInfo]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    fetchAdmin();
  }, [fetchAdmin]);

  useEffect(() => {
    const localTheme = localStorage.getItem("theme");

    if (
      localTheme === "dark" ||
      (!localTheme && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      dispatch(updateTheme("dark"));
    } else {
      dispatch(updateTheme("light"));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchCurrencies();
  }, [user?._id, fetchCurrencies]);

  useEffect(() => {
    setSocket(io.connect(process.env.REACT_APP_SERVER_URL));

    return () => {
      socket.emit("disconnect");
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      socket?.emit("newUser", user?._id);

      socket?.on("notification", (msg) => {
        dispatch(addNewNotification(msg?.notification));
      });

      socket?.on("activity", (msg) => {
        if (msg.activity) {
          dispatch(updateRecentActivities(msg?.activity));
          if (msg?.activity?.transactionType !== "withdraw") {
            dispatch(
              updateBalance({
                ...msg?.activity,
                isSender: user._id === msg?.activity?.sender?._id,
              })
            );
          }
        }
      });
    }
  }, [isLoggedIn]);

  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
  };

  useEffect(() => {
    documentHeight();
    window.addEventListener("resize", documentHeight);

    return () => {
      window.removeEventListener("resize", documentHeight);
    };
  }, []);

  const routing = useRoutes(ThemeRoutes);
  return isLoading ? <MainLoader /> : routing;
}

export default App;
