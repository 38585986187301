import { Country, State } from "country-state-city";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import axios from "../../../axios";
import { BtnLoader } from "../../../components";

export default function AddAddressModal({
    setIsAddAddressModalOpen,
    type,
    setAddress,
    isEdit,
    address,
}) {
    const [data, setData] = useState({
        address: isEdit ? address?.address : "",
        isocode: isEdit ? address?.isocode : "",
        state: isEdit ? address?.state : "",
        city: isEdit ? address?.city : "",
        pincode: isEdit ? address?.pincode : "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [states, setStates] = useState([]);

    const countries = Country.getAllCountries();
    const { selectedCustomer } = useSelector((state) => state.invoices);
    const { jwtToken, accountId } = useSelector((state) => state.user);

    const handleChange = (e) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);

            let response;
            if (isEdit) {
                response = await axios.patch(
                    `/customers-address/single/${address?._id}`,
                    {
                        ...data,
                        country: Country.getCountryByCode(data.isocode)?.name,
                    },
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );
            } else {
                response = await axios.post(
                    "/customers-address/add",
                    {
                        ...data,
                        accountId,
                        type,
                        customerId: selectedCustomer?._id,
                        country: Country.getCountryByCode(data.isocode)?.name,
                    },
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );
            }

            if (type === "shipping") {
                setAddress((prev) => {
                    return { ...prev, shipping: response.data };
                });
            } else {
                setAddress((prev) => {
                    return { ...prev, billing: response.data };
                });
            }

            setIsLoading(false);
            setIsAddAddressModalOpen({ bool: false, type: "" });
        } catch (err) {
            console.log(err);
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (data.isocode) {
            setStates(State.getStatesOfCountry(data.isocode));
        }
    }, [data.isocode]);

    return (
        <div
            id="add-address-modal"
            className="fixed inset-0 bg-[#fff5] dark:bg-[#0005] z-10 flex items-center justify-center"
            onClick={(e) => {
                if (e.target === document.getElementById("add-address-modal")) {
                    setIsAddAddressModalOpen(false);
                }
            }}
        >
            <div className="bg-[#fff] dark:bg-darkCardColor max-h-[100%] sm:max-h-[90%] overflow-y-auto w-[100%] sm:max-w-[400px] rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
                <div className="flex items-center justify-between gap-[10px] p-5 border-b dark:border-darkBorderColor ">
                    <h1 className="font-[600] capitalize">
                        {isEdit ? "Edit" : "Add"} {type} Address
                    </h1>
                    <button
                        className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
                        onClick={() => setIsAddAddressModalOpen(false)}
                        type="button"
                    >
                        <IoMdClose />
                    </button>
                </div>
                <form action="" className="p-5" onSubmit={handleSubmit}>
                    <div>
                        {/* <label htmlFor="">Country</label> */}
                        <select
                            name="isocode"
                            id=""
                            className="w-full"
                            onChange={handleChange}
                            value={data.isocode || ""}
                        >
                            <option value="" hidden>
                                Select Country
                            </option>
                            {countries?.map((country, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={country?.isoCode}
                                    >
                                        {country?.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="mt-4">
                        {/* <label htmlFor="">State</label> */}
                        <select
                            name="state"
                            id=""
                            className="w-full"
                            onChange={handleChange}
                            value={data.state || ""}
                        >
                            <option value="" hidden>
                                Select State
                            </option>
                            {states.map((state, index) => {
                                return (
                                    <option key={index} value={state?.name}>
                                        {state?.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="mt-4">
                        {/* <label htmlFor="">Address line</label> */}
                        <input
                            type="text"
                            name="address"
                            onChange={handleChange}
                            value={data.address || ""}
                            placeholder="ADDRESS"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-[1em] mt-4">
                        <div className="">
                            {/* <label htmlFor="">PIN Code</label> */}
                            <input
                                type="number"
                                name="pincode"
                                onChange={handleChange}
                                className="arrow-hidden"
                                value={data.pincode || ""}
                                placeholder="PINCODE"
                            />
                        </div>
                        <div>
                            {/* <label htmlFor="">City</label> */}
                            <input
                                type="text"
                                name="city"
                                onChange={handleChange}
                                value={data.city || ""}
                                placeholder="CITY"
                            />
                        </div>
                    </div>
                    {error && (
                        <span className="text-sm text-red-500 block mt-3">
                            {error}
                        </span>
                    )}
                    <div className="mt-6">
                        <button
                            className="w-full hover:bg-btnHoverColor disabled:opacity-80"
                            disabled={
                                isLoading ||
                                !data.isocode ||
                                !data.state ||
                                !data.address ||
                                !data.pincode ||
                                !data.city
                            }
                        >
                            {isLoading ? (
                                <BtnLoader />
                            ) : isEdit ? (
                                "Update Customer"
                            ) : (
                                "Add Customer"
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
