import layoutReducer from "./layoutSlice";
import currenciesReducer from "./currenciesSlice";
import userReducer from "./userSlice";
import paymentLinksReducer from "./paymentLinksSlice";
import paymentPagesReducer from "./paymentPagesSlice";
import transactionsReducer from "./transactionsSlice";
import paymentsReducer from "./paymentsSlice";
import invocesReducer from "./invocesSlice";
import presaleReducer from "./presaleSlices";
import RouterReducer from "./routerSlice";
import KYCReducer from "./kycSlice";

export {
  layoutReducer,
  currenciesReducer,
  userReducer,
  paymentLinksReducer,
  paymentPagesReducer,
  transactionsReducer,
  paymentsReducer,
  invocesReducer,
  presaleReducer,
  RouterReducer,
  KYCReducer,
};
