import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import {
    setIsCreateAccountModalOpen,
    setIsErrorAndReloadModalOpen,
} from "../../redux/slices/layoutSlice";
import {
    addNewAccount,
    setAccountId,
    setCurrentAccount,
} from "../../redux/slices/userSlice";
import BtnLoader from "../BtnLoader";

export default function CreateNewAccountModal() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [isAccountCreated, setIsAccountCreated] = useState(false);
    const [newAccountId, setNewAccountId] = useState("");

    const { accounts, jwtToken, currentAccount } = useSelector(
        (state) => state.user
    );
    const { isFetching } = useSelector((state) => state.currencies);

    const dispatch = useDispatch();

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            setError("");

            const response = await axios.post(
                "/accounts/create",
                {},
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            dispatch(addNewAccount(response.data));
            setNewAccountId(response?.data?._id);
            setIsLoading(false);
            setIsAccountCreated(true);
        } catch (err) {
            console.log(err);
            setError(
                err?.response?.data?.erorr || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    const changeAccount = async () => {
        try {
            dispatch(setIsCreateAccountModalOpen(false));
            if (!isFetching && currentAccount?._id !== newAccountId) {
                dispatch(setAccountId(newAccountId));

                const response = await axios.get(
                    `/accounts/single/details/${newAccountId}`,
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                dispatch(setCurrentAccount(response?.data));
                localStorage.setItem("current-account", response?.data?._id);
            }
        } catch (err) {
            dispatch(setIsErrorAndReloadModalOpen(true));
            console.log(err);
        }
    };

    return (
        <div
            id="account-add-modal"
            className="fixed inset-0 flex bg-[#fff5] dark:bg-[#0005] backdrop-bluer-sm items-center justify-center z-20 transition-all p-[15px] "
            onClick={(e) => {
                if (e.target === document.getElementById("account-add-modal")) {
                    dispatch(setIsCreateAccountModalOpen(false));
                }
            }}
        >
            <div className="bg-white dark:bg-darkCardColor max-w-[450px] p-4 rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
                <h1 className="font-[600] text-lg">Add New Account</h1>
                <span className="text-grayColor dark:text-darkGrayColor text-[14px] leading-[28px] block mt-1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit nulla, omnis velit quasi suscipit amet, non
                    voluptatem quia consectetur
                </span>

                <div className="mt-4">
                    <input
                        type="text"
                        disabled
                        value={`Account ${accounts?.length + 1}`}
                    />
                </div>

                {error && (
                    <p className="text-red-500 font-medium text-sm block mt-4">
                        {error}
                    </p>
                )}

                {!isAccountCreated ? (
                    <button
                        className="mt-4 w-[100%] hover:bg-btnHoverColor disabled:cursor-not-allowed"
                        disabled={isLoading}
                        onClick={handleSubmit}
                    >
                        {isLoading ? <BtnLoader /> : "Add New Account"}
                    </button>
                ) : (
                    <div className="flex items-center gap-[10px] mt-4 ">
                        <button
                            onClick={() =>
                                dispatch(setIsCreateAccountModalOpen(false))
                            }
                            className="w-[100%] bg-transparent text-textColor border hover:bg-[#f6f6f6] disabled:cursor-not-allowed"
                        >
                            Exit
                        </button>
                        <button
                            className="w-[100%] hover:bg-btnHoverColor disabled:cursor-not-allowed"
                            onClick={changeAccount}
                        >
                            Got to New Account
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
