import toast from 'react-hot-toast';

const toasts = {
    showSuccess: (successMsg) =>
        toast.success(successMsg, {
            backgroundColor: "#34c38f",
        }),
};

export default toasts;
