import BigNumber from "bignumber.js";
import React from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";

import { avatarImg } from "../../assets/images";
import { formatBalance } from "../../utils";

export default function PaymentConfirmSection({
    setSection,
    receiver,
    amount,
    balance,
    selectedCurrency,
}) {
    return (
        <div>
            <div className="mb-3">
                <button
                    className="text-xl p-0 h-auto bg-transparent text-textColor dark:text-darkTextColor"
                    onClick={() => {
                        setSection(1);
                    }}
                >
                    <HiArrowNarrowLeft />
                </button>
            </div>
            <div className="">
                <div className="flex items-center justify-between">
                    <span className="text-sm text-grayColor dark:text-darkGrayColor">To</span>
                    <div className="flex items-center gap-[12px]">
                        <div className="text-sm font-medium text-right">
                            <span className="block">{receiver?.name}</span>
                            <span className="block text-grayColor dark:text-darkGrayColor text-[13px]">
                                @{receiver?.username}
                            </span>
                        </div>
                        <div className="w-[35px] h-[35px] rounded-full overflow-hidden ml-auto">
                            <img
                                src={
                                    receiver?.avatar
                                        ? process.env.REACT_APP_SERVER_URL +
                                          receiver?.avatar
                                        : avatarImg
                                }
                                alt=""
                                className="w-[100%] h-[100%] object-cover"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-between mt-3">
                    <span className="text-sm text-grayColor dark:text-darkGrayColor">Amount</span>
                    <span className="font-medium text-sm">
                        {amount} {selectedCurrency?.symbol}
                    </span>
                </div>
                <div className="flex items-center justify-between mt-3">
                    <span className="text-sm text-grayColor dark:text-darkGrayColor">
                        Transaction Fee
                    </span>
                    <span className="font-medium text-sm">0.5%</span>
                </div>
                <div className="flex items-center justify-between mt-3">
                    <span className="text-sm text-grayColor dark:text-darkGrayColor">Total</span>
                    <span className="text-base font-[600] text-primaryColor">
                        {formatBalance(
                            BigNumber(amount)
                                .multipliedBy(0.5)
                                .dividedBy(100)
                                .plus(amount)
                                .toString()
                        )}{" "}
                        {selectedCurrency?.symbol}
                    </span>
                </div>
            </div>
            {Number(balance) <
                BigNumber(amount)
                    .multipliedBy(0.5)
                    .dividedBy(100)
                    .plus(amount)
                    .toNumber() && (
                <span className="text-[13px] text-red-500 mt-4 block">
                    * You don't have enough balance to pay transaction fee 0.5%
                </span>
            )}
        </div>
    );
}
