import React from "react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { formatBalance, formatPrice } from "../../../utils";

export default function CurrencyCard({
    currency,
    selectedCurrency,
    setSelectedCurrency,
    setSection,
}) {
    return (
        <div
            className={
                "flex items-start justify-between cursor-pointer md:bg-gradient-to-r from-[#f4f4f4] via-[#fdfdfd] to-[#f4f4f4]  md:px-3 py-4 md:py-4 border-b dark:border-b-darkBorderColor md:border-none rounded md:mb-5 last:mb-0 " +
                (selectedCurrency?._id === currency?._id
                    ? "md:from-primaryColor md:via-primaryColor md:to-[#e3b3ff] md:text-[#fff] md:shadow-md"
                    : "dark:from-darkCardColor dark:to-darkCardColor")
            }
            onClick={() => {
                setSelectedCurrency(currency);
                setSection(2);
            }}
        >
            <div className="flex items-start gap-[13px]">
                <div className="w-[35px] h-[35px] md:w-[40px] md:h-[40px] rounded-full overflow-hidden">
                    <img
                        src={process.env.REACT_APP_SERVER_URL + currency?.logo}
                        alt=""
                        className="w-[100%] h-[100%] object-cover"
                    />
                </div>
                <div>
                    <span className="block font-[500] text-[15px]">
                        {currency?.name}
                    </span>
                    <span className="block font-[600] text-[14px] mt-1 md:mt-2">
                        {formatBalance(currency?.balance)} {currency?.symbol}
                    </span>
                    <span
                        className={
                            "hidden md:block text-[13px] font-medium block mt-2 " +
                            (selectedCurrency?._id === currency?._id
                                ? "text-[#f5f5f5]"
                                : "text-[#555] dark:text-darkGrayColor")
                        }
                    >
                        &asymp; $
                        {currency?.balance && currency?.price?.price_usd
                            ? formatBalance(
                                  currency?.balance * currency?.price?.price_usd
                              )
                            : "0.0"}
                    </span>
                </div>
            </div>
            <div className="text-right">
                <span
                    className={
                        "flex justify-end items-center gap-[3px] text-sm " +
                        (currency?.price?.percent_change_1h < 0
                            ? "text-red-500"
                            : "text-green-500")
                    }
                >
                    {currency?.price?.percent_change_1h < 0 ? (
                        <MdArrowDownward />
                    ) : (
                        <MdArrowUpward />
                    )}
                    ${formatPrice(currency?.price?.price_usd)}{" "}
                </span>
            </div>
        </div>
    );
}
