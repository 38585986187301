import React, { useRef } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { MdOutlineAccountBalance } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import axios from "../../axios";
import { setIsErrorAndReloadModalOpen } from "../../redux/slices/layoutSlice";
import { setAccountId, setCurrentAccount } from "../../redux/slices/userSlice";
import { setIsCreateAccountModalOpen } from "../../redux/slices/layoutSlice";
import { useHandleClickOutside } from "../../hooks";

export default function AccountDropdown({ setIsAccountDropdownOpen }) {
    const { accounts, currentAccount, jwtToken } = useSelector(
        (state) => state.user
    );
    const { isFetching } = useSelector((state) => state.currencies);

    const dispatch = useDispatch();
    const wrapperRef = useRef();

    useHandleClickOutside(wrapperRef, () => setIsAccountDropdownOpen(false));

    const changeAccount = async (id) => {
        try {
            if (!isFetching && currentAccount?._id !== id) {
                dispatch(setAccountId(id));

                const response = await axios.get(
                    `/accounts/single/details/${id}`,
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                dispatch(setCurrentAccount(response?.data));
                localStorage.setItem("current-account", response?.data?._id);
            }
        } catch (err) {
            console.log(err);
            dispatch(setIsErrorAndReloadModalOpen(true));
        }
    };

    return (
        <div
            ref={wrapperRef}
            className="bg-white dark:bg-darkCardColor overflow-hidden rounded absolute right-0 top-[54px] min-w-[200px] shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] "
        >
            <div>
                {accounts?.map((account, index) => {
                    return (
                        <span
                            key={index}
                            to="/profile"
                            className={
                                "flex items-center gap-[10px] text-[13px] py-[6px] px-6 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor " +
                                (isFetching
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer")
                            }
                            onClick={() => {
                                changeAccount(account._id);
                                setIsAccountDropdownOpen(false);
                            }}
                        >
                            {currentAccount?._id === account?._id ? (
                                <span className="">
                                    <BsCheckLg />
                                </span>
                            ) : (
                                <span className="w-[14px]"></span>
                            )}
                            {account?.accountName || `Account ${index + 1}`}
                        </span>
                    );
                })}

                <Link
                    to="/settings/accounts"
                    onClick={() => setIsAccountDropdownOpen(false)}
                >
                    <span className="border-t border-borderColor dark:border-darkBorderColor flex items-center gap-[10px] cursor-pointer text-[13px] py-[6px] px-6 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor">
                        <MdOutlineAccountBalance />
                        Accounts
                    </span>
                </Link>
                <span
                    onClick={() => {
                        dispatch(setIsCreateAccountModalOpen(true));
                        setIsAccountDropdownOpen(false);
                    }}
                    className="border-borderColor flex items-center gap-[10px] cursor-pointer text-[13px] py-[6px] px-6 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                >
                    <AiOutlinePlus />
                    Add New Account
                </span>
                {/* <Link
                    to="/settings/accounts/import"
                    onClick={() => setIsAccountDropdownOpen(false)}
                >
                    <span className="border-borderColor flex items-center gap-[10px] cursor-pointer text-[13px] py-[6px] px-6 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor">
                        <AiOutlineCloudDownload />
                        Import Account
                    </span>
                </Link> */}
            </div>
        </div>
    );
}
