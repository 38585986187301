import React from "react";
import { MdClose } from "react-icons/md";

function CurrencyPageFilter({ filters, setFilters, fetchAllCurrencies }) {
  return (
    <div>
      <div className="flex sm:hidden items-center justify-between p-5 border-b dark:border-[#4c4e53]">
        <h1 className="font-medium">Filters</h1>
        <button
          className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
          // onClick={() => {
          //   setIsFiltersModalOpen(false);
          // }}
        >
          <MdClose />
        </button>
      </div>
      <div className="sm:flex flex-wrap items-end gap-[15px] p-5 h-[100%] overflow-y-auto">
        <div>
          <label htmlFor="">Name</label>
          <input
            type="text"
            placeholder="Name"
            name="name"
            onChange={(e) => {
              setFilters({
                ...filters,
                name: e.target.value,
              });
            }}
            value={filters.name || ""}
          />
        </div>
        <div className="mt-4 sm:mt-0">
          <label htmlFor="">Slect Date</label>
          <select
            name="date"
            id=""
            className="w-[100%] sm:w-[180px]"
            //   onChange={handleFilterChange}
            //   value={filters.date || ""}
          >
            <option value="all">All</option>
            <option value="7">Last Weak</option>
            <option value="30">Last Month</option>
            <option value="360">Last Year</option>
          </select>
        </div>

        <div className="mt-4 sm:mt-0">
          <label htmlFor="">Sort</label>
          <select
            name="sort"
            id=""
            className="w-[100%] sm:w-[180px]"
            //   onChange={(e) => {
            //     dispatch(updatePaymentLinkSort(e.target.value));
            //   }}
            //   value={sort || ""}
          >
            <option value="default">Default</option>
            <option value="createdAt:asc">Date (Ascending)</option>
            <option value="createdAt:desc">Date (Descending)</option>
          </select>
        </div>
        <div className="sm:ml-3 flex items-center justify-end sm:justify-start mt-5 sm:mt-0 gap-3">
          <button
            onClick={() => {
              setFilters({
                name: "",
                limit: 10,
                skip: 0,
                totalUsers: 0,
              });
            }}
            className="border border-borderColor dark:border-[#4c4e53] text-textColor dark:text-darkTextColor bg-transparent"
          >
            Clear
          </button>
          <button
            className="hover:bg-btnHoverColor"
            onClick={() => {
              fetchAllCurrencies();
            }}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default CurrencyPageFilter;
