import React from "react";
import { useSelector } from "react-redux";
import moment from "moment/moment";

import { avatarImg } from "../../../assets/images";
import HomeActivitiesTableSkeleton from "./HomeActivitiesTableSkeleton";
import {
    MdArrowDownward,
    MdArrowUpward,
    MdOutlineMoneyOffCsred,
} from "react-icons/md";
import { formatBalance } from "../../../utils";

export default function HomeActivitiesTable() {
    const { user, recnetActivities, isHomeDataFetching } = useSelector(
        (state) => state.user
    );

    if (isHomeDataFetching) {
        return <HomeActivitiesTableSkeleton />;
    }

    if (recnetActivities?.length < 1) {
        return (
            <div className="flex flex-col items-center justify-center h-[250px] text-grayColor dark:text-darkGrayColor">
                <span className="text-2xl text-green-1">
                    <MdOutlineMoneyOffCsred />
                </span>
                <span className="block font-medium mt-5">
                    No Activities Found!
                </span>
            </div>
        );
    }

    return (
        <div>
            <table className="w-full">
                <thead></thead>
                <tbody className="">
                    {recnetActivities
                        ?.slice(0, 5)
                        ?.map((transaction, index) => {
                            const isSender =
                                transaction?.sender?._id === user?._id;
                            const type = transaction?.transactionType;

                            return (
                                <tr
                                    key={index}
                                    className="group transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                                >
                                    <td className="pr-[10px] py-[10px] transition-all group-hover:pl-[10px]">
                                        <div className="flex items-center gap-[1em]">
                                            <div className="w-[35px] h-[35px] min-w-[35px] min-h-[35px] rounded overflow-hidden flex items-center justify-center bg-[#e5e7eb] dark:bg-darkCardColor text-lg">
                                                {type === "transfer" ? (
                                                    <img
                                                        src={
                                                            isSender
                                                                ? transaction
                                                                      ?.receiver
                                                                      ?.avatar
                                                                    ? process
                                                                          .env
                                                                          .REACT_APP_SERVER_URL +
                                                                      transaction
                                                                          ?.receiver
                                                                          ?.avatar
                                                                    : avatarImg
                                                                : transaction
                                                                      ?.sender
                                                                      ?.avatar
                                                                ? process.env
                                                                      .REACT_APP_SERVER_URL +
                                                                  transaction
                                                                      ?.sender
                                                                      ?.avatar
                                                                : avatarImg
                                                        }
                                                        alt=""
                                                        className="w-[100%] h-[100%] object-cover"
                                                    />
                                                ) : type === "withdraw" ||
                                                  (type === "payment" &&
                                                      isSender) ? (
                                                    <span className="text-red-500">
                                                        <MdArrowUpward />
                                                    </span>
                                                ) : type === "deposit" ||
                                                  (type === "payment" &&
                                                      !isSender) ? (
                                                    <span className="text-green-500">
                                                        <MdArrowDownward />
                                                    </span>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div>
                                                <h4 className="font-[500] text-[14px]">
                                                    {type === "transfer"
                                                        ? isSender
                                                            ? "Transferred To"
                                                            : "Received From"
                                                        : type === "payment"
                                                        ? isSender
                                                            ? "Paid To"
                                                            : "Paid by"
                                                        : type === "withdraw"
                                                        ? "Withdrawn To"
                                                        : type === "deposit"
                                                        ? "Deposited To"
                                                        : ""}
                                                </h4>
                                                <span className="block text-[14px] text-grayColor dark:text-darkGrayColor capitalize mt-[2px] sm:mt-0">
                                                    {type === "transfer"
                                                        ? isSender
                                                            ? transaction
                                                                  ?.receiver
                                                                  ?.name
                                                            : transaction
                                                                  ?.sender?.name
                                                        : type === "deposit"
                                                        ? "Your Account"
                                                        : type === "payment"
                                                        ? isSender
                                                            ? transaction
                                                                  ?.receiver
                                                                  ?.name
                                                            : transaction
                                                                  ?.sender?.name
                                                        : type === "withdraw"
                                                        ? `${transaction?.receiverAddress?.slice(
                                                              0,
                                                              6
                                                          )}...${transaction?.receiverAddress?.slice(
                                                              -6
                                                          )}`
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-[10px] hidden md:table-cell">
                                        <div className="flex items-center gap-[10px] ">
                                            <div className="w-[20px] h-[20px] rounded-full overflow-hidden">
                                                <img
                                                    src={
                                                        process.env
                                                            ?.REACT_APP_SERVER_URL +
                                                        transaction?.currency
                                                            ?.logo
                                                    }
                                                    alt=""
                                                    className="w-[100%] h-[100%] object-cover"
                                                />
                                            </div>
                                            <div>
                                                <span className="block font-[500] text-[15px]">
                                                    {
                                                        transaction?.currency
                                                            ?.symbol
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="hidden md:table-cell px-[10px] text-center">
                                        <span
                                            className={
                                                "block text-[15px] font-medium  " +
                                                ((type === "transfer" &&
                                                    !isSender) ||
                                                type === "deposit" ||
                                                (type === "payment" &&
                                                    !isSender)
                                                    ? "text-green-500"
                                                    : "text-red-500")
                                            }
                                        >
                                            {(type === "transfer" &&
                                                !isSender) ||
                                            type === "deposit" ||
                                            (type === "payment" && !isSender)
                                                ? "+" +
                                                  formatBalance(
                                                      transaction?.amount
                                                  )
                                                : "-" +
                                                  formatBalance(
                                                      transaction?.amount
                                                  )}{" "}
                                            {
                                                transaction?.currency
                                                    ?.currencySymbol
                                            }
                                        </span>
                                    </td>
                                    <td className="hidden md:table-cell pl-[10px] text-right transition-all group-hover:pr-[10px]">
                                        <span className="block text-[14px] text-grayColor dark:text-darkGrayColor">
                                            {moment(
                                                transaction?.createdAt
                                            ).fromNow()}
                                        </span>
                                    </td>
                                    <td className="md:hidden text-right">
                                        <span className="text-[15px] font-medium">
                                            {formatBalance(transaction?.amount)}{" "}
                                            {transaction?.currency?.symbol}
                                        </span>
                                        <span className="block text-[14px] text-grayColor dark:text-darkGrayColor">
                                            {moment(
                                                transaction?.createdAt
                                            ).fromNow()}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>

            <div></div>
        </div>
    );
}
