import BigNumber from "bignumber.js";
import React from "react";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";

import {
    changeProductQuantity,
    removeProductRow,
} from "../../../redux/slices/invocesSlice";
import AddProductModal from "./AddProductModal";
import ProductsListDropdown from "./ProductsListModal";

export default function SingleProductRow({ rowData, rowIndex }) {
    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState({
        bool: false,
        isEdit: false,
    });
    // const [isDeleting, setIsDeleting] = useState(false);
    const [isProductsListDropdownOpen, setIsProductsListDropdownOpen] =
        useState(false);

    const dispatch = useDispatch();

    // const deleProduct = async () => {
    //     try {
    //         setIsDeleting(true);
    //         const response = await axios.delete(
    //             `/products/single/${rowData?.selectedProduct?._id}`,
    //             {
    //                 headers: { Authorization: `Bearer ${jwtToken}` },
    //             }
    //         );
    //         dispatch(deleteProduct(response?.data?.productId));
    //         setIsDeleting(false);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    return (
        <tr className="even:bg-[#f6f6f6] dark:even:bg-darkCardColor">
            <td className="relative py-4 px-[10px] w-[50%] whitespace-nowrap">
                {isAddProductModalOpen.bool && (
                    <AddProductModal
                        setIsAddProductModalOpen={setIsAddProductModalOpen}
                        {...isAddProductModalOpen}
                        product={rowData?.selectedProduct}
                        rowIndex={rowIndex}
                    />
                )}

                <span
                    className="absolute cursor-pointer text-sm top-[50%] translate-y-[-50%] left-[-28px] block w-[20px] h-[20px] flex items-center justify-center rounded-full text-red-500 bg-red-200 "
                    onClick={() => dispatch(removeProductRow(rowIndex))}
                >
                    <MdClose />
                </span>
                <div className="relative">
                    <div
                        className="border-b dark:border-darkBorderColor text-sm flex items-center justify-between py-2 cursor-pointer"
                        onClick={() => {
                            setIsProductsListDropdownOpen(true);
                        }}
                    >
                        <span className="font-medium">
                            {rowData?._id
                                ? rowData?.selectedProduct?.name
                                : "Select Product"}
                        </span>
                        <span className="text-[13px]">
                            <FaChevronDown />
                        </span>
                    </div>

                    {isProductsListDropdownOpen && (
                        <div
                            className="fixed inset-0 z-10"
                            onClick={() => {
                                setIsProductsListDropdownOpen(false);
                            }}
                        ></div>
                    )}
                    {isProductsListDropdownOpen && (
                        <ProductsListDropdown
                            setIsProductsListDropdownOpen={
                                setIsProductsListDropdownOpen
                            }
                            rowIndex={rowIndex}
                            setIsAddProductModalOpen={setIsAddProductModalOpen}
                        />
                    )}
                </div>
                {rowData?._id && (
                    <>
                        <span className="text-[13px] text-grayColor mt-[5px] block leading-[26px]">
                            {rowData?.selectedProduct?.description?.slice(
                                0,
                                180
                            )}
                        </span>
                        <div className="flex items-center gap-[10px] text-sm mt-2">
                            <button
                                className="text-blue-500 cursor-pointer underline p-0 h-auto bg-transparent font-normal"
                                onClick={() => {
                                    setIsAddProductModalOpen({
                                        bool: true,
                                        isEdit: true,
                                    });
                                }}
                            >
                                Edit Product
                            </button>
                            {/* <button
                                className="text-red-500 cursor-pointer underline p-0 h-auto bg-transparent font-normal"
                                onClick={deleProduct}
                            >
                                {isDeleting ? "Deleting..." : "Delete"}
                            </button> */}
                        </div>
                    </>
                )}
            </td>
            <td className="text-right py-3 text-sm px-[10px]">
                {rowData?.selectedProduct?.price || "0.00"} USDT
            </td>
            <td className="text-right py-3 text-sm px-[10px] outline-none w-[100px]">
                <div className="flex justify-end">
                    <input
                        type="number"
                        value={rowData?.quantity || 1}
                        onChange={(e) =>
                            dispatch(
                                changeProductQuantity({
                                    rowIndex,
                                    value: e.target.value,
                                })
                            )
                        }
                        disabled={!rowData?._id}
                        className="border-none dark:border-none h-auto w-[100px] arrow-hidden text-right px-[5px] bg-transparent"
                    />
                </div>
            </td>
            <td className="text-right py-3 text-sm text-grayColor dark:text-darkGrayColor px-[10px]">
                {rowData?._id
                    ? new BigNumber(rowData?.selectedProduct?.price)
                          .multipliedBy(rowData?.quantity)
                          .toString()
                    : "0.00"}{" "}
                USDT
            </td>
        </tr>
    );
}
