import React, { useState } from "react";
import { useEffect } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { avatarImg } from "../../../assets/images";
import axios from "../../../axios";
import { BtnLoader, CountriesModal } from "../../../components";
import { setIsCountriesModalOpen } from "../../../redux/slices/layoutSlice";
import { country } from "../../../utils";

export default function TransferVerifySection({
    setUser,
    setSection,
    section,
}) {
    const [searchParams] = useSearchParams();

    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("username");
    const [userInfo, setUserInfo] = useState(
        searchParams.get("username") || ""
    );
    const [selectedCountry, setSelectedCountry] = useState({});

    const {
        jwtToken,
        recentTransfers,
        isHomeDataFetching,
        geoDetails,
        countries,
    } = useSelector((state) => state.user);
    const { isCountriesModalOpen } = useSelector((state) => state.layout);
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        try {
            e?.preventDefault();

            setError("");
            setIsLoading(true);

            const response = await axios.post(
                "/users/find",
                {
                    paymentMethod,
                    userInfo,
                    phonecode: selectedCountry?.phonecode,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            setUser(response.data);
            setIsLoading(false);
            setSection(2);
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (searchParams.get("username")) {
            handleSubmit();
        }
    }, []);

    useEffect(() => {
        if (geoDetails?.country_code && countries?.length > 0) {
            setSelectedCountry(
                country.getCountryByCode(countries, geoDetails?.country_code)
            );
        }
    }, [geoDetails?.country_code, countries]);

    return (
        <div
            className={
                "flex-1 flex flex-col " + (section === 1 ? "block" : "hidden")
            }
        >
            <h1 className="text-lg font-[600] text-left sm:text-center mb-7">
                Verify User
            </h1>
            <div className="flex items-center gap-[1em]">
                <div className="flex items-center gap-[7px]">
                    <input
                        type="radio"
                        className="w-[16px] h-[16px]"
                        id="username"
                        name="payment-method"
                        checked={paymentMethod === "username"}
                        onChange={() => {
                            setUserInfo("");
                            setPaymentMethod("username");
                        }}
                    />
                    <label htmlFor="username" className="mb-0">
                        Username
                    </label>
                </div>
                <div className="flex items-center gap-[7px]">
                    <input
                        type="radio"
                        className="w-[16px] h-[16px]"
                        id="email"
                        name="payment-method"
                        checked={paymentMethod === "email"}
                        onChange={() => {
                            setUserInfo("");
                            setPaymentMethod("email");
                        }}
                    />
                    <label htmlFor="email" className="mb-0">
                        Email
                    </label>
                </div>
                <div className="flex items-center gap-[7px]">
                    <input
                        type="radio"
                        className="w-[16px] h-[16px]"
                        id="mobile-number"
                        name="payment-method"
                        checked={paymentMethod === "mobile-number"}
                        onChange={() => {
                            setUserInfo("");
                            setPaymentMethod("mobile-number");
                        }}
                    />
                    <label htmlFor="mobile-number" className="mb-0">
                        Mobile Number
                    </label>
                </div>
            </div>

            <div className="mt-7">
                {paymentMethod === "email" && (
                    <>
                        <label htmlFor="">Email *</label>
                        <input
                            type="text"
                            placeholder="Ex: example@gmail.com"
                            onChange={(e) => {
                                setUserInfo(e.target.value);
                            }}
                            className="h-[40px]"
                            value={userInfo || ""}
                        />
                        <span className="block font-medium text-[13px] font-medium text-blue-500 mt-[7px]">
                            Provide Reciepient Email id
                        </span>
                    </>
                )}
                {paymentMethod === "username" && (
                    <>
                        <label htmlFor="">Username *</label>
                        <input
                            type="text"
                            placeholder="Ex: nihal"
                            onChange={(e) => {
                                setUserInfo(e.target.value);
                            }}
                            className="h-[40px]"
                            value={userInfo || ""}
                        />
                        <span className="block font-medium text-[13px] font-medium text-blue-500 mt-[7px]">
                            Provide Reciepient Username
                        </span>
                    </>
                )}
                {paymentMethod === "mobile-number" && (
                    <>
                        <label htmlFor="">Mobile Number *</label>
                        <div className="flex items-center gap-[10px] h-[45px]">
                            <div className="relative h-full">
                                <button
                                    className="h-[100%] border border-[#ced4da] dark:border-darkBorderColor text-center dark:text-darkTextColor bg-transparent min-w-[75px] text-textColor flex gap-[5px] items-center justify-center px-0"
                                    onClick={() => {
                                        dispatch(setIsCountriesModalOpen(true));
                                    }}
                                    type="button"
                                >
                                    {selectedCountry?.phonecode || 0}{" "}
                                    <FiChevronDown />
                                </button>

                                {isCountriesModalOpen && (
                                    <CountriesModal
                                        setSelectedCountry={setSelectedCountry}
                                    />
                                )}
                            </div>
                            <input
                                type="number"
                                id="mobile-number"
                                className="arrow-hidden h-full"
                                onChange={(e) => {
                                    setUserInfo(e.target.value);
                                }}
                                placeholder="☎ Mobile Number"
                            />
                        </div>
                        <span className="block font-medium text-[13px] font-medium text-blue-500 mt-[7px]">
                            Provide Reciepient Mobile Number
                        </span>
                    </>
                )}
            </div>

            {recentTransfers?.length > 0 && (
                <div className="mt-7 h-[100%] flex-1">
                    <div className="flex items-center gap-[18px] mt-3 overflow-x-auto">
                        {isHomeDataFetching
                            ? Array.from({ length: 4 }).map((_, index) => {
                                  return (
                                      <div
                                          key={index}
                                          className="flex flex-col items-center cursor-pointer transition-all hover:translate-y-[-1px]"
                                      >
                                          <div className="w-[35px] h-[35px] rounded-full overflow-hidden bg-slate-300 dark:bg-slate-600 animte-pulse"></div>
                                          <span className="text-sm block mt-1 h-[14px] w-[50px] bg-slate-300 dark:bg-slate-600 animte-pulse"></span>
                                      </div>
                                  );
                              })
                            : recentTransfers?.map((user, index) => {
                                  return (
                                      <div
                                          key={index}
                                          className="flex flex-col items-center cursor-pointer transition-all hover:translate-y-[-1px]"
                                          onClick={() => {
                                              setSection(2);
                                              setUser(user);
                                          }}
                                      >
                                          <div className="w-[35px] h-[35px] rounded-full overflow-hidden">
                                              <img
                                                  src={
                                                      user?.avatar
                                                          ? process.env
                                                                ?.REACT_APP_SERVER_URL +
                                                            user?.avatar
                                                          : avatarImg
                                                  }
                                                  alt=""
                                                  className="w-[100%] h-[100%] object-cover"
                                              />
                                          </div>
                                          <span className="text-sm block mt-1">
                                              @{user?.username}
                                          </span>
                                      </div>
                                  );
                              })}
                    </div>
                </div>
            )}

            <div className="mt-7">
                {error && (
                    <span className="text-[13px] font-medium block text-red-600 block mb-2">
                        {error}
                    </span>
                )}
                <div className="flex items-center justify-end gap-[10px]">
                    <button
                        className="hover:bg-btnHoverColor p-0 h-[40px] text-[15px] w-[100%] disabled:opacity-80 disabled:hover:bg-primaryColor"
                        onClick={handleSubmit}
                        disabled={isLoading || !userInfo}
                    >
                        {isLoading ? <BtnLoader /> : "Next"}
                    </button>
                </div>
                <span className="text-[12px] text-grayColor dark:text-darkGrayColor mt-2 block leading-[23px]">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Corporis quasi nostrum{" "}
                    <span className="font-medium text-primaryColor">
                        Privacy
                    </span>{" "}
                    and{" "}
                    <span className="font-medium text-primaryColor">Terms</span>{" "}
                    magni amet dolores
                </span>
            </div>
        </div>
    );
}
