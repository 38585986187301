import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allRouters: [],
  isFetching: true,
};

const routerSlices = createSlice({
  name: "routers",
  initialState,
  reducers: {
    setRouterData: (state, { payload }) => {
      state.allRouters = payload;
      state.isFetching = false;
    },
  },
});

export const { setRouterData } = routerSlices.actions;
export default routerSlices.reducer;
