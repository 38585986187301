import React, { useEffect, useState } from "react";
import { VscClose } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../axios";
import { BtnLoader } from "../../../components";
import { useParams } from "react-router-dom";
import {
  setEditInitialPersonalData,
  setEditPersonalData,
  clearAllFormData,
} from "../../../redux/slices/presaleSlices";

function EditModal({ presale, setEditModal, fetchSingleDetails }) {
  const { id } = useParams();

  const { formData } = useSelector((state) => state.presales);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState();

  const dispatch = useDispatch();

  const handleLogo = (e) => {
    const file = e.target.files[0];
    setImg(file);
  };

  useEffect(() => {
    dispatch(setEditInitialPersonalData(presale));
  }, [presale]);

  const newFormData = new FormData();
  newFormData.append("website", formData.website);
  newFormData.append("twitter", formData.twitter);
  newFormData.append("facebook", formData.facebook);
  newFormData.append("github", formData.github);
  newFormData.append("telegram", formData.telegram);
  newFormData.append("descord", formData.descord);
  newFormData.append("reddit", formData.reddit);
  newFormData.append("youtube", formData.youtube);
  newFormData.append("description", formData.description);
  newFormData.append("instagram", formData.instagram);
  if (img) {
    newFormData.append("logo", img);
  } else {
    newFormData.append("oldLogo", presale.logo);
  }

  const { jwtToken } = useSelector((state) => state.user);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const res = await axios.patch(
        `/presale/update/${id}`,
        newFormData,

        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      dispatch(clearAllFormData());
      fetchSingleDetails();
      setEditModal(false);
    } catch (error) {
      console.log(error);
      setError(
        error.response.data.error ||
          error.response.data.err ||
          "somthing went wrong"
      );
    }
  };

  const clearData = () => {
    dispatch(clearAllFormData());
    setEditModal(false);
  };

  return (
    <div className="fixed inset-0 bg-[#fff5] dark:bg-[#0005] flex items-center justify-center z-20 sm:px-5">
      <div className="bg-[#fff] dark:bg-darkCardColor w-[100%] sm:max-w-[450px] h-[100vh] sm:h-[85vh] overflow-y-auto rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
        <div className="flex items-center justify-between border-b border-borderColor dark:border-[#4c4e53] px-4 py-3">
          <h3 className="font-medium">Create Payment Page</h3>
          <button
            className="bg-transparent text-textColor dark:text-darkTextColor p-0 text-xl"
            onClick={() => {
              setEditModal(false);
            }}
          >
            <VscClose />
          </button>
        </div>
        <form action="" className="p-5" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="">Logo</label>
            <input
              type="file"
              name="logo"
              onChange={(e) => {
                handleLogo(e);
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Website</label>
            <input
              type="text"
              placeholder="Enter Website URL"
              name="website"
              value={formData.website}
              onChange={(e) => {
                dispatch(
                  setEditPersonalData({
                    name: "website",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Instagram</label>
            <input
              type="text"
              placeholder="Enter Twitter Account URL"
              name="instagram"
              value={formData.instagram}
              onChange={(e) => {
                dispatch(
                  setEditPersonalData({
                    name: "instagram",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Twitter</label>
            <input
              type="text"
              placeholder="Enter Twitter Account URL"
              name="twitter"
              value={formData.twitter}
              onChange={(e) => {
                dispatch(
                  setEditPersonalData({
                    name: "twitter",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Facebook</label>
            <input
              type="text"
              value={formData.facebook}
              placeholder="Enter Twitter Account URL"
              name="facebook"
              onChange={(e) => {
                dispatch(
                  setEditPersonalData({
                    name: "facebook",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Github</label>
            <input
              type="text"
              value={formData.github}
              placeholder="Enter Telegram Account"
              name="github"
              onChange={(e) => {
                dispatch(
                  setEditPersonalData({
                    name: "github",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Telegram</label>
            <input
              type="text"
              value={formData.telegram}
              placeholder="Enter Dscord Account"
              name="telegram"
              onChange={(e) => {
                dispatch(
                  setEditPersonalData({
                    name: "telegram",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Descord</label>
            <input
              type="text"
              value={formData.descord}
              placeholder="Enter Dscord Account"
              name="descord"
              onChange={(e) => {
                dispatch(
                  setEditPersonalData({
                    name: "descord",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Reddit</label>
            <input
              type="text"
              value={formData.reddit}
              placeholder="Enter Reddit Account"
              name="reddit"
              onChange={(e) => {
                dispatch(
                  setEditPersonalData({
                    name: "reddit",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">YouTube</label>
            <input
              type="text"
              value={formData.youtube}
              placeholder="Enter YouTube Account"
              name="youtube"
              onChange={(e) => {
                dispatch(
                  setEditPersonalData({
                    name: "youtube",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Description</label>
            <textarea
              type="text"
              value={formData.description}
              placeholder="Enter Description"
              name="description"
              onChange={(e) => {
                dispatch(
                  setEditPersonalData({
                    name: "description",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          {error && (
            <span className="text-[13px] font-medium text-red-500 block mt-3">
              {error}
            </span>
          )}

          <div className="mt-4 flex flex-col sm:flex-row items-center gap-[10px]">
            <button
              className="w-[100%] bg-transparent text-textColor dark:text-darkTextColor border border-[#ced4da] dark:border-[#4c4e53] order-2 sm:order-none"
              type="button"
              onClick={() => {
                clearData();
              }}
            >
              Cancel
            </button>
            <button className="w-[100%] disabled:opacity-80">
              {isLoading ? <BtnLoader /> : "Update"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditModal;
