import { useSelector } from "react-redux";

const usePrice = (currency) => {
    const { prices } = useSelector((state) => state.currencies);

    let price = 0;
    for (let i = 0; i < prices?.length; i++) {
        if (currency.symbol === prices[i]?.symbol) {
            price = prices[i]?.price_usd;
            break;
        }
    }

    console.log("PRICE IS ", Number(price));

    return Number(price);
};

export default usePrice;
