import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../../axios";
import { updateTotalProductPrice } from "../../../redux/slices/invocesSlice";
import AddAddressModal from "./AddAddressModal";
import AddCustomerModal from "./AddCustomerModal";
import CustomersListModal from "./CustomersListModal";

export default function CreateInvoiceDetails({ handleChange }) {
    const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState({
        bool: false,
        isEdit: false,
    });
    const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState({
        bool: false,
        type: "",
        isEdit: false,
    });
    const [isAddressLoading, setIsAddressLoading] = useState("");
    const [isShippingLoading, setIsShippingLoading] = useState(false);
    const [isBillingLoading, setIsBillingLoading] = useState(false);
    const [address, setAddress] = useState({});
    const [isCustomersListModalOpen, setIsCustomersListModalOpen] =
        useState(false);

    const { selectedCustomer, productsRows, totalProductPrice } = useSelector(
        (state) => state.invoices
    );
    const { jwtToken } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    // const delCustomer = async () => {
    //     try {
    //         setIsCustomerLoading(true);

    //         const response = await axios.delete(
    //             `/customers/single/${selectedCustomer?._id}`,
    //             {
    //                 headers: { Authorization: `Bearer ${jwtToken}` },
    //             }
    //         );

    //         dispatch(deleteCustomer(response.data?.customerId));
    //         setIsCustomerLoading(false);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    const deleteAddress = async (type) => {
        try {
            if (type === "shipping") {
                setIsShippingLoading(true);
            } else {
                setIsBillingLoading(true);
            }

            await axios.delete(
                `/customers-address/single/${address[type]?._id}`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            if (type === "shipping") {
                setAddress({ ...address, shipping: {} });
                setIsShippingLoading(false);
            } else {
                setAddress({ ...address, billing: {} });
                setIsBillingLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const fetchAddress = useCallback(async () => {
        try {
            setIsAddressLoading(true);
            setAddress({});

            const response = await axios.get(
                `/customers-address/customer/${selectedCustomer?._id}`,
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            setAddress(response.data);
            setIsAddressLoading(false);
        } catch (err) {
            console.log(err);
        }
    }, [jwtToken, selectedCustomer?._id]);

    useEffect(() => {
        if (selectedCustomer._id) {
            fetchAddress();
        }
    }, [selectedCustomer, fetchAddress]);

    useEffect(() => {
        let total = "0";
        productsRows?.map((row) => {
            if (row?._id) {
                total = new BigNumber(row?.selectedProduct?.price)
                    .multipliedBy(row?.quantity)
                    .plus(total)
                    .toString();
            }
            return row;
        });
        dispatch(updateTotalProductPrice(total));
    }, [productsRows, dispatch]);

    return (
        <div>
            <div className="flex flex-wrap items-center gap-[2em] mb-10">
                <span className="text-[13px] font-medium uppercase text-grayColor dark:text-darkGrayColor">
                    Invoice No *
                </span>
                <div className="flex items-center gap-[2px]">
                    <span className="font-[600]">#</span>
                    <input
                        type="text"
                        className="border-0 dark:border-0 border-b dark:border-b rounded-none h-[30px] px-0"
                        name="invoiceNo"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="flex flex-wrap items-center gap-[2em] mb-10">
                <span className="text-[13px] font-medium uppercase text-grayColor dark:text-darkGrayColor">
                    Amount Due
                </span>
                <div className="flex items-center gap-[10px]">
                    <div className="w-[20px] h-[20px] rounded-full overflow-hidden">
                        <img
                            src="https://cryptologos.cc/logos/tether-usdt-logo.png"
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <span className="font-[600] text-lg">
                        {totalProductPrice || 0} USDT
                    </span>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-[4em]">
                <div className="">
                    <span className="block text-[13px] font-medium uppercase mb-[5px] text-grayColor dark:text-darkGrayColor">
                        Invoice To *
                    </span>
                    <div className="relative">
                        <div
                            className="border-b dark:border-darkBorderColor text-sm flex items-center justify-between py-2 cursor-pointer"
                            onClick={() => {
                                setIsCustomersListModalOpen(true);
                            }}
                        >
                            <span className="font-medium">
                                {selectedCustomer?._id
                                    ? selectedCustomer?.name
                                    : "Select Customer"}
                            </span>
                            <span className="text-[13px]">
                                <FaChevronDown />
                            </span>
                        </div>
                        {isCustomersListModalOpen && (
                            <CustomersListModal
                                setIsCustomersListModalOpen={
                                    setIsCustomersListModalOpen
                                }
                                setIsAddCustomerModalOpen={
                                    setIsAddCustomerModalOpen
                                }
                            />
                        )}
                    </div>
                    {selectedCustomer?._id && (
                        <>
                            <span className="block text-sm mt-2">
                                {selectedCustomer?.email}
                            </span>
                            <span className="block text-sm mt-2">
                                {selectedCustomer?.mobileNumber}
                            </span>
                            <div className="flex items-center gap-[10px] text-sm mt-2">
                                <button
                                    className="text-blue-500 cursor-pointer underline p-0 h-auto bg-transparent font-normal"
                                    onClick={() => {
                                        setIsAddCustomerModalOpen({
                                            bool: true,
                                            isEdit: true,
                                        });
                                    }}
                                >
                                    Edit customer
                                </button>
                            </div>
                        </>
                    )}

                    <div className="flex items-center gap-[2em] mt-10">
                        <span className="whitespace-nowrap text-[13px] font-medium uppercase text-grayColor dark:text-darkGrayColor">
                            Issue Date *
                        </span>
                        <input
                            type="date"
                            className="border-0 dark:border-0 border-b dark:border-b rounded-none p-0"
                            name="issueDate"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex items-center gap-[2em] mt-6">
                        <span className="whitespace-nowrap text-[13px] font-medium uppercase text-grayColor dark:text-darkGrayColor">
                            Expiry Date
                        </span>
                        <input
                            type="date"
                            className="border-0 dark:border-0 border-b dark:border-b rounded-none p-0"
                            name="expiryDate"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <span className="uppercase block text-[13px] font-medium mb-2 text-grayColor dark:text-darkGrayColor">
                            Billing address
                        </span>
                        {isAddressLoading || isBillingLoading ? (
                            "Loading..."
                        ) : address?.billing?._id ? (
                            <div>
                                <span className="block text-sm">
                                    {address?.billing?.address}
                                </span>
                                <span className="block text-sm mt-2">
                                    {address?.billing?.city},{" "}
                                    {address?.billing?.state},{" "}
                                    {address?.billing?.country} -{" "}
                                    {address?.billing?.pincode}
                                </span>
                                <div className="flex items-center gap-[10px] text-sm mt-2">
                                    <span
                                        className="text-blue-500 cursor-pointer underline"
                                        onClick={() => {
                                            setIsAddAddressModalOpen({
                                                bool: true,
                                                type: "billing",
                                                isEdit: true,
                                            });
                                        }}
                                    >
                                        Edit
                                    </span>
                                    <span
                                        className="text-red-500 cursor-pointer underline"
                                        onClick={() => {
                                            deleteAddress("billing");
                                        }}
                                    >
                                        Delete
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <button
                                className="block text-sm text-blueColor cursor-pointer p-0 font-normal h-auto bg-transparent disabled:opacity-80"
                                onClick={() => {
                                    setIsAddAddressModalOpen({
                                        bool: true,
                                        type: "billing",
                                        isEdit: false,
                                    });
                                }}
                                disabled={!selectedCustomer?._id}
                            >
                                + Add Billing Address
                            </button>
                        )}
                    </div>
                    <div className="mt-6">
                        <span className="uppercase block text-[13px] font-medium mb-2 text-grayColor dark:text-darkGrayColor">
                            Shipping address
                        </span>
                        {isAddressLoading || isShippingLoading ? (
                            "Loading..."
                        ) : address?.shipping?._id ? (
                            <div>
                                <span className="block text-sm">
                                    {address?.shipping?.address}
                                </span>
                                <span className="block text-sm mt-2">
                                    {address?.shipping?.city},{" "}
                                    {address?.shipping?.state},{" "}
                                    {address?.shipping?.country} -{" "}
                                    {address?.shipping?.pincode}
                                </span>
                                <div className="flex items-center gap-[10px] text-sm mt-2">
                                    <span
                                        className="text-blue-500 cursor-pointer underline"
                                        onClick={() => {
                                            setIsAddAddressModalOpen({
                                                bool: true,
                                                type: "shipping",
                                                isEdit: true,
                                            });
                                        }}
                                    >
                                        Edit
                                    </span>
                                    <span
                                        className="text-red-500 cursor-pointer underline"
                                        onClick={() => {
                                            deleteAddress("shipping");
                                        }}
                                    >
                                        Delete
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <button
                                className="block text-sm text-blueColor cursor-pointer p-0 font-normal h-auto bg-transparent disabled:opacity-80"
                                onClick={() => {
                                    setIsAddAddressModalOpen({
                                        bool: true,
                                        type: "shipping",
                                        isEdit: false,
                                    });
                                }}
                                disabled={!selectedCustomer?._id}
                            >
                                + Add Shipping Address
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {isAddCustomerModalOpen.bool && (
                <AddCustomerModal
                    setIsAddCustomerModalOpen={setIsAddCustomerModalOpen}
                    {...isAddCustomerModalOpen}
                    selectedCustomer={selectedCustomer}
                />
            )}

            {isAddAddressModalOpen.bool && (
                <AddAddressModal
                    setIsAddAddressModalOpen={setIsAddAddressModalOpen}
                    {...isAddAddressModalOpen}
                    setAddress={setAddress}
                    address={address[isAddAddressModalOpen?.type]}
                />
            )}
        </div>
    );
}
