import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Country, State } from "country-state-city";

import axios from "../../axios";
import { BtnLoader } from "../../components";
import {
    MerchantContactForm,
    MerchantDetailsForm,
} from "../../features/MerchantForm";
import { upgradeUserToMerchant } from "../../redux/slices/userSlice";

export default function MerchantUpgradePage() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [data, setData] = useState({
        name: "",
        businessType: "",
        website: "",
        panNumber: "",
        pincode: "",
        address1: "",
        address2: "",
        country: "",
        state: "",
        city: "",
    });
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const { jwtToken } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            setError("");

            const response = await axios.post(
                "/users/upgrade/merchant",
                {
                    ...data,
                    country: Country.getCountryByCode(data.country)?.name,
                    state: State.getStateByCodeAndCountry(
                        data.state,
                        data.country
                    )?.name,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            dispatch(upgradeUserToMerchant(response.data));
            navigate("/");
        } catch (err) {
            console.log(err);
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div className="relative flex py-6 flex-col items-center justify-center min-h-[calc(100vh-5em)] max-w-[430px] mx-auto">
            <h1 className="font-bold text-xl mb-7">Upgrade Account</h1>

            <form className="w-full" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="">Company Name</label>
                    <input
                        type="text"
                        placeholder="Enter Company Name"
                        name="name"
                        onChange={(e) => {
                            setData((prev) => {
                                return { ...prev, name: e.target.value };
                            });
                        }}
                    />
                </div>
                <MerchantDetailsForm data={data} setData={setData} />
                <div className="mt-7">
                    <MerchantContactForm
                        data={data}
                        setData={setData}
                        states={states}
                        setStates={setStates}
                        cities={cities}
                        setCities={setCities}
                    />
                </div>

                <div className="mt-5">
                    {error && (
                        <span className="text-red-500 block text-sm font-medium mb-3">
                            {error}
                        </span>
                    )}

                    <button
                        className="w-[100%]  hover:bg-btnHoverColor disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor"
                        disabled={
                            isLoading ||
                            !data.address1 ||
                            !data.businessType ||
                            !data.panNumber ||
                            !data.pincode ||
                            !data.country ||
                            (states.length > 0 && !data.state) ||
                            (cities.length > 0 && !data.city) ||
                            !data.name
                        }
                    >
                        {isLoading ? <BtnLoader /> : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
}
