import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GiConfirmed } from "react-icons/gi";
import {
    TransferAmountSection,
    TransferConfirmSection,
    TransferSuccessSection,
    TransferVerifySection,
} from "../../features/Transfer";
import { AiOutlineUser } from "react-icons/ai";
import { MdGeneratingTokens } from "react-icons/md";

const TransferPage = () => {
    const { currencies } = useSelector((state) => state.currencies);

    const [section, setSection] = useState(1);
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [user, setUser] = useState({});
    const [data, setData] = useState({
        amount: "",
        note: "",
    });
    const [transferResponse, setTransferResponse] = useState({});

    const clearData = () => {
        setUser({});
        setData({ amount: "", note: "" });
        setSection(1);
    };

    useEffect(() => {
        setSelectedCurrency(currencies[0]);
    }, [currencies]);

    return (
        <div className="py-5 sm:py-6 sm:min-h-[calc(100vh-5em)] flex flex-col items-center max-w-[450px] mx-auto sm:justify-center">
            <div className="hidden sm:flex items-center justify-center mb-[3.5em] w-[100%] max-w-[450px] mx-auto">
                <div className="w-full flex items-center">
                    <div className="relative w-[30px] h-[30px] min-w-[30px] min-h-[30px]">
                        <button
                            className={
                                "w-[100%] h-[100%] p-0 cursor-pointer rounded-full flex items-center justify-center font-medium disabled:cursor-not-allowed " +
                                (section === 1
                                    ? "border-2 border-primaryColor text-primaryColor bg-transparent"
                                    : "bg-primaryColor text-[#fff]")
                            }
                            disabled
                        >
                            <AiOutlineUser />
                        </button>
                        <span className="absolute bottom-[-28px] left-[50%] translate-x-[-50%] whitespace-nowrap text-primaryColor text-[13px] font-[600]">
                            Verify
                        </span>
                    </div>
                    <span
                        className={
                            "relative flex w-[100%] h-[2px] bg-slate-400 dark:bg-slate-600 "
                        }
                    >
                        <span
                            className={
                                "absolute w-[100%] h-[100%] bg-primaryColor transition-all " +
                                (section > 1
                                    ? "h-[100%] md:h-[100%] md:w-[100%]"
                                    : "h-[0%] md:h-[100%] md:w-[0%]")
                            }
                        ></span>
                    </span>
                </div>
                <div className="flex items-center w-full">
                    <div className="relative w-[30px] h-[30px] min-w-[30px] min-h-[30px]">
                        <button
                            className={
                                "w-[100%] h-[100%] p-0 cursor-pointer border-2 bg-transparent rounded-full flex items-center justify-center font-medium disabled:cursor-not-allowed " +
                                (section > 2
                                    ? "bg-primaryColor border-transparent text-[#fff]"
                                    : section === 2
                                    ? "border-primaryColor text-primaryColor"
                                    : "border-slate-400 dark:border-slate-600 text-slate-400 dark:text-slate-600")
                            }
                            disabled
                        >
                            <MdGeneratingTokens />
                        </button>
                        <span
                            className={
                                "absolute bottom-[-30px] left-[50%] translate-x-[-50%] whitespace-nowrap text-[13px] font-[600] " +
                                (section >= 2
                                    ? "text-primaryColor"
                                    : "text-slate-400 dark:text-slate-600")
                            }
                        >
                            Amount
                        </span>
                    </div>
                    <span
                        className={
                            "relative flex w-[100%] h-[2px] bg-slate-400 dark:bg-slate-600 "
                        }
                    >
                        <span
                            className={
                                "absolute w-[100%] h-[100%] bg-primaryColor transition-all " +
                                (section > 2
                                    ? "h-[100%] md:h-[100%] md:w-[100%]"
                                    : "h-[0%] md:h-[100%] md:w-[0%]")
                            }
                        ></span>
                    </span>
                </div>
                <div className="flex items-center">
                    <div className="relative w-[30px] h-[30px] min-w-[30px] min-h-[30px]">
                        <button
                            className={
                                "w-[100%] h-[100%] cursor-pointer bg-transparent border-2 rounded-full p-0 flex items-center justify-center font-medium disabled:cursor-not-allowed " +
                                (section > 3
                                    ? "bg-primaryColor text-[#fff] border-transparent"
                                    : section === 3
                                    ? "border-primaryColor text-primaryColor"
                                    : "border-slate-400 dark:border-slate-600 text-slate-400 dark:text-slate-600")
                            }
                            disabled
                        >
                            <GiConfirmed />
                        </button>
                        <span
                            className={
                                "absolute bottom-[-30px] left-[50%] translate-x-[-50%] whitespace-nowrap text-textColor text-[13px] font-[600] " +
                                (section >= 3
                                    ? "text-primaryColor"
                                    : "text-slate-400 dark:text-slate-600")
                            }
                        >
                            Confirm
                        </span>
                    </div>
                    <span></span>
                </div>
            </div>

            <div className="w-full mx-auto">
                <TransferVerifySection
                    section={section}
                    setSection={setSection}
                    setUser={setUser}
                />
                <TransferAmountSection
                    section={section}
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                    setSection={setSection}
                    setData={setData}
                    data={data}
                    user={user}
                />
                <TransferConfirmSection
                    section={section}
                    setSection={setSection}
                    selectedCurrency={selectedCurrency}
                    user={user}
                    data={data}
                    setTransferResponse={setTransferResponse}
                />
                <TransferSuccessSection
                    section={section}
                    transferResponse={transferResponse}
                    clearData={clearData}
                    selectedCurrency={selectedCurrency}
                />
            </div>
        </div>
    );
};

export default TransferPage;
