import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { IoFilterSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import { Pagination } from "../../components";
import {
    CreatePaymentPageModal,
    PaymentPagesFilters,
    PaymentPagesTable,
} from "../../features/PaymentPages";
import {
    fetchPaymentPages,
    incOrDecPaymentPageSkip,
    updatePaymentPageSkip,
} from "../../redux/slices/paymentPagesSlice";

export default function PaymentPagesPage() {
    const [isCreatePaymentPageModalOpen, setIsCreatePaymentPageModalOpen] =
        useState(false);
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

    const { accountId } = useSelector((state) => state.user);
    const { paymentPages, isLoading, limit, skip, totalPaymentPages } =
        useSelector((state) => state.paymentPages);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPaymentPages());
    }, [dispatch, accountId]);

    return (
        <div className="py-5 sm:py-6">
            <div className="mb-6">
                <div className="flex items-ceenter gap-[10px] justify-between">
                    <h3 className="text-base font-[600]">Payment Pages</h3>
                    <div className="flex items-center gap-[15px]">
                        <button
                            className="sm:hidden bg-transparent text-textColor dark:text-darkTextColor h-auto p-0 text-xl"
                            onClick={() =>
                                setIsFiltersModalOpen(!isFiltersModalOpen)
                            }
                        >
                            <IoFilterSharp />
                        </button>
                        <button
                            className="bg-in hover:bg-btnHoverColor"
                            onClick={() =>
                                setIsCreatePaymentPageModalOpen(true)
                            }
                        >
                            + Create Page
                        </button>
                    </div>
                </div>
                <div
                    className={
                        "sm:mt-3 z-20 sm:z-1 bg-white sm:bg-[#f6f6f6] dark:bg-darkCardColor sm:rounded " +
                        (isFiltersModalOpen
                            ? "fixed inset-0 sm:relative"
                            : "hidden sm:block")
                    }
                >
                    <PaymentPagesFilters
                        setIsFiltersModalOpen={setIsFiltersModalOpen}
                    />
                </div>
            </div>

            {isCreatePaymentPageModalOpen && (
                <CreatePaymentPageModal
                    setIsCreatePaymentPageModalOpen={
                        setIsCreatePaymentPageModalOpen
                    }
                />
            )}

            <div>
                {isLoading ? (
                    <div className="flex items-center justify-center h-[200px]">
                        <div className="w-[30px] h-[30px] border-4 border-primaryColor border-r-transparent rounded-full animate-spin "></div>
                    </div>
                ) : !paymentPages || paymentPages?.length < 1 ? (
                    <div className="flex flex-col items-center justify-center h-[200px]">
                        <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
                            <BiSearch />
                        </div>
                        <span className="block text-xl font-[600] mt-3">
                            No Result found
                        </span>
                        <span className="block text-sm mt-2 text-grayColor">
                            There are no Payment Pages created yet!!.
                        </span>
                    </div>
                ) : (
                    <div>
                        <PaymentPagesTable />

                        <Pagination
                            limit={limit}
                            skip={skip}
                            total={totalPaymentPages}
                            incOrDecSkip={(number) =>
                                dispatch(incOrDecPaymentPageSkip(number))
                            }
                            updateSkip={(skip) =>
                                dispatch(updatePaymentPageSkip(skip))
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
