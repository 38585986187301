import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { IoWarningOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";

import axios from "../../axios";
import { PaymentAccountBar } from "../../components";
import {
    SinglePaymentPageDetails,
    SinglePaymentPageDetailsSkeleton,
    SinglePaymentPageForm,
    SinglePaymentPageFormSkeleton,
} from "../../features/SinglePaymentPage";

export default function SinglePaymentPagePage() {
    const [paymentPage, setPaymentPage] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    const { id } = useParams();

    const fetchPaymentPage = useCallback(async () => {
        try {
            const response = await axios.get(`/payment-pages/single/${id}`);

            setPaymentPage(response.data);
            setIsLoading(false);
        } catch (err) {
            setError(true);
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchPaymentPage();
    }, [fetchPaymentPage]);

    return (
        <div className="relative">
            {!error && (
                <div className="hidden md:block fixed top-0 left-0 w-[50vw] h-[100%] bg-[#f3f3f3] dark:bg-darkCardColor shadow-sm"></div>
            )}

            <PaymentAccountBar />

            {isLoading ? (
                <div className="relative grid md:grid-cols-2 md:min-h-[calc(100vh-70px)] max-w-[900px] gap-[2em] md:gap-[4em] lg:gap-[8em] md:px-[1.8em] mx-auto md:py-20">
                    <SinglePaymentPageDetailsSkeleton />
                    <SinglePaymentPageFormSkeleton />
                </div>
            ) : error ? (
                <div className="flex flex-col items-center justify-center min-h-[calc(100vh-4.2em)] md:min-h-[100vh] px-5">
                    <span className="block text-[110px] sm:text-[150px] md:text-[180px] font-[900]">
                        404
                    </span>
                    <span className="block text-xl font-[600] text-center">
                        We can't find the page you're looking for
                    </span>
                    <span className="text-sm text-grayColor dark:text-darkGrayColor block mt-4 text-center">
                        Visit our support page for further assistance.
                    </span>
                </div>
            ) : (
                <div className="relative grid md:grid-cols-2 md:min-h-[calc(100vh-70px)] max-w-[900px] gap-[2em] md:gap-[4em] lg:gap-[8em] md:px-[1.8em] mx-auto md:py-20">
                    <SinglePaymentPageDetails paymentPage={paymentPage} />

                    {paymentPage?.expiryDate &&
                    new Date(paymentPage?.expiryDate).getDate() <
                        new Date().getDate() ? (
                        <div className="">
                            <div className="flex justify-center text-[100px] text-yellow-500">
                                <IoWarningOutline />
                            </div>
                            <h3 className="font-[600] text-xl text-center mt-3">
                                Page Expired !
                            </h3>
                            <span className="text-sm text-grayColor dark:text-darkGrayColor text-center block mt-3 leading-[28px]">
                                This payment page is expired. Please contact to
                                merchant to get new link.
                            </span>

                            <Link
                                to="/"
                                className="text-sm underline text-blueColor text-center block mt-5"
                            >
                                Back To Home
                            </Link>
                        </div>
                    ) : (
                        <SinglePaymentPageForm paymentPage={paymentPage} />
                    )}
                </div>
            )}
        </div>
    );
}
