import React, { useState, useEffect } from "react";
import {
  setPresaleFormData,
  removeUTCInitialFields,
  setUTCInitialFields,
  setUTCFieldData,
  setCurrency,
  removeCurrency,
} from "../../../redux/slices/presaleSlices";
import { useSelector, useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import PresaleCurrencyModal from "./PresaleCurrencModal";
import PresaleRouterDropdown from "./PresaleRouterDropdown";

function PresalePageFormBasic({
  setForms,
  acceptedCurrencies,
  setAcceptedCurrencies,
  selectedRouters,
  setSelectedRouters,
}) {
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.presales);

  return (
    <div>
      <div>
        <div className="grid md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label htmlFor="">
              Token Name <span className="text-red-500">*</span>
            </label>
            <input
              value={formData?.token_name || ""}
              type="text"
              placeholder="Enter Token Name"
              name="token_name"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "token_name",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Token Address</label>
            <input
              value={formData?.token_address || ""}
              type="text"
              placeholder="Enter Token Address"
              name="token_address"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "token_address",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">
              Token Symbol <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData?.token_symbol || ""}
              placeholder="Enter Token Symbol"
              name="token_symbol"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "token_symbol",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">
              Total Supply <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              placeholder="Enter Token Supply"
              name="total_supply"
              value={formData?.total_supply || ""}
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "total_supply",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="">
            Pay Symbol <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            placeholder="Enter Pay Symbol"
            name="pay_symbol"
            value={formData?.pay_symbol || ""}
            onChange={(e) => {
              dispatch(
                setPresaleFormData({
                  name: "pay_symbol",
                  value: e.target.value,
                })
              );
            }}
          />
        </div>
        {/* <div className="mb-4 flex gap-2">
          <input
            type="checkbox"
            value={formData?.isMultyFundRaising}
            name="isMultyFundRaising"
            className="h-5 w-5"
            onChange={(e) => {
              dispatch(
                setPresaleFormData({
                  name: "isMultyFundRaising",
                  value: e.target.checked,
                })
              );
            }}
          />
          <label htmlFor="">Multi FundRaising</label>
        </div> */}

        {/* {formData.isMultyFundRaising === true ? (
          <div className="mb-4 ml-5">
            <label htmlFor="">
              Count <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              value={formData?.fundRaising_round_count}
              placeholder="Enter Round Count"
              name="fundRaising_round_count"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "fundRaising_round_count",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
        ) : (
          ""
        )} */}
        <div className="grid md:grid-cols-2 gap-5">
          <div className="mb-4 flex gap-2">
            <input
              type="checkbox"
              name="isVesting"
              value={formData?.isVesting}
              className="h-5 w-5"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "isVesting",
                    value: e.target.checked,
                  })
                );
              }}
            />
            <label htmlFor="">Affiliate Program</label>
          </div>
          <div className="mb-4 flex gap-2">
            <input
              type="checkbox"
              name="affiliate_program"
              value={formData?.affiliate_program}
              className="h-5 w-5"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "affiliate_program",
                    value: e.target.checked,
                  })
                );
              }}
            />
            <label htmlFor="">Vesting</label>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label htmlFor="">
              Percentage of Sale <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              value={formData?.percentage_for_sale}
              placeholder="Enter Percentage of Sale"
              name="percentage_for_sale"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "percentage_for_sale",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">
              Percentage of Vesting <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              value={formData?.percentage_for_vesting}
              placeholder="Enter Percentage of Vesting"
              name="percentage_for_vesting"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "percentage_for_vesting",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label htmlFor="">
              Liquidity Locup <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              value={formData?.liquidity_locup}
              placeholder="Enter Liquidity Locup"
              name="liquidity_locup"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "liquidity_locup",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">
              Liquidity Price <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              value={formData?.liquidity_price}
              placeholder="Enter Liquidity Price"
              name="liquidity_price"
              onChange={(e) => {
                dispatch(
                  setPresaleFormData({
                    name: "liquidity_price",
                    value: e.target.value,
                  })
                );
              }}
            />
          </div>
        </div>
        {/* <div className="mb-4">
          <label htmlFor="">
            Router <span className="text-red-500">*</span>
          </label>
          <select
            name=""
            value={formData?.router}
            id=""
            className="w-full"
            onChange={(e) => {
              dispatch(
                setPresaleFormData({
                  value: e.target.value,
                  name: "router",
                })
              );
            }}
          >
            <option value="one">router one</option>
            <option value="two">router two</option>
            <option value="three">router three</option>
            <option value="four">router four</option>
          </select>
        </div> */}

        <div className="mb-5">
          <label htmlFor="">Routers</label>
          <PresaleRouterDropdown
            selectedRouters={selectedRouters}
            setSelectedRouters={setSelectedRouters}
          />
        </div>

        <div className="mb-5 ">
          <label htmlFor="">
            Accepted Currencies <span className="text-red-500">*</span>
          </label>
          <PresaleCurrencyModal
            setAcceptedCurrencies={setAcceptedCurrencies}
            acceptedCurrencies={acceptedCurrencies}
          />
        </div>

        {formData?.UTC?.length ? (
          <>
            <div className="mb-3">
              <h1>
                Select Start Time & End Time (UTC){" "}
                <span className="text-red-500">*</span>
              </h1>
            </div>
            {formData?.UTC?.map((ele, index) => (
              <div className="flex gap-3">
                <div className="mb-2 grid md:grid-cols-2 gap-3">
                  <div className="">
                    <label htmlFor="">
                      Start Time <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="date"
                      value={formData.UTC[index]?.start_time}
                      name="start_time"
                      onChange={(e) => {
                        dispatch(
                          setUTCFieldData({
                            name: "start_time",
                            value: e.target.value,
                            index: index,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="">
                    <label htmlFor="">
                      End Time <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="date"
                      value={formData.UTC[index]?.end_time}
                      name="end_time"
                      onChange={(e) => {
                        dispatch(
                          setUTCFieldData({
                            name: "end_time",
                            value: e.target.value,
                            index: index,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="">
                      Presale Price <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      value={formData.UTC[index]?.presale_price}
                      placeholder="Enter Presale Price"
                      name="presale_price"
                      onChange={(e) => {
                        dispatch(
                          setUTCFieldData({
                            name: "presale_price",
                            value: Number(e.target.value),
                            index: index,
                          })
                        );
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="">
                      Token Price <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      value={formData.UTC[index]?.token_price}
                      placeholder="Enter Token Price"
                      name="token_price"
                      onChange={(e) => {
                        dispatch(
                          setUTCFieldData({
                            name: "token_price",
                            value: Number(e.target.value),
                            index: index,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p
                    className="text-red-500 cursor-pointer"
                    onClick={() => {
                      dispatch(removeUTCInitialFields(index));
                    }}
                  >
                    <IoMdClose />
                  </p>
                </div>
              </div>
            ))}
            <div className="mb-4 flex justify-end">
              <button
                onClick={() => {
                  dispatch(setUTCInitialFields());
                }}
                className="bg-gray-400 dark:bg-gray-700 border border-white "
              >
                Add More
              </button>
            </div>
          </>
        ) : (
          ""
        )}

        <button
          className="w-[100%]  hover:bg-btnHoverColor disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor"
          disabled={
            !formData.token_name ||
            !formData.token_symbol ||
            !formData.total_supply ||
            !formData.pay_symbol ||
            !formData.percentage_for_sale ||
            !formData.percentage_for_vesting
          }
          onClick={() => {
            setForms({
              basic: false,
              personal: true,
            });
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default PresalePageFormBasic;
