import React from "react";
import { FiChevronDown } from "react-icons/fi";

export default function SinglePaymentLinkDetailsSkeleton() {
  return (
    <div className="flex flex-col">
      <div className="relative flex-1">
        <div className="block md:hidden sticky top-[4.2em] left-0 w-full">
          <div className="bg-[#f6f6f6] dark:bg-darkCardHoverColor p-5 flex items-center justify-between">
            <div className="flex items-center gap-[10px]">
              <div className="w-[45px] h-[45px] rounded-full bg-slate-300 dark:bg-slate-600 animate-pulse"></div>
              <div>
                <span className="block h-[16px] w-[100px] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
                <span className="block h-[14px] w-[70px] bg-slate-300 dark:bg-slate-600 animate-pulse mt-1"></span>
              </div>
            </div>
            <div>
              <FiChevronDown />
            </div>
          </div>
        </div>

        <div
          className={
            "hidden md:block top-[100%] md:top-auto p-5 md:p-0 left-0 md:left-auto w-[100%] z-10 bg-[#fff] md:bg-transparent shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] md:shadow-none "
          }
        >
          <h3 className="font-[600] text-[17px] mb-5">
            Payment Requested From
          </h3>
          <div className="flex items-center gap-[10px] mb-5">
            <div className="w-[45px] h-[45px] rounded-full bg-slate-300 dark:bg-slate-600 animate-pulse"></div>
            <div className="">
              <span className="block h-[16px] w-[140px] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
              <span className="block h-[14px] w-[60px] bg-slate-300 dark:bg-slate-600 animate-pulse mt-1"></span>
            </div>
          </div>
          <div>
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Amount
            </span>
            <span className="block h-[24px] w-[70px] bg-slate-300 dark:bg-slate-600 animate-pulse mt-1"></span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Payment For
            </span>
            <span className="h-[15px] block w-[140px] bg-slate-300 dark:bg-slate-600 animate-pulse mt-1"></span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Accepted Currencies
            </span>
            <div className="flex items-center gap-[10px] mt-[7px]">
              {Array.from({ length: 2 })?.map((_, index) => {
                return (
                  <div
                    key={index}
                    className="h-[25px] rounded px-[5px] w-[60px] bg-slate-300 dark:bg-slate-600 animate-pulse gap-[6px]"
                  >
                    <span className=""></span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Reference No
            </span>
            <span className="h-[15px] w-[70px] bg-slate-300 dark:bg-slate-600 animate-pulse block mt-1"></span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Expiry Date
            </span>
            <span className="h-[15px] w-[100px] block bg-slate-300 dark:bg-slate-600 animate-pulse mt-1"></span>
          </div>
        </div>
      </div>

      <div className="hidden md:flex items-center gap-[10px] text-[12px]">
        <span>
          Powered by{" "}
          <a href="" target="blank" className="font-[600] text-sm">
            Simple
            <span className="text-primaryColor">pay</span>
          </a>
        </span>
        <span className="text-grayColor">|</span>
        <span>Terms</span>
        <span>Privacy</span>
      </div>
    </div>
  );
}
