import { configureStore } from "@reduxjs/toolkit";
import {
  currenciesReducer,
  invocesReducer,
  layoutReducer,
  paymentLinksReducer,
  paymentPagesReducer,
  paymentsReducer,
  transactionsReducer,
  userReducer,
  presaleReducer,
  RouterReducer,
  KYCReducer,
} from "./slices";

import { adminReducer } from "./slices/admin";

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    currencies: currenciesReducer,
    user: userReducer,
    paymentLinks: paymentLinksReducer,
    paymentPages: paymentPagesReducer,
    transactions: transactionsReducer,
    payments: paymentsReducer,
    invoices: invocesReducer,
    presales: presaleReducer,
    routers: RouterReducer,
    admin: adminReducer,
    kyc: KYCReducer,
  },
});
