import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import axios from "../../../axios";
import { useSelector } from "react-redux";
import EditAdminModal from "./EditAdminModal";

function AdminListTable({ admins, fetchAdmins }) {
  const { jwtToken } = useSelector((state) => state.admin);

  const [adminEditModal, setAdminEditModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState({});

  const handleDeleteAdmin = async (id) => {
    try {
      const res = await axios.delete(`/admin/auth/delete/${id}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      fetchAdmins();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="w-[100%]">
          <thead className="text-left bg-[#f6f6f6] dark:bg-darkCardColor">
            <tr className="text-[13px]">
              <th className="p-[12px] font-[600]">No</th>
              <th className="p-[12px] font-[600]">Email Address</th>
              <th className="p-[12px] font-[600]">Role</th>
              <th className="p-[12px] font-[600]">Last Loggin</th>
              <th className="p-[12px] font-[600]">Actions</th>
            </tr>
          </thead>
          <tbody>
            {admins?.map((item, index) => {
              return (
                <tr
                  onClick={() => {
                    setSelectedAdmin(item);
                    setAdminEditModal(true);
                  }}
                  className="border-b cursor-pointer  dark:border-darkBorderColor text-[13px] hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                  key={index}
                >
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {index + 1}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.email}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.role}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.lastLogin}
                  </td>
                  <td
                    className="px-[16px] text-xl text-red-500 cursor-pointer py-[19px] max-w-max min-w-[100px]"
                    onClick={() => {
                      handleDeleteAdmin(item?._id);
                    }}
                  >
                    <MdDelete />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {adminEditModal && (
        <EditAdminModal
          setAdminEditModal={setAdminEditModal}
          fetchAdmins={fetchAdmins}
          admin={selectedAdmin}
        />
      )}
    </div>
  );
}

export default AdminListTable;
