import React from "react";

export default function SinglePaymentPageOthers() {
    return (
        <div>
            <label htmlFor="">Pay with Others</label>
            {/* <div className="flex items-center gap-[10px] cursor-pointer border border-[#ced4da] dark:border-[#32394e] rounded h-[45px] px-[0.75rem] transition-all hover:bg-[#f6f6f6]">
        <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAAAnCAYAAABUr/U/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAR2SURBVHgBxZpNUhNBFMdf93yIO25gvAHuFLSEG8gJSKpUpFwAJyCcwLiwImoV4QTiCYglodwZT8B4g+woMky37zUBkslkprunJ/xWqZmp7p7/9PvsMJgzT9sXNcaDJUhEjTG2yDz+aPy+TMQ/KeVAMtZfWPD73QYbwJxgUDGrh3Lx8iKu40u/xMlW8dIimBEBY12ZyJ/hgndcpTiVifHs4OoVZ7ANUgngDsY6SSKOfm8FXXCMczGW21d14GyPgaxBtURSwP7Zlt8BRzgT42k7XuWcH85BhAkkwLEQ8e7vrYcRlKS0GOQT4qHYw2XtwD0iOWuevfH2oQSlxKDIwHl4Mu/dMAvaJWHoNWydrLUYy5+GS8znJ2AeHaomSkS8ZmM2VmK8wEghAA7BRggJXQnyL0jWByH6gicTX9EHv5ZIuYSRaEly76XlrrMSxFgMMg2PB+dgRsSEPPIX/JbpFlbzgde0ECbqbfqPDZ7HD2EIfTmp/3jp8Df6unX6/bwdNwXnG1X5KA6GnKpkR+wWPcek/BiE3hOXeQDO3RRiuIbmdVTwqDITMMTegbaHdYZ5RcatASrc+LXpH0OF0C7BcLo3dQNrmiDgazYRpVRozYgokbwS62fvwz7MgfQHubfQersgFAR87zvaMdiGtDLcRjYhfvS2wjrcN+TxKROFe8LV3FM7Q4UyLzikatNFilvEyje5AUnSwp+DeeyslS9X9G51fPVWb9ObCAQTYmSl11UKMhKiM3apMp9Du2c4TLCQhFc319I+5laM/DpjWsWyLH9N9jARa2bcGuD19VOH/QpVTMbiBDCznbo5Fn2UGGNOsDZzRAad3lu/AQ7IEeIOz6v3XrOifKIQzWJS5SXXYhwk51pZXYkYfoOWECPKmqhJVY191x8qA2VMRqADbjPabjQJWEDOS1cItS58lsQDC0zbCyhEpMQIAm8dp9ZzWigIFmpGgpDN3nlxM2wEIbPHNf7RFYJKh9N3wY4Sg7Z9EHKdnP+Gmq4gd87LXIjbxRoIQu1Hkz4Ljr1PQqjf6ZvPP8ctPLPYBj1y+wbXOUv4PdOL21DgxDNCdf5wJAQWf3fDZzCzCMpmgLnBWjo3qKwlyKBLZp124s++JNtcypb+QGK3txlOPJ9ZwpNapBrosUjbkmqEmwumQlDyI4FFOs9SZpx24mRCJkJgj6WRFoLILdQMd4jKDeRl/LcwZxlfwMh5jbrsaOvaJqVMlPvhhkGEyk3oCqvWlYMhOhf+AfSh7avvvMZsNitldjUXzDDnifWABqO+wQdw2AlPCzHOSnvYAWzvgTu0GsTa/Qy3RwPTziuNsYnORrtTbtTccREhyHnp9kUdCGJ0ZGB1VGApiFU1ai2IRR1l1fazEKTQeeVh7MTxoCp4MJ2LFGHdA70+TMLssjgURi46WDnd+EmwpLDthZZqCGvkBpHLVl6RE8+LUDo4+X/GjFAYVdHTnNWIKiuEGgMcMVHgOWgC5ZH2WS6EUOOAQ+gvTBwXaHPAbIoShPk7HmNdV6d3/wEsaMl/W2EeVAAAAABJRU5ErkJggg=="
            alt=""
            className="w-[25px]"
        />
        <span className="text-sm font-[500] ">
            Wallet Connect
        </span>
    </div> */}
            <div
                className="mt-4 flex items-center gap-[10px] cursor-pointer border border-[#ced4da] dark:border-[#32394e] rounded h-[45px] px-[0.75rem] transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                // onClick={payWithMetamask}
            >
                <img
                    src="https://app.hami.live/static/media/meta-mask.908a7bd02e43908895d8.png"
                    alt=""
                    className="w-[25px]"
                />
                <span className="text-sm font-[500] ">Metamask</span>
            </div>
        </div>
    );
}
