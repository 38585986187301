import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "../../../components";
import { useNavigate } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import NetworkListTable from "../../../features/admin/networks/NetworkListTable";
import NetworkPageFilter from "../../../features/admin/networks/NetworkPageFilter";
import AddNetworkModal from "../../../features/admin/networks/AddNetworkModal";

function AdmNetworkPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [networks, setNetworks] = useState([]);
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    totalUsers: 0,
    name: "",
  });
  const [networkAddModal, setNetworkAddModal] = useState(false);

  const { jwtToken } = useSelector((state) => state.admin);

  const fetchNetworks = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/admin/networks/all`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setIsLoading(false);
      setNetworks(res.data);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNetworks();
  }, []);

  return (
    <div className="py-5 sm:py-6">
      <div className="mb-6">
        <div className="flex items-ceenter gap-[10px] justify-between">
          <h3 className="text-base font-[600]">Networks Pages</h3>
          <div className="flex items-center gap-[15px]">
            <button
              className="bg-in hover:bg-btnHoverColor"
              onClick={() => {
                setNetworkAddModal(true);
              }}
            >
              + Add Network
            </button>
          </div>
        </div>
        <div
          className={
            "sm:mt-3 z-20 sm:z-1 bg-white sm:bg-[#f6f6f6] dark:bg-darkCardColor sm:rounded "
          }
        >
          <NetworkPageFilter
            setFilters={setFilters}
            filters={filters}
            fetchNetworks={fetchNetworks}
          />
        </div>
      </div>

      <div>
        {isLoading ? (
          <div className="flex items-center justify-center h-[200px]">
            <div className="w-[30px] h-[30px] border-4 border-primaryColor border-r-transparent rounded-full animate-spin "></div>
          </div>
        ) : !networks || networks?.length < 1 ? (
          <div className="flex flex-col items-center justify-center h-[200px]">
            <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
              <BiSearch />
            </div>
            <span className="block text-xl font-[600] mt-3">
              No Result found
            </span>
            <span className="block text-sm mt-2 text-grayColor">
              There are no Payment Pages created yet!!.
            </span>
          </div>
        ) : (
          <div>
            <NetworkListTable
              networks={networks}
              fetchNetworks={fetchNetworks}
            />

            <Pagination
              limit={filters.limit}
              skip={filters.skip}
              total={filters.totalUsers}
              incOrDecSkip={(number) =>
                setFilters({
                  ...filters,
                  skip: number,
                })
              }
              updateSkip={(skip) =>
                setFilters({
                  ...filters,
                  skip: skip,
                })
              }
            />
          </div>
        )}
      </div>
      <div>
        {networkAddModal && (
          <AddNetworkModal
            setNetworkAddModal={setNetworkAddModal}
            fetchNetworks={fetchNetworks}
          />
        )}
      </div>
    </div>
  );
}

export default AdmNetworkPage;
