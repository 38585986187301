import { monthNames } from "../data";

const formatDate = (date) => {
    const dt = new Date(date);

    return (
        monthNames[dt.getMonth()] + " " + dt.getDate() + ", " + dt.getFullYear()
    );
};

export default formatDate;
