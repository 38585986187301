import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";

const fetchInvoicesData = createAsyncThunk(
    "/user/fetchInvoicesData",
    async (args, { getState }) => {
        const { jwtToken, accountId } = getState().user;
        const response = await axios.get(
            `/invoices/data/all?accountId=${accountId}`,
            {
                headers: { Authorization: `Bearer ${jwtToken}` },
            }
        );
        return response.data;
    }
);

const fetchInvoices = createAsyncThunk(
    "/user/fetchInvoices",
    async (args, { getState }) => {
        const { jwtToken, accountId } = getState().user;
        const { filters, sort, limit, skip } = getState().invoices;
        const response = await axios.get(
            `/invoices/merchant/all?accountId=${accountId}&limit=${limit}&skip=${skip}&currency=${filters.currency}&invoiceNo=${filters.invoiceNo}&issueDate=${filters.issueDate}&status=${filters.status}&sort=${sort}&customer=${filters.customer}`,
            {
                headers: { Authorization: `Bearer ${jwtToken}` },
            }
        );
        return response.data;
    }
);

const initialState = {
    isProductsFetching: true,
    products: [],
    productsRows: [
        {
            selectedProduct: {},
            quantity: 0,
            _id: "",
        },
    ],
    customers: [],
    selectedCustomer: {},
    totalProductPrice: 0,
    invoices: [],
    isLoading: true,
    totalInvoices: 0,
    filters: {
        invoiceNo: "",
        currency: "all",
        issueDate: "all",
        status: "all",
        customer: "",
    },
    sort: "default",
    skip: 0,
    limit: 10,
};

export const invoicesSlice = createSlice({
    name: "invoices",
    initialState,
    reducers: {
        setSelectedCustomer: (state, action) => {
            state.selectedCustomer = action.payload;
        },
        addNewCustomer: (state, action) => {
            state.customers?.unshift(action.payload);
            state.selectedCustomer = action.payload;
        },
        deleteCustomer: (state, action) => {
            const filteredCustomers = state.customers.filter((customer) => {
                return customer?._id !== action.payload;
            });
            state.customers = filteredCustomers;
            if (state.selectedCustomer?._id === action.payload) {
                state.selectedCustomer = {};
            }
        },
        updateCustomer: (state, action) => {
            const objIndex = state.customers.findIndex(
                (obj) => obj._id === action.payload?._id
            );
            state.customers[objIndex] = action.payload;
            if (state.selectedCustomer?._id === action.payload?._id) {
                state.selectedCustomer = action.payload;
            }
        },
        addNewProduct: (state, action) => {
            console.log(action.payload);
            state.products?.unshift(action.payload?.product);
            state.productsRows[action?.payload?.rowIndex] = {
                quantity: 1,
                _id: action.payload?.product?._id,
                selectedProduct: action.payload?.product,
            };
        },
        changeProductInRow: (state, action) => {
            state.productsRows[action?.payload?.rowIndex] = {
                quantity: 1,
                _id: action.payload?.product?._id,
                selectedProduct: action.payload?.product,
            };
        },
        changeProductQuantity: (state, action) => {
            state.productsRows[action?.payload?.rowIndex].quantity =
                action.payload?.value < 1 ? 1 : action.payload?.value;
        },
        updateTotalProductPrice: (state, action) => {
            state.totalProductPrice = action.payload;
        },
        addNewPorductRow: (state, action) => {
            state.productsRows?.push({
                selectedProduct: {},
                quantity: 0,
                _id: "",
            });
        },
        removeProductRow: (state, action) => {
            const filteredProductRows = state.productsRows?.filter(
                (_, index) => {
                    return index !== action.payload;
                }
            );
            state.productsRows = filteredProductRows;
        },
        deleteProduct: (state, action) => {
            const filteredProducts = state.products.filter((product) => {
                return product?._id !== action.payload;
            });
            state.products = filteredProducts;
            state.productsRows = state.productsRows?.map((row) => {
                if (row?._id === action.payload) {
                    row.selectedProduct = {};
                    row.quantity = 1;
                    row._id = "";
                }
                return row;
            });
        },
        updateProduct: (state, action) => {
            const proObjIndex = state.products.findIndex(
                (obj) => obj._id === action.payload?._id
            );
            state.products[proObjIndex] = action.payload;
            state.productsRows = state.productsRows?.map((row) => {
                if (row?._id === action.payload?._id) {
                    row.selectedProduct = action.payload;
                }
                return row;
            });
        },

        updateInvoiceFilters: (state, action) => {
            state.filters[action.payload?.name] = action.payload?.value;
            state.skip = 0;
        },
        updateInvoiceSort: (state, action) => {
            state.sort = action.payload;
            state.skip = 0;
        },
        clearInvoiceFilters: (state, action) => {
            state.filters = {
                invoiceNo: "",
                currency: "all",
                issueDate: "all",
                status: "all",
                customer: "",
            };
            state.sort = "default";
            state.limit = 10;
            state.skip = 0;
        },
        updateInvoiceSkip: (state, action) => {
            state.skip = action.payload;
        },
        incOrDecInvoiceSkip: (state, action) => {
            state.skip += action.payload;
        },
        updateInvoiceLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateInvoiceStatus: (state, action) => {
            const objIndex = state.invoices?.findIndex(
                (obj) => obj.id === action.payload.id
            );
            state.invoices[objIndex].status = action.payload?.status;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInvoicesData.fulfilled, (state, action) => {
            state.products = action.payload?.products;
            state.customers = action.payload?.customers;
            state.isProductsFetching = false;
        });
        builder.addCase(fetchInvoices.fulfilled, (state, action) => {
            state.invoices = action.payload?.invoices;
            state.totalInvoices = action.payload?.totalInvoices;
            state.isLoading = false;
        });
    },
});

export const {
    setSelectedCustomer,
    addNewCustomer,
    deleteCustomer,
    updateCustomer,
    addNewProduct,
    changeProductInRow,
    changeProductQuantity,
    updateTotalProductPrice,
    addNewPorductRow,
    removeProductRow,
    deleteProduct,
    updateProduct,
    clearInvoiceFilters,
    incOrDecInvoiceSkip,
    updateInvoiceFilters,
    updateInvoiceSkip,
    updateInvoiceSort,
    updateInvoiceLoading,
    updateInvoiceStatus,
} = invoicesSlice.actions;

export { fetchInvoicesData, fetchInvoices };

export default invoicesSlice.reducer;
