import React, { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { HiOutlineShare } from "react-icons/hi";
import { useParams } from "react-router-dom";

import { ShareModal } from "../../../components";
import { CreatePaymentPageModal } from "../../PaymentPages";

export default function PaymentPageEditAndShare({
    paymentPage,
    setPaymentPage,
}) {
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const { id } = useParams();

    return (
        <div className="flex items-center gap-[10px] justify-end mb-6">
            <button
                className="bg-transparent border dark:border-darkBorderColor text-textColor dark:text-darkTextColor"
                onClick={() => {
                    setIsShareModalOpen(true);
                }}
            >
                <HiOutlineShare />
            </button>
            {isShareModalOpen && (
                <ShareModal
                    setIsShareModalOpen={setIsShareModalOpen}
                    url={`${process.env.REACT_APP_CLIENT_URL}/page/${id}`}
                />
            )}
            <button
                className="flex items-center gap-[10px]"
                onClick={() => setIsEditModalOpen(true)}
            >
                <AiOutlineEdit />
                Edit Page
            </button>
            {isEditModalOpen && (
                <CreatePaymentPageModal
                    setIsCreatePaymentPageModalOpen={setIsEditModalOpen}
                    isEdit={true}
                    paymentPage={paymentPage}
                    setPaymentPage={setPaymentPage}
                />
            )}
        </div>
    );
}
