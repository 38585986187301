import PaymentPagePaymentsTable from "./components/PaymentPagePaymentsTable";
import PaymentPageStatistics from "./components/PaymentPageStatistics";
import PyamentPageDetailedInfo from "./components/PyamentPageDetailedInfo";
import PaymentPageEditAndShare from "./components/PaymentPageEditAndShare";

export {
    PaymentPagePaymentsTable,
    PaymentPageStatistics,
    PyamentPageDetailedInfo,
    PaymentPageEditAndShare,
};
