import React, { useEffect, useRef } from "react";

export default function OtpPinInput({ pin, setPin }) {
    const input1Ref = useRef();

    const handleChange = (elmnt) => {
        if (!pin[elmnt.target.name] || !elmnt.target.value) {
            setPin((prev) => {
                return { ...prev, [elmnt.target.name]: elmnt.target.value };
            });
        }

        if (elmnt.target.value) {
            const next = elmnt.target.tabIndex;
            if (next < 5) {
                elmnt.target.parentNode.childNodes[next].focus();
            }
        } else {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.parentNode.childNodes[next].focus();
            }
        }
    };

    useEffect(() => {
        input1Ref.current.focus();
    }, []);

    return (
        <div className={"grid grid-cols-5 gap-[1em] "}>
            <input
                type="number"
                className="text-center arrow-hidden"
                tabIndex={1}
                maxLength={1}
                name="pin1"
                value={pin.pin1}
                onChange={handleChange}
                ref={input1Ref}
            />
            <input
                type="number"
                className="text-center arrow-hidden"
                tabIndex={2}
                maxLength={1}
                name="pin2"
                value={pin.pin2}
                onChange={handleChange}
            />
            <input
                type="number"
                className="text-center arrow-hidden"
                tabIndex={3}
                maxLength={1}
                name="pin3"
                value={pin.pin3}
                onChange={handleChange}
            />
            <input
                type="number"
                className="text-center arrow-hidden"
                tabIndex={4}
                maxLength={1}
                name="pin4"
                value={pin.pin4}
                onChange={handleChange}
            />
            <input
                type="number"
                className="text-center arrow-hidden"
                tabIndex={5}
                maxLength={1}
                name="pin5"
                value={pin.pin5}
                onChange={handleChange}
            />
        </div>
    );
}
