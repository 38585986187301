import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

import axios from "../../axios";
import {
    SingleInvoiceDetails,
    SingleInvoiceDetailsSkeleton,
    SingleInvoiceForm,
    SingleInvoiceFormSkeleton,
} from "../../features/SingleInvoicePage";
import { PaymentAccountBar } from "../../components";
import { IoWarningOutline } from "react-icons/io5";
import { useCallback } from "react";

export default function SingleInvoicePage() {
    const [invoice, setInvoice] = useState({});
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const { id } = useParams();

    const fetchInvoice = useCallback(async () => {
        try {
            const response = await axios.get(`/invoices/single/${id}`);

            setInvoice(response.data);
            setIsLoading(false);
        } catch (err) {
            setError(true);
        }
    }, [id]);

    useEffect(() => {
        fetchInvoice();
    }, [fetchInvoice]);

    return (
        <div className="relative">
            {!error && (
                <div className="hidden md:block fixed top-0 left-0 w-[50vw] h-[100%] bg-[#f3f3f3] dark:bg-darkCardColor shadow-sm"></div>
            )}

            <PaymentAccountBar />

            {isLoading ? (
                <div className="relative grid md:grid-cols-2 md:min-h-[calc(100vh-70px)] max-w-[900px] gap-[2em] md:gap-[4em] lg:gap-[8em] md:px-[1.8em] mx-auto md:py-20">
                    <SingleInvoiceDetailsSkeleton />
                    <SingleInvoiceFormSkeleton />
                </div>
            ) : error ? (
                <div className="flex flex-col items-center justify-center min-h-[calc(100vh-4.2em)] md:min-h-[100vh] px-5">
                    <span className="block text-[110px] sm:text-[150px] md:text-[180px] font-[900]">
                        404
                    </span>
                    <span className="block text-xl font-[600] text-center">
                        We can't find the page you're looking for
                    </span>
                    <span className="text-sm text-grayColor block mt-4 text-center">
                        Visit our support page for further assistance.
                    </span>
                </div>
            ) : (
                <div className="relative grid md:grid-cols-2 md:min-h-[calc(100vh-70px)] max-w-[900px] gap-[2em] md:gap-[4em] lg:gap-[8em] md:px-[1.8em] mx-auto md:py-20">
                    <SingleInvoiceDetails invoice={invoice} />

                    {invoice?.expiryDate &&
                    new Date(invoice?.expiryDate).getDate() <
                        new Date().getDate() ? (
                        <div className="">
                            <div className="flex justify-center text-[100px] text-yellow-500">
                                <IoWarningOutline />
                            </div>
                            <h3 className="font-[600] text-xl text-center mt-3">
                                Invoice Expired !
                            </h3>
                            <span className="text-sm text-grayColor text-center block mt-3 leading-[28px]">
                                This Invoice is expired. Please contact to
                                merchant to know more.
                            </span>

                            <Link
                                to="/"
                                className="text-sm underline text-blueColor text-center block mt-5"
                            >
                                Back To Home
                            </Link>
                        </div>
                    ) : (
                        <SingleInvoiceForm invoice={invoice} />
                    )}
                </div>
            )}
        </div>
    );
}
