import React, { useState, useEffect } from "react";
import { BiLockAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import axios from "../../axios";
import { BtnLoader } from "../../components";
import { setAccounts, setUser } from "../../redux/slices/userSlice";

export default function LoginPage() {
    const [data, setData] = useState({ emailOrUsername: "", password: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { accountId, isLoggedIn } = useSelector((state) => state.user);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            setError("");
            setIsLoading(true);

            const response = await axios.post(
                `/users/login?accountId=${accountId}`,
                {
                    userInfo: data.emailOrUsername,
                    password: data.password,
                }
            );

            dispatch(setUser(response.data));
            dispatch(
                setAccounts({
                    accounts: response?.data?.accounts,
                    account: response?.data?.account,
                })
            );
            setIsLoading(false);

            if (searchParams.get("redirect")) {
                navigate(searchParams.get("redirect"), { replace: true });
            } else {
                navigate("/", { replace: true });
            }
        } catch (err) {
            setIsLoading(false);
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/", { replace: true });
        }
    }, [navigate]);

    return (
        <div className="flex items-center justify-center mx-auto py-6 min-h-[calc(var(--doc-height)-4.2em)] sm:min-h-[calc(100vh-5em)] max-w-[430px] px-5">
            <div className="w-full">
                <h1 className="font-bold text-lg sm:text-xl mb-10 text-center">
                    Login
                </h1>

                <form className="w-full" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="username">Email Or Username *</label>
                        <input
                            type="text"
                            id="username"
                            placeholder="Enter Email Id or Username"
                            name="emailOrUsername"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-6">
                        <label htmlFor="password">Password *</label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Enter password"
                            name="password"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex mt-6 items-center gap-[7px]">
                        <input
                            type="checkbox"
                            name=""
                            id="remember-me"
                            className="cursor-pointer w-[14px] h-[14px]"
                        />
                        <label htmlFor="remember-me" className="mb-0">
                            Remember me
                        </label>
                    </div>
                    <div className="mt-6">
                        {error && (
                            <span className="text-[13px] font-medium mb-4 block text-red-600">
                                {error}
                            </span>
                        )}
                        <button
                            className="w-[100%] hover:bg-btnHoverColor py-0 disabled:opacity-80 disabled:hover:bg-primaryColor"
                            disabled={
                                isLoading ||
                                !data.emailOrUsername ||
                                !data.password
                            }
                        >
                            {isLoading ? <BtnLoader /> : "Log In"}
                        </button>
                    </div>
                </form>

                <div className="px-5 mt-6">
                    <Link
                        to="/auth/forget-password"
                        className="text-[13px] text-grayColor dark:text-darkGrayColor flex flex-wrap items-center justify-center gap-[5px] cursor-pointer "
                    >
                        <BiLockAlt />
                        Forgot your password?
                    </Link>
                </div>
                <div className="text-center mt-5">
                    <span className="text-[13px]">
                        Don't have an account ?{" "}
                        <Link
                            to="/auth/select-account-type"
                            className="text-primaryColor font-medium"
                        >
                            Signup now
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    );
}
