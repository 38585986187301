import React, { useState } from "react";
import { PresalePageForm } from "../../features/PresalePage/index";

function PresalePage() {
  return (
    <div>
      <PresalePageForm />
    </div>
  );
}

export default PresalePage;
