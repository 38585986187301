import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function MerchantAccountDetails() {
  const { merchantDetails, user } = useSelector((state) => state.user);

  const navigate = useNavigate();

  return (
    <div>
      {user?.type === "merchant" ? (
        <div className="w-full max-w-md text-gray-800">
          <h2 className="text-xl font-semibold mb-4 dark:text-white">
            Merchant Details
          </h2>
          <ul className="space-y-3 list-none">
            <li className="flex justify-between dark:text-white">
              <strong>Merchant ID :</strong>{" "}
              <span>{merchantDetails?.merchantId}</span>
            </li>
            <li className="flex justify-between dark:text-white">
              <strong>UserName :</strong>{" "}
              <span>{merchantDetails?.user?.username}</span>
            </li>

            <li className="flex justify-between dark:text-white">
              <strong>Business Type :</strong>{" "}
              <span>{merchantDetails?.businessType}</span>
            </li>
            <li className="flex justify-between dark:text-white">
              <strong>Country :</strong> <span>{merchantDetails?.country}</span>
            </li>
            <li className="flex justify-between dark:text-white">
              <strong>City :</strong> <span>{merchantDetails?.city}</span>
            </li>
            <li className="flex justify-between dark:text-white">
              <strong>Pin Code :</strong>{" "}
              <span>{merchantDetails?.pincode}</span>
            </li>
            <li className="flex justify-between dark:text-white">
              <strong>Pan Number :</strong>{" "}
              <span>{merchantDetails?.panNumber}</span>
            </li>
            <li className="flex justify-between dark:text-white">
              <strong>Address 1 :</strong>{" "}
              <span>{merchantDetails?.address1}</span>
            </li>
            <li className="flex justify-between dark:text-white">
              <strong>Address 2 :</strong>{" "}
              <span>
                {merchantDetails?.address2 ? merchantDetails?.address2 : "N/A"}
              </span>
            </li>
          </ul>
        </div>
      ) : (
        <div className="text-center p-6 py-10">
          <div className="mb-4">
            <h1 className="text-2xl font-semibold text-gray-200">
              Upgrade to a Merchant Account
            </h1>
            <p className="text-gray-400 mt-2">
              Your current plan does not include merchant features. Upgrade now
              to unlock additional benefits and tools.
            </p>
          </div>
          <div>
            <button
              className=" text-white px-4 py-2 rounded-md  transition-colors"
              onClick={() => {
                navigate("/upgrade/merchant");
              }}
            >
              Upgrade Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MerchantAccountDetails;
