import React from "react";
import { BiMailSend } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { setIsEmailVerifyModalOpen } from "../../redux/slices/layoutSlice";

export default function EmailVerifyModal() {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    return (
        <div className="fixed inset-0 flex bg-[#fff5] dark:bg-[#0005] items-center justify-center z-20 transition-all p-[15px]">
            <div className="relative flex flex-col bg-[#fff] dark:bg-darkCardColor transition-all w-[100%] max-w-[430px] overflow-y-auto rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
                <div className="text-center px-5 py-7">
                    <div className="w-[72px] h-[72px] rounded-full flex items-center mx-auto justify-center bg-[#eff3f7] dark:bg-darkBgColor text-primaryColor text-3xl">
                        <BiMailSend />
                    </div>
                    <h3 className="font-bold text-lg sm:text-xl mt-4 ">
                        Verify your email
                    </h3>
                    <span className="text-[14px] block text-grayColor dark:text-darkGrayColor mt-3 leading-[28px]">
                        We have sent you a verification email to{" "}
                        <span className="font-[600] text-textColor dark:text-darkTextColor">
                            {user?.email}
                        </span>
                        , Please check your email and click the verify link to
                        accept payment through Email.
                    </span>

                    <a href="https://mail.google.com/" target="blank">
                        <button className="mt-6 h-[40px] bg-[#f44336]">
                            Open Gmail
                        </button>
                    </a>
                </div>

                <button
                    className="absolute top-[10px] right-[10px] bg-[#f6f6f6] dark:bg-darkCardHoverColor text-xl h-auto p-1 rounded-full hover:text-red-500"
                    onClick={() => dispatch(setIsEmailVerifyModalOpen(false))}
                >
                    <MdClose />
                </button>
            </div>
        </div>
    );
}
