import { useSelector } from "react-redux";
import { getPrice } from "../../../utils";

const usePaymentPageAmount = (paymentPage, selectedCurrency, price) => {
    const { prices } = useSelector((state) => state.currencies);

    const amount =
        paymentPage?.amountType === "fixed"
            ? paymentPage?.currency?._id === selectedCurrency?._id
                ? paymentPage?.amount
                : (getPrice(prices, paymentPage.currency) / price) *
                  paymentPage?.amount
            : "";

    return amount;
};

export default usePaymentPageAmount;
