import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";

const fetchTransactions = createAsyncThunk(
    "/user/fetchTransactions",
    async (args, { getState }) => {
        const { jwtToken, accountId } = getState().user;
        const { filters, sort, skip } = getState().transactions;
        const response = await axios.get(
            `/transactions/user/all?accountId=${accountId}&type=${filters.type}&currency=${filters.currency}&date=${filters.date}&sort=${sort}&transactionId=${filters.transactionId}&emailOrUsername=${filters.emailOrUsername}&skip=${skip}`,
            {
                headers: { Authorization: `Bearer ${jwtToken}` },
            }
        );
        console.log(response.data);
        return response.data;
    }
);

const initialState = {
    transactions: [],
    totalTransactions: 0,
    isLoading: true,
    filters: {
        transactionId: "",
        currency: "all",
        type: "all",
        date: "all",
        status: "all",
        emailOrUsername: "",
    },
    sort: "default",
    skip: 0,
    limit: 10,
};

export const transactionsSlice = createSlice({
    name: "transactions",
    initialState,
    reducers: {
        updateIsTxnLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateTxnFilters: (state, action) => {
            state.filters[action.payload?.name] = action.payload?.value;
            state.skip = 0;
        },
        updateTxnSort: (state, action) => {
            state.sort = action.payload;
            state.skip = 0;
        },
        clearTxnFilters: (state, action) => {
            state.filters = {
                referenceNo: "",
                currency: "all",
                type: "all",
                date: "all",
                status: "all",
                emailOrUsername: "",
            };
            state.sort = "default";
            state.limit = 10;
            state.skip = 0;
        },
        updateTxnSkip: (state, action) => {
            state.skip = action.payload;
        },
        incOrDecSkipTxn: (state, action) => {
            state.skip += action.payload;
        },
        updateLimit: (state, action) => {
            state.limit = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTransactions.fulfilled, (state, action) => {
            state.transactions = action.payload?.transactions;
            state.totalTransactions = action.payload?.totalTransactions;
            state.isLoading = false;
        });
    },
});

export { fetchTransactions };

export const {
    clearTxnFilters,
    incOrDecSkipTxn,
    updateIsTxnLoading,
    updateTxnFilters,
    updateTxnSkip,
    updateTxnSort,
    updateLimit,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
