import React, { useState, useEffect } from "react";
import { FaMobileAlt } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../axios";
import { BtnLoader, CountriesModal } from "../../components";
import { setIsCountriesModalOpen } from "../../redux/slices/layoutSlice";

export default function ForgetPasswordPage() {
    const [section, setSection] = useState(1);
    const [selectedCountry, setSelectedCountry] = useState(false);
    const [data, setData] = useState({
        mobileNumber: "",
        email: "",
        method: "email",
        otpNumber: "",
        password: "",
        cPassword: "",
    });
    const [status, setStatus] = useState({
        isLoading: false,
        error: "",
    });
    const [errors, setErrors] = useState({
        cPassword: "",
    });
    const [timer, setTimer] = useState(false);
    const [timerActive, setTimerActive] = useState(false);

    const { isCountriesModalOpen } = useSelector((state) => state.layout);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    console.log("Hi");

    const handleChange = (e) => [
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        }),
    ];

    const handleVerify = async (e) => {
        try {
            e.preventDefault();

            setStatus((prev) => {
                return { ...prev, isLoading: true, error: "" };
            });

            await axios.post("/otp/generate-reset-password-otp", {
                ...data,
                phonecode: selectedCountry?.phonecode,
            });

            setStatus((prev) => {
                return { ...prev, isLoading: false };
            });

            setTimerActive(true);
            setSection(2);
        } catch (err) {
            setStatus((prev) => {
                return {
                    ...prev,
                    isLoading: false,
                    error:
                        err?.response?.data?.error ||
                        "Something went wrong, Try again",
                };
            });
        }
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            setStatus((prev) => {
                return { ...prev, isLoading: true, error: "" };
            });

            await axios.post("/users/reset-password", {
                ...data,
                phonecode: selectedCountry?.phonecode,
            });

            setStatus((prev) => {
                return { ...prev, isLoading: false };
            });

            navigate("/auth/login");
        } catch (err) {
            setStatus((prev) => {
                return {
                    ...prev,
                    isLoading: false,
                    error:
                        err?.response?.data?.error ||
                        "Something went wrong, Try again",
                };
            });
        }
    };

    useEffect(() => {
        console.log("Running");
        if (data.password && data.cPassword) {
            if (data.password !== data.cPassword) {
                setErrors((prev) => {
                    return {
                        ...prev,
                        cPassword: "Confirm password is not same!",
                    };
                });
            } else {
                setErrors((prev) => {
                    return { ...prev, cPassword: "" };
                });
            }
        }
    }, [data.password, data.cPassword]);

    useEffect(() => {
        let interval = null;
        if (timerActive) {
            interval = window.setInterval(() => {
                if (timer <= 1) {
                    setTimerActive(false);
                    window.clearInterval(interval);
                }
                setTimer((prev) => {
                    return prev - 1;
                });
            }, 1000);
        }

        return () => window.clearInterval(interval);
    }, [timerActive, timer]);

    return (
        <div className="flex items-center mx-auto justify-center min-h-[calc(100vh-5em)] py-6 max-w-[430px]">
            <div className="w-[100%]">
                <h1 className="font-bold text-xl mb-10 text-center">
                    Forget Password
                </h1>

                {section === 1 && (
                    <form className="" onSubmit={handleVerify}>
                        <div
                            className={
                                data.method === "email" ? "block" : "hidden"
                            }
                        >
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Enter Email"
                                name="email"
                                onChange={handleChange}
                                className={
                                    "arrow-hidden invalid:border-red-500 "
                                }
                            />
                            <span className="text-[13px] text-grayColor block mt-[7px] leading-[23px]">
                                Enter your registered Email address and password
                                reset email will be sent to you!
                            </span>
                        </div>
                        <div
                            className={
                                data.method === "mobile" ? "block" : "hidden"
                            }
                        >
                            <div className="flex items-center gap-[10px] h-[45px]">
                                <div className="relative h-full">
                                    <button
                                        className="h-[100%] border border-[#ced4da] dark:border-darkBorderColor text-center dark:text-darkTextColor bg-transparent min-w-[75px] text-textColor flex gap-[5px] items-center justify-center px-0"
                                        onClick={() => {
                                            dispatch(
                                                setIsCountriesModalOpen(true)
                                            );
                                        }}
                                        type="button"
                                    >
                                        +{selectedCountry?.phonecode || 0}{" "}
                                        <FiChevronDown />
                                    </button>

                                    {isCountriesModalOpen && (
                                        <CountriesModal
                                            setSelectedCountry={
                                                setSelectedCountry
                                            }
                                        />
                                    )}
                                </div>
                                <input
                                    type="number"
                                    id="mobile-number"
                                    className="arrow-hidden h-full"
                                    name="mobileNumber"
                                    onChange={handleChange}
                                    placeholder="☎ Mobile Number"
                                />
                            </div>
                            <span className="text-[13px] text-grayColor block mt-[10px] leading-[23px]">
                                Enter your registered Mobile number, we will
                                sent you an OTP (one time password).
                            </span>
                        </div>
                        <div className="mt-3">
                            {data.method === "email" && (
                                <span
                                    className="text-sm text-blueColor flex items-center gap-[5px] cursor-pointer "
                                    onClick={() =>
                                        setData((prev) => {
                                            return {
                                                ...prev,
                                                method: "mobile",
                                            };
                                        })
                                    }
                                >
                                    <FaMobileAlt /> Continue with OTP
                                </span>
                            )}
                            {data.method === "mobile" && (
                                <span
                                    className="text-sm text-blueColor flex items-center gap-[5px] cursor-pointer"
                                    onClick={() =>
                                        setData((prev) => {
                                            return {
                                                ...prev,
                                                method: "email",
                                            };
                                        })
                                    }
                                >
                                    <FaMobileAlt /> Continue with Email
                                </span>
                            )}
                        </div>
                        <div className="mt-5">
                            {status.error && (
                                <span className="text-sm text-red-500 block mb-3">
                                    {status.error}
                                </span>
                            )}
                            <button
                                className="hover:bg-btnHoverColor block ml-auto w-[100%] disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor"
                                disabled={
                                    (data.method === "email" && !data.email) ||
                                    (data.method === "mobile" &&
                                        (!data.mobileNumber ||
                                            !selectedCountry?.phonecode))
                                }
                            >
                                {status.isLoading ? <BtnLoader /> : "Verify"}
                            </button>
                        </div>
                    </form>
                )}

                {section === 2 && (
                    <form action="" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="">OTP</label>
                            <input
                                type="number"
                                className="arrow-hidden"
                                placeholder="xxxxx"
                                name="otpNumber"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="">New Password</label>
                            <input
                                type="password"
                                placeholder="********"
                                name="password"
                                onChange={handleChange}
                            />
                            <span className="block text-[13px] text-grayColor mt-[7px]">
                                Password must be atleast 8 characters long.
                            </span>
                        </div>
                        <div className="mt-4">
                            <label htmlFor="">Confirm New Password</label>
                            <input
                                type="password"
                                placeholder="********"
                                name="cPassword"
                                onChange={handleChange}
                            />
                            {errors.cPassword && (
                                <span className="block text-[13px] text-red-500 mt-[7px]">
                                    {errors.cPassword}
                                </span>
                            )}
                        </div>

                        <div className="mt-5">
                            {status.error && (
                                <span className="text-sm text-red-500 block mb-3">
                                    {status.error}
                                </span>
                            )}
                            <button
                                className="hover:bg-btnHoverColor block ml-auto w-[100%] disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor"
                                disabled={
                                    !data.otpNumber ||
                                    !data.password ||
                                    !data.cPassword ||
                                    data.password?.length < 8 ||
                                    data.password !== data.cPassword
                                }
                            >
                                {status.isLoading ? <BtnLoader /> : "Submit"}
                            </button>
                        </div>
                    </form>
                )}

                {section === 2 && (
                    <div className="text-center mt-5">
                        <span className="text-[13px]">
                            Didn't receive a code ?{" "}
                            {timer > 0 ? (
                                <span className="text-primaryColor font-medium cursor-not-allowed">
                                    Resend in {timer} s
                                </span>
                            ) : (
                                <span
                                    className="text-primaryColor font-medium cursor-pointer"
                                    onClick={handleVerify}
                                >
                                    Resend
                                </span>
                            )}
                        </span>
                    </div>
                )}

                <div className="text-center mt-5">
                    <span className="text-[13px]">
                        Remember It ?{" "}
                        <Link
                            to="/auth/login"
                            className="text-primaryColor font-medium"
                        >
                            login here
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    );
}
