import React from "react";

export default function ConfirmModal({ confirmText, handleClick }) {
    return (
        <div className="fixed inset-0 flex bg-[#0007] items-center justify-center z-20 transition-all p-[15px]">
            <div className="relative flex flex-col bg-[#fff] transition-all w-[100%] max-w-[380px] overflow-y-auto shadow-sm p-4 rounded">
                <h3 className="font-[600] text-lg">Are you sure?</h3>
                <span className="block text-sm text-grayColor leading-[28px] mt-1">
                    {confirmText}
                </span>
                <div className="mt-5 w-[100%] flex items-center gap-[15px]">
                    <button
                        className="w-full bg-transparent border text-textColor"
                        onClick={() => handleClick(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="w-full hover:bg-btnHoverColor"
                        onClick={() => handleClick(true)}
                    >
                        Ok
                    </button>
                </div>
            </div>
        </div>
    );
}
