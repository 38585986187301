import React from "react";
import { BiPlus } from "react-icons/bi";

export default function CurrenciesTableSingleRow({ currency }) {
    return (
        <tr className="transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor">
            <td className="py-[12px] pl-4">
                <div className="flex items-center gap-[10px]">
                    <div className="w-[30px] h-[30px] rounded-full overflow-hidden">
                        <img
                            src={
                                currency?.logo
                                    ? process.env.REACT_APP_SERVER_URL +
                                      currency?.logo
                                    : `https://avatars.dicebear.com/api/identicon/${currency._id}.svg`
                            }
                            alt=""
                            className="w-[100%] h-[100%] object-cover"
                        />
                    </div>
                    <div>
                        <span className="block text-[14px] font-[600]">
                            {currency.name}
                        </span>
                        <span className="block text-[12px] text-grayColor">
                            {currency.symbol}
                        </span>
                    </div>
                </div>
            </td>
            <td className="px-[10px]">
                <span className="block text-[14px] font-medium">18795.07</span>
                <span className="block text-[13px] text-red-500">-1.22%</span>
            </td>
            <td className="pl-[10px] pr-4 ">
                {/* <button className="flex items-center gap-[5px] bg-green-100 text-green-700 p-0 w-[25px] h-[25px] flex items-center justify-center rounded-full">
                    <BiCheckDouble />
                 </button> */}
                <button className="flex items-center gap-[5px] bg-blue-100 text-blue-700 p-0 w-[25px] h-[25px] justify-center rounded-full">
                    <BiPlus />
                </button>
            </td>
        </tr>
    );
}
