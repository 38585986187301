import React from "react";
import { useSelector } from "react-redux";
import CurrenciesTableSingleRow from "./CurrenciesTableSingleRow";

export default function CurrenciesTable() {
    const { currencies } = useSelector((state) => state.currencies);

    return (
        <div>
            <table className="w-[100%]">
                <tbody>
                    {currencies?.map((currency, index) => {
                        return (
                            <CurrenciesTableSingleRow
                                key={index}
                                currency={currency}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
