import React, { useCallback, useEffect, useState } from "react";
import { BiLink, BiSearch } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import { HiDownload } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { avatarImg, invoiceImg } from "../../../assets/images";
import axios from "../../../axios";
import { ConfirmModal } from "../../../components";
import { updateInvoiceStatus } from "../../../redux/slices/invocesSlice";
import { updatePaymentLinkStatus } from "../../../redux/slices/paymentLinksSlice";
import { formatDate, getFormatedDate } from "../../../utils";

export default function InvoiceDetailsModal({
    setIsInvoiceDetailsModalOpen,
    id,
}) {
    const [invoice, setInvoice] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const [isCopied, setIsCopied] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isCancelLoading, setIsCancelLoading] = useState(false);

    const { jwtToken, user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const handleCancel = async (isConfirmed) => {
        try {
            setIsConfirmModalOpen(false);

            if (isConfirmed) {
                setIsCancelLoading(true);
                await axios.patch(
                    `/invoices/update/status/${id}`,
                    {},
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );
                setInvoice((prev) => {
                    return { ...prev, status: "Cancelled" };
                });
                dispatch(updateInvoiceStatus({ id, status: "Cancelled" }));
                setIsCancelLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const fetchInvoices = useCallback(async () => {
        try {
            const response = await axios.get(`/invoices/single/${id}/details`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });
            setInvoice(response.data);
            setIsLoading(false);
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchInvoices();
    }, [fetchInvoices]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsCopied(false);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [isCopied]);

    return (
        <div
            className="fixed inset-0 sm:inset-0 bg-[#0007] dark:bg-[#0005] z-20 flex items-center justify-end"
            id="invoice-details-modal"
            onClick={(e) => {
                if (
                    e.target ===
                    document.getElementById("invoice-details-modal")
                ) {
                    setIsInvoiceDetailsModalOpen(false);
                }
            }}
        >
            <div className="flex flex-col w-[100%] h-[100vh] sm:max-w-[500px] overflow-y-auto bg-[#fff] dark:bg-[#212529]">
                <div className="flex items-center justify-between p-4 border-b dark:border-darkBorderColor">
                    <h1 className="font-medium">Invoice Details</h1>
                    <button
                        className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
                        onClick={() => {
                            setIsInvoiceDetailsModalOpen(false);
                        }}
                    >
                        <MdClose />
                    </button>
                </div>

                {isLoading ? (
                    <div className="flex items-center mt-8 justify-center">
                        <div className="w-[30px] h-[30px] border-4 border-primaryColor border-r-transparent rounded-full animate-spin"></div>
                    </div>
                ) : error ? (
                    <div className="flex flex-col items-center justify-center h-[200px]">
                        <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
                            <BiSearch />
                        </div>
                        <span className="block text-xl font-[600] mt-3">
                            No Result found
                        </span>
                        <span className="block text-sm mt-2 text-grayColor dark:text-darkGrayColor">
                            This invoice not found at this time!!.
                        </span>
                    </div>
                ) : (
                    <div className="p-4 h-[100%] overflow-y-auto">
                        <table className="w-[100%]">
                            <tbody>
                                <tr>
                                    <td className="flex items-start text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                                        Invoice Id
                                    </td>
                                    <td className="text-sm font-medium py-3 pl-[10px]">
                                        {invoice?.id}
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                                        Link Type
                                    </td>
                                    <td className="text-sm font-medium py-3 flex gap-[7px] pl-[10px]">
                                        <span className="text-lg">
                                            <BiLink />
                                        </span>
                                        Payment Link
                                    </td>
                                </tr> */}
                                {/* <tr>
                                    <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                                        Payment For
                                    </td>
                                    <td className="text-sm font-medium py-3 pl-[10px]">
                                        {paymentLink?.paymentFor}
                                    </td>
                                </tr> */}
                                <tr>
                                    <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                                        Status
                                    </td>
                                    <td className="py-3 pl-[10px]">
                                        <div className="flex gap-[15px] items-center">
                                            <span
                                                className={
                                                    "text-[11px] rounded px-[4.5px] py-[2.7px] " +
                                                    (invoice?.status === "paid"
                                                        ? "bg-[#daf4eb] text-[#34c38f]"
                                                        : invoice?.status ===
                                                          "issued"
                                                        ? "bg-blue-100 text-blue-500"
                                                        : "text-gray-500 bg-gray-100")
                                                }
                                            >
                                                {invoice?.status}
                                            </span>
                                            {invoice?.status === "issued" && (
                                                <>
                                                    <button
                                                        className="block h-auto p-0 bg-transparent font-medium text-[13px] text-blue-500 cursor-pointer"
                                                        onClick={() =>
                                                            setIsConfirmModalOpen(
                                                                true
                                                            )
                                                        }
                                                        disabled={
                                                            isCancelLoading
                                                        }
                                                    >
                                                        {isCancelLoading
                                                            ? "Cancelling..."
                                                            : "Cancel"}
                                                    </button>
                                                    {isConfirmModalOpen && (
                                                        <ConfirmModal
                                                            handleClick={
                                                                handleCancel
                                                            }
                                                            confirmText="Are you sure to cancel this payment link ?. This action can't be reverted."
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                                        Amount
                                    </td>
                                    <td className="text-sm font-medium py-3 pl-[10px]">
                                        {invoice?.amount} USDT
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                                        Accepted Currenices
                                    </td>
                                    <td className="text-sm font-medium py-3 pl-[10px]">
                                        <div className="flex items-center gap-[10px]">
                                            {invoice?.acceptedCurrencies?.map(
                                                (currency, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="py-[5px] rounded px-[5px] bg-[#f6f6f6] dark:bg-darkBgColor flex items-center gap-[6px]"
                                                        >
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_SERVER_URL +
                                                                    currency?.logo
                                                                }
                                                                alt=""
                                                                className="w-[20px] h-[20px] rounded-full object-cover"
                                                            />
                                                            <span>
                                                                {
                                                                    currency?.symbol
                                                                }
                                                            </span>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                                        Payment Link Url
                                    </td>
                                    <td className="text-sm font-medium py-3 pl-[10px]">
                                        <a
                                            href={`${process.env.REACT_APP_CLIENT_URL}/inv/${invoice?.id}`}
                                            target="blank"
                                            className="text-primaryColor"
                                        >
                                            {`https://.../inv/...${invoice?.id?.slice(
                                                -8
                                            )}`}
                                        </a>
                                        <button
                                            className="bg-transparent text-textColor ml-1"
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    `${process.env.REACT_APP_CLIENT_URL}/inv/${invoice?.id}`
                                                );
                                                setIsCopied(true);
                                            }}
                                        >
                                            {isCopied ? (
                                                <FaCheck />
                                            ) : (
                                                <FiCopy />
                                            )}
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="flex text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                                        Created By
                                    </td>
                                    <td className="text-sm font-medium py-3 pl-[10px]">
                                        <div className="flex items-center gap-[10px]">
                                            <img
                                                src={
                                                    user?.avatar
                                                        ? process.env
                                                              .REACT_APP_SERVER_URL +
                                                          user?.avatar
                                                        : avatarImg
                                                }
                                                alt=""
                                                className="w-[20px] h-[20px] rounded-full object-cover"
                                            />
                                            <span>{user?.name}</span>
                                        </div>
                                        <div className="mt-5">
                                            <a
                                                href={
                                                    process.env
                                                        .REACT_APP_SERVER_URL +
                                                    invoice?.pdf
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                download
                                            >
                                                <div className="relative w-[80px] h-[100px]">
                                                    <img
                                                        src={invoiceImg}
                                                        alt=""
                                                        className="w-[100%] h-[100%] object-fit"
                                                    />

                                                    <div className="absolute inset-0 group bg-[#fff7] dark:bg-[#000a] flex items-center justify-center cursor-pointer transition-all hover:bg-[#fffa]">
                                                        <span className="text-2xl transition-all group-hover:text-3xl">
                                                            <HiDownload />
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                                        Issued Date
                                    </td>
                                    <td className="text-sm font-medium py-3 pl-[10px]">
                                        {formatDate(invoice?.issueDate)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                                        Expiry
                                    </td>
                                    <td className="text-sm font-medium py-3 pl-[10px]">
                                        {invoice?.expiryDate
                                            ? formatDate(invoice?.expiryDate)
                                            : "No Expiry"}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 flex pr-[10px]">
                                        Paid By
                                    </td>
                                    <td className="text-sm font-medium py-3 pl-[10px]">
                                        {invoice?.payment ? (
                                            <div>
                                                <div className="flex items-center gap-[10px]">
                                                    <div className="w-[20px] h-[20px] rounded-full overflow-hidden">
                                                        <img
                                                            src={
                                                                invoice?.payment
                                                                    ?.sender
                                                                    ?.avatar
                                                                    ? process
                                                                          .env
                                                                          .REACT_APP_SERVER_URL +
                                                                      invoice
                                                                          ?.payment
                                                                          ?.sender
                                                                          ?.avatar
                                                                    : avatarImg
                                                            }
                                                            alt=""
                                                            className="w-[100%] h-[100%] object-cover"
                                                        />
                                                    </div>
                                                    <span>
                                                        {
                                                            invoice?.payment
                                                                ?.sender?.name
                                                        }
                                                    </span>
                                                </div>
                                                <div className="flex items-center gap-[10px] mt-4">
                                                    <span>
                                                        Paid -{" "}
                                                        {
                                                            invoice?.payment
                                                                ?.amount
                                                        }{" "}
                                                        {
                                                            invoice?.payment
                                                                ?.currency
                                                                ?.symbol
                                                        }
                                                    </span>
                                                </div>
                                                <span className="text-grayColor block mt-3">
                                                    {getFormatedDate(
                                                        invoice?.payment
                                                            ?.updatedAt,
                                                        true
                                                    )}
                                                </span>
                                            </div>
                                        ) : (
                                            "-- --"
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}
