import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

export default function PyamentPageDetailsCurrenciesChart({ revenues }) {
    const [options, setOptions] = useState({
        series: [],
        data: {
            chart: {
                type: "donut",
                width: "100%",
            },
            labels: [],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                width: 2,
            },
            legend: {
                position: "right",
                show: true,
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        chart: {
                            width: "100%",
                        },
                        legend: {
                            position: "bottom",
                            show: true,
                        },
                    },
                },
            ],
        },
    });

    useEffect(() => {
        setOptions((prev) => {
            return {
                ...prev,
                series: revenues.map((revenue) => revenue.totalAmount),
                data: {
                    ...prev.data,
                    labels: revenues.map(
                        (revenue) => revenue?.currency?.symbol
                    ),
                },
            };
        });
    }, [revenues]);

    return (
        <div className="mt-5 max-w-[430px]">
            <h3 className="text-[17px] font-[600] mb-2">Currencies</h3>

            <Chart
                options={options.data}
                series={options.series}
                type="donut"
                width="100%"
            />
        </div>
    );
}
