import React, { useState, useEffect } from "react";
import { Country, State, City } from "country-state-city";

export default function MerchantContactForm({
    data,
    setData,
    states,
    setStates,
}) {
    const countries = Country.getAllCountries();

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (data.country) {
            setStates(State.getStatesOfCountry(data.country));
            setData((prev) => {
                return { ...prev, state: "", city: "" };
            });
        }
    }, [data.country]);

    return (
        <div>
            <h2 className="font-[600]">Contact Address</h2>
            <div className="mt-4">
                <label htmlFor="">Country</label>
                <select
                    name="country"
                    id=""
                    onChange={handleChange}
                    className="w-[100%]"
                    value={data.country || ""}
                >
                    <option value="" hidden>
                        Select Country
                    </option>
                    {countries.map((country, index) => {
                        return (
                            <option value={country.isoCode} key={index}>
                                {country.name}
                            </option>
                        );
                    })}
                </select>
            </div>
            {states.length > 0 && (
                <div className="mt-4">
                    <label htmlFor="">State</label>
                    <select
                        name="state"
                        id=""
                        onChange={handleChange}
                        className="w-[100%]"
                        value={data.state || ""}
                    >
                        <option value="" hidden>
                            Select State
                        </option>
                        {states.map((state, index) => {
                            return (
                                <option value={state.name} key={index}>
                                    {state.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
            )}
            <div className="mt-4">
                <label htmlFor="">Town / City</label>
                <input
                    type="text"
                    name="city"
                    onChange={handleChange}
                    placeholder="Enter City"
                />
            </div>
            <div className="mt-4">
                <label htmlFor="">PIN code</label>
                <input
                    type="number"
                    placeholder="Enter PIN code"
                    name="pincode"
                    onChange={handleChange}
                    className="arrow-hidden"
                />
            </div>
            <div className="mt-4">
                <label htmlFor="">Address 1</label>
                <input
                    type="text"
                    placeholder="Enter Address"
                    name="address1"
                    onChange={handleChange}
                />
            </div>
            <div className="mt-4">
                <label htmlFor="">Address 2 (optional)</label>
                <input
                    type="text"
                    placeholder="Enter Address"
                    name="address2"
                    onChange={handleChange}
                />
            </div>
        </div>
    );
}
