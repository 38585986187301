import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import { MdClose } from "react-icons/md";

import { avatarImg } from "../../../assets/images";
import { getFormatedDate } from "../../../utils";

export default function TxnModal({ setIsTxnModalOpen, transaction }) {
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsCopied(false);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [isCopied]);

    return (
        <div
            className="fixed inset-0 sm:inset-0 bg-[#0007] dark:bg-[#0005] z-20 flex items-center justify-end"
            id="txn-modal"
            onClick={(e) => {
                if (e.target === document.getElementById("txn-modal")) {
                    setIsTxnModalOpen(false);
                }
            }}
        >
            <div className="flex flex-col w-[100%] h-[100vh] sm:max-w-[500px] overflow-y-auto bg-[#fff] dark:bg-[#212529]">
                <div className="flex items-center justify-between p-4 border-b dark:border-darkBorderColor">
                    <h1 className="font-medium">Transaction Details</h1>
                    <button
                        className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
                        onClick={() => {
                            setIsTxnModalOpen(false);
                        }}
                    >
                        <MdClose />
                    </button>
                </div>

                <div className="p-4 h-[100%] overflow-y-auto">
                    <table className="w-[100%]">
                        <tbody>
                            <tr>
                                <td className="text-sm text-grayColor dark:text-darkGrayColor py-3">
                                    Reference No
                                </td>
                                <td className="text-sm font-medium py-3">
                                    #{transaction?.transactionId}
                                </td>
                            </tr>
                            {transaction?.transactionType !== "deposit" && (
                                <tr className="">
                                    <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 flex">
                                        From
                                    </td>
                                    {transaction?.transactionType ===
                                        "transfer" ||
                                    transaction?.transactionType ===
                                        "payment" ||
                                    transaction?.transactionType ===
                                        "withdraw" ? (
                                        <td className="py-3">
                                            <div className="flex items-center gap-[12px]">
                                                <div className="w-[30px] h-[30px] rounded-full overflow-hidden">
                                                    <img
                                                        src={
                                                            transaction?.sender
                                                                ?.avatar
                                                                ? process.env
                                                                      .REACT_APP_SERVER_URL +
                                                                  transaction
                                                                      ?.sender
                                                                      ?.avatar
                                                                : avatarImg
                                                        }
                                                        alt=""
                                                        className="w-[100%] h-[100%] object-cover"
                                                    />
                                                </div>
                                                <div>
                                                    <span className="block font-medium text-sm">
                                                        {
                                                            transaction?.sender
                                                                ?.name
                                                        }
                                                    </span>
                                                    <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                                                        @
                                                        {
                                                            transaction?.sender
                                                                ?.username
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    ) : (
                                        <></>
                                    )}
                                </tr>
                            )}
                            <tr>
                                <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 flex">
                                    To
                                </td>
                                {transaction?.transactionType === "transfer" ||
                                transaction?.transactionType === "deposit" ||
                                transaction?.transactionType === "payment" ? (
                                    <td className="py-3">
                                        <div className="flex items-center gap-[12px]">
                                            <div className="w-[30px] h-[30px] rounded-full overflow-hidden">
                                                <img
                                                    src={
                                                        transaction?.receiver
                                                            ?.avatar
                                                            ? process.env
                                                                  .REACT_APP_SERVER_URL +
                                                              transaction
                                                                  ?.receiver
                                                                  ?.avatar
                                                            : avatarImg
                                                    }
                                                    alt=""
                                                    className="w-[100%] h-[100%] object-cover"
                                                />
                                            </div>
                                            <div>
                                                <span className="block font-medium text-sm">
                                                    {
                                                        transaction?.receiver
                                                            ?.name
                                                    }
                                                </span>
                                                <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                                                    @
                                                    {
                                                        transaction?.receiver
                                                            ?.username
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                ) : (
                                    <td>
                                        <div className="flex flex-wrap items-center gap-[10px]">
                                            <span>
                                                {transaction?.receiverAddress?.slice(
                                                    0,
                                                    6
                                                )}
                                                ...
                                                {transaction?.receiverAddress?.slice(
                                                    -6
                                                )}
                                            </span>
                                            <span
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        transaction?.receiverAddress
                                                    );
                                                    setIsCopied(true);
                                                }}
                                                className="cursor-pointer"
                                            >
                                                {isCopied ? (
                                                    <FaCheck />
                                                ) : (
                                                    <FiCopy />
                                                )}
                                            </span>
                                        </div>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                <td className="text-sm text-grayColor dark:text-darkGrayColor py-3">
                                    Transaction Type
                                </td>
                                <td className="text-sm capitalize py-3">
                                    {transaction?.transactionType}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-sm text-grayColor dark:text-darkGrayColor py-3">
                                    Amount
                                </td>
                                <td className="text-sm font-[600] text-primaryColor py-3">
                                    {transaction?.amount}{" "}
                                    {transaction?.currency?.symbol}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-sm text-grayColor dark:text-darkGrayColor">
                                    Fee
                                </td>
                                <td className="text-sm py-3 ">
                                    {transaction?.fee}{" "}
                                    {transaction?.currency?.symbol}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-sm text-grayColor dark:text-darkGrayColor py-3">
                                    Currency
                                </td>
                                <td className="py-3">
                                    <div className="flex items-center gap-[12px]">
                                        <div className="w-[25px] h-[25px] rounded-full overflow-hidden">
                                            <img
                                                src={
                                                    process.env
                                                        .REACT_APP_SERVER_URL +
                                                    transaction?.currency?.logo
                                                }
                                                alt=""
                                                className="w-[100%] h-[100%] object-cover"
                                            />
                                        </div>
                                        <div>
                                            <span className="block text-sm">
                                                {transaction?.currency?.name}
                                            </span>
                                            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                                                {transaction?.currency?.symbol}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-sm text-grayColor dark:text-darkGrayColor py-3">
                                    Status
                                </td>
                                <td className="py2">
                                    <span
                                        className={
                                            "text-[11px] rounded px-[4.5px] py-[2.7px] capitalize " +
                                            (transaction?.status === "completed"
                                                ? "bg-[#daf4eb] text-[#34c38f]"
                                                : transaction?.status ===
                                                  "failed"
                                                ? "text-[#f46a6a] bg-[#fde4e4]"
                                                : "text-gray-500 bg-gray-100")
                                        }
                                    >
                                        {transaction?.status}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-sm text-grayColor dark:text-darkGrayColor py-3">
                                    Date & Time
                                </td>
                                <td className="py-3 text-sm">
                                    {getFormatedDate(
                                        transaction?.createdAt,
                                        true
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
