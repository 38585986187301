import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";

import {
  BtnLoader,
  ConfirmPinModal,
  CurrencySelectDropdown,
} from "../../components";
import axios from "../../axios";
import { updateBalance } from "../../redux/slices/currenciesSlice";
import { pendingAnim } from "../../assets/gif";
import { RecentWithdrawals } from "../../features/Withdraw";
import { formatBalance } from "../../utils";

export default function WithdrawPage() {
  const { currencies, isFetching } = useSelector((state) => state.currencies);
  const { jwtToken, currentAccount, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [selectedCurrency, setSelectedCurrency] = useState();
  const [selectedAsset, setSelectedAsset] = useState({});
  const [data, setData] = useState({
    amount: "",
    address: "",
    note: "",
    isCheckboxTrue: false,
  });
  const [section, setSection] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [resp, setResp] = useState({});
  const [isConfirmPinModalOpen, setIsConfirmPinModalOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const clearAllData = () => {
    setData({ address: "", amount: "", note: "", isCheckboxTrue: false });
    setIsLoading(false);
    setSection(1);
  };

  const handleSubmit = async (pinNumber) => {
    try {
      setError("");
      setIsLoading(true);

      const response = await axios.post(
        "/withdrawals/withdraw",
        {
          assetId: selectedAsset?._id,
          address: data.address,
          amount: data.amount,
          note: data.note,
          accountId: currentAccount?._id,
          pinNumber,
          currencyId: selectedCurrency?._id,
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      dispatch(
        updateBalance({
          ...response?.data,
          isSender: user._id === response.data?.sender?._id,
        })
      );

      setResp(response.data);
      setSection(2);
    } catch (err) {
      setIsLoading(false);
      setError(err?.response?.data?.error || "Something went wrong, Try again");
    }
  };

  useEffect(() => {
    setSelectedCurrency(currencies[0]);
  }, [currencies]);

  useEffect(() => {
    if (selectedCurrency?._id) {
      setSelectedAsset(selectedCurrency?.assets[0]);
    }
  }, [selectedCurrency]);

  useEffect(() => {
    if (searchParams.get("address")) {
      setData((prev) => {
        return { ...prev, address: searchParams.get("address") };
      });
    }
  }, [searchParams]);

  console.log(selectedCurrency, "new");

  return (
    <div className="py-6">
      <div className="flex items-center gap-[10px] text-[17px] font-[600] mb-6 sm:mb-8">
        <h1 className="">Withdraw Asset</h1>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-[70px]">
        <div className="lg:col-span-4">
          <div className={section === 1 ? "block" : "hidden"}>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                setIsConfirmPinModalOpen(true);
              }}
            >
              <div className="sm:flex gap-[20px]">
                <label htmlFor="" className="min-w-[200px]">
                  Select Currency
                </label>
                <div className="relative w-full">
                  <CurrencySelectDropdown
                    setSelectedCurrency={setSelectedCurrency}
                    selectedCurrency={selectedCurrency}
                    labelVisible={false}
                  />
                </div>
              </div>
              <div className="mt-6 sm:flex gap-[20px]">
                <label htmlFor="" className="min-w-[200px]">
                  Network
                </label>
                <div>
                  {isFetching ? (
                    <div className="flex items-center gap-[10px]">
                      {Array.from({ length: 3 }).map((_, index) => {
                        return (
                          <span
                            key={index}
                            className="bg-slate-300 dark:bg-slate-600 h-[29px] w-[50px] rounded px-3 py-1 text-[14px] block cursor-pointer"
                          ></span>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="flex items-center gap-[10px]">
                      {selectedCurrency?.assets?.map((asset, index) => {
                        return (
                          <span
                            key={index}
                            className={
                              "rounded px-3 py-1 text-[14px] block cursor-pointer " +
                              (selectedAsset?._id === asset?._id
                                ? "bg-slate-200 dark:bg-darkCardHoverColor"
                                : "border border-slate-200 dark:border-darkBorderColor")
                            }
                            onClick={() => setSelectedAsset(asset)}
                          >
                            {asset?.network?.tokenStandard}
                          </span>
                        );
                      })}
                    </div>
                  )}
                  <span className="text-[13px] text-grayColor dark:text-darkGrayColor leading-[23px] mt-3 block">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed
                    repellat placeat itaque ullam sapiente tempora
                  </span>
                </div>
              </div>
              <div className="sm:flex gap-[20px] mt-7">
                <label htmlFor="" className="min-w-[200px]">
                  Address
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="0x83C6E80273533bE21B92fE77fA5A2D16D7156F6d"
                    className="h-[40px]"
                    name="address"
                    value={data.address}
                    onChange={handleChange}
                  />
                  <span className="text-[13px] font-normal text-grayColor dark:text-darkGrayColor block mt-[5px]">
                    Enter the address that you want to share your asset
                  </span>
                </div>
              </div>
              <div className="sm:flex gap-[20px] mt-7">
                <label htmlFor="" className="min-w-[200px]">
                  Amount
                </label>
                <div className="w-full">
                  <input
                    type="number"
                    placeholder="0.007"
                    className="h-[40px] font-[600] arrow-hidden"
                    value={data.amount || ""}
                    name="amount"
                    onChange={handleChange}
                  />
                  <div className="flex items-center justify-between mt-[5px]">
                    <span className="text-[13px] font-medium text-grayColor dark:text-darkGrayColor block">
                      Balance: {formatBalance(selectedCurrency?.balance)}{" "}
                      {selectedCurrency?.currencySymbol} &asymp;{" "}
                      {selectedCurrency?.balance &&
                      selectedCurrency?.price?.price_usd
                        ? formatBalance(
                            Number(selectedCurrency?.balance) *
                              Number(selectedCurrency?.price?.price_usd)
                          )
                        : "0.0"}{" "}
                      USD
                    </span>
                    <button
                      className="text-[13px] p-0 h-auto bg-transparent font-medium text-blue-500 block cursor-pointer"
                      onClick={() => {
                        setData({
                          ...data,
                          amount: selectedCurrency?.balance,
                        });
                      }}
                      disabled={isFetching}
                      type="button"
                    >
                      Max
                    </button>
                  </div>
                </div>
              </div>
              <div className="sm:flex gap-[20px] mt-7">
                <label htmlFor="" className="min-w-[200px]">
                  Note (optional)
                </label>
                <div className="w-full flex items-center gap-[10px]">
                  <textarea
                    name="note"
                    placeholder="Enter a description "
                    className="h-[100px]"
                    onChange={handleChange}
                    value={data.note || ""}
                  ></textarea>
                </div>
              </div>
              <div className="sm:flex gap-[20px] mt-6">
                <label htmlFor="" className="min-w-[200px]"></label>
                <div className="flex gap-[10px]">
                  <input
                    type="checkbox"
                    name=""
                    id="checkbox"
                    className="w-[14px] h-[14px] min-w-[14px] min-h-[14px]"
                    onChange={(e) =>
                      setData({
                        ...data,
                        isCheckboxTrue: e.target.checked,
                      })
                    }
                    checked={data.isCheckboxTrue || ""}
                  />
                  <label
                    htmlFor="checkbox"
                    className="mt-0 font-normal text-[13px] text-grayColor dark:text-darkGrayColor leading-[22px]"
                  >
                    By withdrawing you acknowledge that SimplePay is not
                    responsible for any coins sent to the wrong address.
                  </label>
                </div>
              </div>
              {error && (
                <div className="flex gap-[20px] mt-[6px]">
                  <span className="min-w-[200px]"></span>
                  <span className="text-[13px] font-medium text-red-500 leading-[24px]">
                    {error}
                  </span>
                </div>
              )}
              <div className="sm:flex gap-[20px] mt-7">
                <label htmlFor="" className="min-w-[200px]">
                  Amount
                </label>
                <div
                  className={
                    "w-full flex justify-between flex-wrap gap-[10px] " +
                    (Number(data.amount) > Number(selectedCurrency?.balance) ||
                    (data.amount &&
                      (data.amount <= 0 || !selectedCurrency?.balance))
                      ? "text-red-500"
                      : "text-textColor dark:text-darkTextColor")
                  }
                >
                  <div>
                    <h3 className="font-bold text-2xl break-all">
                      {data.amount || "0.0"}{" "}
                      {selectedCurrency?.symbol ? selectedCurrency?.symbol : ""}
                    </h3>
                    <span className="text-[13px] text-grayColor dark:text-darkGrayColor font-medium">
                      Gas fee + Transaction fee = 0.00005
                    </span>
                  </div>
                  <button
                    className="h-[40px] w-[140px] text-[14px] hover:bg-btnHoverColor disabled:opacity-80 disabled:hover:bg-primaryColor"
                    disabled={
                      isLoading ||
                      !data.address ||
                      !selectedCurrency ||
                      !data.amount ||
                      !selectedCurrency?.balance ||
                      Number(data.amount) > Number(selectedCurrency?.balance) ||
                      data.amount <= 0 ||
                      !data.isCheckboxTrue
                    }
                  >
                    {isLoading ? <BtnLoader /> : "Withdraw"}
                  </button>

                  {isConfirmPinModalOpen && (
                    <ConfirmPinModal
                      handleSubmit={handleSubmit}
                      setIsConfirmPinModalOpen={setIsConfirmPinModalOpen}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className={section === 2 ? "block" : "hidden"}>
            <div className="w-[120px] h-[120px] mx-auto">
              <img src={pendingAnim} alt="" className="w-[100%] h-[100%]" />
            </div>
            <h3 className="font-[600] text-xl text-center mt-8">
              Transaction Initiated !
            </h3>
            <span className="text-sm text-grayColor dark:text-darkGrayColor leading-[28px] block mt-2 text-center max-w-[500px] mx-auto">
              Your withdrawal request of {resp?.amount} {resp?.currency?.symbol}{" "}
              to {resp?.receiverAddress} is intitated. Transaction Reference Id
              is #{resp?.transactionId}
            </span>
            <div className="text-center text-sm text-grayColor dark:text-darkGrayColor block mt-2">
              <span>
                Withdrawal should take some time according to netowork.
              </span>
            </div>
            <Link to="/transactions">
              <button className="w-[100%] mt-6 hover:bg-btnHoverColor max-w-[350px] block mx-auto">
                View Details
              </button>
            </Link>
            <button
              className="bg-transparent text-textColor w-full mt-2"
              onClick={clearAllData}
            >
              Go Back
            </button>
          </div>
        </div>

        <div className="lg:col-span-2">
          <RecentWithdrawals />
        </div>
      </div>
    </div>
  );
}
