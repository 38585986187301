import React from "react";
import { BiHome, BiTimeFive, BiTransferAlt, BiUser } from "react-icons/bi";
import { IoWalletOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function BottomBar() {
    return (
        <div className="sm:hidden fixed bottom-0 left-0 w-[100%] h-[60px] bg-primaryColor rounded-t-3xl shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
            <div className="relative flex items-center justify-around h-full">
                <div>
                    <Link to="/">
                        <button className="text-[23px] h-auto p-0 bg-transparent text-[#fff]">
                            <BiHome />
                        </button>
                    </Link>
                </div>
                <div className="">
                    <Link to="/currencies">
                        <button className="text-[23px] h-auto p-0 bg-transparent text-[#fff]">
                            <IoWalletOutline />
                        </button>
                    </Link>
                </div>
                <div></div>
                <div>
                    <Link to="/transactions">
                        <button className="text-[23px] h-auto p-0 bg-transparent text-[#fff]">
                            <BiTimeFive />
                        </button>
                    </Link>
                </div>
                <div>
                    <Link to="/settings">
                        <button className="text-[23px] h-auto p-0 bg-transparent text-[#fff]">
                            <BiUser />
                        </button>
                    </Link>
                </div>

                <div className="absolute top-[-16px] left-[50%] translate-x-[-50%]">
                    <button
                        id="bottom-bar-center-btn"
                        className="text-[24px] bg-[#fff] text-primaryColor w-[40px] h-[40px] rounded-full block p-0 flex items-center justify-center "
                    >
                        <BiTransferAlt />
                    </button>
                </div>
            </div>
        </div>
    );
}
