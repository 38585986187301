import React from "react";

function RecentTransactionLIst({ recentTransactions }) {
  return (
    <div>
      <div className="overflow-x-auto">
        <div className="w-full h-10 bg-[#f6f6f6] dark:bg-darkCardColor mb-1 ">
          <div className="p-2 flex justify-center items-center">
            <h1>Recent Transactions</h1>
          </div>
        </div>
        <table className="w-[100%]">
          <thead className="text-left bg-[#f6f6f6] dark:bg-darkCardColor">
            <tr className="text-[13px]">
              <th className="p-[12px] font-[600]">No</th>
              <th className="p-[12px] font-[600]">Reciever Name</th>
              <th className="p-[12px] font-[600]">Amount</th>
              <th className="p-[12px] font-[600]">Transaction ID</th>
              <th className="p-[12px] font-[600]">Transaction Type</th>
              <th className="p-[12px] font-[600]">Status</th>
            </tr>
          </thead>
          <tbody>
            {recentTransactions?.map((item, index) => {
              return (
                <tr
                  className="border-b  dark:border-darkBorderColor text-[13px] hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                  key={index}
                >
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {index + 1}
                  </td>

                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.receiver?.name}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.amount}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.transactionId}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.transactionType}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.status}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RecentTransactionLIst;
