import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";

const useTotalPrice = (data, val) => {
    const { prices } = useSelector((state) => state.currencies);

    if (data?.length < 1 || prices?.length < 1) {
        return 0;
    }

    let total = "0";
    for (let i = 0; i < data?.length; i++) {
        for (let j = 0; j < prices?.length; j++) {
            if (data[i]?.currency?.symbol === prices[j]?.symbol) {
                if (!Number(data[i][val]) || !Number(prices[j]?.price_usd)) {
                    continue;
                }
                total = BigNumber(data[i][val])
                    .multipliedBy(prices[j]?.price_usd)
                    .plus(total)
                    .toString();
            }
        }
    }

    return total;
};

export default useTotalPrice;
