import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AccountSelect } from "../../features/Accounts";
import { setIsCreateAccountModalOpen } from "../../redux/slices/layoutSlice";

export default function AccountsPage() {
    const [selectedAccount, setSelectedAccount] = useState({ _id: "" });

    const { user } = useSelector((state) => state.user);
    const { accounts } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedAccount((prev) => {
            return { ...prev, _id: user.primaryAccountId };
        });
    }, [user.primaryAccountId]);

    return (
        <div className="">
            <div className="sm:flex items-start gap-[20px] justify-between mb-7">
                <div>
                    <h3 className="mb-1 text-lg font-[600]">Accounts</h3>
                    <p className="text-sm text-grayColor dark:text-darkGrayColor leading-[28px]">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Maiores reiciendis perferendis eos magni, repellat iste
                    </p>
                </div>
                <div className="relative">
                    <button
                        className="hover:bg-btnHoverColor whitespace-nowrap mt-3 sm:mt-0"
                        onClick={() => {
                            dispatch(setIsCreateAccountModalOpen(true));
                        }}
                    >
                        Add Account
                    </button>

                    {/* {isPlusModalOpen && (
                        <div className="absolute right-0 top-[100%] bg-[#fff] rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] min-w-[150px]">
                            <Link
                                to="import"
                                className="block text-sm px-[15px] py-[10px] cursor-pointer transition-all hover:bg-[#f6f6f6]"
                            >
                                Import Account
                            </Link>
                            <span
                                className="block text-sm px-[15px] py-[10px] cursor-pointer transition-all hover:bg-[#f6f6f6]"
                                onClick={() => {
                                    dispatch(setIsCreateAccountModalOpen(true));
                                    setIsPlusModalOpen(false);
                                }}
                            >
                                Add Account
                            </span>
                        </div>
                    )} */}
                </div>
            </div>
            <div className="">
                {accounts?.map((account, index) => {
                    return (
                        <AccountSelect
                            key={index}
                            account={account}
                            index={index}
                            selectedAccount={selectedAccount}
                            setSelectedAccount={setSelectedAccount}
                        />
                    );
                })}
            </div>
        </div>
    );
}
