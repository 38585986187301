import React, { useEffect, useState } from "react";
import { BiLockAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../axios";
import { BtnLoader } from "../../components";
import { setAdmin } from "../../redux/slices/admin/adminSlice";

function AdminLogin() {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state.admin);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setError("");
      setIsLoading(true);

      const response = await axios.post("admin/auth/login", data);
      setIsLoading(false);
      dispatch(setAdmin(response.data));
      navigate("/admin/dashboard");
    } catch (err) {
      setIsLoading(false);
      setError(err?.response?.data?.error || "Something went wrong, Try again");
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/admin/dashboard", { replace: true });
    }
  }, [navigate]);

  return (
    <div>
      <div className="flex  justify-center mx-auto py-40 min-h-[calc(var(--doc-height)-4.2em)] sm:min-h-[calc(100vh-5em)] max-w-[430px] px-5">
        <div className="w-full">
          <h1 className="font-bold text-lg sm:text-xl mb-10 text-center">
            Login
          </h1>

          <form className="w-full" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="username">Email Or Username *</label>
              <input
                type="text"
                id="username"
                placeholder="Enter Email Id or Username"
                name="email"
                onChange={handleChange}
              />
            </div>
            <div className="mt-6">
              <label htmlFor="password">Password *</label>
              <input
                type="password"
                id="password"
                placeholder="Enter password"
                name="password"
                onChange={handleChange}
              />
            </div>

            <div className="mt-6">
              {error && (
                <span className="text-[13px] font-medium mb-4 block text-red-600">
                  {error}
                </span>
              )}
              <button
                className="w-[100%] hover:bg-btnHoverColor py-0 disabled:opacity-80 disabled:hover:bg-primaryColor"
                disabled={isLoading || !data.email || !data.password}
              >
                {isLoading ? <BtnLoader /> : "Log In"}
              </button>
            </div>
          </form>

          <div className="px-5 mt-6">
            <Link
              to="/auth/forget-password"
              className="text-[13px] text-grayColor dark:text-darkGrayColor flex flex-wrap items-center justify-center gap-[5px] cursor-pointer "
            >
              <BiLockAlt />
              Forgot your password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
