import React from "react";
import { useRef } from "react";
import { IoMdClose } from "react-icons/io";

import { useDispatch, useSelector } from "react-redux";
import { useHandleClickOutside } from "../../../hooks";
import { setSelectedCustomer } from "../../../redux/slices/invocesSlice";

export default function CustomersListDropdown({
    setIsCustomersListModalOpen,
    setIsAddCustomerModalOpen,
}) {
    const wrapperRef = useRef();

    const { customers } = useSelector((state) => state.invoices);
    useHandleClickOutside(wrapperRef, () => setIsCustomersListModalOpen(false));

    const dispatch = useDispatch();

    return (
        <div className="fixed inset-0 z-10 sm:bg-[#fff5] dark:sm:bg-[#0005] flex items-end sm:items-center justify-center">
            <div
                ref={wrapperRef}
                className="z-10 bg-[#fff] dark:bg-darkCardColor h-[50vh] sm:h-auto sm:max-h-[60vh] overflow-y-auto w-[100%] sm:max-w-[400px] rounded-t sm:rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]"
            >
                <div className="sticky top-0 bg-white dark:bg-darkCardColor">
                    <div className="flex items-center justify-between gap-[10px] p-5 border-b dark:border-darkBorderColor">
                        <h1 className="font-[600]">Customers List</h1>
                        <button
                            className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
                            onClick={() => setIsCustomersListModalOpen(false)}
                            type="button"
                        >
                            <IoMdClose />
                        </button>
                    </div>
                    <div className="px-5 mt-3">
                        <input
                            type="text"
                            placeholder="Search here..."
                            className=""
                        />
                    </div>
                    <div className="text-center py-2 ">
                        <span
                            className="text-sm text-primaryColor cursor-pointer block"
                            onClick={() => {
                                setIsAddCustomerModalOpen({
                                    bool: true,
                                    isEdit: false,
                                });
                                setIsCustomersListModalOpen(false);
                            }}
                        >
                            + Add New Customer
                        </span>
                    </div>
                </div>

                {customers?.length < 1 ? (
                    <div className="text-center px-5 pb-4">
                        <span className="text-center text-[13px] text-grayColor dark:text-darkGrayColor">
                            You are not added any customers yet
                        </span>
                    </div>
                ) : (
                    customers?.map((customer, index) => {
                        return (
                            <div
                                key={index}
                                className="flex items-center justify-between gap-[10px] px-5 cursor-pointer hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                                onClick={() => {
                                    dispatch(setSelectedCustomer(customer));
                                    setIsCustomersListModalOpen(false);
                                }}
                            >
                                <div className="cursor-pointer text-sm py-2 ">
                                    <span className="block">
                                        {customer?.name}
                                    </span>
                                    <span className="block text-grayColor dark:text-darkGrayColor">
                                        {customer.email}
                                    </span>
                                </div>
                                <span>{/* <span>Del</span> */}</span>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
}
