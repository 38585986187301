import React, { useState } from "react";
import axios from "../../../axios";
import { useSelector } from "react-redux";
import { BtnLoader } from "../../../components";
import { VscClose } from "react-icons/vsc";

function EditNetWorkModal({ setNetworkEditModal, fetchNetworks, networks }) {
  const [error, setError] = useState("");
  const [data, setData] = useState({
    name: networks.name,
    tokenStandard: networks.tokenStandard,
    networkVM: networks.networkVM,
    rpcUrl: networks.rpcUrl,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { jwtToken } = useSelector((state) => state.admin);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const res = await axios.put(
        `/admin/networks/update/${networks._id}`,
        data,
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      fetchNetworks();
      setNetworkEditModal(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.response.data.error || "somthing went wrong");
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <div className="fixed inset-0 bg-[#fff5] dark:bg-[#0005] flex items-center justify-center z-20 sm:px-5">
      <div className="bg-[#fff] dark:bg-darkCardColor w-[100%] sm:max-w-[450px] h-[100vh] sm:h-[85vh] overflow-y-auto rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
        <div className="flex items-center justify-between border-b border-borderColor dark:border-[#4c4e53] px-4 py-3">
          <h3 className="font-medium">Edit Network</h3>
          <button
            className="bg-transparent text-textColor dark:text-darkTextColor p-0 text-xl"
            onClick={() => setNetworkEditModal(false)}
          >
            <VscClose />
          </button>
        </div>
        <form action="" className="p-5" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="">Name*</label>
            <input
              type="text"
              placeholder="Enter Network Name"
              name="name"
              value={data.name}
              onChange={handleOnChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Token Standard*</label>
            <input
              type="text"
              placeholder="Enter Token Standard"
              name="tokenStandard"
              value={data.tokenStandard}
              onChange={handleOnChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">RPC URL*</label>
            <input
              type="text"
              placeholder="Enter RPC URL"
              name="rpcUrl"
              value={data.rpcUrl}
              onChange={handleOnChange}
            />
          </div>
          <div className="mt-4 sm:mt-0">
            <label htmlFor="">Network VM</label>
            <select
              name="networkVM"
              id=""
              className="w-[100%]"
              onChange={handleOnChange}
              value={data.networkVM || ""}
            >
              <option value="">Select Network VM</option>
              <option value="ethereum">Ethereum</option>
              <option value="bitcoin">Bitcoin</option>
              <option value="solana">Solana</option>
              <option value="bitcoin-testnet">Bitcoin-Testnet</option>
            </select>
          </div>

          {error && (
            <span className="text-[13px] font-medium text-red-500 block mt-3">
              {error}
            </span>
          )}

          <div className="pt-6">
            <button className="w-[100%] disabled:opacity-80">
              {isLoading ? <BtnLoader /> : "Update"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditNetWorkModal;
