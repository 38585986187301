import React, { useEffect, useState } from "react";
import { FaMobileAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FiChevronDown, FiChevronLeft } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../axios";
import { updateIsMobileNumberVerified } from "../../redux/slices/userSlice";
import { BtnLoader, CountriesModal } from "../../components";
import { setIsCountriesModalOpen } from "../../redux/slices/layoutSlice";
import { country } from "../../utils";

export default function MobileNumberVerifyPage() {
    const [selectedCountry, setSelectedCountry] = useState({});
    const [isNumberVerified, setIsNumberVerfied] = useState(false);
    const [otp, setOtp] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
    });
    const [mobileNumber, setMobileNumber] = useState({ number: "", error: "" });
    const [otpError, setOtpError] = useState("");
    const [timer, setTimer] = useState(45);
    const [timerActive, setTimerActive] = useState(false);
    const [isLoading, setIsLoading] = useState("");

    const { jwtToken, geoDetails, countries } = useSelector((state) => state.user);
    const { isCountriesModalOpen } = useSelector((state) => state.layout);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (elmnt) => {
        if (!otp[elmnt.target.name] || !elmnt.target.value) {
            setOtp((prev) => {
                return { ...prev, [elmnt.target.name]: elmnt.target.value };
            });
        }

        if (elmnt.target.value) {
            const next = elmnt.target.tabIndex;
            if (next < 5) {
                elmnt.target.parentNode.childNodes[next].focus();
            }
        } else {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.parentNode.childNodes[next].focus();
            }
        }
    };

    const handleMobileNumberSubmit = async (e) => {
        try {
            e.preventDefault();
            setMobileNumber((prev) => {
                return { ...prev, error: "" };
            });
            setIsLoading(true);

            await axios.post(
                "/users/mobile-number/add",
                {
                    phonecode: selectedCountry.phonecode,
                    mobileNumber: mobileNumber.number.trim(),
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            setIsNumberVerfied(true);
            setIsLoading(false);
            setTimerActive(true);
            setTimer(45);
        } catch (err) {
            setMobileNumber((prev) => {
                return {
                    ...prev,
                    error:
                        err?.response?.data?.error ||
                        "Something went wrong, Try again",
                };
            });
            setIsLoading(false);
            console.log(err);
        }
    };

    const handleVerifyOtpSubmit = async (e) => {
        try {
            e.preventDefault();
            setOtpError("");
            setIsLoading(true);

            const myOtp = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}${otp.otp5}`;
            await axios.post(
                "/users/mobile-number/verify",
                {
                    otp: myOtp,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            setIsLoading(false);
            dispatch(updateIsMobileNumberVerified(true));
            navigate("/");
        } catch (err) {
            console.log(err);
            setOtpError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    const resendCodeHandler = async () => {
        try {
            setTimerActive(true);
            setTimer(45);

            const response = await axios.post(
                "/otp/generate-otp/mobile",
                {},
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );
            console.log(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        let interval = null;
        if (timerActive) {
            interval = window.setInterval(() => {
                if (timer <= 1) {
                    setTimerActive(false);
                    window.clearInterval(interval);
                }
                setTimer((prev) => {
                    return prev - 1;
                });
            }, 1000);
        }

        return () => window.clearInterval(interval);
    }, [timerActive, timer]);

    useEffect(() => {
        if (geoDetails?.country_code && countries?.length > 0) {
            setSelectedCountry(
                country.getCountryByCode(countries, geoDetails?.country_code)
            );
        }
    }, [geoDetails?.country_code, countries]);

    return (
        <div className="flex items-center justify-center min-h-[calc(var(--doc-height)-4.2em)] sm:min-h-[calc(100vh-5em)] py-6 px-[20px]">
            <div className="w-[100%] max-w-[450px]">
                <div className="relative">
                    {isNumberVerified && (
                        <button
                            className="w-[23px] h-[23px] rounded-full p-0 flex items-center justify-center bg-[#eff3f7] dark:bg-darkCardColor text-primaryColor text-lg hover:bg-primaryColor hover:text-[#fff]"
                            onClick={() => setIsNumberVerfied(false)}
                            disabled={isLoading}
                        >
                            <FiChevronLeft />
                        </button>
                    )}
                    <div className="text-center">
                        <div className="w-[72px] h-[72px] rounded-full flex items-center mx-auto justify-center bg-[#eff3f7] dark:bg-darkCardColor text-primaryColor text-2xl">
                            <FaMobileAlt />
                        </div>
                        <h1 className="font-bold text-lg sm:text-xl text-center mt-4 mb-2">
                            {!isNumberVerified
                                ? "Add Mobile Number"
                                : "Verify Mobile Number"}
                        </h1>
                        <span className="text-[14px] leading-[28px]">
                            {!isNumberVerified
                                ? "Select your country code and enter your mobile number"
                                : `We sent a code to +${
                                      selectedCountry.phonecode
                                  } ${mobileNumber.number.trim()}. Enter below to get started! `}
                        </span>
                        {isNumberVerified && (
                            <span className="block mt-3 text-sm font-medium">
                                Please Wait, It may take a little moment
                            </span>
                        )}
                    </div>

                    <form
                        className={
                            "mt-[40px] " +
                            (isNumberVerified ? "hidden" : "block")
                        }
                        onSubmit={handleMobileNumberSubmit}
                    >
                        <div className="flex items-center gap-[10px] h-[45px]">
                            <div className="relative h-full">
                                <button
                                    className="h-[100%] border border-[#ced4da] dark:border-darkBorderColor text-center dark:text-darkTextColor bg-transparent min-w-[75px] text-textColor flex gap-[5px] items-center justify-center px-0"
                                    onClick={() => {
                                        dispatch(setIsCountriesModalOpen(true));
                                    }}
                                    type="button"
                                >
                                    {selectedCountry?.phonecode || 0}{" "}
                                    <FiChevronDown />
                                </button>

                                {isCountriesModalOpen && (
                                    <CountriesModal
                                        setSelectedCountry={setSelectedCountry}
                                    />
                                )}
                            </div>
                            <input
                                type="number"
                                id="mobile-number"
                                className="arrow-hidden h-full"
                                onChange={(e) => {
                                    setMobileNumber((prev) => {
                                        return {
                                            ...prev,
                                            number: e.target.value,
                                        };
                                    });
                                }}
                                placeholder="☎ Mobile Number"
                            />
                        </div>
                        {mobileNumber?.error && (
                            <span className="text-[12px] mt-1 block text-red-600">
                                {mobileNumber?.error}
                            </span>
                        )}
                        <button
                            className="w-[100%] hover:bg-btnHoverColor mt-4 disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor "
                            disabled={isLoading || !mobileNumber.number}
                        >
                            {isLoading ? <BtnLoader /> : "Get Otp"}
                        </button>
                    </form>

                    <form
                        className={
                            "mt-[40px] " +
                            (isNumberVerified ? "block" : "hidden")
                        }
                        onSubmit={handleVerifyOtpSubmit}
                    >
                        <div className={"grid grid-cols-5 gap-[1em] "}>
                            <input
                                type="number"
                                className="text-center arrow-hidden"
                                tabIndex={1}
                                maxLength={1}
                                name="otp1"
                                value={otp.otp1}
                                onChange={handleChange}
                            />
                            <input
                                type="number"
                                className="text-center arrow-hidden"
                                tabIndex={2}
                                maxLength={1}
                                name="otp2"
                                value={otp.otp2}
                                onChange={handleChange}
                            />
                            <input
                                type="number"
                                className="text-center arrow-hidden"
                                tabIndex={3}
                                maxLength={1}
                                name="otp3"
                                value={otp.otp3}
                                onChange={handleChange}
                            />
                            <input
                                type="number"
                                className="text-center arrow-hidden"
                                tabIndex={4}
                                maxLength={1}
                                name="otp4"
                                value={otp.otp4}
                                onChange={handleChange}
                            />
                            <input
                                type="number"
                                className="text-center arrow-hidden"
                                tabIndex={5}
                                maxLength={1}
                                name="otp5"
                                value={otp.otp5}
                                onChange={handleChange}
                            />
                        </div>
                        {otpError && (
                            <span className="text-[13px] mt-4 block text-red-600">
                                {otpError}
                            </span>
                        )}
                        <button
                            className="w-[100%] hover:bg-btnHoverColor mt-2 disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor"
                            disabled={
                                !otp.otp1 ||
                                !otp.otp2 ||
                                !otp.otp3 ||
                                !otp.otp4 ||
                                !otp.otp5 ||
                                isLoading
                            }
                        >
                            {isLoading ? <BtnLoader /> : "Verify Otp"}
                        </button>
                    </form>
                </div>
                {isNumberVerified && (
                    <div className="text-center mt-5">
                        <span className="text-[13px]">
                            Didn't receive a code ?{" "}
                            {timer > 0 ? (
                                <span className="text-primaryColor font-medium cursor-not-allowed">
                                    Resend in {timer} s
                                </span>
                            ) : (
                                <span
                                    className="text-primaryColor font-medium cursor-pointer"
                                    onClick={resendCodeHandler}
                                >
                                    Resend
                                </span>
                            )}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}
