import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { PaymentAccountBar } from "../../../components";
import { IoWarningOutline } from "react-icons/io5";
import axios from "../../../axios";
import SinglePaymentPage from "./SinglePaymentPage";
import {
  SinglePaymentPageDetailsSkeleton,
  SinglePaymentPageFormSkeleton,
} from "../../SinglePaymentPage";
import SinglePaymentForm from "./SinglePaymentForm";
import { useSelector } from "react-redux";

function PayementPage() {
  const { id } = useParams();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentPage, setPaymentPage] = useState([]);
  const { jwtToken } = useSelector((state) => state.user);

  const fetchPaymentPage = useCallback(async () => {
    try {
      setIsLoading(false);
      console.log(id, "show id");

      const response = await axios.get(`/presale/single/${id}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setPaymentPage(response?.data);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchPaymentPage();
  }, []);

  return (
    <div className="relative">
      {!error && (
        <div className="hidden md:block fixed top-0 left-0 w-[50vw] h-[100%] bg-[#f3f3f3] dark:bg-darkCardColor shadow-sm"></div>
      )}

      <PaymentAccountBar />

      {isLoading ? (
        <div className="relative grid md:grid-cols-2 md:min-h-[calc(100vh-70px)] max-w-[900px] gap-[2em] md:gap-[4em] lg:gap-[8em] md:px-[1.8em] mx-auto md:py-20">
          <SinglePaymentPageDetailsSkeleton />
          <SinglePaymentPageFormSkeleton />
        </div>
      ) : error ? (
        <div className="flex flex-col items-center justify-center min-h-[calc(100vh-4.2em)] md:min-h-[100vh] px-5">
          <span className="block text-[110px] sm:text-[150px] md:text-[180px] font-[900]">
            404
          </span>
          <span className="block text-xl font-[600] text-center">
            We can't find the page you're looking for
          </span>
          <span className="text-sm text-grayColor dark:text-darkGrayColor block mt-4 text-center">
            Visit our support page for further assistance.
          </span>
        </div>
      ) : (
        <div className="relative grid md:grid-cols-2 md:min-h-[calc(100vh-70px)] max-w-[900px] gap-[2em] md:gap-[4em] lg:gap-[8em] md:px-[1.8em] mx-auto md:py-20">
          <SinglePaymentPage paymentPage={paymentPage} />

          {paymentPage?.expiryDate &&
          new Date(paymentPage?.expiryDate).getDate() < new Date().getDate() ? (
            <div className="">
              <div className="flex justify-center text-[100px] text-yellow-500">
                <IoWarningOutline />
              </div>
              <h3 className="font-[600] text-xl text-center mt-3">
                Page Expired !
              </h3>
              <span className="text-sm text-grayColor dark:text-darkGrayColor text-center block mt-3 leading-[28px]">
                This payment page is expired. Please contact to merchant to get
                new link.
              </span>

              <Link
                to="/"
                className="text-sm underline text-blueColor text-center block mt-5"
              >
                Back To Home
              </Link>
            </div>
          ) : (
            <SinglePaymentForm paymentPage={paymentPage} />
          )}
        </div>
      )}
    </div>
  );
}

export default PayementPage;
