import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import PresalePageFormBasic from "./PresalePageFormBasic";
import PresalePagePersonal from "./PresalePagePersonal";
import axios from "../../../axios";
import { useNavigate } from "react-router-dom";
import { clearAllFormData } from "../../../redux/slices/presaleSlices";

function PresalePageForm() {
  const { formData } = useSelector((state) => state.presales);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [forms, setForms] = useState({
    basic: true,
    personal: false,
  });
  const [acceptedCurrencies, setAcceptedCurrencies] = useState([]);
  const [selectedRouters, setSelectedRouters] = useState([]);

  const [image, setImage] = useState();
  const { jwtToken } = useSelector((state) => state.user);

  const newFormData = new FormData();
  newFormData.append("token_name", formData.token_name);
  newFormData.append("token_symbol", formData.token_symbol);
  newFormData.append("total_supply", formData.total_supply);
  newFormData.append("pay_symbol", formData.pay_symbol);
  // newFormData.append("isMultyFundRaising", formData.isMultyFundRaising);
  // newFormData.append(
  //   "fundRaising_round_count",
  //   formData.fundRaising_round_count
  // );
  newFormData.append("isVesting", formData.isVesting);
  newFormData.append("percentage_for_sale", formData.percentage_for_sale);
  newFormData.append("percentage_for_vesting", formData.percentage_for_vesting);
  newFormData.append("fee_options", formData.fee_options);
  newFormData.append("affiliate_program", formData.affiliate_program);
  newFormData.append("router", JSON.stringify(selectedRouters));
  newFormData.append("UTC", JSON.stringify(formData.UTC));
  newFormData.append("liquidity_locup", formData.liquidity_locup);
  newFormData.append("website", formData.website);
  newFormData.append("twitter", formData.twitter);
  newFormData.append("facebook", formData.facebook);
  newFormData.append("github", formData.github);
  newFormData.append("telegram", formData.telegram);
  newFormData.append("descord", formData.descord);
  newFormData.append("reddit", formData.reddit);
  newFormData.append("youtube", formData.youtube);
  newFormData.append("description", formData.description);
  newFormData.append("instagram", formData.instagram);
  newFormData.append("logo", image);
  newFormData.append("liquidity_price", formData.liquidity_price);
  newFormData.append("token_address", formData?.token_address);
  newFormData.append("currency", JSON.stringify(acceptedCurrencies));

  const handleSubmit = async () => {
    try {
      const res = await axios.post(`/presale/create`, newFormData, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      dispatch(clearAllFormData());
      navigate("/presale-page");
    } catch (error) {
      console.log(error);
      setError(
        error.response.data.error ||
          error.response.data.err ||
          "somthing went wrong"
      );
    }
  };

  return (
    <div>
      <div className="relative flex py-2 flex-col justify-center min-h-[calc(100vh-5em)] max-w-[630px] mx-auto">
        <h1 className="font-bold text-xl mb-7">
          Presale {forms.basic ? "Basic Infermation" : "Personal Infermation"}
        </h1>

        {forms.basic ? (
          <PresalePageFormBasic
            setForms={setForms}
            acceptedCurrencies={acceptedCurrencies}
            setAcceptedCurrencies={setAcceptedCurrencies}
            selectedRouters={selectedRouters}
            setSelectedRouters={setSelectedRouters}
          />
        ) : forms.personal ? (
          <PresalePagePersonal
            setForms={setForms}
            isLoading={isLoading}
            setImage={setImage}
            handleSubmit={handleSubmit}
            error={error}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default PresalePageForm;
