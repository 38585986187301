import React, { useEffect, useState } from "react";
import { BiPencil } from "react-icons/bi";
import { AiOutlineWarning } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";

import axios from "../../axios";
import { avatarImg } from "../../assets/images";
import { useImageChange } from "../../hooks";
import { updateUser } from "../../redux/slices/userSlice";
import { BtnLoader } from "../../components";
import { setIsEmailVerifyModalOpen } from "../../redux/slices/layoutSlice";
import { toasts } from "../../utils";

export default function ProfilePage() {
    const { user } = useSelector((state) => state.user);

    const [data, setData] = useState({
        mobileNumber: user?.mobileNumber || "",
        username: user?.username || "",
        email: user?.email || "",
        name: user?.name || "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [timer, setTimer] = useState(0);
    const [timerActive, setTimerActive] = useState(false);

    const { jwtToken } = useSelector((state) => state.user);
    const { image, handleImageChange } = useImageChange();
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdate = async (e) => {
        try {
            e.preventDefault();

            setError("");
            setIsLoading(true);

            const formData = new FormData();

            formData.append("firstName", data.firstName);
            formData.append("lastName", data.lastName);
            formData.append("email", data.email);
            formData.append("username", data.username);
            formData.append("avatar", image);

            const response = await axios.patch("/users/update", formData, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            toasts.showSuccess("Profile Informations updated successfully");

            setIsLoading(false);
            dispatch(updateUser(response.data));
        } catch (err) {
            console.log(err);
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    const resendConfirmEmailHandler = async () => {
        try {
            setTimerActive(true);
            setTimer(45);

            await axios.post(
                "/users/resend-email",
                {},
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            dispatch(setIsEmailVerifyModalOpen(true));
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        let interval = null;
        if (timerActive) {
            interval = window.setInterval(() => {
                if (timer <= 1) {
                    setTimerActive(false);
                    window.clearInterval(interval);
                }
                setTimer((prev) => {
                    return prev - 1;
                });
            }, 1000);
        }

        return () => window.clearInterval(interval);
    }, [timerActive, timer]);

    return (
        <div className="">
            {!user.isEmailVerified && (
                <div className="w-[100%] rounded border bg-yellow-100 text-sm px-5 py-4 mb-6">
                    <span className="flex items-center gap-[10px] font-medium text-yellow-500">
                        <AiOutlineWarning /> Email Address in not Verified
                        <span className="underline p-0 bg-transparent text-yellow-500 text-sm">
                            {timer > 0 ? (
                                <span className="font-medium cursor-not-allowed">
                                    Resend in {timer} s
                                </span>
                            ) : (
                                <span
                                    className="font-medium cursor-pointer"
                                    onClick={resendConfirmEmailHandler}
                                >
                                    Resend
                                </span>
                            )}
                        </span>
                    </span>
                </div>
            )}
            <Toaster />
            <div>
                <h1 className="text-lg font-[600] mb-5">Edit Profile</h1>
                <div className="relative w-[70px] h-[70px] rounded-full mx-auto">
                    <div className="absolute top-12 right-0 flex items-center justify-center cursor-pointer w-[18px] h-[18px] min-w-[18px] min-h-[18px] overflow-hidden text-[12px] rounded-full bg-primaryColor text-white">
                        <div className="relative w-[100%] h-[100%]">
                            <div className="absolute inset-0 flex items-center justify-center text-[12px] ">
                                <BiPencil />
                            </div>
                            <input
                                type="file"
                                className="w-[100%] h-[100%] opacity-0 cursor-pointer"
                                onChange={handleImageChange}
                            />
                        </div>
                    </div>
                    <img
                        src={
                            image
                                ? URL.createObjectURL(image)
                                : user?.avatar
                                ? process.env.REACT_APP_SERVER_URL +
                                  user?.avatar
                                : avatarImg
                        }
                        alt=""
                        className="w-[100%] h-[100%] object-cover rounded-full"
                    />
                </div>
                <form action="" className="mt-8" onSubmit={handleUpdate}>
                    <div className="md:flex gap-[20px]">
                        <label htmlFor="" className="md:w-[400px]">
                            Full Name
                        </label>
                        <input
                            type="text"
                            value={data.name || ""}
                            name="name"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-5 md:flex gap-[20px]">
                        <label htmlFor="" className="md:w-[400px]">
                            Username
                        </label>
                        <input
                            type="text"
                            value={data.username || ""}
                            name="username"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-5 md:flex gap-[20px]">
                        <label htmlFor="" className="md:w-[400px]">
                            Email
                        </label>
                        <div className="w-full">
                            <input
                                type="email"
                                value={data.email || ""}
                                name="email"
                                onChange={handleChange}
                            />
                            <span className="block mt-[7px] text-[13px] text-blueColor font-medium">
                                If you update E-mail, Then you should confirm
                                new email
                            </span>
                        </div>
                    </div>
                    <div className="mt-4 md:flex gap-[20px]">
                        <label htmlFor="" className="md:w-[400px]">
                            Mobile Number
                        </label>
                        <input
                            type="text"
                            value={data.mobileNumber || "fsa"}
                            name="mobileNumber"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-6">
                        {error && (
                            <span className="block mb-3 text-[13px] font-medium text-red-500">
                                {error}
                            </span>
                        )}
                        <button
                            disabled={isLoading}
                            className="block ml-auto w-[140px]"
                        >
                            {isLoading ? <BtnLoader /> : "Update Profile"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
