import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { avatarImg } from "../../assets/images";
import { PaymentAcceptedCurrency } from "../../components";
import {
    CreateInvoiceDetails,
    CreateInvoiceTable,
    IssueInvoiceModal,
} from "../../features/CreateInvoice";
import { fetchInvoicesData } from "../../redux/slices/invocesSlice";

export default function CreateInvoicePage() {
    const [invoice, setInvoice] = useState({
        invoiceNo: "",
        issueDate: "",
        expiryDate: "",
        customerNotes: "",
        termsAndConditions: "",
    });
    const [isIssueInvoiceModalOpen, setIsIssueInvoiceModalOpen] =
        useState(false);
    const [acceptedCurrencies, setAcceptedCurrencies] = useState([]);

    const { user, accountId } = useSelector((state) => state.user);
    const { totalProductPrice, selectedCustomer, productsRows } = useSelector(
        (state) => state.invoices
    );
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setInvoice((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    useEffect(() => {
        dispatch(fetchInvoicesData());
    }, [accountId, dispatch]);

    return (
        <div className="py-5 sm:py-6 grid grid-cols-1 lg:grid-cols-[66%_auto] gap-[2em] xl:gap-[4em]">
            <div className="border dark:border-darkBorderColor">
                <div className="flex items-center gap-[15px] py-5 px-5 lg:px-10">
                    <div className="w-[60px] h-[60px] rounded overflow-hidden">
                        <img
                            src={
                                user?.avatar
                                    ? process.env.REACT_APP_SERVER_URL +
                                      user?.avatar
                                    : avatarImg
                            }
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div>
                        <h3 className="font-[700] text-xl">{user?.name}</h3>
                        <span className="block text-sm text-grayColor dark:text-darkGrayColor font-medium mt-1">
                            {user?.email}
                        </span>
                    </div>
                </div>
                <div className="w-full h-[50px] bg-primaryColor mb-8">
                    <div className="h-full bg-white dark:bg-darkBgColor w-min ml-auto mr-5 lg:mr-10 flex items-center justify-center px-[15px]">
                        <span className="font-[600] uppercase text-2xl">
                            INVOICE
                        </span>
                    </div>
                </div>
                <div className="px-5 lg:px-10">
                    <CreateInvoiceDetails handleChange={handleChange} />
                    <CreateInvoiceTable />
                </div>
                <div className="flex items-center justify-end mt-5">
                    <div className="bg-primaryColor text-white pr-10 pl-[20px] py-2 flex items-center gap-[1em]">
                        <span className="uppercase text-sm font-medium">
                            Total Amount
                        </span>
                        <span className="font-[600] text-lg">
                            {totalProductPrice || 0} USDT
                        </span>
                    </div>
                </div>

                <div className="px-5 lg:px-10 py-[3em]">
                    <div>
                        <div>
                            <span className="text-[13px] font-medium uppercase block mb-[5px] text-grayColor dark:text-darkGrayColor">
                                Customer Notes
                            </span>
                            <input
                                type="text"
                                className="border-0 dark:border-0 border-b dark:border-b rounded-none px-0 h-[28px]"
                                placeholder="Enter Customer Notes"
                                name="notes"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mt-[3em]">
                            <span className="text-[13px] font-medium uppercase block mb-[5px] text-grayColor dark:text-darkGrayColor">
                                Terms & Conditions
                            </span>
                            <input
                                type="text"
                                className="border-0 dark:border-0 border-b dark:border-b rounded-none px-0 h-[28px]"
                                placeholder="Enter Terms and conditions"
                                name="termsAndConditions"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-[#f6f6f6] dark:bg-darkCardColor mt-[4em] py-[6px] text-center">
                    <span className="text-sm font-medium text-grayColor dark:text-darkGrayColor">
                        {user?.name}
                    </span>
                </div>
            </div>
            <div className="w-[100%] sm:max-w-[370px] lg:max-w-auto sticky top-[6.2em] h-max">
                <div className="mb-5">
                    <label htmlFor="">Accepted Currencies *</label>

                    <PaymentAcceptedCurrency
                        setAcceptedCurrencies={setAcceptedCurrencies}
                        acceptedCurrencies={acceptedCurrencies}
                    />
                </div>

                <button
                    className="w-[100%] bg-primaryColor hover:bg-btnHoverColor disabled:opacity-80"
                    onClick={() => {
                        setIsIssueInvoiceModalOpen(true);
                    }}
                    disabled={
                        acceptedCurrencies?.length < 1 ||
                        !invoice.invoiceNo ||
                        !invoice.issueDate ||
                        !selectedCustomer?._id ||
                        productsRows?.some((a) => a?._id === "") ||
                        productsRows.length < 1
                    }
                >
                    Issue Invoice
                </button>

                <span className="text-sm text-grayColor dark:text-darkGrayColor leading-[28px] block mt-4">
                    * This will automatically generate a payment link for
                    accepting invoice payment
                </span>
            </div>
            <div className="relative">
                {isIssueInvoiceModalOpen && (
                    <IssueInvoiceModal
                        setIsIssueInvoiceModalOpen={setIsIssueInvoiceModalOpen}
                        invoice={invoice}
                        acceptedCurrencies={acceptedCurrencies}
                    />
                )}
            </div>
        </div>
    );
}
