import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useCallback } from "react";
import { Link } from "react-router-dom";

import axios from "../../axios";
import {
  AddCurrencyModal,
  CurrencyCard,
  CurrencyCardSkeleton,
  CurrencyInfoCard,
  RecentTransactionsTable,
} from "../../features/Currencies";
import { formatBalance, formatPrice } from "../../utils";

export default function CurrenciesPage() {
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddCurrencyModalOpen, setIsAddCurrencyModalOpen] = useState(false);
  const [section, setSection] = useState(1);

  const { currencies, isFetching } = useSelector((state) => state.currencies);
  const { jwtToken, accountId } = useSelector((state) => state.user);

  const fetchCurrencyTransactions = useCallback(async () => {
    try {
      const response = await axios.get(
        `/transactions/currency/${selectedCurrency?._id}?accountId=${accountId}`,
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
      setTransactions(response.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [jwtToken, selectedCurrency?._id]);

  useEffect(() => {
    setSelectedCurrency(currencies[0]);
  }, [currencies]);

  useEffect(() => {
    if (selectedCurrency?._id) {
      fetchCurrencyTransactions();
    }
  }, [selectedCurrency, fetchCurrencyTransactions]);

  return (
    <div className="py-5 sm:py-6">
      <div className="grid grid-cols-1 md:grid-cols-[30%_auto] max-w-[1200px] mx-auto gap-10">
        <div
          className={
            "md:sticky md:top-[5.5em] md:max-h-[calc(100vh-7.98em)] md:overflow-y-scroll " +
            (section === 1 ? "block" : "hidden md:block")
          }
          id="vertical-scroll"
        >
          <div className="flex items-center justify-between gap-[10px] pr-[8px] mb-3">
            <h1 className="font-[600] text-lg">Currencies</h1>
          </div>

          {isFetching ? (
            <div className="md:pr-[8px]">
              {Array.from({ length: 4 })?.map((_, index) => {
                return <CurrencyCardSkeleton key={index} />;
              })}
            </div>
          ) : (
            <div className="md:pr-[8px]">
              {currencies?.map((currency, index) => {
                return (
                  <CurrencyCard
                    key={index}
                    currency={currency}
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                    setSection={setSection}
                  />
                );
              })}
            </div>
          )}
        </div>

        <div className={section === 2 ? "block" : "hidden md:block"}>
          <div
            className="md:hidden text-xl  mb-2"
            onClick={() => setSection(1)}
          >
            <HiArrowNarrowLeft />
          </div>
          {isFetching ? (
            <div className="flex gap-[10px] justify-between">
              <div>
                <span className="block h-[16px] w-[180px] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
                <div className="flex items-center gap-[1em] mt-2">
                  <span className="block h-[14px] w-[120px] bg-slate-300 dark:bg-slate-600 animate-pulse "></span>
                </div>
                <div className="flex items-center gap-[10px] mt-3">
                  <span className="block h-[40px] w-[60px] bg-slate-300 dark:bg-slate-600 animate-pulse rounded"></span>
                  <span className="block h-[40px] w-[80px] bg-slate-300 dark:bg-slate-600 animate-pulse rounded"></span>
                </div>
              </div>
              <div className="text-right">
                <span className="block h-[16px] w-[160px] bg-slate-300 dark:bg-slate-600 ml-auto animate-pulse"></span>
                <span className="block h-[14px] w-[140px] bg-slate-300 dark:bg-slate-600 animate-pulse ml-auto mt-2"></span>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex gap-[10px] justify-between">
                <div>
                  <span className="block text-base font-[600]">
                    {selectedCurrency?.name} {selectedCurrency?.symbol}
                  </span>
                  <div className="flex items-center gap-[1em] mt-2">
                    <span className={"block text-sm font-medium "}>
                      ${formatPrice(selectedCurrency?.price?.price_usd)}{" "}
                    </span>
                    <span
                      className={
                        "text-sm font-medium " +
                        (selectedCurrency?.price?.percent_change_1h > 0
                          ? "text-green-500"
                          : "text-red-500")
                      }
                    >
                      {selectedCurrency?.price?.percent_change_1h > 0
                        ? "+"
                        : ""}
                      {selectedCurrency?.price?.percent_change_1h
                        ? Number(
                            selectedCurrency?.price?.percent_change_1h
                          )?.toFixed(2)
                        : "0"}
                      %
                    </span>
                  </div>
                </div>
                <div className="text-right">
                  <span className="block font-[600]">
                    {selectedCurrency?.balance || "0.0"}{" "}
                    {selectedCurrency?.currencySymbol}
                  </span>
                  <span className="block text-sm font-medium text-grayColor dark:text-darkGrayColor mt-1 whitespace-nowrap">
                    &asymp; $
                    {selectedCurrency?.balance &&
                    selectedCurrency?.price?.price_usd
                      ? formatBalance(
                          selectedCurrency?.balance *
                            selectedCurrency?.price?.price_usd
                        )
                      : "0.0"}{" "}
                    USD
                  </span>
                </div>
              </div>
              <div className="flex overflow-y-auto items-center gap-[10px] sm:gap-[15px] mt-4">
                <Link to="/transfer">
                  <button className="bg-[#f6f6f6] dark:bg-darkCardColor border dark:border-darkBorderColor text-textColor dark:text-darkTextColor hover:bg-btnHoverColor dark:hover:bg-btnHoverColor hover:text-white">
                    Send
                  </button>
                </Link>
                <Link to="/receive">
                  <button className="bg-[#f6f6f6] dark:bg-darkCardColor border dark:border-darkBorderColor text-textColor dark:text-darkTextColor hover:bg-btnHoverColor dark:hover:bg-btnHoverColor hover:text-white">
                    Recieve
                  </button>
                </Link>
                <Link to="/deposit">
                  <button className="bg-[#f6f6f6] dark:bg-darkCardColor border dark:border-darkBorderColor text-textColor dark:text-darkTextColor hover:bg-btnHoverColor dark:hover:bg-btnHoverColor hover:text-white">
                    Deposit
                  </button>
                </Link>
                <Link to="/withdraw">
                  <button className="bg-[#f6f6f6] dark:bg-darkCardColor border dark:border-darkBorderColor text-textColor dark:text-darkTextColor hover:bg-btnHoverColor dark:hover:bg-btnHoverColor hover:text-white">
                    Withdraw
                  </button>
                </Link>
              </div>
            </div>
          )}

          {isFetching ? (
            <div className="mx-auto mt-10 ">
              <div className="w-[30px] mx-auto h-[30px] rounded-full border-4 border-primaryColor border-r-transparent animate-spin"></div>
              <span className="text-center block mt-5 text-sm text-grayColor dark:text-darkGrayColor leading-[24px] font-medium">
                It should take a while.
              </span>
            </div>
          ) : (
            <>
              {/* <div className="mt-6">
                                <CurrencyInfoCard />
                            </div> */}

              <div className="mt-7">
                {isLoading ? (
                  <div className="w-[30px] mx-auto h-[30px] rounded-full border-4 border-primaryColor border-r-transparent animate-spin"></div>
                ) : transactions?.length < 1 ? (
                  <div className="text-center">
                    <span className="text-sm text-grayColor dark:text-darkGrayColor">
                      Recent activities not found!
                    </span>
                  </div>
                ) : (
                  <div>
                    <h2 className="font-[500] text-[15px] mb-3">
                      Recent Transactions
                    </h2>
                    <RecentTransactionsTable transactions={transactions} />
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {isAddCurrencyModalOpen && (
          <AddCurrencyModal
            setIsAddCurrencyModalOpen={setIsAddCurrencyModalOpen}
          />
        )}
      </div>
    </div>
  );
}
