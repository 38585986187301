const useNetworkChange = (account, network) => {
    let address = "";
    if (network?.networkVM) {
        switch (network?.networkVM) {
            case "ethereum":
                address = account?.ethereumWallet;
                break;
            case "solana":
                address = account?.solanaWallet;
                break;
            case "bitcoin":
                address = account?.bitcoinWallet;
                break;
            case "bitcoin-testnet":
                address = account?.bitcoinTestWallet;
                break;
            default:
                address = "";
                break;
        }
    }

    return address;
};

export default useNetworkChange;
