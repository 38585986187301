import React, { useState } from "react";
import { useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import { IoFilterSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { InvoicesFilter, InvoicesTable } from "../../features/Invoices";
import {
    fetchInvoices,
    incOrDecInvoiceSkip,
    updateInvoiceSkip,
} from "../../redux/slices/invocesSlice";
import { Pagination } from "../../components";

export default function InvoicesPage() {
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

    const dispatch = useDispatch();

    const { accountId } = useSelector((state) => state.user);
    const { limit, skip, isLoading, invoices, totalInvoices } = useSelector(
        (state) => state.invoices
    );

    useEffect(() => {
        dispatch(fetchInvoices());
    }, [dispatch, accountId]);

    return (
        <div className="py-5 md:py-6">
            <div className="mb-6">
                <div className="flex items-ceenter gap-[10px] justify-between">
                    <h3 className="text-base font-[600]">Invoices</h3>
                    <div className="flex items-center gap-[15px]">
                        <button
                            className="sm:hidden bg-transparent text-textColor dark:text-darkTextColor h-auto p-0 text-xl"
                            onClick={() =>
                                setIsFiltersModalOpen(!isFiltersModalOpen)
                            }
                        >
                            <IoFilterSharp />
                        </button>
                        <Link to="/create-invoice">
                            <button className="bg-in hover:bg-btnHoverColor">
                                + Create Invoice
                            </button>
                        </Link>
                    </div>
                </div>
                <div
                    className={
                        "sm:mt-3 z-20 sm:z-1 bg-white sm:bg-[#f6f6f6] dark:bg-darkCardColor sm:rounded " +
                        (isFiltersModalOpen
                            ? "fixed inset-0 sm:relative"
                            : "hidden sm:block")
                    }
                >
                    <div className="flex sm:hidden items-center justify-between p-5 border-b dark:border-darkBorderColor">
                        <h1 className="font-medium">Filters</h1>
                        <button
                            className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
                            onClick={() => {
                                setIsFiltersModalOpen(false);
                            }}
                        >
                            <MdClose />
                        </button>
                    </div>

                    <InvoicesFilter
                        setIsFiltersModalOpen={setIsFiltersModalOpen}
                    />
                </div>
            </div>

            <div>
                {isLoading ? (
                    <div className="flex items-center justify-center h-[200px]">
                        <div className="w-[30px] h-[30px] border-4 border-primaryColor border-r-transparent rounded-full animate-spin "></div>
                    </div>
                ) : !invoices || invoices?.length < 1 ? (
                    <div className="flex flex-col items-center justify-center h-[200px]">
                        <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
                            <BiSearch />
                        </div>
                        <span className="block text-xl font-[600] mt-3">
                            No Result found
                        </span>
                        <span className="block text-sm mt-2 text-grayColor dark:text-darkGrayColor">
                            There are no invoices created yet!!.
                        </span>
                    </div>
                ) : (
                    <div>
                        <InvoicesTable />

                        <Pagination
                            limit={limit}
                            skip={skip}
                            total={totalInvoices}
                            updateSkip={(skip) =>
                                dispatch(updateInvoiceSkip(skip))
                            }
                            incOrDecSkip={(number) =>
                                dispatch(incOrDecInvoiceSkip(number))
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
