import React, { useEffect, useState } from "react";
import { useRef } from "react";
import {
  FaCheck,
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { useHandleClickOutside } from "../../../hooks";
import { FiCopy } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

function ShareModal({ setIsShareModalOpen, url }) {
  const [isCopied, setIsCopied] = useState(false);
  const wrapperRef = useRef();
  useHandleClickOutside(wrapperRef, () => setIsShareModalOpen(false));

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);
  return (
    <div className="fixed inset-0 z-10 p-4 bg-[#fff7] dark:bg-[#0007] flex items-center justify-center">
      <div
        ref={wrapperRef}
        className="bg-[#fff] dark:bg-darkCardColor w-[100%] max-w-[400px] rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]"
      >
        <div className="flex items-center justify-between gap-[10px] p-5 border-b dark:border-[#4c4e53]">
          <h1 className="font-[600]">Share This</h1>
          <button
            className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
            onClick={() => setIsShareModalOpen(false)}
            type="button"
          >
            <IoMdClose />
          </button>
        </div>

        <div className="p-5">
          <div className="flex items-center gap-[15px]">
            <input type="text" disabled value={url} className="bg-[#f6f6f6]" />
            <button
              onClick={() => {
                navigator.clipboard.writeText(url);
                setIsCopied(true);
              }}
              className="p-0 h-auto bg-transparent text-textColor dark:text-darkTextColor text-lg"
            >
              {isCopied ? <FaCheck /> : <FiCopy />}
            </button>
          </div>

          <div className="flex flex-wrap items-center gap-[10px] mt-6">
            <span className="text-sm font-medium">Share To:</span>
            <a
              href={`http://www.facebook.com/share.php?u=${url}`}
              target="blank"
            >
              <button className="bg-[#4267B2] rounded-full text-base p-0 w-[30px] h-[30px] flex items-center justify-center cursor-pointer hover:opacity-90">
                <FaFacebookF />
              </button>
            </a>
            <a
              href={`http://twitter.com/share?url=${url}&hashtags=hamipay`}
              target="blank"
            >
              <button className="bg-[#00acee] rounded-full text-base p-0 w-[30px] h-[30px] flex items-center justify-center cursor-pointer hover:opacity-90">
                <FaTwitter />
              </button>
            </a>
            <a href={`whatsapp://send?text=${url}`} target="blank">
              <button className="bg-[#25d366] rounded-full text-base p-0 w-[30px] h-[30px] flex items-center justify-center cursor-pointer hover:opacity-90">
                <FaWhatsapp />
              </button>
            </a>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
              target="blank"
            >
              <button className="bg-[#0077b5] rounded-full text-base p-0 w-[30px] h-[30px] flex items-center justify-center cursor-pointer hover:opacity-90">
                <FaLinkedinIn />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareModal;
