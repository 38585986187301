import React from "react";
import { BiLink } from "react-icons/bi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { MdRequestPage } from "react-icons/md";
import { SiFastapi } from "react-icons/si";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GiJerusalemCross } from "react-icons/gi";
export default function DropdownMenu({ setIsDropdownMenuOpen }) {
  const { user } = useSelector((state) => state.user);

  return (
    <div className="absolute top-[49px] rounded left-[50%] translate-x-[-50%] bg-[#fff] dark:bg-darkCardColor w-[520px] shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] z-10">
      <div className="grid grid-cols-2 gap-[1.5em] p-5">
        <div>
          <ul>
            <li className="">
              <Link
                to={
                  user?.type === "merchant"
                    ? "/payment-pages"
                    : "/upgrade/merchant"
                }
                className={
                  "block py-[10px] px-[10px] rounded hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor " +
                  (user?.type === "merchant" ? "opacity-100" : "opacity-80")
                }
                onClick={() => {
                  setIsDropdownMenuOpen(false);
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <MdRequestPage />
                  <span className="block font-medium text-[14px]">
                    Payment Pages
                  </span>
                </div>
                {user?.type === "merchant" ? (
                  <span className=" text-[13px] text-grayColor dark:text-darkGrayColor block mt-1">
                    A Customised payment page
                  </span>
                ) : (
                  <span className=" text-[13px] text-blue-500 block mt-1">
                    Upgrade to merchant
                  </span>
                )}
              </Link>
            </li>
            <li className="">
              <Link
                to={
                  // user?.type === "merchant"
                  //   ? "/payment-links"
                  //   : "/upgrade/merchant"
                  "/payment-links"
                }
                className={
                  "block py-[10px] px-[10px] rounded hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor "
                  // (user?.type === "merchant" ? "opacity-100" : "opacity-80")
                }
                onClick={() => {
                  setIsDropdownMenuOpen(false);
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <BiLink />
                  <span className="block font-medium text-[14px]">
                    Payment Links
                  </span>
                </div>
                {/* {user?.type === "merchant" ? ( */}
                <span className=" text-[13px] text-grayColor dark:text-darkGrayColor block mt-1">
                  A Payment link
                </span>
                {/* // ) : (
                //   <span className=" text-[13px] text-blue-500 block mt-1">
                //     Upgrade to merchant
                //   </span>
                // )} */}
              </Link>
            </li>
            <li className="">
              <Link
                to={
                  user?.type === "merchant" ? "/invoices" : "/upgrade/merchant"
                }
                className={
                  "block py-[10px] px-[10px] rounded hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor " +
                  (user?.type === "merchant" ? "opacity-100" : "opacity-80")
                }
                onClick={() => {
                  setIsDropdownMenuOpen(false);
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <FaFileInvoiceDollar />
                  <span className="block font-medium text-[14px]">
                    Invoices
                  </span>
                </div>
                {user?.type === "merchant" ? (
                  <span className=" text-[13px] text-grayColor dark:text-darkGrayColor block mt-1">
                    Online Invoices
                  </span>
                ) : (
                  <span className=" text-[13px] text-blue-500 block mt-1">
                    Upgrade to merchant
                  </span>
                )}
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            {/* <li className="">
              <Link
                to={user?.type === "merchant" ? "/" : "/upgrade/merchant"}
                className={
                  "block py-[10px] px-[10px] rounded hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor " +
                  (user?.type === "merchant" ? "opacity-100" : "opacity-80")
                }
                onClick={() => {
                  setIsDropdownMenuOpen(false);
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <SiFastapi />
                  <span className="block font-medium text-[14px]">
                    Payment SDK
                  </span>
                </div>
                {user?.type === "merchant" ? (
                  <span className=" text-[13px] text-grayColor dark:text-darkGrayColor dar block mt-1">
                    Online Invoices
                  </span>
                ) : (
                  <span className=" text-[13px] text-blue-500 block mt-1">
                    Upgrade to merchant
                  </span>
                )}
              </Link>
            </li> */}
            <li className="">
              <Link
                to={
                  user?.type === "merchant"
                    ? "/presale-page"
                    : "/upgrade/merchant"
                }
                className={
                  "block py-[10px] px-[10px] rounded hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor " +
                  (user?.type === "merchant" ? "opacity-100" : "opacity-80")
                }
                onClick={() => {
                  setIsDropdownMenuOpen(false);
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <GiJerusalemCross />
                  <span className="block font-medium text-[14px]">
                    Presales
                  </span>
                </div>
                {user?.type === "merchant" ? (
                  <span className=" text-[13px] text-grayColor dark:text-darkGrayColor block mt-1">
                    A Presale
                  </span>
                ) : (
                  <span className=" text-[13px] text-blue-500 block mt-1">
                    Upgrade to merchant
                  </span>
                )}
              </Link>
            </li>
          </ul>

          <h3 className="font-[600] mt-5">Apps</h3>
          <div className="flex items-start gap-[20px] mt-3">
            <img
              src="https://www.freepnglogos.com/uploads/google-play-png-logo/google-play-arrow-png-logo-8.png"
              alt=""
              className="w-[25px]"
            />
            <img
              src="https://www.freepnglogos.com/uploads/apple-logo-png/apple-logo-png-dallas-shootings-don-add-are-speech-zones-used-4.png"
              alt=""
              className="w-[25px]"
            />
            <img
              src="https://www.freepnglogos.com/uploads/google-chrome-png-logo/google-chrome-icon-png-logo-1.png"
              alt=""
              className="w-[25px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
