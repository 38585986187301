import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { formatDate } from "../../../utils";
import InvoiceDetailsModal from "./invoiceDetailsModal";

export default function InvoicesTable() {
    const [isInvoiceDetailsModalOpen, setIsInvoiceDetailsModalOpen] =
        useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState({});

    const { invoices } = useSelector((state) => state.invoices);
    const navigate = useNavigate();

    return (
        <div>
            <div className="overflow-x-auto">
                <table className="w-[100%]">
                    <thead className="text-left bg-[#f6f6f6] dark:bg-darkCardColor">
                        <tr className="text-[13px]">
                            <th className="p-[12px] font-[600]">Invoice No</th>
                            <th className="font-[600] p-[12px]">Issued Date</th>
                            <th className="font-[600] p-[12px]">Amount</th>
                            <th className="font-[600] p-[12px]">Customer</th>
                            <th className="font-[600] p-[12px]">Payment Url</th>
                            <th className="font-[600] p-[12px]">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices?.map((invoice, index) => {
                            return (
                                <tr
                                    className="border-b dark:border-darkBorderColor text-[13px] hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                                    key={index}
                                >
                                    <td
                                        className="pl-[2px] pr-[12px] py-[19px] underline text-blueColor cursor-pointer"
                                        onClick={() => {
                                            setSelectedInvoice(invoice);
                                            setIsInvoiceDetailsModalOpen(true);
                                        }}
                                    >
                                        #{invoice.invoiceNo}
                                    </td>
                                    <td className="px-[12px] py-[19px]">
                                        {formatDate(invoice?.issueDate)}
                                    </td>
                                    <td className="px-[12px] py-[19px] whitespace-nowrap">
                                        {invoice?.amount} USDT
                                    </td>
                                    <td className="px-[12px] py-[19px]">
                                        {`${invoice?.customerName} (${invoice?.customerEmail})`}
                                    </td>
                                    <td className="px-[12px] py-[19px] whitespace-nowrap">
                                        <a
                                            href={`${process.env.REACT_APP_CLIENT_URL}/inv/${invoice?.id}`}
                                            target="blank"
                                            className="text-primaryColor"
                                        >
                                            {`https://.../inv/${invoice?.id}`}
                                        </a>
                                    </td>
                                    <td className="pr-[2px] pl-[12px] py-[19px] whitespace-nowrap">
                                        <span
                                            className={
                                                "text-[11px] rounded px-[4.5px] py-[2.7px] " +
                                                (invoice?.status === "issued"
                                                    ? "bg-blue-100 text-blue-500"
                                                    : invoice?.status ===
                                                      "cancelled"
                                                    ? "text-gray-500 bg-gray-100"
                                                    : invoice?.status === "paid"
                                                    ? "bg-[#daf4eb] text-[#34c38f]"
                                                    : "text-gray-500 bg-gray-100")
                                            }
                                        >
                                            {invoice?.status}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {isInvoiceDetailsModalOpen && (
                <InvoiceDetailsModal
                    setIsInvoiceDetailsModalOpen={setIsInvoiceDetailsModalOpen}
                    id={selectedInvoice?.id}
                />
            )}
        </div>
    );
}
