import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useSelector } from "react-redux";
import { formatBalance, getBalance } from "../utils";

export default function PayableCurrencySelect({
    selectedCurrency,
    setSelectedCurrency,
    currencies,
}) {
    const [isCurrenciesDropdownOpen, setIsCurrenciesDropdownOpen] =
        useState(false);

    const { isFetching, balances } = useSelector((state) => state.currencies);

    return (
        <div>
            <div className="mb-[5px] flex items-center justify-between">
                <label htmlFor="" className="mb-0">
                    Currency
                </label>
                {Object.keys(selectedCurrency)?.length > 0 && (
                    <span className="text-sm ">
                        {formatBalance(
                            getBalance(balances, selectedCurrency?._id)
                        )}{" "}
                        {selectedCurrency?.symbol}
                    </span>
                )}
            </div>
            <div className="relative w-full">
                <div
                    className="border border-borderColor dark:border-darkBorderColor cursor-pointer w-full flex items-center gap-[10px] justify-between h-[45px] rounded px-[15px]"
                    onClick={() => {
                        if (!isFetching) {
                            setIsCurrenciesDropdownOpen(
                                !isCurrenciesDropdownOpen
                            );
                        }
                    }}
                >
                    {isFetching ? (
                        <>
                            <div className="flex items-center gap-[10px]">
                                <div className="w-[25px] h-[25px] rounded-full bg-slate-300 dark:bg-slate-600 animate-pulse"></div>
                                <span className="h-[14px] w-[100px] bg-slate-300 dark:bg-slate-600 block animate-pulse"></span>
                            </div>
                            <div className="w-[20px] h-[20px] animate-spin rounded-full border-4 border-r-transparent border-primaryColor"></div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-center gap-[10px]">
                                {Object.keys(selectedCurrency).length > 0 ? (
                                    <>
                                        <img
                                            src={
                                                process.env
                                                    ?.REACT_APP_SERVER_URL +
                                                selectedCurrency?.logo
                                            }
                                            alt=""
                                            className="w-[25px] h-[25px] rounded-full object-cover"
                                        />
                                        <span className="text-sm">
                                            {selectedCurrency?.name}
                                        </span>
                                        <span className="text-sm">
                                            {selectedCurrency?.symbol}
                                        </span>
                                    </>
                                ) : (
                                    <span className="text-sm">
                                        Choose Currency
                                    </span>
                                )}
                            </div>
                            <div>
                                <FiChevronDown />
                            </div>
                        </>
                    )}
                </div>
                {isCurrenciesDropdownOpen && (
                    <div className="absolute top-[100%] left-0 rounded w-full bg-white dark:bg-darkCardColor shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
                        {currencies?.map((currency, index) => {
                            return (
                                <div
                                    key={index}
                                    className="flex items-center justify-between transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor px-[15px] py-[7.5px] cursor-pointer"
                                    onClick={() => {
                                        setSelectedCurrency(currency);
                                        setIsCurrenciesDropdownOpen(false);
                                    }}
                                >
                                    <div className="flex items-center gap-[10px] text-sm">
                                        <div className="w-[25px] h-[25px] rounded-full overflow-hidden">
                                            <img
                                                src={
                                                    process.env
                                                        ?.REACT_APP_SERVER_URL +
                                                    currency?.logo
                                                }
                                                alt=""
                                                className="w-[100%] h-[100%] object-cover"
                                            />
                                        </div>
                                        <span>{currency.name} </span>
                                    </div>
                                    <span className="text-sm font-medium">
                                        {formatBalance(
                                            getBalance(balances, currency?._id)
                                        )}{" "}
                                        {currency?.symbol}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
