import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useSelector } from "react-redux";
import { formatBalance } from "../utils";

export default function CurrencySelectDropdown({
  selectedCurrency,
  setSelectedCurrency,
  labelVisible = true,
  setSelectedNetwork,
}) {
  const [isCurrenciesDropdownOpen, setIsCurrenciesDropdownOpen] =
    useState(false);

  const { currencies, isFetching } = useSelector((state) => state.currencies);

  return (
    <div>
      {labelVisible && (
        <div className="flex items-center gap-[10px] justify-between mb-[6px]">
          <label htmlFor="" className="mb-0">
            Currency *
          </label>
          <span className="text-sm font-medium text-primaryColor">
            {formatBalance(selectedCurrency?.balance)}{" "}
            {selectedCurrency?.symbol}
          </span>
        </div>
      )}
      <div className="relative w-full">
        <div
          className="border border-borderColor dark:border-darkBorderColor cursor-pointer w-full flex items-center gap-[10px] justify-between h-[45px] rounded px-[15px]"
          onClick={() => {
            if (!isFetching) {
              setIsCurrenciesDropdownOpen(!isCurrenciesDropdownOpen);
            }
          }}
        >
          {isFetching ? (
            <>
              <div className="flex items-center gap-[10px]">
                <div className="w-[25px] h-[25px] rounded-full bg-slate-300 dark:bg-slate-600 animate-pulse"></div>
                <span className="h-[14px] w-[100px] bg-slate-300 dark:bg-slate-600 block animate-pulse"></span>
              </div>
              <div className="w-[20px] h-[20px] animate-spin rounded-full border-4 border-r-transparent border-primaryColor"></div>
            </>
          ) : (
            <>
              <div className="flex items-center gap-[10px]">
                <img
                  src={
                    process.env?.REACT_APP_SERVER_URL + selectedCurrency?.logo
                  }
                  alt=""
                  className="w-[25px] h-[25px] rounded-full object-cover"
                />
                <span className="text-sm">{selectedCurrency?.name}</span>
                <span className="text-sm">{selectedCurrency?.symbol}</span>
              </div>
              <div>
                <FiChevronDown />
              </div>
            </>
          )}
        </div>
        {isCurrenciesDropdownOpen && (
          <div className="absolute top-[100%] z-10 left-0 max-h-[300px] overflow-y-auto rounded w-full bg-white dark:bg-darkCardColor shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
            {currencies?.map((currency, index) => {
              return (
                <div
                  key={index}
                  className="flex items-center justify-between transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor px-[15px] py-[7.5px] cursor-pointer"
                  onClick={() => {
                    setSelectedCurrency(currency);
                    setIsCurrenciesDropdownOpen(false);
                    setSelectedNetwork({});
                  }}
                >
                  <div className="flex items-center gap-[10px] text-sm">
                    <div className="w-[25px] h-[25px] rounded-full overflow-hidden">
                      <img
                        src={process.env?.REACT_APP_SERVER_URL + currency?.logo}
                        alt=""
                        className="w-[100%] h-[100%] object-cover"
                      />
                    </div>
                    <span>{currency.name} </span>
                  </div>
                  <span className="text-sm font-medium">
                    {formatBalance(currency?.balance)} {currency?.symbol}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
