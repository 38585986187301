import React, { useState } from "react";
import { useRef } from "react";
import { MdAccountBalanceWallet } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHandleClickOutside } from "../../../hooks";
import { formatBalance } from "../../../utils";

import HomeBalanceCardSkeleton from "./HomeBalanceCardSkeleton";

export default function HomeBalanceCard() {
  const [isMoreDropdownOpen, setIsMoreDrodownOpen] = useState(false);

  const { balanceInUSD, isFetching } = useSelector((state) => state.currencies);
  const moreWrapperRef = useRef();
  useHandleClickOutside(moreWrapperRef, () => setIsMoreDrodownOpen(false));

  const {
    user,
    totalPaymentsReceived,
    totalPaymentsReceivedToday,
    isHomeDataFetching,
  } = useSelector((state) => state.user);

  return (
    <div>
      <h1 className="font-[600] text-lg sm:text-[20px]">
        Good Morning, {user?.name}
      </h1>
      {isFetching || isHomeDataFetching ? (
        <HomeBalanceCardSkeleton />
      ) : user.type === "merchant" ? (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-[10px] mt-3 rounded">
          <div className="flex items-center gap-[20px] max-h-[112px] sm:max-h-[120px] bg-[#f3f3f3] dark:bg-darkCardColor overflow-hidden px-4 sm:px-6 py-6 rounded ">
            <div className="text-xl bg-[#fff] dark:bg-darkBgColor w-[40px] h-[40px] min-w-[40px] min-h-[40px] flex items-center justify-center rounded-full">
              <MdAccountBalanceWallet />
            </div>
            <div className="whitespace-nowrap">
              <span className="block  font-medium text-[14px] mb-1 text-grayColor dark:text-darkGrayColor">
                Total Blanace
              </span>
              <div className="flex items-center gap-[2em]">
                <span className="block font-[600] text-xl">
                  <span className="text-base font-[600]">$ </span>
                  {formatBalance(balanceInUSD)}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-[20px] max-h-[112px] sm:max-h-[120px] bg-[#f3f3f3] dark:bg-darkCardColor overflow-hidden px-4 sm:px-6 py-6 rounded ">
            <div className="text-xl bg-[#fff] dark:bg-darkBgColor w-[40px] h-[40px] min-w-[40px] min-h-[40px] flex items-center justify-center rounded-full">
              <MdAccountBalanceWallet />
            </div>
            <div className="whitespace-nowrap">
              <span className="block  font-medium text-[14px] mb-1 text-grayColor dark:text-darkGrayColor">
                Total Payments
              </span>
              <div className="flex items-center gap-[2em]">
                <span className="block font-[600] text-xl">
                  {totalPaymentsReceived}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-[20px] max-h-[112px] sm:max-h-[120px] bg-[#f3f3f3] dark:bg-darkCardColor overflow-hidden px-4 sm:px-6 py-6 rounded ">
            <div className="text-xl bg-[#fff] dark:bg-darkBgColor w-[40px] h-[40px] min-w-[40px] min-h-[40px] flex items-center justify-center rounded-full">
              <MdAccountBalanceWallet />
            </div>
            <div className="whitespace-nowrap">
              <span className="block  font-medium text-[14px] mb-1 text-grayColor dark:text-darkGrayColor">
                Payments Today
              </span>
              <div className="flex items-center gap-[2em]">
                <span className="block font-[600] text-xl">
                  {totalPaymentsReceivedToday}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between max-h-[112px] sm:max-h-[120px] overflow-hidden bg-gradient-to-r from-[#54d0e9] via-[#1c55ad] to-[#1559c5] px-5 sm:px-8 py-6 rounded mt-3">
          <div className="whitespace-nowrap">
            <span className="block text-[#fff]  font-medium text-[15px] mb-1">
              Total Blanace
            </span>
            <div className="flex items-center gap-[2em]">
              <span className="block font-[600] text-3xl text-white">
                <span className="text-base font-[600]">$ </span>
                {formatBalance(balanceInUSD)}
              </span>
            </div>
          </div>
          <div>
            <img
              src="https://hami.network/uinel-assets/images/dashboard-banners/header.png"
              alt=""
            />
          </div>
        </div>
      )}
      <div className="flex items-center justify-between mt-[1em] gap-[1em]">
        <div className="flex items-center gap-[10px]">
          <Link to="/transfer">
            <button className="h-[40px] hover:bg-btnHoverColor">Send</button>
          </Link>
          <Link to="/deposit">
            <button className="h-[40px] hover:bg-btnHoverColor">Deposit</button>
          </Link>
        </div>
        <div className="relative">
          <button
            className="bg-transparent border-2 text-textColor h-[40px] dark:text-darkTextColor dark:border-darkBorderColor p-0 px-[15px]"
            onClick={() => {
              setIsMoreDrodownOpen(true);
            }}
          >
            More
          </button>

          {isMoreDropdownOpen && (
            <div
              ref={moreWrapperRef}
              className="absolute right-0 top-[100%] bg-[#fff] dark:bg-darkCardColor overflow-hidden rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] min-w-[150px]"
            >
              <Link
                to="/receive"
                className="block text-sm px-[10px] py-[8px] cursor-pointer transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
              >
                Receive
              </Link>
              <Link
                to="/withdraw"
                className="block text-sm px-[10px] py-[8px] cursor-pointer transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
              >
                Withdraw
              </Link>
              <Link
                to="/swap"
                className="block text-sm px-[10px] py-[8px] cursor-pointer transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
              >
                Swap
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
