import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../../axios";
import { BtnLoader } from "../../../components";
import {
    addNewCustomer,
    updateCustomer,
} from "../../../redux/slices/invocesSlice";

export default function AddCustomerModal({
    isEdit,
    setIsAddCustomerModalOpen,
    selectedCustomer,
}) {
    const [data, setData] = useState({
        name: isEdit ? selectedCustomer?.name : "",
        email: isEdit ? selectedCustomer?.email : "",
        mobileNumber: isEdit ? selectedCustomer?.mobileNumber : "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const { accountId, jwtToken } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            setError("");

            if (isEdit) {
                const response = await axios.patch(
                    `/customers/single/${selectedCustomer?._id}`,
                    {
                        ...data,
                        accountId,
                    },
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                dispatch(updateCustomer(response.data));
            } else {
                const response = await axios.post(
                    "/customers/add",
                    {
                        ...data,
                        accountId,
                    },
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                dispatch(addNewCustomer(response.data));
            }

            setIsLoading(false);
            setIsAddCustomerModalOpen({ bool: false, isEdit: false });
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div
            id="add-customer-modal"
            className="fixed inset-0 bg-[#fff5] dark:bg-[#0005] z-10 flex items-center justify-center px-4"
            onClick={(e) => {
                if (
                    e.target === document.getElementById("add-customer-modal")
                ) {
                    setIsAddCustomerModalOpen({ bool: false, isEdit: false });
                }
            }}
        >
            <div className="bg-[#fff] dark:bg-darkCardColor max-h-[100%] sm:max-h-[90%] overflow-y-auto w-[100%] sm:max-w-[400px] rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
                <div className="flex items-center justify-between gap-[10px] p-5 border-b dark:border-darkBorderColor">
                    <h1 className="font-[600]">
                        {isEdit ? "Edit" : "Add"} Customer
                    </h1>
                    <button
                        className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
                        onClick={() =>
                            setIsAddCustomerModalOpen({
                                bool: false,
                                isEdit: false,
                            })
                        }
                        type="button"
                    >
                        <IoMdClose />
                    </button>
                </div>
                <form action="" className="p-5" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="">Name</label>
                        <input
                            type="text"
                            placeholder="Enter Customer Name"
                            name="name"
                            onChange={handleChange}
                            value={data.name || ""}
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">Email</label>
                        <input
                            type="email"
                            placeholder="Enter Email"
                            name="email"
                            onChange={handleChange}
                            value={data.email || ""}
                        />
                    </div>
                    <div className="mt-4">
                        <label htmlFor="">Mobile Number (optional)</label>
                        <input
                            type="number"
                            placeholder="Enter mobile number"
                            name="mobileNumber"
                            onChange={handleChange}
                            className="arrow-hidden"
                            value={data.mobileNumber || ""}
                        />
                        <span className="text-sm text-grayColor dark:text-darkGrayColor block mt-[5px]">
                            Provide customer's mobile number with country code.
                        </span>
                    </div>
                    {error && (
                        <span className="text-sm text-red-500 block mt-3">
                            {error}
                        </span>
                    )}
                    <div className="mt-6">
                        <button
                            className="w-full hover:bg-btnHoverColor disabled:opacity-80"
                            disabled={isLoading || !data.name || !data.email}
                        >
                            {isLoading ? (
                                <BtnLoader />
                            ) : isEdit ? (
                                "Update Customer"
                            ) : (
                                "Add Customer"
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
