import React from "react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";

import { formatBalance, formatPrice } from "../../../utils";

export default function HomeWalletCard({ name, symbol, balance, price, logo }) {
    return (
        <div className="flex items-start justify-between bg-gradient-to-r from-[#f4f4f4] via-[#fdfdfd] to-[#f4f4f4] dark:from-darkCardColor dark:to-darkCardColor p-3 rounded mb-3 last:mb-0">
            <div className="flex items-center gap-[10px]">
                <div className="w-[35px] h-[35px] sm:w-[40px] sm:h-[40px] rounded-full overflow-hidden">
                    <img
                        src={process.env.REACT_APP_SERVER_URL + logo}
                        alt=""
                        className="w-[100%] h-[100%] object-cover"
                    />
                </div>
                <div>
                    <span className="block font-[500] text-[15px]">{name}</span>
                    <span
                        className={
                            "block font-medium text-[14px] block mt-[2px] flex items-center gap-[5px] " +
                            (price?.percent_change_1h >= 0
                                ? "text-green-500"
                                : "text-red-500")
                        }
                    >
                        {price?.percent_change_1h >= 0 ? (
                            <MdArrowUpward />
                        ) : (
                            <MdArrowDownward />
                        )}
                        ${formatPrice(price?.price_usd)}{" "}
                    </span>
                </div>
            </div>
            <div className="text-right">
                <span className="block font-[600] text-[14px]">
                    {formatBalance(balance)} {symbol}
                </span>
                <span className="text-[13px] font-medium text-[#555] dark:text-darkGrayColor">
                    $
                    {balance && price?.price_usd
                        ? formatBalance(balance * price?.price_usd)
                        : "0.0"}
                </span>
            </div>
        </div>
    );
}
