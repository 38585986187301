import React, { useEffect, useState } from "react";
import { VscClose } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../../axios";
import {
    BtnLoader,
    PaymentAcceptedCurrency,
    PaymentCurrenySelect,
} from "../../../components";
import { addNewPaymentPage } from "../../../redux/slices/paymentPagesSlice";

export default function CreatePaymentPageModal({
    setIsCreatePaymentPageModalOpen,
    isEdit = false,
    paymentPage,
    setPaymentPage,
}) {
    const [data, setData] = useState({
        amount: isEdit ? paymentPage?.amount : "",
        amountType: isEdit ? paymentPage?.amountType : "",
        title: isEdit ? paymentPage?.title : "",
        description: isEdit ? paymentPage?.description : "",
        expiryDate: isEdit ? paymentPage?.expiryDate : "",
        contactName: isEdit ? paymentPage?.contactName : "",
        contactEmail: isEdit ? paymentPage?.contactEmail : "",
        redirectionUrl: isEdit ? paymentPage.redirectionUrl : "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isNoExpiry, setIsNoExpiry] = useState(
        isEdit ? (paymentPage?.expiryDate ? false : true) : false
    );
    const [error, setError] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [acceptedCurrencies, setAcceptedCurrencies] = useState(
        isEdit ? paymentPage?.acceptedCurrencies : []
    );

    const { jwtToken, currentAccount } = useSelector((state) => state.user);
    const { currencies } = useSelector((state) => state.currencies);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            setIsLoading(true);
            setError("");

            if (isEdit) {
                const response = await axios.patch(
                    `/payment-pages/update/${paymentPage?.id}`,
                    {
                        ...data,
                        currencyId: selectedCurrency._id,
                        acceptedCurrencies,
                    },
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                setPaymentPage(response.data);
            } else {
                const response = await axios.post(
                    "/payment-pages/create",
                    {
                        ...data,
                        currencyId: selectedCurrency._id,
                        acceptedCurrencies,
                        accountId: currentAccount?._id,
                    },
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                dispatch(addNewPaymentPage(response.data));
            }

            setIsLoading(false);
            setIsCreatePaymentPageModalOpen(false);
        } catch (err) {
            console.log(err);
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setSelectedCurrency(currencies[0]);
    }, [currencies]);

    return (
        <div
            className="fixed inset-0 bg-[#fff5] dark:bg-[#0005] flex items-center justify-center z-20 sm:px-5"
            id="create-payment-page-modal"
            onClick={(e) => {
                if (
                    e.target ===
                    document.getElementById("create-payment-page-modal")
                ) {
                    setIsCreatePaymentPageModalOpen(false);
                }
            }}
        >
            <div className="bg-[#fff] dark:bg-darkCardColor w-[100%] sm:max-w-[450px] h-[100vh] sm:h-[85vh] overflow-y-auto rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
                <div className="flex items-center justify-between border-b border-borderColor dark:border-[#4c4e53] px-4 py-3">
                    <h3 className="font-medium">Create Payment Page</h3>
                    <button
                        className="bg-transparent text-textColor dark:text-darkTextColor p-0 text-xl"
                        onClick={() => setIsCreatePaymentPageModalOpen(false)}
                    >
                        <VscClose />
                    </button>
                </div>
                <form action="" className="p-5" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="">Title *</label>
                        <input
                            type="text"
                            placeholder="Enter Page Title"
                            name="title"
                            onChange={handleChange}
                            value={data.title || ""}
                        />
                    </div>

                    <div className="mt-4">
                        <label htmlFor="">Description *</label>
                        <textarea
                            placeholder="Enter Page Description"
                            name="description"
                            onChange={handleChange}
                            className="h-[100px]"
                            value={data.description || ""}
                        ></textarea>
                    </div>

                    <div className="mt-4">
                        <label htmlFor="">
                            Amount in {selectedCurrency?.symbol} *
                        </label>
                        <div className="flex items-center gap-[10px] mb-2">
                            <input
                                type="checkbox"
                                className="w-[15px] h-[15px]"
                                name="amountType"
                                id="amountType"
                                onChange={(e) => {
                                    if (e.target.checked === true) {
                                        setData({
                                            ...data,
                                            amount: "",
                                            amountType: "non-fixed",
                                        });
                                    } else {
                                        setData({
                                            ...data,
                                            amountType: "fixed",
                                        });
                                    }
                                }}
                                checked={data.amountType === "non-fixed" || ""}
                            />
                            <label htmlFor="amountType" className="mb-0">
                                Non Fixed Amount
                            </label>
                        </div>
                        <div className="flex items-center border dark:border-transparent rounded overflow-hidden h-[45px]">
                            <PaymentCurrenySelect
                                selectedCurrency={selectedCurrency}
                                setSelectedCurrency={setSelectedCurrency}
                            />
                            <input
                                type="number"
                                className="arrow-hidden h-full border-0 rounded-none"
                                placeholder="0.00"
                                name="amount"
                                onChange={handleChange}
                                value={data.amount || ""}
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <label htmlFor="">Accepted Currencies *</label>

                        <PaymentAcceptedCurrency
                            setAcceptedCurrencies={setAcceptedCurrencies}
                            acceptedCurrencies={acceptedCurrencies}
                        />
                    </div>

                    <div className="mt-4">
                        <label htmlFor="">Link Expiry *</label>
                        <div className="flex items-center gap-[10px] mb-2">
                            <input
                                type="checkbox"
                                className="w-[15px] h-[15px]"
                                name="no-expiry"
                                id="no-expiry"
                                onChange={(e) => {
                                    setIsNoExpiry(e.target.checked);
                                    if (e.target.checked === true) {
                                        setData({ ...data, expiryDate: "" });
                                    }
                                }}
                                checked={isNoExpiry || ""}
                            />
                            <label htmlFor="no-expiry" className="mb-0">
                                No Expiry
                            </label>
                        </div>
                        <input
                            type="date"
                            disabled={isNoExpiry}
                            name="expiryDate"
                            onChange={handleChange}
                            value={
                                data?.expiryDate
                                    ? new Date(data?.expiryDate)
                                          ?.toISOString()
                                          ?.substring(0, 10)
                                    : ""
                            }
                            min={new Date().toISOString().split("T")[0]}
                        />
                    </div>

                    <div className="mt-4">
                        <label htmlFor="">Redirection Url</label>
                        <input
                            type="text"
                            placeholder="Ex: https://your-website.com"
                            name="redirectionUrl"
                            onChange={handleChange}
                            value={data.redirectionUrl || ""}
                        />
                    </div>

                    <div className="mt-4">
                        <label htmlFor="">Contact Name *</label>
                        <input
                            type="text"
                            placeholder="Ex: Nihal"
                            name="contactName"
                            onChange={handleChange}
                            value={data.contactName || ""}
                        />
                    </div>

                    <div className="mt-4">
                        <label htmlFor="">Contact Email *</label>
                        <input
                            type="email"
                            placeholder="Ex: nihal@gmail.com"
                            name="contactEmail"
                            onChange={handleChange}
                            value={data.contactEmail || ""}
                        />
                    </div>

                    <span className="block mt-6 text-[13px] text-grayColor dark:text-darkGrayColor leading-[24px]">
                        This is links is only for one payment request. If you
                        want multiple payment in one link, then try{" "}
                        <span className="font-medium text-primaryColor underline">
                            payment page
                        </span>
                    </span>

                    {error && (
                        <span className="text-[13px] font-medium text-red-500 block mt-3">
                            {error}
                        </span>
                    )}

                    <div className="mt-4 flex flex-col sm:flex-row items-center gap-[10px]">
                        <button
                            className="w-[100%] bg-transparent text-textColor dark:text-darkTextColor border border-[#ced4da] dark:border-[#4c4e53] order-2 sm:order-none"
                            type="button"
                            onClick={() =>
                                setIsCreatePaymentPageModalOpen(false)
                            }
                        >
                            Cancel
                        </button>
                        <button
                            className="w-[100%] disabled:opacity-80"
                            disabled={
                                !data.description ||
                                !data.title ||
                                !data.contactEmail ||
                                !data.contactName ||
                                (isNoExpiry === false && !data.expiryDate) ||
                                (data.amountType === "fixed" && !data.amount) ||
                                isLoading ||
                                acceptedCurrencies.length < 1
                            }
                        >
                            {isLoading ? <BtnLoader /> : "Create Payment Page"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
