import React from "react";
import { Outlet } from "react-router-dom";

import { Header } from "../components";

export default function HeaderLayout() {
    return (
        <div className="">
            <Header />
            <main>
                <Outlet />
            </main>
        </div>
    );
}
