import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import axios from "../../axios";
import {
  PaymentPageEditAndShare,
  PaymentPagePaymentsTable,
  PaymentPageStatistics,
  PyamentPageDetailedInfo,
} from "../../features/PaymentPageDetails";
import { useCallback } from "react";

export default function PaymentPageDetailsPage() {
  const [data, setData] = useState({
    revenues: [],
    totalSales: 0,
  });
  const [paymentPage, setPaymentPage] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);

  const { jwtToken } = useSelector((state) => state.user);
  const { id } = useParams();

  const fetchPaymentPage = useCallback(async () => {
    try {
      const response = await axios.get(`/payment-pages/single/${id}/details`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });

      setPaymentPage(response.data?.paymentPage);
      setData((prev) => {
        return {
          ...prev,
          revenues: response.data?.revenues,
          totalSales: response?.data?.totalSales,
        };
      });

      setIsLoading(false);
    } catch (err) {
      setError(true);
      setIsLoading(false);
    }
  }, [id, jwtToken]);

  useEffect(() => {
    fetchPaymentPage();
  }, [fetchPaymentPage]);

  return (
    <div className="py-6">
      {isLoading ? (
        <div className="flex items-center justify-center h-[200px]">
          <div className="w-[30px] h-[30px] border-4 border-primaryColor border-r-transparent rounded-full animate-spin "></div>
        </div>
      ) : error ? (
        <div className="flex flex-col items-center justify-center h-[200px]">
          <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
            <BiSearch />
          </div>
          <span className="block text-xl font-[600] mt-3">No Result found</span>
          <span className="block text-sm mt-2 text-grayColor dark:text-darkGrayColor">
            Invalid payment page reference no!!.
          </span>
        </div>
      ) : (
        <>
          <div
            className={
              "relative grid grid-cols-1 lg:grid-cols-2 gap-[2em] mb-[2em]  " +
              (isShowMore ? "h-auto" : "h-[370px] overflow-hidden")
            }
          >
            <PyamentPageDetailedInfo
              paymentPage={paymentPage}
              setPaymentPage={setPaymentPage}
            />
            <div>
              <PaymentPageEditAndShare
                paymentPage={paymentPage}
                setPaymentPage={setPaymentPage}
              />
              <PaymentPageStatistics data={data} />
            </div>

            <div className="absolute left-0 bottom-0 h-[70px] w-[100%] bg-gradient-to-b from-transparent to-[#fff] dark:to-[#1a1d21]">
              <div className="flex justify-center h-full items-end">
                <button
                  className="h-auto py-[5px] flex items-center gap-[8px] hover:bg-btnHoverColor"
                  onClick={() => setIsShowMore(!isShowMore)}
                >
                  {isShowMore ? (
                    <>
                      Show Less <FaChevronUp />
                    </>
                  ) : (
                    <>
                      Show more <FaChevronDown />
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="border-t dark:border-darkBorderColor">
            <PaymentPagePaymentsTable paymentPage={paymentPage} />
          </div>
        </>
      )}
    </div>
  );
}
