const getCountryByCode = (countries, isocode) => {
    let country;
    for (let i = 0; i < countries?.length; i++) {
        if (countries[i]?.isocode === isocode?.toUpperCase()) {
            country = countries[i];
            break;
        }
    }
    return country;
};

const country = { getCountryByCode };

export default country;
