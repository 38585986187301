import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiSearch } from "react-icons/bi";
import { IoFilterSharp } from "react-icons/io5";

import {
    TransactionsFilter,
    TransactionsTable,
} from "../../features/Transactions";
import {
    incOrDecSkipTxn,
    updateIsTxnLoading,
    updateTxnSkip,
    fetchTransactions,
} from "../../redux/slices/transactionsSlice";
import { Pagination } from "../../components";

export default function TransactionsPage() {
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

    const { accountId } = useSelector((state) => state.user);
    const { isLoading, transactions, totalTransactions, limit, skip } =
        useSelector((state) => state.transactions);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateIsTxnLoading(true));
        dispatch(fetchTransactions());
    }, [skip, accountId, dispatch]);

    return (
        <div className="py-5 sm:py-6">
            <div className="">
                <div className="mb-6">
                    <div>
                        <div className="flex items-center gap-[10px] justify-between ">
                            <h1 className="text-[17px] font-[600]">
                                Transactions
                            </h1>
                            <button
                                className="sm:hidden bg-transparent text-textColor dark:text-darkTextColor h-auto p-0 text-xl"
                                onClick={() =>
                                    setIsFiltersModalOpen(!isFiltersModalOpen)
                                }
                            >
                                <IoFilterSharp />
                            </button>
                        </div>
                    </div>
                    <div
                        className={
                            "sm:mt-3 z-20 sm:z-1 bg-white sm:bg-[#f6f6f6] dark:bg-darkCardColor sm:rounded " +
                            (isFiltersModalOpen
                                ? "fixed inset-0 sm:relative"
                                : "hidden sm:block")
                        }
                    >
                        <TransactionsFilter
                            setIsFiltersModalOpen={setIsFiltersModalOpen}
                        />
                    </div>
                </div>
                {isLoading ? (
                    <div className="flex items-center justify-center h-[200px]">
                        <div className="w-[30px] h-[30px] border-4 border-primaryColor border-r-transparent rounded-full animate-spin "></div>
                    </div>
                ) : !transactions || transactions?.length < 1 ? (
                    <div className="flex flex-col items-center justify-center h-[200px]">
                        <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
                            <BiSearch />
                        </div>
                        <span className="block text-xl font-[600] mt-3">
                            No Result found
                        </span>
                        <span className="block text-sm mt-2 text-grayColor">
                            There are no Transactions yet!!.
                        </span>
                    </div>
                ) : (
                    <div>
                        <TransactionsTable />

                        <Pagination
                            skip={skip}
                            limit={limit}
                            total={totalTransactions}
                            incOrDecSkip={(number) =>
                                dispatch(incOrDecSkipTxn(number))
                            }
                            updateSkip={(skip) => dispatch(updateTxnSkip(skip))}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
