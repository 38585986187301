import React from "react";

import { loadHamiPay } from "../../main";

export default function Test() {
    const handleClick = async () => {
        loadHamiPay({}, () => {
            console.log("object");
        });
    };
    
    return (
        <div>
            <button onClick={handleClick}>Pay With Hamipay</button>
        </div>
    );
}
