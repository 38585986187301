import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { useSelector } from "react-redux";
import RecentTransactionLIst from "./RecentTransactionLIst";

function AdminHomePage() {
  const { jwtToken } = useSelector((state) => state.admin);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalCompletedTransaction, setTotalCompletedTransaction] = useState(0);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [balances, setBalances] = useState([]);

  const fetchInitial = async () => {
    try {
      const res = await axios.get(`/admin/dashboard`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setTotalUserCount(res?.data?.totalUsers);
      setTotalTransactions(res?.data?.totalTransactions);
      setTotalCompletedTransaction(res?.data?.totalCompletedTransactions);
      setRecentTransactions(res?.data?.recnetTransactions);
      setBalances(res?.data?.balances);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchInitial();
  }, []);

  return (
    <div>
      <div className="py-4">
        <div className="grid md:grid-cols-3">
          <div className="dark:bg-darkCardColor shadow-xl w-96 h-40">
            <div className="flex items-center justify-center py-5 px-5">
              <div>
                <h1 className="text-2xl font-bold"> Users</h1>
                <div className="py-2">
                  <h1 className="text-center font-bold text-3xl">
                    {totalUserCount}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="dark:bg-darkCardColor shadow-xl w-96 h-40">
            <div className="flex items-center justify-center py-5 px-5">
              <div>
                <h1 className="text-2xl font-bold">Transactions</h1>
                <div className="py-2">
                  <h1 className="text-center font-bold text-3xl">
                    {totalTransactions}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="dark:bg-darkCardColor shadow-xl w-96 h-40">
            <div className="flex items-center justify-center py-5 px-5">
              <div>
                <h1 className="text-2xl font-bold">Completed Transactions</h1>
                <div className="py-2">
                  <h1 className="text-center font-bold text-3xl">
                    {totalCompletedTransaction}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="grid md:grid-cols-2 gap-4">
          {balances.map((balance, index) => (
            <div
              key={index}
              className="dark:bg-darkCardColor shadow-xl flex items-center p-4 rounded-lg"
            >
              <img
                src={process.env?.REACT_APP_SERVER_URL + balance.currency.logo}
                alt={balance.currency.name}
                className="w-12 h-12 mr-4"
              />
              <div>
                <h2 className="text-lg font-bold">{balance.currency.name}</h2>
                <p className="text-sm text-gray-500">
                  {balance.currency.symbol}
                </p>
              </div>
              <div className="ml-auto">
                <h2 className="text-2xl font-bold">{balance.balance}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pt-5">
        <RecentTransactionLIst recentTransactions={recentTransactions} />
      </div>
    </div>
  );
}

export default AdminHomePage;
