import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import AdminHeader from "../components/Header/AdminHeader";

function AdminMainLayout() {
  return (
    <div>
      <AdminHeader />
      <main className="w-[100%] max-w-[1240px] px-5 mx-auto pb-[4.5em] sm:pb-0">
        <Suspense fallback={<div>Loading.....</div>}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
}

export default AdminMainLayout;
