import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FiChevronDown } from "react-icons/fi";
import axios from "../../../axios";

function AddNetWorkInRouter({ setSelectedNetwork, selectedNetwork }) {
  const [isFetching, setIsFetching] = useState(false);
  const [isAllNetworkDropdownOpen, setIsAllRouterDropdownOpen] =
    useState(false);
  const [networks, setNetworks] = useState([]);
  const { jwtToken } = useSelector((state) => state.admin);

  const fetchNetworks = async () => {
    try {
      setIsFetching(true);
      const res = await axios.get(`/admin/networks/all`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setIsFetching(false);
      setNetworks(res.data);
    } catch (error) {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchNetworks();
  }, []);

  const addAcceptedRouter = (id) => {
    networks?.map((ele) => {
      if (ele._id === id) {
        setSelectedNetwork([...selectedNetwork, ele]);
      }
      return ele;
    });

    let filterAllRouters = networks.filter((rout) => {
      return rout._id !== id;
    });

    setNetworks(filterAllRouters);
  };

  const removeSelectedRouters = (id) => {
    const filteredRouter = selectedNetwork.filter((rout) => {
      if (rout._id !== id) {
        setNetworks([...networks, rout]);
      }
      return rout._id !== id;
    });

    setSelectedNetwork(filteredRouter);
  };

  return (
    <div>
      <div>
        {selectedNetwork?.length > 0 && (
          <div className="flex items-center gap-[10px] flex-wrap mb-3">
            {selectedNetwork?.map((router, index) => {
              return (
                <div
                  key={index}
                  className="border dark:border-darkBorderColor flex items-center gap-[10px] px-2 rounded text-sm bg-[#f6f6f6] dark:bg-darkBgColor py-[4px] cursor-pointer"
                  onClick={() => {
                    removeSelectedRouters(router._id);
                  }}
                >
                  <span>{router?.name}</span>
                  <span>x</span>
                </div>
              );
            })}
          </div>
        )}

        <div className="relative w-full">
          <div
            className="border border-borderColor dark:border-darkBorderColor dark:bg-darkBgColor cursor-pointer w-full flex items-center gap-[10px] justify-between h-[45px] rounded px-[15px]"
            onClick={() => {
              if (!isFetching) {
                setIsAllRouterDropdownOpen(!isAllNetworkDropdownOpen);
              }
            }}
          >
            {isFetching ? (
              <>
                <div className="flex items-center gap-[10px]">
                  <div className="w-[25px] h-[25px] rounded-full bg-slate-300 dark:bg-slate-600 animate-pulse"></div>
                  <span className="h-[14px] w-[100px] bg-slate-300 dark:bg-slate-600 block animate-pulse"></span>
                </div>
                <div className="w-[20px] h-[20px] animate-spin rounded-full border-4 border-r-transparent border-primaryColor"></div>
              </>
            ) : (
              <>
                <div className="flex items-center gap-[10px]">
                  <span className="text-sm">Choose Routers</span>
                </div>
                <div>
                  <FiChevronDown />
                </div>
              </>
            )}
          </div>
          {isAllNetworkDropdownOpen && (
            <div className="absolute top-[100%] z-10 left-0 rounded max-h-[300px] overflow-y-auto w-full bg-white dark:bg-darkBgColor shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
              {networks?.length < 0 && (
                <span className="block py-[12px] text-center text-sm text-grayColor dark:text-darkGrayColor">
                  Empty
                </span>
              )}
              {networks
                ?.filter((curr) => {
                  return !selectedNetwork?.some(
                    (accCurr) => accCurr._id === curr._id
                  );
                })
                ?.map((network, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between transition-all  hover:bg-darkCardHoverColor px-[15px] py-[7.5px] cursor-pointer"
                      onClick={() => {
                        addAcceptedRouter(network?._id);
                        setIsAllRouterDropdownOpen(false);
                      }}
                    >
                      <div className=" text-md">
                        <span>{network?.name}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddNetWorkInRouter;
