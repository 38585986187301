import React from "react";

export default function CurrencyCardSkeleton() {
    return (
        <div className="flex items-start justify-between cursor-pointer bg-gradient-to-r from-[#f4f4f4] via-[#fdfdfd] to-[#f4f4f4] dark:from-darkCardColor dark:to-darkCardColor px-3 py-5 rounded mb-5 last:mb-0 ">
            <div className="flex items-start gap-[10px]">
                <div className="w-[40px] h-[40px] rounded-full overflow-hidden bg-slate-300 dark:bg-slate-600 animate-pulse"></div>
                <div>
                    <span className="block h-[15px] w-[150px] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
                    <span className="block h-[14px] w-[130px] bg-slate-300 dark:bg-slate-600 animate-pulse mt-4"></span>
                    <span className="h-[13px] w-[80px] block mt-[13px] bg-slate-300 dark:bg-slate-600 animate-pulse "></span>
                </div>
            </div>
        </div>
    );
}
