import React from "react";
import { BiLink } from "react-icons/bi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { MdRequestPage } from "react-icons/md";
import { SiFastapi } from "react-icons/si";
import { Link } from "react-router-dom";

function AdminDropdownMenu({ setIsDropdownMenuOpen }) {
  return (
    <div className="absolute top-[49px] rounded left-[50%] translate-x-[-50%] bg-[#fff] dark:bg-darkCardColor w-[520px] shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] z-10">
      <div className="grid grid-cols-2 gap-[1.5em] p-5">
        <div>
          <ul>
            <li className="">
              <Link
                to={"/admin/presales"}
                className={
                  "block py-[10px] px-[10px] rounded hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor "
                }
                onClick={() => {
                  setIsDropdownMenuOpen(false);
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <MdRequestPage />
                  <span className="block font-medium text-[14px]">Presale</span>
                </div>

                <span className=" text-[13px] text-grayColor dark:text-darkGrayColor block mt-1">
                  Customise Presale
                </span>
              </Link>
            </li>
            <li className="">
              <Link
                to={"/admin/networks"}
                className={
                  "block py-[10px] px-[10px] rounded hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor "
                }
                onClick={() => {
                  setIsDropdownMenuOpen(false);
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <MdRequestPage />
                  <span className="block font-medium text-[14px]">
                    Networks
                  </span>
                </div>

                <span className=" text-[13px] text-grayColor dark:text-darkGrayColor block mt-1">
                  Customise Networks
                </span>
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li className="">
              <Link
                to={"/admin/routers"}
                className={
                  "block py-[10px] px-[10px] rounded hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor "
                }
                onClick={() => {
                  setIsDropdownMenuOpen(false);
                }}
              >
                <div className="flex items-center gap-[10px]">
                  <SiFastapi />
                  <span className="block font-medium text-[14px]">Router</span>
                </div>
                <span className=" text-[13px] text-grayColor dark:text-darkGrayColor dar block mt-1">
                  Customise Routers
                </span>
              </Link>
            </li>
          </ul>
          <h3 className="font-[600] mt-5">Apps</h3>
          <div className="flex items-start gap-[20px] mt-3">
            <img
              src="https://www.freepnglogos.com/uploads/google-play-png-logo/google-play-arrow-png-logo-8.png"
              alt=""
              className="w-[25px]"
            />
            <img
              src="https://www.freepnglogos.com/uploads/apple-logo-png/apple-logo-png-dallas-shootings-don-add-are-speech-zones-used-4.png"
              alt=""
              className="w-[25px]"
            />
            <img
              src="https://www.freepnglogos.com/uploads/google-chrome-png-logo/google-chrome-icon-png-logo-1.png"
              alt=""
              className="w-[25px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDropdownMenu;
