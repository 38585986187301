import React, { useState } from "react";
import { Country } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "../../axios";
import { BtnLoader } from "../../components";
import { updateIsMerchantInfoProvided } from "../../redux/slices/userSlice";
import MerchantDetailsForm from "../../features/MerchantForm/components/MerchantDetailsForm";
import { MerchantContactForm } from "../../features/MerchantForm";

export default function MerchantDetailsFormPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [data, setData] = useState({
        businessType: "",
        website: "",
        panNumber: "",
        pincode: "",
        address1: "",
        address2: "",
        country: "",
        state: "",
        city: "",
    });
    const [states, setStates] = useState([]);

    const { jwtToken } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            setError("");

            await axios.post(
                "/merchants/details",
                {
                    ...data,
                    country: Country.getCountryByCode(data.country)?.name,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            setIsLoading(false);
            dispatch(updateIsMerchantInfoProvided(true));
            navigate("/auth/mobile-number");
        } catch (err) {
            console.log(err);
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div className="relative flex py-6 flex-col items-center justify-center min-h-[calc(var(--doc-height)-4.2em)] sm:min-h-[calc(100vh-5em)] max-w-[450px] px-5 mx-auto">
            <h1 className="font-bold text-lg sm:text-xl mb-7">
                Merchant Details
            </h1>

            <form className="w-full" onSubmit={handleSubmit}>
                <MerchantDetailsForm data={data} setData={setData} />
                <div className="mt-7">
                    <MerchantContactForm
                        data={data}
                        setData={setData}
                        states={states}
                        setStates={setStates}
                    />
                </div>

                <div className="mt-5">
                    {error && (
                        <span className="text-red-500 block text-sm font-medium mb-3">
                            {error}
                        </span>
                    )}

                    <button
                        className="w-[100%]  hover:bg-btnHoverColor disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor"
                        disabled={
                            isLoading ||
                            !data.address1 ||
                            !data.businessType ||
                            !data.panNumber ||
                            !data.pincode ||
                            !data.country ||
                            (states.length > 0 && !data.state) ||
                            !data.city
                        }
                    >
                        {isLoading ? <BtnLoader /> : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
}
