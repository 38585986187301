import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";

const fetchPaymentPages = createAsyncThunk(
    "/user/fetchPaymentPages",
    async (args, { getState }) => {
        const { jwtToken, accountId } = getState().user;
        const { filters, sort, skip, limit } = getState().paymentPages;
        const response = await axios.get(`/payment-pages/user/all?accountId=${accountId}&limit=${limit}&skip=${skip}&currency=${filters.currency}&id=${filters.id}&date=${filters.date}&status=${filters.status}&sort=${sort}`, {
            headers: { Authorization: `Bearer ${jwtToken}` },
        });
        console.log(response.data);
        return response.data;
    }
);

const initialState = {
    paymentPages: [],
    isLoading: true,
    totalPaymentPages: 0,
    filters: {
        id: "",
        currency: "all",
        date: "all",
        status: "all",
    },
    sort: "default",
    skip: 0,
    limit: 10,
};

export const paymentPagesSlice = createSlice({
    name: "payment-pages",
    initialState,
    reducers: {
        addNewPaymentPage: (state, action) => {
            state.paymentPages.unshift(action.payload);
        },
        updateIsPaymentPageLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updatePaymentPageFilters: (state, action) => {
            state.filters[action.payload?.name] = action.payload?.value;
            state.skip = 0;
        },
        updatePaymentPageSort: (state, action) => {
            state.sort = action.payload;
            state.skip = 0;
        },
        clearPaymentPageFilters: (state, action) => {
            state.filters = {
                id: "",
                currency: "all",
                date: "all",
                status: "all",
            };
            state.sort = "default";
            state.limit = 10;
            state.skip = 0;
        },
        updatePaymentPageSkip: (state, action) => {
            state.skip = action.payload;
        },
        incOrDecPaymentPageSkip: (state, action) => {
            state.skip += action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPaymentPages.fulfilled, (state, action) => {
            state.paymentPages = action.payload?.paymentPages;
            state.totalPaymentPages = action.payload?.totalPaymentPages;
            state.isLoading = false;
        });
    },
});

export { fetchPaymentPages };

export const {
    addNewPaymentPage,
    clearPaymentPageFilters,
    incOrDecPaymentPageSkip,
    updateIsPaymentPageLoading,
    updatePaymentPageFilters,
    updatePaymentPageSkip,
    updatePaymentPageSort,
} = paymentPagesSlice.actions;

export default paymentPagesSlice.reducer;
