import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { avatarImg } from "../../assets/images";
import { settingsNavLinks } from "../../data";

export default function SettingsPage() {
    const { user } = useSelector((state) => state.user);

    return (
        <div>
            <div className="sm:hidden">
                <div className="text-center">
                    <div className="mx-auto w-[40px] h-[40px] rounded-full overflow-hidden">
                        <img
                            src={
                                user?.avatar
                                    ? process.env.REACT_APP_SERVER_URL +
                                      user?.avatar
                                    : avatarImg
                            }
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <span className="block mt-[10px] font-medium">
                        {user?.name}
                    </span>
                    <span className="block text-sm text-grayColor dark:text-darkGrayColor mt-[2px]">
                        @{user?.username}
                    </span>
                </div>

                <ul className="mt-3">
                    {settingsNavLinks?.map((nav, index) => {
                        return (
                            <li className="" key={index}>
                                <Link
                                    to={nav?.link}
                                    className="flex border-b dark:border-darkBorderColor items-center gap-[5px] py-3 text-[14px] transition-all "
                                >
                                    <span className="flex items-center gap-[10px]">
                                        <span className="text-sm">
                                            {nav.icon}
                                        </span>{" "}
                                        {nav?.name}
                                    </span>{" "}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>

            <span className="text-sm text-grayColor hidden sm:block">/settings not found</span>
        </div>
    );
}
