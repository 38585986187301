import React, { useState } from "react";
import { useRef } from "react";
import { BiGlobe } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";
import { HiMoon, HiOutlineSun } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { avatarImg } from "../assets/images";
import axios from "../axios";
import { useHandleClickOutside } from "../hooks";
import {
    setIsErrorAndReloadModalOpen,
    updateTheme,
} from "../redux/slices/layoutSlice";
import {
    logoutUser,
    setAccountId,
    setCurrentAccount,
} from "../redux/slices/userSlice";

export default function PaymentAccountBar() {
    const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);

    const { isFetching } = useSelector((state) => state.currencies);
    const { theme } = useSelector((state) => state.layout);
    const { user, accounts, currentAccount, jwtToken, isLoggedIn } =
        useSelector((state) => state.user);
    const dispatch = useDispatch();
    const dropdownWrapperRef = useRef();

    useHandleClickOutside(dropdownWrapperRef, () =>
        setIsAccountDropdownOpen(false)
    );

    const changeAccount = async (id) => {
        try {
            if (!isFetching && currentAccount?._id !== id) {
                dispatch(setAccountId(id));

                const response = await axios.get(
                    `/accounts/single/details/${id}`,
                    {
                        headers: { Authorization: `Bearer ${jwtToken}` },
                    }
                );

                dispatch(setCurrentAccount(response?.data));
                localStorage.setItem("current-account", response?.data?._id);
            }
        } catch (err) {
            console.log(err);
            dispatch(setIsErrorAndReloadModalOpen(true));
        }
    };

    const handleThemeChange = () => {
        localStorage.setItem("theme", theme === "light" ? "dark" : "light");
        dispatch(updateTheme(theme === "light" ? "dark" : "light"));
    };

    return (
        <div className="sticky md:fixed top-0 md:top-5 right-0 w-full border-b dark:border-darkBorderColor md:border-b-0 md:w-max h-[4.2em] md:h-[40px] bg-[#fff] dark:bg-darkCardColor md:bg-[#f8f8f8] md:rounded-l-full px-[16px] flex items-center justify-between gap-[15px]">
            <div className="md:hidden">
                <div>
                    <img
                        src="https://app.hami.live/static/media/hamilogo.b98b74641e3caeca8a7d591025e520a8.svg"
                        alt=""
                        className="w-[30px] min-w-[30px]"
                    />
                </div>
            </div>

            <div className="flex items-center justify-between gap-[25px] md:gap-[17px]">
                <button
                    className="p-0 h-auto bg-transparent text-textColor dark:text-darkTextColor text-xl"
                    onClick={handleThemeChange}
                >
                    {theme === "dark" ? <HiOutlineSun /> : <HiMoon />}
                </button>
                <button className="p-0 h-auto bg-transparent text-textColor dark:text-darkTextColor text-xl">
                    <BiGlobe />
                </button>
                {isLoggedIn && (
                    <div className="relative ">
                        <div
                            className="flex items-center gap-[10px]"
                            onClick={() => {
                                setIsAccountDropdownOpen(
                                    !isAccountDropdownOpen
                                );
                            }}
                        >
                            <div className="w-[30px] h-[30px] rounded-full overflow-hidden">
                                <img
                                    src={
                                        user?.avatar
                                            ? process.env.REACT_APP_SERVER_URL +
                                              user?.avatar
                                            : avatarImg
                                    }
                                    alt=""
                                    className="w-[100%] h-[100%] object-cover"
                                />
                            </div>
                            <span className="hidden md:flex font-medium text-sm items-center gap-[5px] cursor-pointer">
                                Account 1 <FiChevronDown />
                            </span>
                        </div>
                        {isAccountDropdownOpen && (
                            <div
                                ref={dropdownWrapperRef}
                                className="bg-white dark:bg-darkCardColor z-10 rounded absolute right-0 top-[38px] min-w-[150px] shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] text-[#212529] dark:text-darkTextColor"
                            >
                                {accounts?.map((account, index) => {
                                    return (
                                        <span
                                            to="/profile"
                                            className={
                                                "flex items-center gap-[10px] text-[13px] py-[6px] px-6 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor " +
                                                (isFetching
                                                    ? "cursor-not-allowed"
                                                    : "cursor-pointer")
                                            }
                                            onClick={() => {
                                                changeAccount(account._id);
                                                setIsAccountDropdownOpen(false);
                                            }}
                                            key={index}
                                        >
                                            {currentAccount?._id ===
                                            account?._id ? (
                                                <span className="block w-[10px] h-[10px] rounded-full bg-primaryColor"></span>
                                            ) : (
                                                <span className="w-[10px]"></span>
                                            )}
                                            {account?.accountName ||
                                                `Account ${index + 1}`}
                                        </span>
                                    );
                                })}

                                <div className="border-t dark:border-darkBorderColor">
                                    <Link
                                        to="/"
                                        className="border-borderColor flex items-center gap-[10px] cursor-pointer text-[13px] py-[6px] px-6 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                                    >
                                        Back Home
                                    </Link>
                                    <span
                                        className="border-borderColor flex items-center gap-[10px] cursor-pointer text-[13px] py-[6px] px-6 transition-all hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                                        onClick={() => dispatch(logoutUser())}
                                    >
                                        Logout
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
