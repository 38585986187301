import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function PresalePageTable({ presales }) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="w-[100%]">
          <thead className="text-left bg-[#f6f6f6] dark:bg-darkCardColor">
            <tr className="text-[13px]">
              <th className="p-[12px] font-[600]">ID</th>
              <th className="p-[12px] font-[600]">Token Name</th>
              <th className="p-[12px] font-[600]">Token Symbol</th>
              <th className="p-[12px] font-[600]">Token Supply</th>
              <th className="p-[12px] font-[600]">Percentage of Sale</th>
              <th className="p-[12px] font-[600]">Percentage of Vesting</th>
            </tr>
          </thead>
          <tbody>
            {presales?.map((item, index) => {
              return (
                <tr
                  className="border-b  dark:border-darkBorderColor text-[13px] hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                  key={index}
                >
                  <td
                    onClick={() => {
                      navigate(`/presale-single/${item?.id}`);
                    }}
                    className="text-blue-600 cursor-pointer px-[12px] py-[19px] max-w-max min-w-[200px]"
                  >
                    {item?.id}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.token_name}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.token_symbol}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.total_supply}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.percentage_for_sale}%
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.percentage_for_vesting}%
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PresalePageTable;
