import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { avatarImg } from "../../../assets/images";

export default function HomeSendAgainCard() {
    const { recentTransfers, isHomeDataFetching } = useSelector(
        (state) => state.user
    );

    return (
        <div>
            <h2 className="font-[600] text-lg mb-4">Send Again</h2>
            <div>
                {isHomeDataFetching ? (
                    <div className="flex items-center gap-[1em]">
                        {Array.from({ length: 4 }).map((_, index) => {
                            return (
                                <div
                                    key={index}
                                    className="w-[40px] h-[40px] min-w-[40px] min-h-[40px] bg-slate-300 dark:bg-slate-600 animate-pulse rounded overflow-hidden"
                                    style={{ zIndex: -1 }}
                                ></div>
                            );
                        })}
                    </div>
                ) : recentTransfers.length < 1 ? (
                    <div>
                        <Link
                            to="/transfer"
                            className="w-[40px] h-[40px] min-w-[40px] min-h-[40px] rounded overflow-hidden bg-[#e5e7eb] dark:bg-darkCardColor dark:text-darkTextColor dark:hover:bg-primaryColor flex items-center justify-center text-[18px] text-textColor transition-all hover:bg-primaryColor hover:text-white cursor-pointer"
                        >
                            <AiOutlinePlus />
                        </Link>
                    </div>
                ) : (
                    <div className="flex items-center gap-[1em] overflow-x-auto">
                        {recentTransfers?.map((user, index) => {
                            return (
                                <Link
                                    key={index}
                                    to={`/transfer?username=${user?.username}`}
                                    className="w-[40px] h-[40px] min-w-[40px] min-h-[40px] rounded overflow-hidden"
                                >
                                    <img
                                        src={
                                            user?.avatar
                                                ? process.env
                                                      ?.REACT_APP_SERVER_URL +
                                                  user?.avatar
                                                : avatarImg
                                        }
                                        alt=""
                                        className="w-[100%] h-[100%] object-cover"
                                    />
                                </Link>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
