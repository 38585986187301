import { HeaderLayout, MainLayout } from "../layouts";
import {
  AdminLoggedInRoute,
  LoggedInRoute,
  MerchantOnlyRoute,
  UserOnlyRoute,
} from "./PrivateRoute";
import LoginPage from "../pages/auth/LoginPage";
import RegisterPage from "../pages/auth/RegisterPage";
import VerifyEmailPage from "../pages/auth/VerifyEmailPage";
import ConfirmEmailPage from "../pages/auth/ConfirmEmailPage";
import Settings from "../components/Settings";
import PaymentConfirmPage from "../pages/common/PaymentConfirmPage";
import MerchantHomePage from "../pages/merchant/MerchantHomePage";
import HomePage from "../pages/personal/HomePage";
import PaymentLinksPage from "../pages/merchant/PaymentLinksPage";
import CurrenciesPage from "../pages/common/CurrenciesPage";
import TransferPage from "../pages/common/TransferPage";
import PaymentPagesPage from "../pages/merchant/PaymentPagesPage";
import TransactionsPage from "../pages/common/TransactionsPage";
import WithdrawPage from "../pages/common/WithdrawPage";
import ProfilePage from "../pages/personal/ProfilePage";
import AccountsPage from "../pages/common/AccountsPage";
import KycVerificationPage from "../pages/common/KycVerificationPage";
import SinglePaymentLinkPage from "../pages/common/SinglePaymentLinkPage";
import MerchantRegisterPage from "../pages/auth/MerchantRegisterPage";
import AccountTypeSelectPage from "../pages/auth/AccountTypeSelectPage";
import MerchantDetailsFormPage from "../pages/auth/MerchantDetailsFormPage";
import ForgetPasswordPage from "../pages/auth/ForgetPasswordPage";
import PasswordSecurityPage from "../pages/common/PasswordSecurityPage";
import PinNumberSettingPage from "../pages/common/PinNumberSettingPage";
import NotificationsSettingsPage from "../pages/common/NotificationsSettingsPage";
import DepositPage from "../pages/common/DepositPage";
import ReceivePage from "../pages/common/ReceivePage";
import MobileNumberVerifyPage from "../pages/auth/MobileNumberVerifyPage";
import SinglePaymentPagePage from "../pages/common/SinglePaymentPagePage";
import NotificationsPage from "../pages/common/NotificationsPage";
import SwapPage from "../pages/common/SwapPage";
import MerchantUpgradePage from "../pages/personal/MerchantUpgradePage";
import {
  EmailVerifyRoute,
  MerchantDetailsRoute,
  MobileNumberRoute,
  RegisterRoute,
} from "./AuthRoutes";
import PaymentPageDetailsPage from "../pages/merchant/PaymentPageDetailsPage";
import InvoicesPage from "../pages/merchant/InvoicesPage";
import CreateInvoicePage from "../pages/merchant/CreateInvoicePage";
import SingleInvoicePage from "../pages/common/SingleInvoicePage";
import Test from "../pages/personal/Test";
import SettingsPage from "../pages/common/SettingsPage";
import PresalePage from "../pages/merchant/PresalePage";
import PresalePageDetails from "../pages/merchant/PresalePageDetails";
import PresalePageSingleView from "../pages/merchant/PresalePageSingleView";
import PaymentPage from "../features/PresalePage/components/PayementPage";
import AdminHeaderLayout from "../layouts/AdminHeaderLayout";
import AdminMainLayout from "../layouts/AdminMainLayout";
import AdminLogin from "../pages/auth/AdminLogin";
import AdminHomePage from "../pages/adminHome/AdminHomePage";
import AdminPage from "../pages/admin/admins/AdminPage";
import AdmUsersPage from "../pages/admin/users/AdmUsersPage";
import AdmCurrenciesPage from "../pages/admin/currencies/AdmCurrenciesPage";
import AdmRouterPages from "../pages/admin/routers/AdmRouterPages";
import AdmNetworkPage from "../pages/admin/networks/AdmNetworkPage";
import AdmPresalePage from "../pages/admin/presales/AdmPresalePage";
import MerchantAccountDetails from "../pages/common/MerchantAccountDetails";

const ThemeRoutes = [
  {
    path: "",
    element: (
      <LoggedInRoute>
        <MainLayout />
      </LoggedInRoute>
    ),
    children: [
      { path: "", element: <HomePage /> },
      {
        path: "/payment-links",
        element: (
          // <MerchantOnlyRoute>
          <PaymentLinksPage />
          // {/* </MerchantOnlyRoute> */}
        ),
      },
      { path: "/currencies", element: <CurrenciesPage /> },
      { path: "/transfer", element: <TransferPage /> },
      {
        path: "/payment-pages",
        element: (
          <MerchantOnlyRoute>
            <PaymentPagesPage />
          </MerchantOnlyRoute>
        ),
      },
      { path: "/transactions", element: <TransactionsPage /> },
      { path: "/withdraw", element: <WithdrawPage /> },
      { path: "/deposit", element: <DepositPage /> },
      { path: "/receive", element: <ReceivePage /> },
      { path: "/swap", element: <SwapPage /> },
      {
        path: "/upgrade/merchant",
        element: (
          <UserOnlyRoute>
            <MerchantUpgradePage />
          </UserOnlyRoute>
        ),
      },
      { path: "/notifications", element: <NotificationsPage /> },
      {
        path: "/invoices",
        element: (
          <MerchantOnlyRoute>
            <InvoicesPage />
          </MerchantOnlyRoute>
        ),
      },
      {
        path: "/create-invoice",
        element: (
          <MerchantOnlyRoute>
            <CreateInvoicePage />
          </MerchantOnlyRoute>
        ),
      },
      {
        path: "/payment-pages/:id",
        element: (
          <MerchantOnlyRoute>
            <PaymentPageDetailsPage />
          </MerchantOnlyRoute>
        ),
      },
      {
        path: "settings",
        element: <Settings />,
        children: [
          { path: "", element: <SettingsPage /> },
          { path: "profile", element: <ProfilePage /> },
          { path: "accounts", element: <AccountsPage /> },
          // { path: "accounts/import", element: <ImportAccountPage /> },
          {
            path: "kyc-verification",
            element: <KycVerificationPage />,
          },
          { path: "password", element: <PasswordSecurityPage /> },
          {
            path: "pin",
            element: <PinNumberSettingPage />,
          },
          {
            path: "notifications",
            element: <NotificationsSettingsPage />,
          },
          {
            path: "merchant",
            element: <MerchantAccountDetails />,
          },
        ],
      },
      {
        path: "/presale/create",
        element: (
          <MerchantOnlyRoute>
            <PresalePage />
          </MerchantOnlyRoute>
        ),
      },
      {
        path: "/presale-page",
        element: (
          <MerchantOnlyRoute>
            <PresalePageDetails />
          </MerchantOnlyRoute>
        ),
      },
      {
        path: "/presale-single/:id",
        element: (
          <MerchantOnlyRoute>
            <PresalePageSingleView />
          </MerchantOnlyRoute>
        ),
      },
    ],
  },
  {
    path: "/test",
    element: <Test />,
  },
  {
    path: "/link/:id",
    element: <SinglePaymentLinkPage />,
  },
  {
    path: "/page/:id",
    element: <SinglePaymentPagePage />,
  },
  {
    path: "/payment-page/:id",
    element: <PaymentPage />,
  },
  { path: "/inv/:id", element: <SingleInvoicePage /> },
  {
    path: "/transfer/confirm",
    element: <PaymentConfirmPage />,
  },
  {
    path: "/merchant",
    element: <MerchantHomePage />,
  },
  {
    path: "auth",
    element: <HeaderLayout />,
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "register",
        element: (
          <RegisterRoute>
            <RegisterPage />
          </RegisterRoute>
        ),
      },
      {
        path: "verify-email",
        element: (
          <EmailVerifyRoute>
            <VerifyEmailPage />
          </EmailVerifyRoute>
        ),
      },
      {
        path: "confirm-email",
        element: (
          <EmailVerifyRoute>
            <ConfirmEmailPage />
          </EmailVerifyRoute>
        ),
      },
      {
        path: "register/merchant",
        element: (
          <RegisterRoute>
            <MerchantRegisterPage />
          </RegisterRoute>
        ),
      },
      {
        path: "select-account-type",
        element: (
          <RegisterRoute>
            <AccountTypeSelectPage />
          </RegisterRoute>
        ),
      },
      {
        path: "merchant/details",
        element: (
          <MerchantDetailsRoute>
            <MerchantDetailsFormPage />
          </MerchantDetailsRoute>
        ),
      },
      {
        path: "forget-password",
        element: (
          <RegisterRoute>
            <ForgetPasswordPage />
          </RegisterRoute>
        ),
      },
      {
        path: "mobile-number",
        element: (
          <MobileNumberRoute>
            <MobileNumberVerifyPage />
          </MobileNumberRoute>
        ),
      },
    ],
  },
  {
    path: "admin",
    element: (
      <AdminLoggedInRoute>
        <AdminMainLayout />
      </AdminLoggedInRoute>
    ),
    children: [
      {
        path: "dashboard",
        element: <AdminHomePage />,
      },
      {
        path: "admins",
        element: <AdminPage />,
      },
      {
        path: "users",
        element: <AdmUsersPage />,
      },
      {
        path: "currencies",
        element: <AdmCurrenciesPage />,
      },
      {
        path: "routers",
        element: <AdmRouterPages />,
      },
      {
        path: "networks",
        element: <AdmNetworkPage />,
      },
      {
        path: "presales",
        element: <AdmPresalePage />,
      },
    ],
  },
  {
    path: "admin/auth",
    element: <AdminHeaderLayout />,
    children: [
      {
        path: "login",
        element: <AdminLogin />,
      },
    ],
  },
];

export default ThemeRoutes;
