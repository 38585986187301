const getPrice = (prices, currency) => {
    let price = 0;
    for (let i = 0; i < prices?.length; i++) {
        if (currency.symbol === prices[i]?.symbol) {
            price = prices[i]?.price_usd;
            break;
        }
    }

    return Number(price);
};

export default getPrice;
