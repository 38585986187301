export const AdminMenus = [
  {
    name: "Dashboard",
    link: "/admin/dashboard",
  },
  {
    name: "Users",
    link: "/admin/users",
  },
  {
    name: "Currency",
    link: "/admin/currencies",
  },
  {
    name: "Admins",
    link: "/admin/admins",
  },
];
