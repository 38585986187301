import React from "react";
import { formatDate } from "../../../utils";
import { BiLink } from "react-icons/bi";

function PresalPageDetailBasicInfo({ presale, setPresale }) {
  return (
    <div>
      <div>
        <div className={`${presale.logo ? "flex justify-between gap-3" : ""}`}>
          <h1 className="font-[600] text-lg">{presale?.token_name}</h1>
        </div>
        <table className="w-[100%] mt-4">
          <tbody>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Token Address
              </td>
              <td className="text-sm  font-medium py-3 pl-[10px]">
                {presale?.token_address}
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Token Symbol
              </td>
              <td className="text-sm  font-medium py-3 pl-[10px]">
                {presale.token_symbol}
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Token Supply
              </td>
              <td className="text-sm  font-medium py-3 pl-[10px]">
                {presale.total_supply}
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Link Type
              </td>
              <td className="text-sm font-medium py-3 flex gap-[7px] pl-[10px]">
                <span className="text-lg">
                  <BiLink />
                </span>
                Payment Page
              </td>
            </tr>
            <tr>
              <td className="flex text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Selected Currenices
              </td>
              <td className="text-sm font-medium py-3 pl-[10px]">
                <div className="flex flex-wrap items-center gap-[10px]">
                  {presale?.currency?.map((currency, index) => {
                    return (
                      <div
                        key={index}
                        className="py-[5px] rounded px-[5px] bg-[#f6f6f6] dark:bg-darkCardColor flex items-center gap-[6px]"
                      >
                        <img
                          src={
                            process.env.REACT_APP_SERVER_URL + currency?.logo
                          }
                          alt=""
                          className="w-[20px] h-[20px] rounded-full object-cover"
                        />
                        <span>{currency?.symbol}</span>
                      </div>
                    );
                  })}
                </div>
              </td>
            </tr>

            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Vesting
              </td>

              <td className="text-xs  font-medium py-3 pl-[10px]">
                {presale.isVesting === true ? (
                  <span className="bg-green-300 p-1 border border-white text-black">
                    {" "}
                    Active{" "}
                  </span>
                ) : (
                  <span className="bg-red-300 p-1 border border-white text-black">
                    {" "}
                    Not Active
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Router
              </td>
              <td className="text-sm  font-medium py-3 pl-[10px]">
                {presale?.router?.map((ele, ind) => (
                  <span key={ind}>{ele?.title}</span>
                ))}
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Fee Options
              </td>
              <td className="text-sm font-medium py-3 pl-[10px]">
                {presale?.fee_options}%
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Liquidity Price
              </td>
              <td className="text-sm font-medium py-3 pl-[10px]">
                {presale?.liquidity_price}
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Liquidity Locup
              </td>
              <td className="text-sm font-medium py-3 pl-[10px]">
                {presale?.liquidity_locup}
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Affiliate Program
              </td>

              <td className="text-xs  font-medium py-3 pl-[10px]">
                {presale.affiliate_program === true ? (
                  <span className="bg-green-300 p-1 border border-white text-black">
                    {" "}
                    Active{" "}
                  </span>
                ) : (
                  <span className="bg-red-300 p-1 border border-white text-black">
                    {" "}
                    Not Active
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Pay Symbol
              </td>
              <td className="text-sm font-medium py-3 pl-[10px]">
                {presale?.pay_symbol}
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Percentage for Sale
              </td>
              <td className="text-sm font-medium py-3 pl-[10px]">
                {presale?.percentage_for_sale}%
              </td>
            </tr>
            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Percentage for Vesting
              </td>
              <td className="text-sm   font-medium py-3 pl-[10px]">
                {presale?.percentage_for_vesting}%
              </td>
            </tr>

            <tr>
              <td className="text-sm text-grayColor dark:text-darkGrayColor py-3 pr-[10px]">
                Percentage for Vesting
              </td>
              <td className="text-sm   font-medium py-3 pl-[10px]">
                {presale?.percentage_for_vesting}%
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <div className="mb-4">
            <h1>UTC</h1>
          </div>

          <div className="grid md:grid-cols-3 gap-4">
            {presale.UTC?.map((ele, index) => (
              <div
                key={index}
                className="bg-white dark:bg-darkCardColor shadow-lg border dark:border-gray-600  border-gray-300 rounded-lg"
              >
                {ele?.isActive === true && ele?.isCompleted === false ? (
                  <div className=" mb-2 flex justify-end text-green-800 rounded-tr-lg">
                    <h1 className="text-xs w-24 h-4 text-white bg-primaryColor text-center rounded-tr-lg">
                      {" "}
                      Active Round{" "}
                    </h1>
                  </div>
                ) : (
                  <div className=" mb-2 flex justify-end text-green-800 rounded-tr-lg">
                    <h1 className="text-xs w-24 h-4 text-white bg-red-700 text-center rounded-tr-lg">
                      {" "}
                      Completed{" "}
                    </h1>
                  </div>
                )}
                <div className="p-3">
                  <div className="mb-1">
                    <span className="text-sm text-grayColor dark:text-darkGrayColor">
                      Start Date
                    </span>
                    <p className="text-sm font-medium">
                      {formatDate(ele?.start_time)}
                    </p>
                  </div>
                  <div className="mb-1">
                    <span className="text-sm text-grayColor dark:text-darkGrayColor">
                      End Date
                    </span>
                    <p className="text-sm font-medium">
                      {formatDate(ele?.end_time)}
                    </p>
                  </div>
                  <div className="mb-1">
                    <span className="text-sm text-grayColor dark:text-darkGrayColor">
                      Presale Price
                    </span>
                    <p className="text-sm font-medium">{ele?.presale_price}</p>
                  </div>
                  <div className="mb-1">
                    <span className="text-sm text-grayColor dark:text-darkGrayColor">
                      Token Price
                    </span>
                    <p className="text-sm font-medium">{ele?.token_price}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <br />
        <br />
      </div>
    </div>
  );
}

export default PresalPageDetailBasicInfo;
