import React from "react";
import { VscClose } from "react-icons/vsc";

import CurrenciesTable from "./CurrenciesTable";

export default function AddCurrencyModal({ setIsAddCurrencyModalOpen }) {
    // const [isLoading, setIsLoading] = useState(false);

    return (
        <div
            className="fixed top-0 left-0 right-0 bottom-0 bg-[#0007] z-20 flex items-center justify-center"
            id="add-wallet-modal"
            onClick={(e) => {
                if (e.target === document.getElementById("add-wallet-modal")) {
                    setIsAddCurrencyModalOpen(false);
                }
            }}
        >
            <div className="bg-[#fff] w-[100%] max-w-[450px] h-[550px] max-h-[90%] rounded overflow-y-auto">
                <div className="flex items-center justify-between bg-white border-b border-borderColor px-4 py-3 sticky top-0 z-10">
                    <h3 className="font-medium">Add Wallet</h3>
                    <button
                        className="bg-transparent text-textColor p-0 text-xl"
                        onClick={() => setIsAddCurrencyModalOpen(false)}
                    >
                        <VscClose />
                    </button>
                </div>

                <div className="px-4 flex items-center gap-[10px] mt-2">
                    <input type="text" placeholder="Search Currency here..." />
                    <button>Search</button>
                </div>

                <div className="pt-1">
                    <CurrenciesTable />
                </div>
            </div>
        </div>
    );
}
