import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils";

export default function PaymentPagesTable() {
    const { paymentPages } = useSelector((state) => state.paymentPages);

    return (
        <div className="overflow-x-auto">
            <table className="w-[100%]">
                <thead className="text-left bg-[#f6f6f6] dark:bg-darkCardColor">
                    <tr className="text-[13px]">
                        <th className="p-[12px] font-[600]">Payment Page Id</th>
                        <th className="font-[600] p-[12px]">Created Date</th>
                        <th className="font-[600] p-[12px]">Title</th>
                        <th className="font-[600] p-[12px]">Amount</th>
                        <th className="font-[600] p-[12px]">Payment Expiry</th>
                        <th className="font-[600] p-[12px]">Payment Link</th>
                        <th className="font-[600] p-[12px]">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {paymentPages?.map((item, index) => {
                        return (
                            <tr
                                className="border-b dark:border-darkBorderColor text-[13px] hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                                key={index}
                            >
                                <td className="pl-[2px] pr-[12px] py-[19px] underline text-blueColor cursor-pointer">
                                    <Link to={`${item?.id}`}>{item?.id}</Link>
                                </td>
                                <td className="px-[12px] py-[19px]">
                                    {formatDate(item?.createdAt)}
                                </td>
                                <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                                    {item?.title}
                                </td>
                                <td className="px-[12px] py-[19px] whitespace-nowrap">
                                    {item.amountType === "fixed"
                                        ? `${item?.amount} ${item?.currency?.symbol}`
                                        : `No Fixed`}
                                </td>
                                <td className="px-[12px] py-[19px] whitespace-nowrap">
                                    {item?.expiryDate
                                        ? formatDate(item?.expiryDate)
                                        : "No Expiry"}
                                </td>
                                <td className="px-[12px] py-[19px]">
                                    <a
                                        href={`${process.env.REACT_APP_CLIENT_URL}/page/${item?.id}`}
                                        target="blank"
                                        className="text-primaryColor"
                                    >
                                        {`https://...${item?.id?.slice(0, 8)}`}
                                    </a>
                                </td>
                                <td className="pr-[2px] pl-[12px] py-[19px]">
                                    <span
                                        className={
                                            "text-[11px] rounded px-[4.5px] py-[2.7px] " +
                                            (item?.isActive === true
                                                ? "bg-[#daf4eb] text-[#34c38f]"
                                                : "text-[#f46a6a] bg-[#fde4e4]")
                                        }
                                    >
                                        {item?.isActive === true
                                            ? "Active"
                                            : "Disabled"}
                                    </span>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
