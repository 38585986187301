import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clearInvoiceFilters,
    fetchInvoices,
    updateInvoiceFilters,
    updateInvoiceLoading,
    updateInvoiceSort,
} from "../../../redux/slices/invocesSlice";

export default function InvoicesFilter({ setIsFiltersModalOpen }) {
    const { currencies } = useSelector((state) => state.currencies);
    const { filters, sort } = useSelector((state) => state.invoices);
    const dispatch = useDispatch();

    const handleFilterChange = (e) => {
        dispatch(
            updateInvoiceFilters({
                name: e.target?.name,
                value: e.target?.value,
            })
        );
    };

    return (
        <div className="sm:flex flex-wrap items-end gap-[15px] p-5 h-[100%] overflow-y-auto">
            <div>
                <label htmlFor="">Invoice No</label>
                <input
                    type="text"
                    placeholder="Reference No"
                    name="invoiceNo"
                    onChange={handleFilterChange}
                    value={filters.invoiceNo || ""}
                    className="arrow-hidden"
                />
            </div>
            <div className="mt-4 sm:mt-0">
                <label htmlFor="">Accepted Currency</label>
                <select
                    name="currency"
                    id=""
                    className="w-[100%] sm:w-[180px]"
                    onChange={handleFilterChange}
                    value={filters.currency || ""}
                >
                    <option value="all">All</option>
                    {currencies?.map((currency, index) => {
                        return (
                            <option key={index} value={currency?._id}>
                                {currency?.symbol}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className="mt-4 sm:mt-0">
                <label htmlFor="">Slect Issue Date</label>
                <select
                    name="issueDate"
                    id=""
                    className="w-[100%] sm:w-[180px]"
                    onChange={handleFilterChange}
                    value={filters.issueDate || ""}
                >
                    <option value="all">All</option>
                    <option value="7">Last Weak</option>
                    <option value="30">Last Month</option>
                    <option value="360">Last Year</option>
                </select>
            </div>
            <div className="mt-4 sm:mt-0">
                <label htmlFor="">Customer</label>
                <input
                    type="text"
                    placeholder="Customer Email"
                    name="customer"
                    onChange={handleFilterChange}
                    value={filters.customer || ""}
                />
            </div>
            <div className="mt-4 sm:mt-0">
                <label htmlFor="">Select Status</label>
                <select
                    name="status"
                    id=""
                    className="w-[100%] sm:w-[180px]"
                    onChange={handleFilterChange}
                    value={filters.status || ""}
                >
                    <option value="all">All</option>
                    <option value="paid">Paid</option>
                    <option value="issued">Issued</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="expired">Expired</option>
                </select>
            </div>
            <div className="mt-4 sm:mt-0">
                <label htmlFor="">Sort</label>
                <select
                    name="sort"
                    id=""
                    className="w-[100%] sm:w-[200px]"
                    onChange={(e) => {
                        dispatch(updateInvoiceSort(e.target.value));
                    }}
                    value={sort || ""}
                >
                    <option value="default">Default</option>
                    <option value="createdAt:asc">
                        Issue Date (Ascending)
                    </option>
                    <option value="createdAt:desc">
                        Issue Date (Descending)
                    </option>
                </select>
            </div>
            <div className="sm:ml-3 flex items-center justify-end sm:justify-start mt-5 sm:mt-0 gap-3">
                <button
                    className="border border-borderColor dark:border-darkBorderColor text-textColor dark:text-darkTextColor bg-transparent"
                    onClick={() => {
                        dispatch(clearInvoiceFilters());
                        dispatch(updateInvoiceLoading(true));
                        dispatch(fetchInvoices());
                        setIsFiltersModalOpen(false);
                    }}
                >
                    Clear
                </button>
                <button
                    className="hover:bg-btnHoverColor"
                    onClick={() => {
                        dispatch(updateInvoiceLoading(true));
                        dispatch(fetchInvoices());
                        setIsFiltersModalOpen(false);
                    }}
                >
                    Search
                </button>
            </div>
        </div>
    );
}
