import { useSelector } from "react-redux";

const useBalance = (currencyId) => {
    const { balances } = useSelector((state) => state.currencies);

    let balance = 0;
    for (let i = 0; i < balances?.length; i++) {
        if (balances[i].currency === currencyId) {
            balance = balances[i]?.balance || 0;
            break;
        }
    }

    console.log("BALANCE IS ", balance);

    return balance;
};

export default useBalance;
