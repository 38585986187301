const loadHamiPay = ({ type, referenceno, amount, currencyId }, callback) => {
    // if (!type || !referenceno || !currencyId || !amount) {
    //     callback(
    //         "Receipient email, account, amount and curreny id is required",
    //         null
    //     );
    //     return;
    // }

    const win = window.open(
        `http://localhost:3000/transfer/confirm?&amount=${amount}&currencyId=${currencyId}`,
        "mywindow",
        `menubar=1,resizable=1,directories=0,left=${
            window.screen.width - 20
        },top=0,width=330,height=520`
    );

    // channel.addEventListener("message", (event) => {
    //     if (event.data?.error) {
    //         callback(event.data?.error, null);
    //         return win.close();
    //     } else {
    //         const { txnHash, txnId } = event.data;

    //         // check transaction with transaction hash
    //         const response = fetch("");

    //         if (response) {
    //             callback(null, event.data);
    //         } else {
    //             callback(
    //                 "Payment Failed. May be this is because of internet failure. Please check transactions page to know what happened",
    //                 null
    //             );
    //         }
    //         return win.close();
    //     }
    // });

    console.log("Hi");
    win.addEventListener("close", () => {
        console.log("Window closed");
    });

    const timer = setInterval(() => {
        if (win.closed) {
            console.log("Hello Window closed");
            callback(
                "You closed payment window. please check transactions page to see your transactions staus",
                null
            );
            clearInterval(timer);
        }
    }, 500);
};

export { loadHamiPay };
