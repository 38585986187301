import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { formatDate } from "../../../utils";
function SinglePaymentPage({ paymentPage }) {
  const [isShowMore, setIsShowMore] = useState(false);
  const [isPaymentDetailsDropdownOpen, setIsPaymentDetailsDropdownOpen] =
    useState(false);

  return (
    <div>
      <div className="flex flex-col gap-[2em]">
        <div className="relative flex-1">
          <div
            className="block md:hidden sticky top-[4.2em] left-0 w-full"
            onClick={() =>
              setIsPaymentDetailsDropdownOpen(!isPaymentDetailsDropdownOpen)
            }
          >
            <div className="bg-[#f6f6f6] dark:bg-darkCardHoverColor p-5 flex items-center justify-between">
              <div className="flex items-center gap-[10px]">
                <h3 className="font-[600] text-[17px]">{paymentPage?.title}</h3>
              </div>
              <div>
                <FiChevronDown />
              </div>
            </div>
          </div>

          <div
            className={
              "absolute md:relative top-[100%] md:top-auto p-5 md:p-0 left-0 md:left-auto w-[100%] z-10 bg-[#fff] dark:bg-darkCardColor md:bg-transparent shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] md:shadow-none " +
              (isPaymentDetailsDropdownOpen
                ? "h-auto block"
                : "h-0 hidden md:h-auto md:block")
            }
          >
            <h3 className="hidden md:block font-[600] text-[20px]">
              {paymentPage?.title}
            </h3>
            <div className="md:mt-2">
              <span className="text-[14px] text-grayColor dark:text-darkGrayColor leading-[28px]">
                {isShowMore
                  ? paymentPage?.description
                  : paymentPage?.description?.slice(0, 140)}{" "}
                {paymentPage?.description?.length > 140 && (
                  <span
                    className="font-medium text-primaryColor cursor-pointer ml-[6px]"
                    onClick={() => {
                      setIsShowMore(!isShowMore);
                    }}
                  >
                    {isShowMore ? "show less" : "show more"}
                  </span>
                )}
              </span>
            </div>
            <div className="mt-4">
              <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                Amount
              </span>
              <span
                className={
                  "block " +
                  (paymentPage.amountType === "fixed"
                    ? "text-xl font-bold"
                    : "text-base font-medium")
                }
              >
                {paymentPage?.amountType === "fixed"
                  ? `${paymentPage?.amount} ${paymentPage?.currency?.symbol}`
                  : "undisclosed"}
              </span>
            </div>
            <div className="mt-4">
              <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                Accepted Currencies
              </span>
              <div className="flex flex-wrap items-center gap-[10px] md:gap-[20px] mt-[7px]">
                {paymentPage?.currency?.map((currency, index) => {
                  return (
                    <div
                      key={index}
                      className="py-[5px] rounded px-[5px] md:px-0 bg-[#f6f6f6] dark:bg-darkCardHoverColor dark:md:bg-transparent md:bg-transparent flex items-center gap-[6px]"
                    >
                      <img
                        src={process.env.REACT_APP_SERVER_URL + currency?.logo}
                        alt=""
                        className="w-[20px] h-[20px] rounded-full object-cover"
                      />
                      <span>{currency?.symbol}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-4">
              <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                Expiry Date
              </span>
              <span className="text-[15px] font-medium">
                {paymentPage?.expiryDate
                  ? formatDate(paymentPage?.expiryDate)
                  : "No Expiry"}
              </span>
            </div>
            <div className="mt-4">
              <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                Name
              </span>
              <span className="text-[15px] font-medium">
                {paymentPage?.userId?.name}
              </span>
            </div>
            <div className="mt-4">
              <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                Email
              </span>
              <span className="text-[15px] font-medium">
                {paymentPage?.userId?.email}
              </span>
            </div>
          </div>
        </div>

        <div className="hidden md:flex items-center gap-[10px] text-[12px]">
          <span>
            Powered by{" "}
            <span className="font-[600] text-sm">
              Simple
              <span className="text-primaryColor">pay</span>
            </span>
          </span>
          <span className="text-grayColor">|</span>
          <span>Terms</span>
          <span>Privacy</span>
        </div>
      </div>
    </div>
  );
}

export default SinglePaymentPage;
