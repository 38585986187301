import React from "react";

export default function HomeActivitiesTableSkeleton() {
    return (
        <table className="w-full">
            <tbody className="">
                {Array.from({ length: 6 })?.map((_, index) => {
                    return (
                        <tr
                            key={index}
                            className="flex items-center justify-between py-[10px]"
                        >
                            <td className="pr-[10px]">
                                <div className="flex items-center gap-[1.4em]">
                                    <div className="w-[35px] h-[35px] rounded bg-slate-300 dark:bg-slate-600 animate-pulse overflow-hidden"></div>
                                    <div>
                                        <h4 className="font-[500] h-[15px] w-[120px] bg-slate-300 dark:bg-slate-600 animate-pulse"></h4>
                                        <span className="block h-[12px] mt-1 w-[60px] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
                                    </div>
                                </div>
                            </td>
                            <td className="px-[10px]">
                                <div className="flex items-center gap-[10px]">
                                    <div className="w-[20px] h-[20px] rounded-full bg-slate-300 dark:bg-slate-600 animate-pulse overflow-hidden"></div>
                                    <div>
                                        <span className="block w-[80px] h-[15px] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
                                    </div>
                                </div>
                            </td>
                            <td className="px-[10px] text-right">
                                <span className="block h-[15px] w-[80px] bg-slate-300 dark:bg-slate-600 animate-pulse "></span>
                            </td>
                            <td className="pl-[10px]">
                                <span className="block h-[14px] w-[120px] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
