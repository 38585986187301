import { createSlice } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";

const initialState = {
    balanceInUSD: 0,
    currencies: [],
    isFetching: true,
    isBalanceFetching: false,
    isPriceFetching: true,
    prices: [],
    balances: [],
};

export const currenciesSlice = createSlice({
    name: "currencies",
    initialState,
    reducers: {
        setCurrencies: (state, action) => {
            state.currencies = action.payload?.currencies;
            state.balances = action?.payload?.balances;
            state.balanceInUSD = 0;
            state.prices = action.payload?.prices;

            state.currencies = state.currencies?.filter((currency) => {
                for (let j = 0; j < state.balances?.length; j++) {
                    if (currency._id === state.balances[j].currency) {
                        currency.balance = state.balances[j]?.balance || "0";
                    }
                }
                for (let i = 0; i < state.prices.length; i++) {
                    if (currency?.symbol === state.prices[i]?.symbol) {
                        currency.price = state.prices[i];
                        if (!isNaN(currency?.balance)) {
                            state.balanceInUSD = BigNumber(currency.balance)
                                .multipliedBy(currency?.price?.price_usd)
                                .plus(state.balanceInUSD)
                                .toString();
                        }
                    }

                    if (
                        currency?.symbol === "goerliETH" &&
                        state.prices[i]?.symbol === "ETH"
                    ) {
                        currency.price = state.prices[i];
                        if (!isNaN(currency?.balance)) {
                            state.balanceInUSD = BigNumber(currency.balance)
                                .multipliedBy(currency?.price?.price_usd)
                                .plus(state.balanceInUSD)
                                .toString();
                        }
                    }
                }
                return currency?.assets?.length >= 1;
            });

            state.isFetching = false;
        },

        updateIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },

        updateBalance: (state, action) => {
            const { transactionType, amount, currency, isSender, fee } =
                action.payload;

            if (!isNaN(amount)) {
                if (
                    transactionType === "deposit" ||
                    (transactionType === "transfer" && !isSender) ||
                    (transactionType === "payment" && !isSender)
                ) {
                    state.currencies = state.currencies.map((curr) => {
                        if (curr._id === currency?._id) {
                            if (!curr.balance) {
                                curr.balance = amount;
                            } else {
                                curr.balance = BigNumber(curr.balance)
                                    .plus(amount)
                                    .toString();
                            }

                            if (curr?.price?.price_usd) {
                                state.balanceInUSD = BigNumber(amount)
                                    .multipliedBy(curr?.price?.price_usd)
                                    .plus(state.balanceInUSD)
                                    .toString();
                            }
                        }
                        return curr;
                    });
                } else if (
                    transactionType === "withdraw" ||
                    (transactionType === "transfer" && isSender) ||
                    (transactionType === "payment" && isSender)
                ) {
                    state.currencies = state.currencies.map((curr) => {
                        if (curr._id === currency?._id) {
                            let total = BigNumber(amount).plus(fee);
                            curr.balance = BigNumber(curr.balance)
                                .minus(total)
                                .toString();

                            if (curr?.price?.price_usd) {
                                let diifAmount = BigNumber(amount).multipliedBy(
                                    curr?.price?.price_usd
                                );
                                state.balanceInUSD = BigNumber(
                                    state.balanceInUSD
                                )
                                    .minus(diifAmount)
                                    .toString();
                            }
                        }
                        return curr;
                    });
                }
            }
        },
    },
});

export const { setCurrencies, updateIsFetching, updateBalance } =
    currenciesSlice.actions;

export default currenciesSlice.reducer;
