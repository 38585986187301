import React from "react";

export default function Footer() {
  return (
    <div className="bg-[#f6f6f6] pb-5">
      <div className="max-w-[1240px] mx-auto px-5 pt-5 flex items-center gap-[10px] justify-between">
        <span className="text-[13px] text-grayColor">
          Copyright © {new Date().getFullYear()} SimplePay.
        </span>
        <ul className="flex items-center gap-[18px] text-[13px] text-grayColor">
          <li>
            <span>Privacy</span>
          </li>
          <li>
            <span>Terms</span>
          </li>
          <li>
            <span>Cookie</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
