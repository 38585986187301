import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "../../../axios";

export default function IssueInvoiceModal({
    setIsIssueInvoiceModalOpen,
    invoice,
    acceptedCurrencies,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const { accountId, jwtToken } = useSelector((state) => state.user);
    const { selectedCustomer, productsRows, totalProductPrice } = useSelector(
        (state) => state.invoices
    );

    const navigate = useNavigate();

    const handleCreateInvoice = async () => {
        try {
            setIsLoading(true);
            setError("");

            let products = productsRows?.filter((row) => {
                if (row?._id) {
                    return {
                        quantity: row.quantity,
                        name: row?.selectedProduct?.name,
                        description: row?.selectedProduct?.description,
                        price: row?.selectedProduct?.price,
                    };
                }
                return "";
            });

            await axios.post(
                "/invoices/create",
                {
                    ...invoice,
                    accountId,
                    products,
                    customerId: selectedCustomer?._id,
                    amount: totalProductPrice,
                    acceptedCurrencies,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            navigate("/invoices");
        } catch (err) {
            console.log(err);
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    return (
        <div
            id="invoice-issue-modal"
            className="fixed inset-0 bg-[#fff5] dark:bg-[#0005] z-10 flex items-center justify-center p-4"
            onClick={(e) => {
                if (
                    e.target === document.getElementById("invoice-issue-modal")
                ) {
                    setIsIssueInvoiceModalOpen(false);
                }
            }}
        >
            <div className="bg-[#fff] dark:bg-darkCardColor max-h-[100%] sm:max-h-[90%] overflow-y-auto w-[100%] sm:max-w-[400px] rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
                <div className="flex items-center justify-between gap-[10px] p-5 border-b dark:border-darkBorderColor">
                    <h1 className="font-[600] capitalize">Issue Invoice</h1>
                    <button
                        className="p-0 bg-transparent h-auto text-textColor dark:text-darkTextColor text-xl"
                        onClick={() => setIsIssueInvoiceModalOpen(false)}
                        type="button"
                    >
                        <IoMdClose />
                    </button>
                </div>
                <div className="p-5">
                    <div className="flex items-start gap-[10px] mb-[12px]">
                        <input
                            type="checkbox"
                            className="w-[16px] h-[16px] min-w-[16px] min-h-[16px]"
                            id="inv-check"
                            checked={true}
                        />
                        <label htmlFor="inv-check" className="mb-0 font-normal">
                            Send Invoice and payment instructions to{" "}
                            <span className="font-medium">nihal@gmail.com</span>
                        </label>
                    </div>

                    <span className="text-sm">
                        The invoice can not be edited after issuing.
                    </span>

                    {error && (
                        <span className="text-sm text-red-500 block mt-3">
                            {error}
                        </span>
                    )}

                    <button
                        className="w-[100%] mt-4 hover:bg-btnHoverColor"
                        onClick={handleCreateInvoice}
                    >
                        {isLoading ? "Loading..." : "Submit"}
                    </button>
                </div>
            </div>
        </div>
    );
}
