import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import { sendNotification } from "../../utils";

const fetchHomeData = createAsyncThunk(
  "/user/fetchHomeData",
  async (_, { getState }) => {
    const { jwtToken, accountId } = getState().user;
    const response = await axios.get(
      `/home/activity/all?accountId=${accountId}`,
      {
        headers: { Authorization: `Bearer ${jwtToken}` },
      }
    );
    console.log(response.data);
    return response.data;
  }
);

const getJwtToken = () => {
  if (localStorage.getItem("random-string")) {
    return localStorage.getItem("random-string");
  } else {
    return "";
  }
};

const initialState = {
  user: {},
  jwtToken: getJwtToken(),
  isLoggedIn: false,
  accounts: [],
  currentAccount: {},
  accountId: localStorage.getItem("current-account") || "",
  notifications: [],
  allNotifications: [],
  totalUnReadNotifications: 0,
  isHomeDataFetching: true,
  recnetActivities: [],
  recentTransfers: [],
  geoDetails: {},
  totalPaymentsReceived: 0,
  totalPaymentsReceivedToday: 0,
  countries: [],
  merchantDetails: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem("random-string", action.payload?.jwtToken);
      state.user = action.payload?.user;
      state.jwtToken = action.payload?.jwtToken;
      state.isLoggedIn = true;
    },
    logoutUser: (state, action) => {
      localStorage.removeItem("random-string");
      state.user = {};
      state.isLoggedIn = false;
      state.jwtToken = "";
    },
    updateUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    updateIsEmailVerified: (state, action) => {
      state.user.isEmailVerified = action.payload;
    },
    updateIsMerchantInfoProvided: (state, action) => {
      state.user.isMerchantInfoProvided = action.payload;
    },
    updateIsMobileNumberVerified: (state, action) => {
      state.user.isMobileNumberVerified = action.payload;
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload;
    },
    setCurrentAccount: (state, action) => {
      state.currentAccount = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload?.accounts;
      state.currentAccount = action.payload?.account;
      state.accountId = state.currentAccount?._id;
      localStorage.setItem("current-account", state.accountId);
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload?.notifications;
      state.totalUnReadNotifications = action.payload?.totalUnReadNotifications;
    },
    setAllNotifications: (state, action) => {
      state.allNotifications = action.payload;
    },
    setNotificationAsRead: (state, action) => {
      console.log(state[action.payload.name]);
      const objIndex = state[action.payload.name].findIndex(
        (obj) => obj._id === action.payload?.notificationId
      );
      if (state[action.payload.name][objIndex].isGlobalNotification) {
        state[action.payload.name][objIndex].openedUsers?.push(
          action.payload?.userId
        );
      } else {
        state[action.payload.name][objIndex].isOpened = true;
      }
      if (state.totalUnReadNotifications >= 1) {
        state.totalUnReadNotifications -= 1;
      }
    },
    setAllNotificationAsRead: (state, action) => {
      state[action.payload.name] = state[action.payload.name].map(
        (notification) => {
          if (notification?.isGlobalNotification) {
            notification?.openedUsers?.push(action.payload?.userId);
          } else {
            notification.isOpened = true;
          }

          return notification;
        }
      );
      state.totalUnReadNotifications = 0;
    },
    addNewNotification: (state, action) => {
      state.notifications.unshift(action.payload);
      state.allNotifications.unshift(action.payload);
      state.totalUnReadNotifications += 1;
    },
    updateIsHomeDataFetching: (state, action) => {
      state.isHomeDataFetching = action.payload;
    },
    updateRecentActivities: (state, action) => {
      state.recnetActivities.unshift(action.payload);

      const {
        transactionType,
        amount,
        currency,
        receiverAddress,
        sender,
        receiver,
      } = action.payload;

      if (transactionType === "deposit") {
        sendNotification({
          title: `Deposit confirmed`,
          body: `${amount} ${currency?.symbol} deposited to your account`,
          url: `${process.env.REACT_APP_CLIENT_URL}/transactions`,
        });
      } else if (transactionType === "withdraw") {
        sendNotification({
          title: `Withdraw confirmed`,
          body: `${amount} ${currency?.symbol} withdrawed to ${receiverAddress}`,
          url: `${process.env.REACT_APP_CLIENT_URL}/transactions`,
        });
      } else if (transactionType === "transfer") {
        if (sender._id === state.user._id) {
          sendNotification({
            title: `Transaction Completed`,
            body: `${amount} ${currency?.symbol} Transferred to ${receiver?.username}`,
            url: `${process.env.REACT_APP_CLIENT_URL}/transactions`,
          });
        } else {
          sendNotification({
            title: `Payment received`,
            body: `${amount} ${currency?.symbol} received from ${sender?.username}`,
            url: `${process.env.REACT_APP_CLIENT_URL}/transactions`,
          });
        }
      } else if (transactionType === "payment") {
        if (sender._id === state.user._id) {
          sendNotification({
            title: `Payment Completed`,
            body: `${amount} ${currency?.symbol} Transferred to ${receiver?.username}`,
            url: `${process.env.REACT_APP_CLIENT_URL}/transactions`,
          });
        } else {
          sendNotification({
            title: `Payment received`,
            body: `${amount} ${currency?.symbol} received from ${sender?.username}`,
            url: `${process.env.REACT_APP_CLIENT_URL}/transactions`,
          });
          state.totalPaymentsReceivedToday += 1;
          state.totalPaymentsReceived += 1;
        }
      }
    },
    addNewAccount: (state, action) => {
      state.accounts.push(action.payload);
    },
    upgradeUserToMerchant: (state, action) => {
      state.user.isMerchantInfoProvided = true;
      state.user.type = action.payload?.type;
      state.user.name = action.payload?.name;
    },
    changePrimaryAccountId: (state, action) => {
      state.user.primaryAccountId = action.payload;
    },
    updateGeoDetails: (state, action) => {
      state.geoDetails = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },

    // new added merchant details
    setMerchantDetails: (state, { payload }) => {
      state.merchantDetails = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchHomeData.fulfilled, (state, action) => {
      state.recnetActivities = action.payload?.transactions;
      state.recentTransfers = action.payload?.recentTransfers;
      state.totalPaymentsReceived = action.payload?.totalPaymentsReceived;
      state.totalPaymentsReceivedToday =
        action.payload?.totalPaymentsReceivedToday;
      state.isHomeDataFetching = false;
    });
  },
});

export const {
  setUser,
  logoutUser,
  updateUser,
  updateIsEmailVerified,
  setCurrentAccount,
  setNotifications,
  setAccounts,
  updateIsHomeDataFetching,
  updateRecentActivities,
  updateIsMobileNumberVerified,
  updateIsMerchantInfoProvided,
  addNewAccount,
  upgradeUserToMerchant,
  changePrimaryAccountId,
  setAllNotificationAsRead,
  setNotificationAsRead,
  addNewNotification,
  setAllNotifications,
  setAccountId,
  clearRedirectionUrl,
  updateGeoDetails,
  setCountries,
  setMerchantDetails,
} = userSlice.actions;

export { fetchHomeData };

export default userSlice.reducer;
