import { MdDelete } from "react-icons/md";
import React from "react";
import axios from "../../../axios";
import { useSelector } from "react-redux";

function AdmPresalePageListTable({ presales, fetchPresales }) {
  const { jwtToken } = useSelector((state) => state.admin);

  const deletePresale = async (id) => {
    try {
      const res = await axios.get(`/admin/presale/delete/${id}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      fetchPresales();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="w-[100%]">
          <thead className="text-left bg-[#f6f6f6] dark:bg-darkCardColor">
            <tr className="text-[13px]">
              <th className="p-[12px] font-[600]">No</th>
              <th className="p-[12px] font-[600]">ID</th>
              <th className="p-[12px] font-[600]">Token Name</th>
              <th className="p-[12px] font-[600]">Token Symbol</th>
              <th className="p-[12px] font-[600]">Total Supply</th>
              <th className="p-[12px] font-[600]">Actions</th>
            </tr>
          </thead>
          <tbody>
            {presales?.map((item, index) => {
              return (
                <tr
                  className="border-b  dark:border-darkBorderColor text-[13px] hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                  key={index}
                >
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {index + 1}
                  </td>

                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.id}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.token_name}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.token_symbol}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.total_supply}
                  </td>
                  <td
                    className="px-[12px] text-xl text-red-500 cursor-pointer py-[19px] max-w-max min-w-[100px]"
                    onClick={() => {
                      deletePresale(item?.id);
                    }}
                  >
                    <MdDelete />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdmPresalePageListTable;
