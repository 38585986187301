import React, { useEffect, useState } from "react";
import axios from "../../../axios";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "../../../components";
import { useNavigate } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { PresalePageFilter } from "../../../features/PresalePage";
import PresalePageFilterAdm from "../../../features/admin/presale/PresalePageFilterAdm";
import AdmPresalePageListTable from "../../../features/admin/presale/AdmPresalePageListTable";

function AdmPresalePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [presales, setPresales] = useState([]);
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    totalPresales: 0,
    name: "",
    token: "",
  });

  const { jwtToken } = useSelector((state) => state.admin);

  const fetchPresales = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`/admin/presale/all`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setPresales(res.data.presales);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPresales();
  }, []);

  return (
    <div className="py-5 sm:py-6">
      <div className="mb-6">
        <div className="flex items-ceenter gap-[10px] justify-between">
          <h3 className="text-base font-[600]">Presale Pages</h3>
          {/* <div className="flex items-center gap-[15px]">
            <button
              className="bg-in hover:bg-btnHoverColor"
              onClick={() => {
                // setAdminAddModal(true);
              }}
            >
              + Add Presale
            </button>
          </div> */}
        </div>
        <div
          className={
            "sm:mt-3 z-20 sm:z-1 bg-white sm:bg-[#f6f6f6] dark:bg-darkCardColor sm:rounded "
          }
        >
          <PresalePageFilterAdm
            setFilters={setFilters}
            filters={filters}
            fetchPresales={fetchPresales}
          />
        </div>
      </div>

      <div>
        {isLoading ? (
          <div className="flex items-center justify-center h-[200px]">
            <div className="w-[30px] h-[30px] border-4 border-primaryColor border-r-transparent rounded-full animate-spin "></div>
          </div>
        ) : !presales || presales?.length < 1 ? (
          <div className="flex flex-col items-center justify-center h-[200px]">
            <div className="text-3xl bg-[#f6f6f6] h-[50px] w-[50px] rounded flex items-center justify-center text-grayColor ">
              <BiSearch />
            </div>
            <span className="block text-xl font-[600] mt-3">
              No Result found
            </span>
            <span className="block text-sm mt-2 text-grayColor">
              There are no Payment Pages created yet!!.
            </span>
          </div>
        ) : (
          <div>
            <AdmPresalePageListTable
              presales={presales}
              fetchPresales={fetchPresales}
            />

            <Pagination
              limit={filters.limit}
              skip={filters.skip}
              total={filters.totalPresales}
              incOrDecSkip={(number) =>
                setFilters({
                  ...filters,
                  skip: number,
                })
              }
              updateSkip={(skip) =>
                setFilters({
                  ...filters,
                  skip: skip,
                })
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AdmPresalePage;
