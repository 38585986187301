import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { Link, Outlet, useLocation } from "react-router-dom";
import { settingsNavLinks } from "../data";

export default function Settings() {
    const location = useLocation();

    return (
        <div className="flex gap-[20px] lg:gap-[50px]">
            <div className="hidden sm:block sticky top-[5em] h-[calc(100vh-5em)] min-w-[200px] lg:min-w-[250px] pt-6 ">
                <ul>
                    {settingsNavLinks?.map((nav, index) => {
                        return (
                            <li className="" key={index}>
                                <Link to={nav?.link}>
                                    <span
                                        className={
                                            "flex items-center gap-[5px] py-3 text-[13px] transition-all " +
                                            (location.pathname === nav.link
                                                ? "text-primaryColor font-[600]"
                                                : "font-medium ")
                                        }
                                    >
                                        <span className="flex items-center gap-[10px]">
                                            <span className="text-sm">{nav.icon}</span> {nav?.name}
                                        </span>{" "}
                                        {location.pathname === nav.link && (
                                            <span className="text-lg">
                                                <BiChevronRight />
                                            </span>
                                        )}
                                    </span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>

            <div className="w-full py-6 mb-10">
                <Outlet />
            </div>
        </div>
    );
}
