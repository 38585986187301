import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import axios from "../../../axios";
import { useSelector } from "react-redux";
import EditNetWorkModal from "./EditNetWorkModal";

function NetworkListTable({ networks, fetchNetworks }) {
  const { jwtToken } = useSelector((state) => state.admin);
  const [networkEditModal, setNetworkEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const deleteNetwork = async (id) => {
    try {
      const res = await axios.delete(`/admin/networks/delete/${id}`, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      fetchNetworks();
    } catch (error) {
      console.log(error);
    }
  };

  console.log(networkEditModal, "sho");

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="w-[100%]">
          <thead className="text-left bg-[#f6f6f6] dark:bg-darkCardColor">
            <tr className="text-[13px]">
              <th className="p-[12px] font-[600]">No</th>
              <th className="p-[12px] font-[600]">Name</th>
              <th className="p-[12px] font-[600]">RPC URL</th>
              <th className="p-[12px] font-[600]">Token Standard</th>
              <th className="p-[12px] font-[600]">Network VM</th>
              <th className="p-[12px] font-[600]">Actions</th>
            </tr>
          </thead>
          <tbody>
            {networks?.map((item, index) => {
              return (
                <tr
                  onClick={() => {
                    setNetworkEditModal(true);
                    setSelectedItem(item);
                  }}
                  className="border-b cursor-pointer dark:border-darkBorderColor text-[13px] hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor"
                  key={index}
                >
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {index + 1}
                  </td>

                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.name}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.rpcUrl}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.tokenStandard}
                  </td>
                  <td className="px-[12px] py-[19px] max-w-max min-w-[200px]">
                    {item?.networkVM}
                  </td>
                  <td
                    className="px-[12px] text-xl text-red-500 cursor-pointer py-[19px] max-w-max min-w-[100px]"
                    onClick={() => {
                      deleteNetwork(item?._id);
                    }}
                  >
                    <MdDelete />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {networkEditModal && (
        <EditNetWorkModal
          setNetworkEditModal={setNetworkEditModal}
          fetchNetworks={fetchNetworks}
          networks={selectedItem}
        />
      )}
    </div>
  );
}

export default NetworkListTable;
