import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    theme: "light",
    isPaymentModalOpen: false,
    isKycModalOpen: false,
    isCreateAccountModalOpen: false,
    isEmailVerifyModalOpen: false,
    isCountriesModalOpen: false,
    isMobileSidebarOpen: false,
    isErrorAndReloadModalOpen: false,
};

export const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setIsCreateAccountModalOpen: (state, action) => {
            state.isCreateAccountModalOpen = action.payload;
        },
        updateTheme: (state, action) => {
            if (action.payload === "dark") {
                document.documentElement.classList.add("dark");
                document.body.style.background = "#1a1d21";
                document.body.style.color = "#f6f6f6";
            } else {
                document.documentElement.classList.remove("dark");
                // document.body.style.background = "#f8f8fb";
                document.body.style.background = "#fff";
                document.body.style.color = "#24272a";
            }
            state.theme = action.payload;
        },
        setIsPaymentModalOpen: (state, action) => {
            state.isPaymentModalOpen = action.payload;
        },
        setIsKycModalOpen: (state, action) => {
            state.isKycModalOpen = action.payload;
        },
        setIsEmailVerifyModalOpen: (state, action) => {
            state.isEmailVerifyModalOpen = action.payload;
        },
        setIsCountriesModalOpen: (state, action) => {
            state.isCountriesModalOpen = action.payload;
        },
        setIsMobileSidebarOpen: (state, action) => {
            state.isMobileSidebarOpen = action.payload;
        },
        setIsErrorAndReloadModalOpen: (state, action) => {
            state.isErrorAndReloadModalOpen = action.payload;
        },
    },
});

export const {
    setIsCreateAccountModalOpen,
    updateTheme,
    setIsPaymentModalOpen,
    setIsKycModalOpen,
    setIsEmailVerifyModalOpen,
    setIsCountriesModalOpen,
    setIsMobileSidebarOpen,
    setIsErrorAndReloadModalOpen,
} = layoutSlice.actions;

export default layoutSlice.reducer;
