const formatPrice = (balance) => {
    if (isNaN(balance)) {
        return "0.0";
    }

    const real = String(balance)?.split(".")[0];
    const decimal = String(balance)?.split(".")[1]?.slice(0, 3);

    const formatedNumber = real + (decimal?.length > 0 ? `.${decimal}` : "");

    return formatedNumber;
};

export default formatPrice;
