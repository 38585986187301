import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useSelector } from "react-redux";

export default function PaymentCurrenySelect({
    selectedCurrency,
    setSelectedCurrency,
}) {
    const [isCurrencyDropdownOpen, setIsCurrencyDropdownOpen] = useState("");

    const { isFetching, currencies } = useSelector((state) => state.currencies);

    return (
        <div className="relative h-full">
            {isFetching ? (
                <div className="flex items-center gap-[7px] cursor-not-allowed bg-[#f6f6f6] dark:bg-darkBgColor h-[100%] px-3">
                    <div className="w-[25px] h-[25px] min-w-[25px] min-h-[25px] rounded-full bg-slate-300 dark:bg-slate-600 animate-pulse"></div>
                    <span className="block h-[14px] w-[30px] bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
                    <span>
                        <FiChevronDown />
                    </span>
                </div>
            ) : (
                <div
                    className="flex items-center gap-[7px] cursor-pointer bg-[#f6f6f6] dark:bg-darkBgColor h-[100%] px-3"
                    onClick={() =>
                        setIsCurrencyDropdownOpen(!isCurrencyDropdownOpen)
                    }
                >
                    <div className="w-[25px] h-[25px] min-w-[25px] min-h-[25px] overflow-hidden rounded-full">
                        <img
                            src={
                                process.env?.REACT_APP_SERVER_URL +
                                selectedCurrency?.logo
                            }
                            alt=""
                            className="w-[100%] h-[100%] object-fit"
                        />
                    </div>
                    <span className="font-medium text-[14px]">
                        {selectedCurrency?.symbol}
                    </span>
                    <span>
                        <FiChevronDown />
                    </span>
                </div>
            )}

            {isCurrencyDropdownOpen && (
                <div className="absolute z-10 left-0 top-[100%] transition-all bg-white dark:bg-darkBgColor min-w-[100%] shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] ">
                    {currencies?.map((currency, index) => {
                        return (
                            <div
                                key={index}
                                className="flex items-center gap-[10px] px-3 py-2 transition-all hover:bg-[#f6f6f6] hover:bg-darkCardHoverColor cursor-pointer "
                                onClick={() => {
                                    setSelectedCurrency(currency);
                                    setIsCurrencyDropdownOpen(false);
                                }}
                            >
                                <div className="w-[25px] h-[25px] rounded-full overflow-hidden">
                                    <img
                                        src={
                                            process.env.REACT_APP_SERVER_URL +
                                            currency?.logo
                                        }
                                        alt=""
                                        className="w-[100%] h-[100%] object-cover"
                                    />
                                </div>
                                <span className="text-[14px] font-medium">
                                    {currency.symbol}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
