import React, { useState } from "react";
import { VscClose } from "react-icons/vsc";
import axios from "../../../axios";
import { BtnLoader } from "../../../components";
import { useSelector } from "react-redux";

function AddModal({ setAdminAddModal, fetchAdmins }) {
  const [error, setError] = useState("");
  const [data, setData] = useState({
    email: "",
    password: "",
    role: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { jwtToken } = useSelector((state) => state.admin);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const res = await axios.post("/admin/auth/new", data, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      setError("");
      setIsLoading(false);
      fetchAdmins();
      setAdminAddModal(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.response.data.error || "somthing went wrong");
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <div className="fixed inset-0 bg-[#fff5] dark:bg-[#0005] flex items-center justify-center z-20 sm:px-5">
      <div className="bg-[#fff] dark:bg-darkCardColor w-[100%] sm:max-w-[450px] h-[100vh] sm:h-[85vh] overflow-y-auto rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
        <div className="flex items-center justify-between border-b border-borderColor dark:border-[#4c4e53] px-4 py-3">
          <h3 className="font-medium">Create Admin</h3>
          <button
            className="bg-transparent text-textColor dark:text-darkTextColor p-0 text-xl"
            onClick={() => {
              setAdminAddModal(false);
            }}
          >
            <VscClose />
          </button>
        </div>
        <form action="" className="p-5" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="">Email Address*</label>
            <input
              type="text"
              placeholder="Enter Eamil Address"
              name="email"
              value={data.email}
              onChange={handleOnChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="">Password*</label>
            <input
              type="text"
              placeholder="Enter Password Credentials"
              name="password"
              value={data.password}
              onChange={handleOnChange}
            />
          </div>
          <div className="mt-4 sm:mt-0">
            <label htmlFor="">Role</label>
            <select
              name="role"
              id=""
              className="w-[100%]"
              onChange={handleOnChange}
              value={data.role || ""}
            >
              <option value="">Select Role</option>
              <option value="super-admin">Super Admin</option>
              <option value="admin">Admin</option>
            </select>
          </div>

          {error && (
            <span className="text-[13px] font-medium text-red-500 block mt-3">
              {error}
            </span>
          )}

          <div className="pt-6">
            <button className="w-[100%] disabled:opacity-80">
              {isLoading ? <BtnLoader /> : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddModal;
