const getBalance = (balances, currencyId) => {
    let balance = 0;
    for (let i = 0; i < balances?.length; i++) {
        if (balances[i].currency === currencyId) {
            balance = balances[i]?.balance || 0;
            break;
        }
    }

    return balance;
};

export default getBalance;
