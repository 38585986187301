import React, { useState } from "react";
import { useEffect } from "react";
import { VscClose } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import axios from "../../../axios";
import {
  BtnLoader,
  PaymentAcceptedCurrency,
  PaymentCurrenySelect,
} from "../../../components";
import { addNewPaymentLink } from "../../../redux/slices/paymentLinksSlice";

export default function CreatePaymentLinkModal({
  setIsCreatePaymentLinkModalOpen,
}) {
  const { jwtToken, currentAccount } = useSelector((state) => state.user);
  const { currencies } = useSelector((state) => state.currencies);

  const [data, setData] = useState({
    amount: "",
    expiryDate: "",
    paymentFor: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isNoExpiry, setIsNoExpiry] = useState(false);
  const [error, setError] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [acceptedCurrencies, setAcceptedCurrencies] = useState([]);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setIsLoading(true);
      setError("");

      const response = await axios.post(
        "/payment-links/create",
        {
          ...data,
          currencyId: selectedCurrency?._id,
          accountId: currentAccount?._id,
          acceptedCurrencies,
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      dispatch(addNewPaymentLink(response.data));
      setIsLoading(false);
      setIsCreatePaymentLinkModalOpen(false);
    } catch (err) {
      setError(err?.response?.data?.error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedCurrency(currencies[0]);
  }, []);

  return (
    <div
      className="fixed inset-0 bg-[#fff5] dark:bg-[#0005] flex items-center justify-center z-20 sm:px-5"
      id="create-page-link-modal"
      onClick={(e) => {
        if (e.target === document.getElementById("create-page-link-modal")) {
          setIsCreatePaymentLinkModalOpen(false);
        }
      }}
    >
      <div className="bg-[#fff] dark:bg-darkCardColor w-[100%] sm:max-w-[450px] h-[100vh] sm:h-[85vh] overflow-y-auto rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
        <div className="flex items-center justify-between border-b border-borderColor dark:border-[#4c4e53] px-4 py-3">
          <h3 className="font-medium">Create Payment Link</h3>
          <button
            className="bg-transparent text-textColor dark:text-darkTextColor p-0 text-xl"
            onClick={() => setIsCreatePaymentLinkModalOpen(false)}
          >
            <VscClose />
          </button>
        </div>
        <form action="" className="p-5" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="">Amount in {selectedCurrency?.symbol} *</label>
            <div className="flex items-center border dark:border-darkBorderColor overflow-hidden rounded h-[45px]">
              <PaymentCurrenySelect
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
              />
              <input
                type="text"
                className="arrow-hidden h-full border-0 rounded-none"
                placeholder="0.00"
                name="amount"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="mt-4">
            <label htmlFor="">Accepted Currencies *</label>

            <PaymentAcceptedCurrency
              setAcceptedCurrencies={setAcceptedCurrencies}
              acceptedCurrencies={acceptedCurrencies}
            />
          </div>

          <div className="mt-4">
            <label htmlFor="">Payment For *</label>
            <input
              type="text"
              placeholder="Enter Payment Description"
              name="paymentFor"
              onChange={handleChange}
            />
          </div>

          <div className="mt-4">
            <label htmlFor="">Link Expiry *</label>
            <div className="flex items-center gap-[10px] mb-2">
              <input
                type="checkbox"
                className="w-[15px] h-[15px]"
                name="no-expiry"
                id="no-expiry"
                onChange={(e) => {
                  setIsNoExpiry(e.target.checked);
                  if (e.target.checked === true) {
                    setData({ ...data, expiryDate: "" });
                  }
                }}
              />
              <label htmlFor="no-expiry" className="mb-0">
                No Expiry
              </label>
            </div>
            <input
              type="date"
              disabled={isNoExpiry}
              name="expiryDate"
              onChange={handleChange}
              value={data.expiryDate || ""}
              min={new Date().toISOString().split("T")[0]}
            />
          </div>

          <span className="block mt-6 text-[13px] text-grayColor dark:text-darkGrayColor leading-[24px]">
            This is links is only for one payment request. If you want multiple
            payment in one link, then try{" "}
            <Link
              to="/payment-pages"
              className="font-medium text-primaryColor underline"
            >
              payment page
            </Link>
          </span>

          {error && (
            <span className="text-[13px] font-medium text-red-500 block mt-3">
              {error}
            </span>
          )}

          <div className="mt-4 flex flex-col sm:flex-row items-center gap-[10px]">
            <button
              className="w-[100%] bg-transparent text-textColor dark:text-darkTextColor border border-[#ced4da] dark:border-[#4c4e53] order-2 sm:order-none"
              type="button"
              onClick={() => setIsCreatePaymentLinkModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="w-[100%] disabled:opacity-80"
              disabled={
                !data.amount ||
                !data.paymentFor ||
                (isNoExpiry === false && !data.expiryDate) ||
                isLoading ||
                acceptedCurrencies.length < 1
              }
            >
              {isLoading ? <BtnLoader /> : "Create Payment Link"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
