import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import { KycModal } from "../../components";
import { setIsKycModalOpen } from "../../redux/slices/layoutSlice";
import { useParams } from "react-router-dom";

export default function KycVerificationPage() {
  const { isKycModalOpen } = useSelector((state) => state.layout);
  const dispatch = useDispatch();

  const [kyc, setKyc] = useState({});

  const { jwtToken } = useSelector((state) => state.user);

  const findsingleKyc = async () => {
    try {
      const res = await axios.get(`/kyc/single`, {
        headers: { Authorization: ` Bearer ${jwtToken}` },
      });
      setKyc(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    findsingleKyc();
  }, []);

  return (
    <div>
      <div className="max-w-[450px]">
        {!kyc ? (
          <div>
            <h1 className="text-lg font-[600] mb-3">KYC Verification</h1>
            <span className="block text-[14px] mb-4 leading-[28px] text-grayColor dark:text-darkGrayColor">
              Please ensure your details are correct and verified for a seamless
              experience.
            </span>
            <button
              className="hover:bg-blueHoverColor"
              onClick={() => dispatch(setIsKycModalOpen(true))}
            >
              Click here for Verification
            </button>
            <div className="w-[60%] mx-auto mt-4">
              <img
                src="https://themesbrand.com/skote-symfony/layouts/assets/images/verification-img.png"
                alt=""
                className="w-[100%] h-[100%] object-cover"
              />
            </div>
          </div>
        ) : (
          <div className="w-full max-w-md text-gray-800">
            <h2 className="text-xl font-semibold mb-4 dark:text-white">
              KYC Details
            </h2>
            <ul className="space-y-3 list-none">
              <li className="flex justify-between dark:text-grayColor">
                <strong>First Name:</strong> <span>{kyc.firstName}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>Last Name:</strong> <span>{kyc.lastName}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>Date of Birth:</strong>{" "}
                <span>{new Date(kyc.date_of_birth).toLocaleDateString()}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>Gender:</strong> <span>{kyc.gender}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>PAN Number:</strong> <span>{kyc.pan_number}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>Phone:</strong> <span>{kyc.phone}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>Address:</strong> <span>{kyc.address}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>City:</strong> <span>{kyc.city}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>Country:</strong> <span>{kyc.country}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>Pincode:</strong> <span>{kyc.pincode}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>CST No:</strong> <span>{kyc.CSTNo}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>Vat/Tin:</strong> <span>{kyc.VatOrTin}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>Company UIN:</strong> <span>{kyc.company_UIN}</span>
              </li>
              <li className="flex justify-between dark:text-grayColor">
                <strong>KYC Status:</strong>{" "}
                <span className="bg-green-200 rounded-xl w-24 text-green-500 font-bold p-1 text-center">
                  {kyc.user?.kycStatus}
                </span>
              </li>
              <li className="mt-4">
                <img
                  src={process.env.REACT_APP_SERVER_URL + kyc.idProofImg}
                  alt="ID Proof"
                  className="w-full h-auto object-contain border rounded"
                />
              </li>
            </ul>
          </div>
        )}
      </div>
      {isKycModalOpen && <KycModal />}
    </div>
  );
}
