import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BigNumber from "bignumber.js";

import { avatarImg } from "../../../assets/images";
import axios from "../../../axios";
import { BtnLoader, ConfirmPinModal } from "../../../components";
import { updateRecentActivities } from "../../../redux/slices/userSlice";
import { updateBalance } from "../../../redux/slices/currenciesSlice";
import { formatBalance } from "../../../utils";

export default function TransferConfirmSection({
    section,
    setSection,
    selectedCurrency,
    user: receiver,
    data,
    setTransferResponse,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [txn, setTxn] = useState({ amount: "", fee: "" });
    const [isConfirmPinModalOpen, setIsConfirmPinModalOpen] = useState(false);

    const { jwtToken, currentAccount, user } = useSelector(
        (state) => state.user
    );
    const dispatch = useDispatch();

    const handleSubmit = async (pinNumber) => {
        try {
            setIsLoading(true);
            setError("");

            const response = await axios.post(
                "/transfer",
                {
                    amount: data.amount,
                    receiverId: receiver?._id,
                    currencyId: selectedCurrency?._id,
                    note: data.note,
                    accountId: currentAccount?._id,
                    pinNumber,
                },
                {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                }
            );

            setTransferResponse(response.data);

            dispatch(updateRecentActivities(response.data));
            dispatch(
                updateBalance({
                    ...response?.data,
                    isSender: user._id === response.data?.sender?._id,
                })
            );

            setIsLoading(false);
            setSection(4);
        } catch (err) {
            setError(
                err?.response?.data?.error || "Something went wrong, Try again"
            );
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!isNaN(data.amount)) {
            if (
                Number(data?.amount) + (Number(data.amount) / 100) * 0.5 >
                Number(selectedCurrency?.balance)
            ) {
                let fee = BigNumber(selectedCurrency?.balance)
                    .dividedBy(100)
                    .multipliedBy(0.5);
                setTxn({
                    amount: BigNumber(selectedCurrency?.balance)
                        .minus(fee)
                        .toString(),
                    fee: fee.toString(),
                });
            } else {
                let fee = BigNumber(data.amount)
                    .dividedBy(100)
                    .multipliedBy(0.5);
                setTxn({
                    amount: data?.amount,
                    fee: fee.toString(),
                });
            }
        }
    }, [data.amount]);

    return (
        <div className={"w-full " + (section === 3 ? "block" : "hidden")}>
            <h1 className="text-lg font-[600] text-left sm:text-center mb-4">
                You are sending
            </h1>

            <div className="mt-7">
                <div className="flex items-center justify-between bg-gradient-to-r from-[#f4f4f4] via-[#fdfdfd] to-[#f4f4f4] dark:from-darkCardColor dark:via-darkCardColor dark:to-darkCardColor min-h-[50px] py-[10px] w-[100%] px-5 rounded">
                    <span className="text-[14px] font-[600]">To</span>
                    <div className="flex items-center gap-[10px]">
                        <div className="text-right">
                            <span className="block font-[600] text-[15px]">
                                {receiver?.username}
                            </span>
                            <span className="font-[400] text-[13px] block">
                                {receiver?.email}
                            </span>
                        </div>
                        <div className="w-[30px] h-[30px] rounded-full overflow-hidden">
                            <img
                                src={
                                    receiver?.avatar
                                        ? process.env?.REACT_APP_SERVER_URL +
                                          receiver?.avatar
                                        : avatarImg
                                }
                                alt=""
                                className="w-[100%] h-[100%] object-cover"
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-gradient-to-r from-[#f4f4f4] via-[#fdfdfd] to-[#f4f4f4] dark:from-darkCardColor dark:via-darkCardColor dark:to-darkCardColor min-h-[50px] py-[10px] w-[100%] px-5 rounded mt-4">
                    <div className="flex items-center justify-between ">
                        <span className="text-[14px] font-medium">Amount</span>
                        <div className="text-right">
                            <span className="font-[600] text-base block">
                                {txn.amount} {selectedCurrency?.symbol}
                            </span>
                            <span className="text-[13px] font-medium text-grayColor dark:text-darkGrayColor">
                                &asymp; $
                                {formatBalance(
                                    Number(txn.amount) *
                                        Number(
                                            selectedCurrency?.price?.price_usd
                                        )
                                )}{" "}
                                USD
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-4 ">
                        <span className="text-[14px] font-medium">
                            Transaction fee
                        </span>
                        <div>
                            <span className="font-[600] text-sm block">
                                {txn.fee} {selectedCurrency?.symbol}
                            </span>
                            <span></span>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-5">
                        <span className="text-[14px] font-medium">Total</span>
                        <div className="text-right">
                            <span className="font-[700] text-lg block text-primaryColor">
                                {BigNumber(txn.amount).plus(txn.fee).toString()}{" "}
                                {selectedCurrency?.symbol}
                            </span>
                            <span className="text-[13px] font-medium text-grayColor dark:text-darkGrayColor">
                                &asymp; $
                                {formatBalance(
                                    (Number(txn.amount) + Number(txn.fee)) *
                                        Number(
                                            selectedCurrency?.price?.price_usd
                                        )
                                )}{" "}
                                USD
                            </span>
                        </div>
                    </div>
                </div>
                <div className="mt-10 w-[100%]">
                    {error && (
                        <span className="text-[13px] font-medium text-red-500 mb-3 block">
                            {error}
                        </span>
                    )}
                    <div className="flex items-center gap-[10px] w-[100%]">
                        <button
                            className=" h-[40px] w-[100%] bg-gray-200 dark:bg-darkCardColor text-textColor dark:text-darkTextColor disabled:bg-opacity-60 py-0"
                            type="button"
                            disabled={isLoading}
                            onClick={() => setSection(2)}
                        >
                            Previous
                        </button>
                        <button
                            className="hover:bg-btnHoverColor h-[40px] w-[100%] p-0"
                            onClick={() => setIsConfirmPinModalOpen(true)}
                        >
                            {isLoading ? <BtnLoader /> : "Confirm & Send"}
                        </button>

                        {isConfirmPinModalOpen && (
                            <ConfirmPinModal
                                handleSubmit={handleSubmit}
                                setIsConfirmPinModalOpen={
                                    setIsConfirmPinModalOpen
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
